import "firebase/compat/database";
import db from ".";
import Shipment, { MarketplaceShipment } from "../app/models/Shipment";
import { converter2 } from "./converter";

export const getAllShipment = async () => {
  return await db
    .collection("shipments")
    .get()
    .then((snaps) => {
      const shipment = snaps.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      return shipment;
    })
    .catch((error) => {
      console.error(`Error getAllShipment ${error}`);
    });
};

export const getAllShipmentParent = async () => {
  const shipment = await db
    .collection("shipments")
    .where("isParent", "==", true)
    .withConverter(converter2<Shipment>())
    .get();

  return shipment.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
};

export const getShipmentParent = async (): Promise<Shipment[]> => {
  const shipment = await db
    .collection("shipments")
    .where("isParent", "==", true)
    .where("isActive", "==", true)
    .orderBy("displayOrder", "asc")
    .withConverter(converter2<Shipment>())
    .get();

  return shipment.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
};
export const getShipmentWithoutParent = async (): Promise<Shipment[]> => {
  const shipment = await db
    .collection("shipments")
    .where("isParent", "==", true)
    .where("isActive", "==", true)
    .orderBy("name", "asc")
    .withConverter(converter2<Shipment>())
    .get();

  return shipment.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
};

export const getShipmentParentByInsName = async (nameInsensitive: string) => {
  return await db
    .collection("shipments")
    .where("isParent", "==", true)
    .where("isActive", "==", true)
    .where("shipmentNameInsensitive", "==", nameInsensitive)
    .withConverter(converter2<Shipment>())
    .limit(1)
    .get()
    .then((snaps) => {
      const shipment = snaps.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      return shipment;
    })
    .catch((error) => {
      console.error("Error getProduct By Name : ", error);
    });
};

export const getShipmentMaxShipTime = async (): Promise<
  MarketplaceShipment[]
> => {
  console.log("fetch shipmentWithMaxShipTime");
  try {
    const shipmentWithMaxShipTime = await db
      .collectionGroup("marketplaceShipments")
      .where("isActive", "==", true)
      .where("enabled", "==", true)
      .orderBy(`maxShipTime`)
      .withConverter(converter2<MarketplaceShipment>())
      .get();

    if (shipmentWithMaxShipTime.empty) {
      console.log("shipmentWithMaxShipTime empty");
      return [];
    }
    const result = await Promise.all(
      shipmentWithMaxShipTime.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      })
    );
    console.log("result shipmentWithMaxShipTime", result);
    return result;
  } catch (error) {
    console.error("Error getShipmentMaxShipTime : ", error);
    return [];
  }
};
