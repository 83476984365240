import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../layout/core";
import { useTranslation } from "react-i18next";
import ListComponent from "../NewList";
import Account from "src/app/models/Account";
import Template from "src/app/models/Template";
import { format } from "date-fns";

// Example usage
// const exampleFilters: Filter<any>[] = [
//   {
//     label: 'Name',
//     field: 'name',
//     type: FilterType.TEXT,
//     value: 'John'
//   },
//   {
//     label: 'Age',
//     field: 'age',
//     type: FilterType.GREATER,
//     value: 30
//   },
//   {
//     label: 'Created At',
//     field: 'createdAt',
//     type: FilterType.DATE_RANGE,
//     valueRange: {
//       startDate: new Date('2020-01-01'),
//       endDate: new Date('2020-12-31')
//     }
//   }
// ];

const NewListPage = () => {
  const { t } = useTranslation();
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: "New List",
      path: "/list-component",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>New List Cmponent</PageTitle>
      <ListComponent<Template>
        collection="templates"
        collectionGroup={false}
        // tabs={[
        //   {
        //     label: "All",
        //     filter: [
        //       {
        //         field: "isActive",
        //         type: "boolean",
        //         value: true,
        //         label: "inactive",
        //       },
        //     ],
        //     sort: [
        //       { field: "createdAt", label: "Dibuat Pada", direction: "asc" },
        //     ],
        //   },
        //   {
        //     label: "Unpaid",
        //     filter: [
        //       {
        //         field: "isActive",
        //         type: "boolean",
        //         value: false,
        //         label: "inactive",
        //       },
        //     ],
        //     sort: [
        //       { field: "createdAt", label: "Dibuat Pada", direction: "desc" },
        //     ],
        //   },
        // ]}
        columns={[
          {
            field: "templateName",
            title: "Nama Template",
            value: (props: any) => {
              return <p className="p-0 m-0">{props.value}</p>;
            },
          },
          {
            field: "isActive",
            title: "isActive",
            value: (props: any) => {
              return (
                <p className="p-0 m-0">
                  {props.value === true
                    ? "true"
                    : props.value === false
                    ? "false"
                    : " - "}
                </p>
              );
            },
          },
          {
            field: "category",
            title: "Kategori",
            value: (props: any) => {
              return <p className="p-0 m-0">{props.value}</p>;
            },
          },
          {
            field: "createdAt",
            title: "Dibuat pada",
            value: (props: any) => {
              return (
                <p className="p-0 m-0">
                  {props?.value?.seconds
                    ? format(
                        new Date(props?.value?.seconds * 1000),
                        "d MMM yyyy, HH:mm"
                      )
                    : " - "}
                </p>
              );
            },
          },
        ]}
        selection={{
          rowSelection: true,
          headerSelection: true,
        }}
        // action={{
        //   headerTitle: "cpba",
        //   value: (props: any) => {
        //     return (
        //       <button className="btn btn-primary m-0">button action</button>
        //     );
        //   },
        // }}
        defaultFilters={
          [
            // {
            //   field: 'isActive',
            //   value: false,
            //   label: '',
            //   type: 'boolean'
            // }
          ]
        }
        defaultSorting={[
          { field: "createdAt", label: "Dibuat Pada", direction: "asc" },
        ]}
        useFilter={[
          {
            field: "isActive",
            type: "boolean",
            label: "Status Aktif",
            value: undefined,
          },
          {
            field: "createdAt",
            type: "date-range",
            label: "Jangka Waktu Dibuat",
            value: undefined,
          },
        ]}
        searching={{
          field: "templateName",
          useFirestore: true,
          value: "",
          caseSensitive: true,
        }}
        extra={[
          {
            label: "button ekstra",
            value: (props: any) => {
              return <button className="btn btn-primary">button ekstra</button>;
            },
          },
          {
            label: "button ekstra kedua",
            value: (props: any) => {
              console.log(props, "pri");
              return (
                <button className="btn btn-primary">button ekstra kedua</button>
              );
            },
          },
          {
            label: "button ekstra ketiga",
            value: (props: any) => {
              console.log(props, "pri");
              return (
                <button className="btn btn-primary">
                  button ekstra ketiga
                </button>
              );
            },
          },
          {
            label: "button ekstra keempat",
            value: (props: any) => {
              console.log(props, "pri");
              return (
                <button className="btn btn-primary">
                  button ekstra keempat
                </button>
              );
            },
          },
        ]}
      />
    </>
  );
};

export default NewListPage;
