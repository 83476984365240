import clsx from "clsx";
import { format, set } from "date-fns";
import firebase from "firebase/compat/app";
import moment from "moment";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Timestamp } from "../../../../../db";
import { createRef } from "../../../../../db/connection";
import { RootState } from "../../../../../setup/redux/store";
import "../../../../../styles/css/margin.scss";
import { RepetitionConfig, ScheduleConfig } from "../../../../models/Campaign";
import * as ReduxCampaign from "../../../../modules/campaign/redux/CampaignSlice";
import * as ReduxTemplate from "../../../../modules/template/redux/TemplateSlice";
import CampaignDaySelect from "./CampaignDaySelect";
import CampaignMonthSelect from "./CampaignMonthSelect";

interface SubmissionProps {
  onChanges: (params: { key: string; value: any }[]) => void;
  //   onSubmitCampaign: () => void;
}

const CampaignSubmission: FC<SubmissionProps> = (props) => {
  const { i18n, t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { onChanges } = props;

  // Date
  let todayDate = new Date();
  const dateString = format(todayDate, "yyyy-MM-dd");
  const timeString =
    ("0" + todayDate.getHours()).slice(-2) +
    ":" +
    ("0" + todayDate.getMinutes()).slice(-2);
  // console.log("the time is: "+ timeString );

  // Account, Company, User, etc.
  const RCaccountID = useSelector(
    (state: RootState) => state.Campaign.accountID
  );

  //   Campaign
  let campaignID = useSelector(
    (state: RootState) => state.Campaign.selectedCampaignID
  );
  let selectedCampaign = useSelector(
    (state: RootState) => state.Campaign.selectedCampaign
  );

  //   Campaign Submission
  const [isScheduled, setIsScheduled] = useState<boolean>(false);
  const [selectedSubmissionType, setSelectedSubmissionType] = useState("");
  const [inputDate, setInputDate] = useState<string>("");
  const [inputTime, setInputTime] = useState<string>(timeString.toString());
  const [campaignDate, setCampaignDate] = useState<string>("");
  const [campaignTime, setCampaignTime] = useState<string>(
    timeString.toString()
  );
  const [isPastTime, setIsPastTime] = useState<boolean>(false);
  const [configSchedule, setConfigSchedule] = useState<ScheduleConfig>();

  // Redux Campaign
  let RCAccountID = useSelector((state: RootState) => state.Campaign.accountID);
  let RCCampaignData = useSelector(
    (state: RootState) => state.Campaign.campaignData
  );

  //Redux Template
  let isCreateTemplateFinish = useSelector(
    (state: RootState) => state.Campaign.campaignTemplateFinish
  );
  let RTTemplateID = useSelector(
    (state: RootState) => state.Template.templateID
  );
  let RTBodyParams = useSelector(
    (state: RootState) => state.Template.templateBodyParams
  );
  let RTHeaderParams = useSelector(
    (state: RootState) => state.Template.templateHeaderParams
  );
  let RTLanguageID = useSelector(
    (state: RootState) => state.Template.templateLanguageID
  );
  let RTGeneratedLanguage = useSelector(
    (state: RootState) => state.Template.generatedLanguage
  );
  let RTGeneratedTemplate = useSelector(
    (state: RootState) => state.Template.generatedTemplate
  );
  let RCCampaignName = useSelector(
    (state: RootState) => state.Campaign.newCampaignName
  );
  let RTName = useSelector((state: RootState) => state.Template.templateName);
  let RTCategory = useSelector(
    (state: RootState) => state.Template.templateCategory
  );
  let RCNewTemplate = useSelector(
    (state: RootState) => state.Campaign.newTemplate
  );

  let RTtes = useSelector((state: RootState) => state.Campaign);
  console.log(`YANG INI NIH`, RTtes);
  let RCtesSelectedTemplateLanguage = useSelector(
    (state: RootState) => state.Campaign.selectedTemplateLanguage
  );
  console.log(
    `INI SELECTED TEMPLATE LANGUAGE DI CAMPAIGNSUBMISSION`,
    RCtesSelectedTemplateLanguage
  );

  const dispatch = useDispatch();

  const selectedAccountId =
    RCCampaignData.accountId !== undefined || ""
      ? RCCampaignData.accountId.toString()
      : RCAccountID;

  useEffect(() => {
    let temp_data = Object.assign({}, RCNewTemplate, {
      category: RTCategory,
      templateName: RTName,
    });
    dispatch(ReduxCampaign.setCampaignData(temp_data));
    dispatch(ReduxCampaign.setNewTemplate(temp_data));
  }, [RTCategory, RTCategory]);

  useEffect(() => {
    if (campaignID && selectedCampaign && selectedCampaign.campaignSchedule) {
      setSelectedSubmissionType(selectedCampaign.campaignSchedule);
      if (selectedCampaign.campaignSchedule.toLowerCase() === "scheduled") {
        setIsScheduled(true);
        // Convert Scheduled At to String aand set to input element
        if (selectedCampaign.scheduledAt) {
          const scheduledAt = selectedCampaign.scheduledAt.toMillis();
          const scheduledDate = new Date(scheduledAt);
          const scheduledDateString = `${scheduledDate.getFullYear()}-${scheduledDate.getMonth()}-${scheduledDate.getDate()}`;

          setInputDate(scheduledDate.toISOString().slice(0, 10));
          setInputTime(scheduledDate.toTimeString().slice(0, 7));
          setCampaignDate(scheduledDate.toISOString().slice(0, 10));
          setCampaignTime(scheduledDate.toTimeString().slice(0, 5));
          console.log(
            "isi dari inputDate dan inputTime adalah: " +
              campaignDate +
              " " +
              campaignTime
          );
        }
        if (selectedCampaign.scheduleConfig) {
          setConfigSchedule(selectedCampaign.scheduleConfig);
          console.log("Selected Config ", selectedCampaign.scheduleConfig);
        }
      }
    }
    if (
      selectedCampaign.status !== undefined &&
      selectedCampaign.status !== "CREATED"
    ) {
      console.log(
        "masuk ke update template yang tidak bisa diedit alias finished"
      );
      document
        .getElementById(`saveChangeNewCampaign`)
        ?.setAttribute("style", "display:none");
    } else if (
      selectedCampaign.status != undefined &&
      selectedCampaign.status === "CREATED"
    ) {
    }
  }, [campaignID, selectedCampaign]);

  // Sent Now Campaign
  const onSentNowHandler = () => {
    // debugger
    setIsScheduled(false);
    setSelectedSubmissionType("sendNow");
    console.log("Perubahan Campaign Data 1 : ");
    if (isCreateTemplateFinish) {
      console.log(
        `Check onCampaign Changes >> onSentNowHandler - CampaignSubmission`
      );
      onChanges([
        { key: "campaignSchedule", value: "sendNow" },
        { key: "scheduledAt", value: Timestamp.now() },
        { key: "templateId", value: RTTemplateID },
        { key: "template", value: RTGeneratedTemplate },
        { key: "language", value: RTGeneratedLanguage },
        { key: "bodyParam", value: RTBodyParams },
        { key: "header", value: RTHeaderParams },
        { key: "campaignName", value: RCCampaignName },
        { key: "templateState", value: "Save" },
      ]);
    } else {
      console.log(
        `Check onCampaign Changes >> onSentNowHandler - CampaignSubmission`
      );
      onChanges([
        { key: "campaignSchedule", value: "sendNow" },
        { key: "scheduledAt", value: Timestamp.now() },
        // {key: "createStatus", value: "CREATED"}
      ]);
    }

    resetScheduleDate();
  };

  //   Scheduled Campaign
  const onScheduledHandler = () => {
    setIsScheduled(true);
    setSelectedSubmissionType("scheduled");
    // Process Set Campaign Data Schedule
    if (isCreateTemplateFinish) {
      console.log(
        `Check onCampaign Changes >> OnSchedulerHandler - CampaignSubmission`
      );
      onChanges([
        { key: "campaignSchedule", value: "scheduled" },
        { key: "scheduledAt", value: Timestamp.now() },
        { key: "templateId", value: RTTemplateID },
        { key: "template", value: RTGeneratedTemplate },
        { key: "language", value: RTGeneratedLanguage },
        { key: "bodyParam", value: RTBodyParams },
        { key: "header", value: RTHeaderParams },
        { key: "campaignName", value: RCCampaignName },
        { key: "templateState", value: "Save" },
      ]);
    }

    console.log("Perubahan Campaign Data 2 : ");
    resetScheduleDate();
  };

  //   On Schedule Time Change
  const onScheduleChanges = (event: any) => {
    let insertedDate: string;
    let insertedTime: string;
    // Get Value
    if (event.target.id === "scheduleDate") {
      insertedDate = event?.target.value;
      console.log("isi dari insertedDate: ", insertedDate);
      setInputDate(insertedDate);
      if (insertedDate <= dateString && inputTime < timeString) {
        setIsPastTime(true);
      } else {
        setIsPastTime(false);
      }
    } else {
      insertedTime = `${event?.target.value}:00`;
      setInputTime(insertedTime);

      // logic for checking the time
      if (
        insertedTime < timeString &&
        (inputDate === dateString || inputDate <= dateString)
      ) {
        console.error("waktu yang diinput kurang dari saat ini");
        console.log("waktu saat ini adalah : ", Timestamp.now().seconds);
        setIsPastTime(true);
        onChanges([
          { key: "campaignSchedule", value: "sendNow" },
          {
            key: "scheduledAt",
            value: { seconds: Timestamp.now().seconds, nanoseconds: 0 },
          },
          // {key: "createStatus", value: "CREATED"}
        ]);
      } else if (insertedTime >= timeString && inputDate === dateString) {
        console.log("waktu yang diinput sudah benar");
        setIsPastTime(false);
        // document.getElementById(`nextStep`)?.classList.remove("disabled");
        // document.getElementById(`saveChangeNewCampaign`)?.removeAttribute("disabled");
      }
    }
  };

  const resetScheduleDate = () => {
    setInputDate("");
    setInputTime("");
  };

  useEffect(() => {
    console.log("IN USE EFFECT SEND NOW >>>");

    console.log("Perubahan Campaign Data 3 : ");
    if (selectedSubmissionType !== "") {
      document.getElementById(`nextStep`)?.classList.remove("disabled");
      if (selectedSubmissionType === "scheduled" && campaignID == "") {
        document.getElementById(`nextStep`)?.classList.add("disabled");
        document
          .getElementById(`saveChangeNewCampaign`)
          ?.setAttribute("disabled", "true");
        const campaignScheduled = RCCampaignData.schedule;
        console.log(
          "isi dari campaignScheduledAt 1: " +
            JSON.stringify(RCCampaignData.scheduledAt)
        );
        if (campaignScheduled !== undefined) {
          console.log("masuk di if campaignScheduled tidak undefined");

          const campaignScheduleStart = Object.values(campaignScheduled)[2];
          console.log(
            "isi dari campaignScheduledAt 2: " +
              JSON.stringify(campaignScheduleStart)
          );
          if (JSON.stringify(campaignScheduleStart) !== "null") {
            console.log("masuk ke remove disabled button save submit");
            document.getElementById(`nextStep`)?.classList.remove("disabled");
            document
              .getElementById(`saveChangeNewCampaign`)
              ?.removeAttribute("disabled");
            console.log(
              "campaing schedule type",
              typeof JSON.stringify(campaignScheduleStart)
            );
            const inputTimestamp = JSON.stringify(campaignScheduleStart);
            let datePicked;
            if (inputTimestamp.includes("seconds")) {
              const data = campaignScheduleStart.toDate();
              const datePicked = `${data.getFullYear()}-${data.getMonth()}-${data.getDate()}`;

              console.log("if seconds", data, datePicked);
            } else {
              datePicked = JSON.stringify(campaignScheduleStart).substring(
                1,
                11
              );
            }
            if (datePicked) {
              setInputDate(datePicked);
            }
            console.log("tanggal yang akan di set adalah: " + datePicked);
            document
              .getElementById("warning-schedule")
              ?.setAttribute("style", "display:none");
          }
        }
      } else {
        document.getElementById(`nextStep`)?.classList.remove("disabled");
        document
          .getElementById(`saveChangeNewCampaign`)
          ?.removeAttribute("disabled");
      }
    }
  }, [isScheduled, selectedSubmissionType, RCCampaignData]);

  useEffect(() => {
    console.log(`Inserted Date ${inputDate} ~ inserted Time ${inputTime}`);
    // Convert to Date
    const [year, month, day] = inputDate.split("-");
    const [hours, minutes, seconds] = inputTime.split(":");
    const date = new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
    console.log("isi dari inputDate dan inputTime 2: " + inputDate + inputTime);
    console.log("isi daripada date 1 : " + date);
    // Set to Campaign
    if (!isScheduled) {
      return;
    }

    console.log("masuk isscheduled");
    if (isPastTime) {
      console.log("Perubahan Campaign Data 5 : ");
      console.log(
        `Check onCampaign Changes >> useEffectInputDate & Time but Past Time - CampaignSubmission`
      );
      onChanges([
        // {key: "createStatus", value: "SCHEDULED" },
        { key: "campaignSchedule", value: "sendNow" },
        { key: "campaignName", value: RCCampaignName },
        { key: "scheduledAt", value: Timestamp.now() },
        {
          key: "schedule",
          value: {
            type: "campaign",
            status: "created",
            start: Timestamp.now(),
            account: createRef("account", selectedAccountId),
            language: i18n.language,
            isActive: true,
            // end
            // campaign: createRef("campaigns", campaignID)
          },
        },
      ]);
    } else {
      console.log("Perubahan Campaign Data 4 : ");
      console.log(
        `Check onCampaign Changes >> useEffectInputDate & Time - CampaignSubmission`
      );
      if (!configSchedule || (configSchedule && !configSchedule.isRepeat)) {
        onChanges([
          // {key: "createStatus", value: "SCHEDULED" },
          { key: "campaignSchedule", value: "scheduled" },
          { key: "campaignName", value: RCCampaignName },
          {
            key: "scheduledAt",
            value: date !== null ? Timestamp.fromDate(date) : Timestamp.now(),
          },
          {
            key: "schedule",
            value: {
              type: "campaign",
              status: "created",
              start: date !== null ? Timestamp.fromDate(date) : Timestamp.now(),
              account: createRef("account", selectedAccountId),
              language: i18n.language,
              isActive: true,
              isRepeat: false,
              // end
              // campaign: createRef("campaigns", campaignID)
            },
          },
        ]);
      } else {
        const dataSchedule: any = {
          type: "campaign",
          status: "created",
          start: date !== null ? Timestamp.fromDate(date) : Timestamp.now(),
          account: createRef("account", selectedAccountId),
          language: i18n.language,
          isActive: true,
        };

        const newScheduleConfig: ScheduleConfig = {
          isRepeat: configSchedule.isRepeat ?? false,
          isRepActive: configSchedule.isRepeat,
        };

        if (configSchedule.isRepeat && configSchedule.repeatConfig) {
          newScheduleConfig.repeatConfig = configSchedule.repeatConfig;
        }

        if (configSchedule.isRepeat && configSchedule.end) {
          newScheduleConfig.end = configSchedule.end;
        }

        onChanges([
          { key: "campaignSchedule", value: "scheduled" },
          { key: "campaignName", value: RCCampaignName },
          {
            key: "scheduledAt",
            value: date !== null ? Timestamp.fromDate(date) : Timestamp.now(),
          },
          {
            key: "schedule",
            value: {
              ...dataSchedule,
              ...newScheduleConfig,
            },
          },
          { key: "scheduleConfig", value: newScheduleConfig },
        ]);
      }
    }
  }, [inputDate, inputTime, isPastTime, configSchedule]);

  useEffect(() => {
    console.log(`Selected Campaign >> ${JSON.stringify(selectedCampaign)}`);
    console.log(
      `isi daripada RCCCampaignData 3 >> ${JSON.stringify(RCCampaignData)}`
    );
  }, [selectedCampaign]);

  useEffect(() => {
    // Convert to Date
    const [year, month, day] = inputDate.split("-");
    const [hours, minutes, seconds] = inputTime.split(":");
    const date = new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
    console.log("isi dari inputDate dan inputTime 2: " + inputDate + inputTime);
    console.log("isi daripada date 1 : " + date);
    console.log(
      "isi dari selectedAccountId : " + JSON.stringify(selectedAccountId)
    );
    //checking
    let accountID = "";
    if (selectedAccountId !== undefined) {
      accountID = selectedAccountId;
    } else {
      return;
    }
    console.log(
      `isi daripada RCCCampaignData 3 >> ${JSON.stringify(RCCampaignData)}`
    );
    console.log("isi dari accountID yang terset adalah: " + accountID);
    if (
      inputDate !== "" &&
      inputTime !== "" &&
      accountID !== "" &&
      RCCampaignData.schedule === undefined
    ) {
      console.log("isi daripada date 2: " + date);
      console.log(
        `isi daripada RCCCampaignData 4 >> ${JSON.stringify(RCCampaignData)}`
      );

      //function for add schedule field in campaignData redux

      let newSchedule: any = {
        type: "campaign",
        status: "created",
        start: Timestamp.fromDate(date),
        account: createRef("account", accountID),
        language: i18n.language,
        isActive: true,
      };
      if (configSchedule) {
        newSchedule.isRepeat = configSchedule.isRepeat;
        newSchedule.isRepActive = configSchedule.isRepActive;

        if (configSchedule.isRepeat && configSchedule.repeatConfig) {
          newSchedule.repeatConfig = configSchedule.repeatConfig;
        }

        if (configSchedule.isRepeat && configSchedule.end) {
          newSchedule.end = configSchedule.end;
        }
      }

      let newCampaignData: any = {
        ...RCCampaignData,
        schedule: newSchedule,
      };
      console.log(
        "isi dari data campaign setelah ditambah schedule adalah disini guys: " +
          JSON.stringify(newCampaignData)
      );
      dispatch(ReduxCampaign.setCampaignData(newCampaignData));

      console.log(
        "isi dari timestring dan datestring: " +
          dateString +
          " " +
          timeString +
          "\n sedangkan isi daripada inputDate dan inputTime: " +
          inputDate +
          " " +
          inputTime
      );

      if (inputDate <= dateString && inputTime <= timeString) {
        setIsPastTime(true);
        onChanges([
          { key: "campaignSchedule", value: "sendNow" },
          { key: "scheduledAt", value: Timestamp.now() },
          // {key: "createStatus", value: "CREATED"}
        ]);
      }
    }
  }, [RCCampaignData.header]);

  console.log("configSchedule", configSchedule);

  return (
    <div
      className={clsx("tab-pane fade")}
      id="kt_tab_pane_5"
      role="tabpanel"
      data-testid="tab-pane-templateSumbission"
    >
      <div
        className={clsx(
          "row justify-content-center w-100",
          isTabletOrMobile ? "flex-column gap-5" : "flex-column-fluid"
        )}
        style={{ overflowX: "hidden" }}
      >
        <div className="col"></div>
        {isTabletOrMobile && (
          <h1 className="mx-2">{t("Campaign.Tab.SubmitCampaign")}</h1>
        )}
        <div className="col">
          {/* Sent Now */}
          <button
            disabled={
              selectedCampaign.status !== undefined &&
              selectedCampaign.status !== "CREATED"
            }
            id="sendNow"
            className={clsx(
              "btn btn-active-light-primary w-100",
              selectedSubmissionType === "sendNow"
                ? "active text-primary"
                : "text-dark ",
              // selectedCampaign.status !== "CREATED" ? "disabled" : ""
              isTabletOrMobile && selectedSubmissionType !== "sendNow"
                ? "btn-secondary"
                : "btn-white",
              isTabletOrMobile ? "d-flex flex-row align-items-center" : ""
            )}
            data-testid="sendnow-button"
            role="sendnow-button"
            onClick={onSentNowHandler}
          >
            <div className="fas fa-rocket fs-custom-large"></div>
            <div
              className={clsx(
                "d-flex flex-column",
                isTabletOrMobile ? "text-start mx-5" : ""
              )}
            >
              <div className="step-sub-title">
                {t("Campaign.Button.SentNow")}
              </div>
              <div>{t("Campaign.Info.SentNow")}</div>
            </div>
          </button>
          {/* Sent Now */}
        </div>
        <div className="col">
          {/* Schedule Button */}
          <button
            disabled={
              selectedCampaign.status !== undefined &&
              selectedCampaign.status !== "CREATED"
            }
            id="scheduled"
            className={clsx(
              "btn btn-white btn-active-light-primary w-100",
              selectedSubmissionType === "scheduled"
                ? "active text-primary"
                : "text-dark ",
              // selectedCampaign.status !== "CREATED" ? "disabled" : ""
              isTabletOrMobile && selectedSubmissionType !== "scheduled"
                ? "btn-secondary"
                : "btn-white",
              isTabletOrMobile ? "d-flex flex-row align-items-center" : ""
            )}
            data-testid="scheduled-button"
            role="scheduled-button"
            onClick={onScheduledHandler}
          >
            <div className="fas fa-business-time fs-custom-large"></div>
            <div
              className={clsx(
                "d-flex flex-column",
                isTabletOrMobile ? "text-start mx-5" : ""
              )}
            >
              <div className="step-sub-title">
                {t("Campaign.Button.ScheduleCampaign")}
              </div>
              <div>{t("Campaign.Info.ScheduleCampaign")}</div>
            </div>
          </button>
          {/* Scheduled Time */}
          {isScheduled && (
            <ScheduledTime
              inputDate={inputDate}
              inputTime={inputTime}
              campaignDate={campaignDate}
              campaignTime={campaignTime}
              dateString={dateString}
              timeString={timeString}
              isPastTime={isPastTime}
              onScheduleChanges={onScheduleChanges}
              configData={configSchedule}
              onChange={(config) => {
                console.log("inputDate & inputTime", inputDate, inputTime);
                const startDate = new Date(`${inputDate} ${inputTime}:00`);
                console.log("Repeat Config", config);
                setConfigSchedule(config);

                const dataSchedule: any = {
                  type: "campaign",
                  status: "created",
                  start: Timestamp.fromDate(startDate),
                  account: createRef("account", selectedAccountId),
                  language: i18n.language,
                  isActive: true,
                };

                const newScheduleConfig: ScheduleConfig = {
                  isRepeat: config.isRepeat,
                  isRepActive: config.isRepeat,
                };

                if (config.isRepeat && config.repeatConfig) {
                  newScheduleConfig.repeatConfig = config.repeatConfig;
                }
                if (config.isRepeat && config.end) {
                  newScheduleConfig.end = config.end;
                }

                // console.log("data selected schedule , ", data);
                onChanges([
                  {
                    key: "schedule",
                    value: {
                      ...dataSchedule,
                      ...newScheduleConfig,
                    },
                  },
                  { key: "scheduleConfig", value: newScheduleConfig },
                ]);
              }}
            />
          )}
        </div>
        <div className="col"></div>
      </div>
    </div>
  );
};

export const ScheduledTime: FC<{
  onScheduleChanges: (event: any) => void;
  campaignDate: string;
  dateString: string;
  campaignTime: string;
  timeString: string;
  isPastTime: boolean;
  inputDate: string;
  inputTime: string;
  configData?: ScheduleConfig;
  onChange?: (config: ScheduleConfig) => void;
}> = ({
  onScheduleChanges,
  campaignDate,
  campaignTime,
  dateString,
  timeString,
  isPastTime,
  inputDate,
  inputTime,
  configData,
  onChange,
}) => {
  const { i18n, t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const [repetition, setRepetition] = useState<boolean>(
    configData?.isRepeat ?? false
  );
  const [dow, setDow] = useState<boolean>(
    configData?.repeatConfig?.type === "yearly" &&
      configData.repeatConfig.byDayConfig
      ? true
      : false
  );
  const [selectedMonthType, setSelectedMonthType] = useState<string>(
    configData?.repeatConfig?.type === "monthly" &&
      configData.repeatConfig.byDayConfig
      ? "onRepeat"
      : "onDate"
  );
  const [frequency, setSelectedFrequency] = useState<string>(
    configData?.repeatConfig?.type ?? "daily"
  );
  const [interval, setSelectedInterval] = useState<number>(
    configData?.repeatConfig?.interval ?? 1
  );
  const [selectedDay, setSelectedDay] = useState<number[]>(
    configData?.repeatConfig?.type === "weekly" &&
      configData?.repeatConfig.selectedByUnit
      ? configData?.repeatConfig.selectedByUnit
      : [new Date(dateString).getDay()]
  );
  const [selectedDate, setSelectedDate] = useState<number[]>(
    configData?.repeatConfig?.type === "monthly" &&
      configData?.repeatConfig.selectedByUnit
      ? configData?.repeatConfig.selectedByUnit
      : [new Date(dateString).getDate()]
  );
  const [selectedMonth, setSelectedMonth] = useState<number[]>(
    configData?.repeatConfig?.type === "yearly" &&
      configData?.repeatConfig.selectedByUnit
      ? configData?.repeatConfig.selectedByUnit
      : [new Date(dateString).getMonth()]
  );
  const [endRepeatType, setEndRepeatType] = useState<string>(
    configData?.end ? "onDate" : "never"
  );
  const [endRepeatDate, setEndRepeatDate] = useState<string>(
    configData?.end ? moment(configData.end.toDate()).format("YYYY-MM-DD") : ""
  );
  const [endRepeatTime, setEndRepeatTime] = useState<string>(
    configData?.end ? moment(configData.end.toDate()).format("HH:mm") : ""
  );
  const [endRepeatTimestamp, setEndRepeatTimestamp] = useState<
    firebase.firestore.Timestamp | undefined
  >(configData?.end);

  const [byDayConfig, setByDayConfig] = useState<{
    key: string;
    value: string;
  }>(
    configData?.repeatConfig?.byDayConfig ?? {
      key: "first",
      value: "sunday",
    }
  );

  console.log("endRepeatDate", endRepeatDate);

  // useEffect(() => {
  //   const data = configData?.end;
  //   if (data) {
  //     setEndRepeatTimestamp(data);
  //     const dataDate = data.toDate();
  //     // const endDate = `${dataDate.getFullYear()}-${dataDate
  //     //   .getMonth()
  //     //   .toString()
  //     //   .padStart(2, "0")}-${dataDate.getDate().toString().padStart(2, "0")}}`;
  //     // setEndRepeatDate(endDate);
  //     const endTime = `${dataDate.getHours()}:${dataDate.getMinutes()}`;
  //     console.log("USE EFFECT SUBMISSION --- endTime", endTime);
  //     setEndRepeatTime(endTime);
  //   }
  //   console.log("USE EFFECT SUBMISSION", data);
  // }, []);

  useEffect(() => {
    console.log(
      "disablebtn",
      inputDate,
      inputTime,
      endRepeatDate,
      endRepeatTime,
      new Date(`${inputDate} ${inputTime}:00`) >
        new Date(`${endRepeatDate} ${endRepeatTime}:00`)
    );
    if (
      endRepeatType === "onDate" &&
      inputDate &&
      inputTime &&
      (!endRepeatDate ||
        !endRepeatTime ||
        (!endRepeatDate && !endRepeatTime) ||
        new Date(`${inputDate} ${inputTime}:00`) >
          new Date(`${endRepeatDate} ${endRepeatTime}:00`))
    ) {
      document.getElementById(`nextStep`)?.setAttribute("disabled", "");
    } else {
      document.getElementById(`nextStep`)?.removeAttribute("disabled");
    }
  }, [inputDate, inputTime, endRepeatDate, endRepeatTime, endRepeatType]);

  const toggleDay = (dayIndex: number) => {
    let newSelected: number[] = [];

    if (selectedDay.includes(dayIndex)) {
      newSelected = selectedDay.filter((d) => d !== dayIndex);
    } else {
      newSelected = [...selectedDay, dayIndex];
    }

    if (newSelected.length !== 0) {
      setSelectedDay(newSelected);

      if (onChange !== undefined) {
        onConfigChange({ selectedByUnit: newSelected });
      }
    }
  };

  const handleChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    let endDate;
    let endTime;
    let endString;

    if (event.target.id === "endRepeatDate") {
      endDate = event?.target.value;
      setEndRepeatDate(endDate);
      if (endRepeatTime) {
        endString = `${endDate} ${endRepeatTime}:00`;
      }
      console.log("HANDLE CANGE END DATE", endDate);
    }

    if (event.target.id === "endRepeatTime") {
      endTime = event?.target.value;
      setEndRepeatTime(endTime);
      if (endRepeatDate) {
        endString = `${endRepeatDate} ${endTime}:00`;
      }
    }

    if (onChange !== undefined && endString) {
      const newDate = new Date(endString);
      setEndRepeatTimestamp(Timestamp.fromDate(newDate));
      onConfigChange({}, repetition, Timestamp.fromDate(newDate));
    }
  };

  const dayLabels = [
    t("Campaign.ScheduleCampaign.Sunday"),
    t("Campaign.ScheduleCampaign.Monday"),
    t("Campaign.ScheduleCampaign.Tuesday"),
    t("Campaign.ScheduleCampaign.Wednesday"),
    t("Campaign.ScheduleCampaign.Thursday"),
    t("Campaign.ScheduleCampaign.Friday"),
    t("Campaign.ScheduleCampaign.Saturday"),
  ];
  const onConfigChange = (
    config: Partial<RepetitionConfig>,
    isRepeat?: boolean,
    endRepeat?: firebase.firestore.Timestamp
  ) => {
    console.log("Generate New Config");
    let newConfig: RepetitionConfig = {
      type: frequency,
      interval: interval,
      ...config,
    };

    if (
      newConfig &&
      (!newConfig.selectedByUnit || newConfig.selectedByUnit.length <= 0)
    ) {
      if (newConfig.type === "weekly") {
        newConfig.selectedByUnit = [...selectedDay];
      }
      if (newConfig.type === "monthly") {
        if (selectedMonthType === "onDate") {
          newConfig = {
            selectedByUnit: selectedDate,
            ...newConfig,
          };
          console.log("onDate", newConfig);
        }
        if (selectedMonthType === "onRepeat") {
          // newConfig.byDayConfig = { ...byDayConfig };
          newConfig = {
            byDayConfig: byDayConfig,
            ...newConfig,
          };
        }
      }
      if (newConfig.type === "yearly") {
        newConfig.selectedByUnit = [...selectedMonth];
        if (dow) {
          newConfig.byDayConfig = { ...byDayConfig };
        }
      }
    }
    if (onChange) {
      let updatedConfig: ScheduleConfig = {
        isRepeat: isRepeat !== undefined ? isRepeat : repetition,
        isRepActive:
          configData?.isRepActive !== undefined
            ? configData.isRepActive
            : isRepeat !== undefined
            ? isRepeat
            : repetition,
      };
      if (updatedConfig.isRepeat) {
        updatedConfig.repeatConfig = newConfig;
      }
      if (endRepeat) {
        updatedConfig.end = endRepeat;
      } else if (endRepeatTimestamp) {
        updatedConfig.end = endRepeatTimestamp;
      }
      console.log("config test", updatedConfig, isRepeat);
      onChange(updatedConfig);
    }
  };
  const onScheduleDateChange = (event: any) => {
    const selectedDate = new Date(event.target.value);
    // Extract day, date, and month from the selected date
    const day = selectedDate.getDay(); // 0 for Sunday, 1 for Monday, etc.
    const date = selectedDate.getDate(); // 1-31
    const month = selectedDate.getMonth(); // 0-11
    console.log("dateSelected", day, date, month);

    // Update the state variables
    setSelectedDay([day]);
    setSelectedDate([date]);
    setSelectedMonth([month]);
  };

  let selectedCampaign = useSelector(
    (state: RootState) => state.Campaign.selectedCampaign
  );
  return (
    <div
      className={clsx(
        "d-flex flex-column py-4 gap-2",
        isTabletOrMobile ? "w-100" : "w-90"
      )}
    >
      <div className="step-sub-title ms-2">{t("BS.Info.Scheduledtime")}</div>
      <div
        className={clsx(
          "d-flex gap-3",
          isTabletOrMobile ? "flex-column" : "flex-row"
        )}
      >
        <input
          onChange={(event) => {
            onScheduleChanges(event);
            onScheduleDateChange(event);
          }}
          // onChange={onScheduleChanges}
          id="scheduleDate"
          type="date"
          className="form-control form-control-lg"
          name="scheduleDate"
          defaultValue={selectedCampaign ? campaignDate : ""}
          min={dateString}
          max={endRepeatDate ?? endRepeatDate}
          data-testid="day-scheduler"
          role="day-scheduler"
          // value={inputDate}
          disabled={
            selectedCampaign.status !== undefined &&
            selectedCampaign.status !== "CREATED"
          }
        />
        <input
          onChange={onScheduleChanges}
          id="scheduleTime"
          type="time"
          className="form-control form-control-lg"
          name="scheduleTime"
          // value={!selectedCampaign ? "" : campaignTime}
          // defaultValue={!selectedCampaign ? "" : campaignTime}
          defaultValue={selectedCampaign ? campaignTime : ""}
          // defaultValue={!selectedCampaign ? "" : inputTime}
          // defaultValue=""
          min={!selectedCampaign ? timeString : campaignTime}
          // min={timeString}
          data-testid="clock-scheduler"
          role="clock-scheduler"
          disabled={
            selectedCampaign.status !== undefined &&
            selectedCampaign.status !== "CREATED"
          }
        />
      </div>
      {(inputDate === "" || inputTime === "") && (
        <div id="warning-schedule" className="text-danger">
          * {t("BS.Error.TimeEmpty")}
        </div>
      )}
      {isPastTime && (
        <div id="warning-time" className="text-danger">
          {t("BS.Alert.PastTime")}
        </div>
      )}

      <div
        className={clsx(
          "d-flex form-check form-switch form-control form-control-lg ps-5 flex-row justify-content-between align-items-center"
        )}
      >
        <label htmlFor="repetition">
          {t("Campaign.ScheduleCampaign.RepetitionField")}
        </label>
        <input
          onChange={(e) => {
            setRepetition(e.target.checked);
            if (onChange !== undefined) {
              if (e.target.checked) {
                onConfigChange({ type: frequency }, e.target.checked);
              } else {
                onConfigChange({}, e.target.checked);
              }
            }
          }}
          checked={repetition}
          id="repetition"
          type="checkbox"
          className="form-check-input"
          role="switch"
          name="repetition"
          data-testid="repetition"
          disabled={
            selectedCampaign.status !== undefined &&
            selectedCampaign.status !== "CREATED"
          }
        />
      </div>

      {repetition && (
        <>
          <div className="step-sub-title ms-2">
            {t("Campaign.ScheduleCampaign.Title.Field.Frequency")}
          </div>
          <select
            className="form-control form-control-lg form-select"
            name="frequency"
            id="frequency"
            data-testid="frequency"
            value={frequency}
            onChange={(e) => {
              setSelectedFrequency(e.target.value);

              if (onChange !== undefined) {
                onConfigChange({ type: e.target.value });
              }
            }}
            disabled={
              selectedCampaign.status !== undefined &&
              selectedCampaign.status !== "CREATED"
            }
          >
            <option value="daily">
              {t("Campaign.ScheduleCampaign.Field.Daily")}
            </option>
            <option value="weekly">
              {t("Campaign.ScheduleCampaign.Field.Weekly")}
            </option>
            <option value="monthly">
              {t("Campaign.ScheduleCampaign.Field.Monthly")}
            </option>
            <option value="yearly">
              {t("Campaign.ScheduleCampaign.Field.Yearly")}
            </option>
          </select>

          <div className="step-sub-title ms-2">
            {t("Campaign.ScheduleCampaign.Title.Field.Every")}
          </div>
          <div
            className={clsx(
              "d-flex gap-3",
              isTabletOrMobile ? "flex-column" : "flex-row"
            )}
          >
            <input
              onChange={(e) => {
                setSelectedInterval(+e.target.value);

                if (onChange !== undefined) {
                  onConfigChange({
                    interval: e.target.value ? parseInt(e.target.value) : 1,
                  });
                }
              }}
              value={interval}
              id="interval"
              type="number" // Change the input type to "number"
              className="form-control form-control-lg"
              name="interval"
              min="1" // Define the minimum value for the number input
              max="100" // Define the minimum value for the number input
              data-testid="interval"
              role="interval"
              disabled={
                selectedCampaign.status !== undefined &&
                selectedCampaign.status !== "CREATED"
              }
            />
            <div className="form-control form-control-lg">
              {frequency === "daily" &&
                t("Campaign.ScheduleCampaign.Field.Day")}
              {frequency === "weekly" &&
                t("Campaign.ScheduleCampaign.Field.Week")}
              {frequency === "monthly" &&
                t("Campaign.ScheduleCampaign.Field.Month")}
              {frequency === "yearly" &&
                t("Campaign.ScheduleCampaign.Field.Year")}
            </div>
          </div>
        </>
      )}

      {repetition && frequency === "weekly" && (
        <>
          {/* sunday to monday checkbox */}
          <div className="mt-3">
            <div className="row px-4">
              {dayLabels.map((day, index) => (
                <div key={index} className="form-check col-6 mt-2">
                  <input
                    type="checkbox"
                    id={`day-${day}`}
                    data-testid={`day-${day}`}
                    className="form-check-input"
                    name={`day-${day}`}
                    checked={selectedDay.includes(index)}
                    onChange={() => toggleDay(index)}
                    disabled={
                      selectedCampaign.status !== undefined &&
                      selectedCampaign.status !== "CREATED"
                    }
                  />
                  <label htmlFor={`day-${day}`} className="form-check-label">
                    {day}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      {repetition && frequency === "monthly" && (
        <>
          {/* MONTHLY */}
          <div
            className={clsx(
              "d-flex gap-3",
              isTabletOrMobile ? "flex-column" : "flex-row"
            )}
          >
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="frequencyType"
                id="radioDaily"
                value="onDate"
                data-testid="radio-on-date"
                checked={selectedMonthType === "onDate"}
                onChange={(e) => {
                  setSelectedMonthType(e.target.value);
                  if (onChange !== undefined) {
                    onConfigChange({
                      selectedByUnit: selectedDate,
                      type: "monthly",
                    });
                  }
                }}
                disabled={
                  selectedCampaign.status !== undefined &&
                  selectedCampaign.status !== "CREATED"
                }
              />
              <label className="form-check-label" htmlFor="radioDaily">
                {t("Campaign.ScheduleCampaign.Monthly.Selectdate")}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="frequencyType"
                id="radioCustom"
                data-testid="radio-on-repeat"
                value="onRepeat"
                checked={selectedMonthType === "onRepeat"}
                onChange={(e) => {
                  setSelectedMonthType(e.target.value);
                  if (onChange !== undefined) {
                    onConfigChange({
                      byDayConfig: byDayConfig,
                      selectedByUnit: undefined,
                      type: "monthly",
                    });
                  }
                }}
                disabled={
                  selectedCampaign.status !== undefined &&
                  selectedCampaign.status !== "CREATED"
                }
              />
              <label className="form-check-label" htmlFor="radioCustom">
                {t("Campaign.ScheduleCampaign.Monthly.Repeaton")}
              </label>
            </div>
          </div>
          {selectedMonthType === "onDate" && (
            <CampaignDaySelect
              onChange={(values) => {
                setSelectedDate(values);

                if (onChange !== undefined) {
                  onConfigChange({ selectedByUnit: values });
                }
              }}
              selected={selectedDate}
              disabled={
                selectedCampaign.status !== undefined &&
                selectedCampaign.status !== "CREATED"
              }
            />
          )}
          {selectedMonthType === "onRepeat" && (
            <>
              <div className="step-sub-title ms-2">
                {t("Campaign.ScheduleCampaign.Title.Field.Every")}
              </div>
              <div
                className={clsx(
                  "d-flex gap-3",
                  isTabletOrMobile ? "flex-column" : "flex-row"
                )}
              >
                <select
                  className="form-control form-control-lg form-select"
                  name="getDow"
                  id="getDow"
                  data-testid="get-dow-month"
                  value={byDayConfig.key}
                  onChange={(e) => {
                    const newConfig = {
                      ...byDayConfig,
                      key: e.target.value,
                    };
                    setByDayConfig(newConfig);
                    console.log("NEWCONFIG MONTH KIRI", newConfig);

                    if (onChange !== undefined) {
                      onConfigChange({ byDayConfig: newConfig });
                    }
                  }}
                  disabled={
                    selectedCampaign.status !== undefined &&
                    selectedCampaign.status !== "CREATED"
                  }
                >
                  <option value="first">
                    {t("Campaign.ScheduleCampaign.PartofDaysofWeek.First")}
                  </option>
                  <option value="second">
                    {t("Campaign.ScheduleCampaign.PartofDaysofWeek.Second")}
                  </option>
                  <option value="third">
                    {t("Campaign.ScheduleCampaign.PartofDaysofWeek.Third")}
                  </option>
                  <option value="fourth">
                    {t("Campaign.ScheduleCampaign.PartofDaysofWeek.Fourth")}
                  </option>
                  <option value="fifth">
                    {t("Campaign.ScheduleCampaign.PartofDaysofWeek.Fifth")}
                  </option>
                  <option value="last">
                    {t("Campaign.ScheduleCampaign.PartofDaysofWeek.Last")}
                  </option>
                </select>

                <select
                  className="form-control form-control-lg form-select"
                  name="dayDow"
                  id="dayDow"
                  data-testid="day-dow-month"
                  value={byDayConfig.value}
                  onChange={(e) => {
                    const newConfig = {
                      ...byDayConfig,
                      value: e.target.value,
                    };
                    setByDayConfig(newConfig);
                    console.log("NEWCONFIG MONTH kanan", newConfig);
                    if (onChange !== undefined) {
                      onConfigChange({ byDayConfig: newConfig });
                    }
                  }}
                  disabled={
                    selectedCampaign.status !== undefined &&
                    selectedCampaign.status !== "CREATED"
                  }
                >
                  <option value="sunday">
                    {t("Campaign.ScheduleCampaign.Sunday")}
                  </option>
                  <option value="monday">
                    {t("Campaign.ScheduleCampaign.Monday")}
                  </option>
                  <option value="tuesday">
                    {t("Campaign.ScheduleCampaign.Tuesday")}
                  </option>
                  <option value="wednesday">
                    {t("Campaign.ScheduleCampaign.Wednesday")}
                  </option>
                  <option value="thursday">
                    {t("Campaign.ScheduleCampaign.Thursday")}
                  </option>
                  <option value="friday">
                    {t("Campaign.ScheduleCampaign.Friday")}
                  </option>
                  <option value="saturday">
                    {t("Campaign.ScheduleCampaign.Saturday")}
                  </option>
                  <option value="day">
                    {t("Campaign.ScheduleCampaign.Day")}
                  </option>
                  <option value="weekday">
                    {t("Campaign.ScheduleCampaign.Weekday")}
                  </option>
                  <option value="weekend">
                    {t("Campaign.ScheduleCampaign.Weekend")}
                  </option>
                </select>
              </div>
            </>
          )}
        </>
      )}

      {repetition && frequency === "yearly" && (
        <>
          {/* YEARLY */}
          <CampaignMonthSelect
            onChange={(values) => {
              setSelectedMonth(values);

              if (onChange !== undefined) {
                onConfigChange({ selectedByUnit: values });
              }
            }}
            selected={selectedMonth}
            disabled={
              selectedCampaign.status !== undefined &&
              selectedCampaign.status !== "CREATED"
            }
          />
          {/* days of week */}
          <div
            className={clsx(
              "d-flex form-check form-switch form-control form-control-lg ps-5 flex-row justify-content-between align-items-center"
            )}
          >
            <label htmlFor="dowYear">
              {t("Campaign.ScheduleCampaign.DaysofWeek")}
            </label>
            <input
              onChange={(e) => {
                setDow(e.target.checked);
                if (onChange !== undefined && e.target.checked) {
                  onConfigChange({
                    byDayConfig: byDayConfig,
                  });
                } else {
                  onConfigChange({ selectedByUnit: selectedMonth });
                }
              }}
              id="dowYear"
              type="checkbox"
              className="form-check-input"
              role="switch"
              name="dowYear"
              // value={dow}
              checked={dow}
              data-testid="dowYear"
              disabled={
                selectedCampaign.status !== undefined &&
                selectedCampaign.status !== "CREATED"
              }
            />
          </div>
          {/* if DOW */}
          {repetition && frequency === "yearly" && dow && (
            <>
              <div className="step-sub-title ms-2">
                {t("Campaign.ScheduleCampaign.Title.Field.Every")}
              </div>
              <div
                className={clsx(
                  "d-flex gap-3",
                  isTabletOrMobile ? "flex-column" : "flex-row"
                )}
              >
                <select
                  className="form-control form-control-lg form-select"
                  name="getDow"
                  id="getDow"
                  data-testid="get-dow-year"
                  value={byDayConfig.key}
                  onChange={(e) => {
                    const newConfig = {
                      ...byDayConfig,
                      key: e.target.value,
                    };
                    setByDayConfig(newConfig);

                    if (onChange !== undefined) {
                      onConfigChange({
                        selectedByUnit: selectedMonth,
                        byDayConfig: newConfig,
                      });
                    }
                  }}
                  disabled={
                    selectedCampaign.status !== undefined &&
                    selectedCampaign.status !== "CREATED"
                  }
                >
                  <option value="first">
                    {t("Campaign.ScheduleCampaign.PartofDaysofWeek.First")}
                  </option>
                  <option value="second">
                    {t("Campaign.ScheduleCampaign.PartofDaysofWeek.Second")}
                  </option>
                  <option value="third">
                    {t("Campaign.ScheduleCampaign.PartofDaysofWeek.Third")}
                  </option>
                  <option value="fourth">
                    {t("Campaign.ScheduleCampaign.PartofDaysofWeek.Fourth")}
                  </option>
                  <option value="fifth">
                    {t("Campaign.ScheduleCampaign.PartofDaysofWeek.Fifth")}
                  </option>
                  <option value="last">
                    {t("Campaign.ScheduleCampaign.PartofDaysofWeek.Last")}
                  </option>
                </select>

                <select
                  className="form-control form-control-lg form-select"
                  name="dayDowYear"
                  id="dayDowYear"
                  data-testid="day-dow-year"
                  value={byDayConfig.value}
                  onChange={(e) => {
                    const newConfig = {
                      ...byDayConfig,
                      value: e.target.value,
                    };
                    setByDayConfig(newConfig);

                    if (onChange !== undefined) {
                      onConfigChange({
                        selectedByUnit: selectedMonth,
                        byDayConfig: newConfig,
                      });
                    }
                  }}
                  disabled={
                    selectedCampaign.status !== undefined &&
                    selectedCampaign.status !== "CREATED"
                  }
                >
                  <option value="sunday">
                    {t("Campaign.ScheduleCampaign.Sunday")}
                  </option>
                  <option value="monday">
                    {t("Campaign.ScheduleCampaign.Monday")}
                  </option>
                  <option value="tuesday">
                    {t("Campaign.ScheduleCampaign.Tuesday")}
                  </option>
                  <option value="wednesday">
                    {t("Campaign.ScheduleCampaign.Wednesday")}
                  </option>
                  <option value="thursday">
                    {t("Campaign.ScheduleCampaign.Thursday")}
                  </option>
                  <option value="friday">
                    {t("Campaign.ScheduleCampaign.Friday")}
                  </option>
                  <option value="saturday">
                    {t("Campaign.ScheduleCampaign.Saturday")}
                  </option>
                  <option value="day">
                    {t("Campaign.ScheduleCampaign.Day")}
                  </option>
                  <option value="weekday">
                    {t("Campaign.ScheduleCampaign.Weekday")}
                  </option>
                  <option value="weekend">
                    {t("Campaign.ScheduleCampaign.Weekend")}
                  </option>
                </select>
              </div>
            </>
          )}
        </>
      )}

      {repetition && (
        <>
          <div className="step-sub-title ms-2">
            {t("Campaign.ScheduleCampaign.Title.Field.EndRepeat")}
          </div>
          <select
            className="form-control form-control-lg form-select"
            name="endRepeatType"
            id="endRepeatType"
            data-testid="end-repeat-type"
            value={endRepeatType}
            onChange={(e) => {
              setEndRepeatType(e.target.value);
              if (e.target.value === "never") {
                onConfigChange({}, repetition, undefined);
              }
              if (e.target.value === "onDate" && endRepeatTimestamp) {
                onConfigChange({}, true, endRepeatTimestamp);
              }
            }}
            disabled={
              selectedCampaign.status !== undefined &&
              selectedCampaign.status !== "CREATED"
            }
          >
            <option value="onDate">
              {t("Campaign.ScheduleCampaign.Title.Field.EndRepeat.OnDate")}
            </option>
            <option value="never">
              {t("Campaign.ScheduleCampaign.Title.Field.EndRepeat.Never")}
            </option>
          </select>
        </>
      )}
      {repetition && endRepeatType === "onDate" && (
        <>
          <div
            className={clsx(
              "d-flex gap-3",
              isTabletOrMobile ? "flex-column" : "flex-row"
            )}
          >
            <input
              onChange={(e) => {
                console.log("endRepeatDate event", e.target.value);
                handleChangeEndDate(e);
              }}
              id="endRepeatDate"
              type="date"
              className="form-control form-control-lg"
              name="endRepeatDate"
              data-testid="end-repeat-date"
              // defaultValue={selectedCampaign ? campaignDate : ""}
              // min={dateString}
              min={inputDate ? inputDate : dateString}
              role="end-day-scheduler"
              value={endRepeatDate}
              disabled={
                selectedCampaign.status !== undefined &&
                selectedCampaign.status !== "CREATED"
              }
            />
            <input
              onChange={(e) => {
                console.log(e.target.value);
                handleChangeEndDate(e);
              }}
              id="endRepeatTime"
              type="time"
              className="form-control form-control-lg"
              name="endRepeatTime"
              // defaultValue={!endRepeatTime ? "" : endRepeatTime}
              // defaultValue=""
              value={endRepeatTime}
              // min={!selectedCampaign ? timeString : campaignTime}
              // min={timeString}
              min={inputTime ? inputTime : timeString}
              data-testid="end-repeat-time"
              role="end-clock-scheduler"
              disabled={
                selectedCampaign.status !== undefined &&
                selectedCampaign.status !== "CREATED"
              }
            />
          </div>
          {(!endRepeatDate ||
            endRepeatDate === "" ||
            !endRepeatTime ||
            endRepeatTime === "") && (
            <div id="warning-schedule" className="text-danger">
              * {t("BS.Error.TimeEmpty")}
            </div>
          )}
          {endRepeatDate &&
            endRepeatTime &&
            new Date(`${inputDate} ${inputTime}:00`) >
              new Date(`${endRepeatDate} ${endRepeatTime}:00`) && (
              <div className="text-danger">
                {t("Campaign.ScheduleCampaign.TimeAlert")}
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default CampaignSubmission;
