import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getShipmentParent } from "src/db/serviceShipment";
import ApprovalModal from "./ApprovalModal";

type ShippingMarkModalProps = {
  onClose: () => void;
  onSubmit?: (data: ShippingMarkData) => void;
};

type ShippingMarkData = {
  carrier?: string;
  quantity?: number;
};

const ShippingMarkModal: React.FC<ShippingMarkModalProps> = ({
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [shippingCarriers, setShippingCarriers] = useState<any[]>([]);
  const [isApprovalModalopen, setApprovalModalopen] = useState<boolean>(false);

  //To Display Error
  const [errors, setErrors] = useState({
    carrier: "",
    quantity: "",
  });

  useEffect(() => {
    const fetchShippingCarriers = async () => {
      try {
        const carriers = await getShipmentParent();
        if (!carriers) {
          return;
        }
        setShippingCarriers(carriers);
      } catch (error) {
        console.error("Failed to fetch shipping carriers", error);
      }
    };

    fetchShippingCarriers();
  }, []);
  console.log(`${JSON.stringify(shippingCarriers)} shipping carrier`);

  // State for form data
  const [formData, setFormData] = useState<ShippingMarkData>({
    carrier: "",
    quantity: 0,
  });

  // Handler for input changes
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "quantity" ? value.replace(/^0+/, "") || "" : value,
    }));
  };

  // Submit handler
  const handleSubmit = () => {
    // Basic validation
    let hasError = false;
    const newErrors = { carrier: "", quantity: "" };

    if (!formData.carrier) {
      newErrors.carrier = t("Shipping.Mark.Select.Shipper.Error");
      hasError = true;
    }

    if (!formData.quantity) {
      newErrors.quantity = t("Shipping.Mark.Quantity.Error");
      hasError = true;
    }

    setErrors(newErrors);

    //call server to query qty based on shipping carrier
    const response = false;
    //if the returned data is error then show modal for apporval of user input is email and password
    if (response === false) {
      setApprovalModalopen(true);
    }
    //if not error then save to shipping mark
  };

  return (
    <>
      <div
        className="modal-backdrop show"
        data-testid="shipping-mark-modal"
      ></div>
      <div className="modal show d-block">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t("Shipping.Mark.Title")}</h5>
            </div>
            <div className="modal-body">
              {/* Shipping Carrier Dropdown */}
              <div className="mb-3">
                <label htmlFor="carrier" className="form-label text-start">
                  {t("Shipping.Mark.Shipper")}
                </label>
                <select
                  id="carrier"
                  name="carrier"
                  className="form-select"
                  value={formData.carrier}
                  data-testID="selectShipper"
                  onChange={handleInputChange}
                  required
                >
                  <option value="">{t("Shipping.Mark.Select.Shipper")}</option>
                  {shippingCarriers.map((carrier) => (
                    <option key={carrier.id} value={carrier.id}>
                      {carrier.name}
                    </option>
                  ))}
                </select>
                {errors.carrier && (
                  <div className="text-danger text-start">{errors.carrier}</div>
                )}
              </div>

              {/* Quantity Input */}
              <div className="mb-3">
                <label htmlFor="quantity" className="form-label text-start">
                  {t("Shipping.Mark.Item")}
                </label>
                <input
                  type="number"
                  id="quantity"
                  name="quantity"
                  className="form-control"
                  data-testID="inputQuantity"
                  value={formData.quantity}
                  onChange={handleInputChange}
                  min={1}
                  required
                />
                {errors.quantity && (
                  <div className="text-danger text-start">
                    {errors.quantity}
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                {t("Common.Button.Submit")}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onClose}
              >
                {t("Common.Modal.Button.Close")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {isApprovalModalopen && (
        <ApprovalModal
          onClose={() => setApprovalModalopen(false)}
          data={errors.quantity}
        />
      )}
    </>
  );
};

export default ShippingMarkModal;
