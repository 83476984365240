import clsx from "clsx";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Select, { components, createFilter } from "react-select";
// import CreatableSelect from "react-select/async-creatable";
import AsyncSelect from "react-select/async";
import { getAccountByCompanyAndMarketplace } from "../../../../../../actions/account";
import * as actions from "../../../../../../actions/actions";
import {
  createDataBrand,
  getListBrands,
} from "../../../../../../actions/brand";
import { createDataProduct } from "../../../../../../actions/product";
import {
  getStoreFrontList as getListStoreFront,
  getStoreFrontMarketplaceCategory,
} from "../../../../../../actions/storefront";
import { Timestamp } from "../../../../../../db";
import { createRef } from "../../../../../../db/connection";
import { searchBrands } from "../../../../../../db/serviceBrand";
import * as servProduct from "../../../../../../db/serviceProduct";
import { RootState } from "../../../../../../setup/redux/store";
import Button from "../../../../../../styles/components/Button";
import { AddDataButton } from "../../../../../../styles/components/SelectComponents";
import "../../../../../../styles/css/select.css";
import Brand from "../../../../../models/Brand";
import {
  attributeList,
  attributeValueList,
  productStatus,
  productStep,
} from "../../../../../models/Product";
import AlertFaieldProcess from "../../../../../modules/alert/AlertFailedProcess";
import AlertSuccessProcess from "../../../../../modules/alert/AlertSuccessProcess";
import * as lc from "../../../../../modules/localstorage";
import ModalAddBrandProduct from "../../../../../modules/modal/modalAddBrandProduct";
import ModalConfirmationMoveToOtherPage from "../../../../../modules/modal/modalConfirmationMoveToOtherPage";
import ModalConfirmationWihtButtonSavedUnsavedChanges from "../../../../../modules/modal/modalConfirmationWithButtonSavedUnsavedChanges";
import { ProductHeaderTitle } from "../../../../../modules/product/productHeader/ProductHeaderTitle";
import { ProductStep } from "../../../../../modules/product/productHeader/productStep";
import * as ReduxProductList from "../../../../../modules/product/productList/ProductListSlice";
import * as ReduxProduct from "../../../../../modules/product/redux/ProductSlice";
import { Loading } from "../../../../../modules/util/Loading";
import { uploadFile } from "../../../../../modules/util/Utils";
import { PageLink, PageTitle } from "../../../../core";
import MediaUpload from "./MediaUpload";
import ProductMobileIcon from "./ProductMobileIcon";

interface BPIOption {
  isSummary?: boolean;
  onClickUpload?: boolean;
}

const BasicProductInformation: React.FC<BPIOption> = ({
  isSummary = false,
  onClickUpload = false,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();

  const reduxProductName = useSelector(
    (state: RootState) => state.Product.productName
  );
  const reduxProductNameLength = useSelector(
    (state: RootState) => state.Product.productNameLength
  );
  const reduxProductSKU = useSelector(
    (state: RootState) => state.Product.productSKU
  );
  const reduxProductSKULength = useSelector(
    (state: RootState) => state.Product.productSKULength
  );
  const reduxDescriptionProduct = useSelector(
    (state: RootState) => state.Product.descriptionProduct
  );
  const reduxDescriptionProductLength = useSelector(
    (state: RootState) => state.Product.descriptionProductLength
  );
  const reduxBrandList = useSelector(
    (state: RootState) => state.Product.brandList
  );

  const reduxStorefrontlist = useSelector(
    (state: RootState) => state.Product.storeFrontList
  );
  const reduxSelectedStoreFrontData = useSelector(
    (state: RootState) => state.Product.selectedStoreFrontData
  );
  const reduxSelectedBrandData = useSelector(
    (state: RootState) => state.Product.selectedBrandData
  );
  const reduxNewProduct = useSelector(
    (state: RootState) => state.Product.newProduct
  );
  const reduxNewBrand = useSelector(
    (state: RootState) => state.Product.newBrand
  );
  /***
   * Media Page - Redux Data
   */
  const rd_ProductPhotos = useSelector(
    (state: RootState) => state.Product.productPhotos
  );
  const rd_ProductVideo = useSelector(
    (state: RootState) => state.Product.productVideo
  );
  const rd_VideoYoutubeURL = useSelector(
    (state: RootState) => state.Product.videoYoutubeURL
  );
  const rd_PhotosCoverIndex = useSelector(
    (state: RootState) => state.Product.coverIndex
  );

  const rd_Shipments = useSelector(
    (state: RootState) => state.Product.shipments
  );
  const rd_SelectedCompanies = useSelector(
    (state: RootState) => state.Product.companies
  );

  const rd_marketplaces = useSelector(
    (state: RootState) => state.Product.marketplaces
  );

  const rd_isProductHaveVariants = useSelector(
    (state: RootState) => state.Product.isProductHaveVariants
  );

  const rd_productID = useSelector(
    (state: RootState) => state.Product.productID
  );

  const rd_Variants = useSelector((state: RootState) => state.Product.variants);

  const rd_VariantMatrix: any = useSelector(
    (state: RootState) => state.Product.variantsMatrix
  );
  const rd_VariantPhotos: any = useSelector(
    (state: RootState) => state.Product.productVariantPhotos
  );
  const rd_isAddStorefront: boolean = useSelector(
    (state: RootState) => state.Product.isAddStorefront
  );
  const rd_tempSelectedStorefront: string = useSelector(
    (state: RootState) => state.Product.tempSelectedStorefront
  );
  const rd_categoryAttributes = useSelector(
    (state: RootState) => state.Product.categoryAttributes
  );

  const reduxShopeeProductCategoryData = useSelector(
    (state: RootState) => state.MarketplaceProductCategory.marketplaceShopee
  );

  const URI = rd_productID ? "/product/update" : "/product/create-product";

  const UsersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Storefront.Column.Product"),
      path: "/product/list",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const nav = useNavigate();
  const dispatch = useDispatch();

  const [isValidProductName, setIsValidProductName] = useState<boolean>(true);
  const [isValidProductSKU, setIsValidProductSKU] = useState<boolean>(true);
  const [isProductNameHasExist, setIsProductNameHasExistHasExist] =
    useState<boolean>(false);
  const [isValidProductDescription, setIsValidProductDescription] =
    useState<boolean>(true);
  const [isValidLengthProductDescription, setIsValidLengthProductDescription] =
    useState<boolean>(true);

  const [isInsertProductName, setIsInsertProductName] = useState(false);
  const productNameRef = useRef<HTMLInputElement>(null);

  const useruid = lc.getItemLC(lc.LCName.UserID);
  const refUser = createRef("users", useruid);

  const [isLoading, setIsLoading] = useState(false);
  const [listStorefront, setListStorefront] = useState<any[]>([]);
  const [isNullSelectedStorefront, setIsNullSelectedStorefront] =
    useState<boolean>(false);
  const [isNullBrandValue, setIsNullBrandValue] = useState<boolean>(false);

  // const AddDataButtoninSelect = (props: any) => {
  //   return (
  //     <Fragment>
  //       <components.Menu {...props}>
  //         <div>
  //           {props.selectProps.fetchingData ? (
  //             <span className="fetching">{t("Common.Pages.Loading")}...</span>
  //           ) : (
  //             <div>{props.children}</div>
  //           )}
  //           {/* <Link to="/product/list"> */}
  //           <button
  //             data-testid="button-to-storefront"
  //             className={"change-data"}
  //             onClick={toAddEtalase}
  //             // onChange={toAddEtalase}
  //             onTouchStart={toAddEtalase}
  //           >
  //             <h6 className="text-primary">
  //
  //               + {t("AddNewProduct.Button.SM.AddNewStorefront")}
  //             </h6>
  //           </button>
  //           {/* </Link> */}
  //         </div>
  //       </components.Menu>
  //     </Fragment>
  //   );
  // };

  const toAddEtalase = () => {
    dispatch(ReduxProduct.setIsAddStorefront(true));
    nav("/storefront/create-storefront/summary");
    console.log("TO ADD DATA PAGE");
  };

  const handleCreateBrand = async (brand: Brand) => {
    //TODO
    const id = brand.id;
    if (id) {
      console.log("masuk ke if create brand");
      const brandReff = createRef("brands", id);
      dispatch(ReduxProduct.setNewBrand(""));
    } else {
      console.log("masuk ke else create brand");
      dispatch(ReduxProduct.setSelectedBrandData(null));
    }
    console.log("Saved Brand : " + id);
    setIsLoading(true);
    setIsNullBrandValue(false);
    setTimeout(() => {
      let newData = {
        label: brand.brandName,
        value: id,
      };
      // let newBrands: any[] = [];
      // if (reduxBrandList && reduxBrandList.length > 0)
      //   newBrands = [...reduxBrandList];

      // newBrands.push(newData);
      // dispatch(ReduxProduct.setBrandList(newBrands));
      // dispatch(ReduxProduct.setNewBrand(brand.brandName));
      dispatch(ReduxProduct.setSelectedBrandData(newData));
      setIsLoading(false);
    }, 1000);
  };

  const handleCreateBrandOld = async (inputValue: string) => {
    //create brand and get id from firebase
    let newProduct = { ...reduxNewProduct };
    const newBrand = initBrand(inputValue);
    const id = await createDataBrand(newBrand);
    if (id) {
      //Set Selected Brand
      console.log("masuk ke if create brand");
      const brandReff = createRef("brands", id);
      newProduct.brand = brandReff;
      dispatch(ReduxProduct.setNewBrand(""));
    } else {
      //Remove New Brand Data
      console.log("masuk ke else create brand");
      dispatch(ReduxProduct.setSelectedBrandData(null));
      // let newBrandsList = reduxBrandList?.filter(
      //   (data) => data.label !== reduxNewBrand
      // );
      // if (newBrandsList) {
      //   dispatch(ReduxProduct.setBrandList(newBrandsList));
      // }
      return;
    }
    console.log("Saved Brand : " + id);

    setIsLoading(true);
    setIsNullBrandValue(false);
    setTimeout(() => {
      let newData = {
        label: inputValue,
        value: id,
      };
      // let newBrands: any[] = [];
      // if (reduxBrandList && reduxBrandList.length > 0)
      //   newBrands = [...reduxBrandList];

      // newBrands.push(newData);
      // dispatch(ReduxProduct.setBrandList(newBrands));
      dispatch(ReduxProduct.setNewBrand(inputValue));
      dispatch(ReduxProduct.setSelectedBrandData(newData));
      setIsLoading(false);
    }, 1000);
  };

  const getMarketplaceCategoryFromStorefront = (storefront: any) => {
    console.log("storefront : ", reduxNewProduct?.storeFront?.id);
    //get marketplacecategory by storefront
    if (rd_marketplaces && rd_marketplaces.length > 0) {
      getStoreFrontMarketplaceCategory(
        storefront?.id,
        (marketplaceCategory: any[]) => {
          console.log("Result Get List marketplaceCategory");
          if (marketplaceCategory && marketplaceCategory.length > 0) {
            console.log("marketplaceCategory : ", marketplaceCategory);
            let data: any = [];
            for (let index = 0; index < marketplaceCategory.length; index++) {
              const marketplaceCategoryData = marketplaceCategory[index];
              //set data [{ marketplace: shopee, category : category }]
              data = [
                ...data,
                {
                  marketplace: marketplaceCategoryData.type,
                  categories: marketplaceCategoryData.categories,
                },
              ];
            }
            console.log("data to push " + JSON.stringify(data));
            dispatch(ReduxProduct.setMarketplacesCategoryList(data));
            const tempMarketplaces = rd_marketplaces;
            const changeCategoryMarketoPlace = tempMarketplaces.map(
              (marketplaceData) => {
                const findMarketplaceCategory = data.find(
                  (market: any) =>
                    market.marketplace.toLowerCase() ===
                    marketplaceData.marketplace.toLowerCase()
                );
                if (findMarketplaceCategory) {
                  return {
                    ...marketplaceData,
                    category: findMarketplaceCategory.categories,
                  };
                }
                return undefined;
              }
            );
            console.log(
              "changeCategoryMarketoPlace : ",
              changeCategoryMarketoPlace
            );
            dispatch(ReduxProduct.setMarketplaces(changeCategoryMarketoPlace));
          }
        }
      );
    }
  };

  const onChangeStorefront = async (event: any) => {
    let storeFrontSelected = event;
    dispatch(ReduxProduct.setSelectedStoreFrontData(storeFrontSelected));
    let newProduct = { ...reduxNewProduct };
    if (
      storeFrontSelected &&
      storeFrontSelected !== null &&
      storeFrontSelected.value &&
      storeFrontSelected.value !== null
    ) {
      const storefrontRef = createRef("storefront", storeFrontSelected.value);
      newProduct.storeFront = storefrontRef;
      dispatch(ReduxProduct.setNewProduct(newProduct));
      getMarketplaceCategoryFromStorefront(newProduct.storeFront);
      dispatch(ReduxProduct.setIsGeneratedCategoryAttributes(false));
    }

    setIsNullSelectedStorefront(
      storeFrontSelected === null || storeFrontSelected === ""
    );
  };

  const onChangeBrand = async (event: any) => {
    let brandSelected = event;
    dispatch(ReduxProduct.setSelectedBrandData(brandSelected));
    let newProduct = { ...reduxNewProduct };
    if (
      brandSelected &&
      brandSelected !== null &&
      brandSelected.value &&
      brandSelected.value !== null
    ) {
      const brandRef = createRef("brands", brandSelected.value);
      newProduct.brand = brandRef;
      dispatch(ReduxProduct.setNewProduct(newProduct));
    }
    if (brandSelected === null || brandSelected === "") {
      setIsNullBrandValue(true);
    } else {
      setIsNullBrandValue(false);
    }
  };

  const onChangeProductName = async (event: any) => {
    setIsValidProductName(true);
    setIsProductNameHasExistHasExist(false);
    let productNameValue = event.target.value;
    if (productNameValue === "" || productNameValue.trim() === "") {
      setIsValidProductName(false);
    }
    dispatch(ReduxProduct.setProductName(productNameValue));
    dispatch(ReduxProduct.setProductNameLength(productNameValue.length));

    let newProduct = { ...reduxNewProduct };
    newProduct.name = productNameValue;
    newProduct.nameInsensitive = productNameValue.trim().toLowerCase();
    dispatch(ReduxProduct.setNewProduct(newProduct));
  };

  const onChangeProductSKU = async (event: any) => {
    setIsValidProductSKU(true);
    let inputValue = event.target.value;
    if (inputValue === "" || inputValue.trim() === "") {
      setIsValidProductSKU(false);
    }
    let SKUProductValue = event.target.value;
    if (SKUProductValue.length < 0) {
      setIsValidProductSKU(false);
    }
    dispatch(ReduxProduct.setProductSKU(inputValue));
    dispatch(ReduxProduct.setProductSKULength(inputValue.length));

    let newProduct = { ...reduxNewProduct, sku: inputValue };
    dispatch(ReduxProduct.setNewProduct(newProduct));
  };

  const onChangeDescriptionProduct = async (event: any) => {
    setIsValidProductDescription(true);
    setIsValidLengthProductDescription(true);
    let descriptionProductValue = event.target.value;
    if (
      descriptionProductValue === "" ||
      descriptionProductValue.trim() === ""
    ) {
      setIsValidProductDescription(false);
    }
    if (descriptionProductValue.length < 20) {
      setIsValidLengthProductDescription(false);
    }
    dispatch(ReduxProduct.setDescriptionProduct(descriptionProductValue));
    dispatch(
      ReduxProduct.setDescriptionProductLength(descriptionProductValue.length)
    );
    let newProduct = { ...reduxNewProduct };
    newProduct.description = descriptionProductValue;
    dispatch(ReduxProduct.setNewProduct(newProduct));
  };

  const validateData = async () => {
    console.log("BPI Validate Data");
    //check is product name not null
    let isValid = true;
    if (
      reduxProductName.trim() === "" ||
      reduxProductName === "" ||
      reduxProductNameLength === 0
    ) {
      setIsValidProductName(false);
      isValid = false;
    } else {
      setIsValidProductName(true);
    }

    //check is storefront selected
    if (
      reduxSelectedStoreFrontData === "" ||
      reduxSelectedStoreFrontData === null
    ) {
      setIsNullSelectedStorefront(true);
      isValid = false;
    } else {
      setIsNullSelectedStorefront(false);
    }

    //check is brand selected
    if (reduxSelectedBrandData === "" || reduxSelectedBrandData === null) {
      setIsNullBrandValue(true);
      isValid = false;
    } else {
      setIsNullBrandValue(false);
    }

    // check is description not null
    let isValidProductDescription = true;
    if (
      reduxDescriptionProduct.trim() === "" ||
      reduxDescriptionProduct === "" ||
      reduxDescriptionProductLength === 0
    ) {
      isValidProductDescription = false;
      isValid = false;
    }
    setIsValidProductDescription(isValidProductDescription);

    if (reduxDescriptionProductLength < 20) {
      isValid = false;
      setIsValidLengthProductDescription(false);
    }
    if (reduxProductSKULength <= 0) {
      isValid = false;
      setIsValidProductSKU(false);
    }
    if (!isValid) {
      return isValid;
    }

    isValid = await checkDuplicateProduct();

    return isValid;
  };

  useEffect(() => {
    if (onClickUpload && isSummary) handleNext();
  }, [onClickUpload, isSummary]);

  const checkDuplicateProduct = async () => {
    let name = reduxNewProduct.nameInsensitive!.toString();

    console.log("Check Duplicate Product ");
    let getProductByName = await servProduct
      .getProductByNameInsensitiveCase(name)
      .then(async (product: any) => {
        if (product) {
          return product;
        }
      });
    console.log("Hasil query : " + JSON.stringify(getProductByName));

    if (getProductByName && getProductByName.length > 0) {
      setIsProductNameHasExistHasExist(true);
      return false;
    }

    return true;
  };

  const bodyAddBrand = () => {
    return (
      <div>
        <div>
          <label>Name Brand:</label>
          <input type="text" />
        </div>
        <div>
          <label>Category List:</label>
          <input type="text" />
        </div>
        <div>
          <label>Photo Category:</label>
          <input type="text" />
        </div>
      </div>
    );
  };

  const handleNext = async () => {
    //check new Brand
    setIsLoading(true);
    let newProduct = { ...reduxNewProduct };
    newProduct.status = productStatus.drafted;
    // if (reduxNewBrand && reduxNewBrand !== "") {
    //   const newBrand = initBrand(reduxNewBrand);
    //   const id = await createDataBrand(newBrand);
    //   if (id) {
    //     const brandReff = createRef("brands", id);
    //     newProduct.brand = brandReff;
    //   } else {
    //     dispatch(ReduxProduct.setSelectedBrandData(null));
    //     let newBrandsList = reduxBrandList?.filter(
    //       (data) => data.label !== reduxNewBrand
    //     );
    //     if (newBrandsList) {
    //       dispatch(ReduxProduct.setBrandList(newBrandsList));
    //     }
    //     return;
    //   }
    //   console.log("Saved Brand : " + id);
    // }
    if (
      newProduct &&
      !newProduct.storeFront &&
      reduxSelectedStoreFrontData &&
      reduxSelectedStoreFrontData.value !== ""
    ) {
      const storeFrontReff = createRef(
        "storefront",
        reduxSelectedStoreFrontData.value
      );
      newProduct.storeFront = storeFrontReff;
    }
    dispatch(ReduxProduct.setNewBrand(""));
    dispatch(ReduxProduct.setNewProduct(newProduct));
    let valid = await validateData();

    if (valid) {
      setIsLoading(false);
      if (!isSummary) {
        nav(`${URI}/media-upload`);
      }
    } else {
      setIsLoading(false);
      return;
    }
  };

  const handleSavedChanges = async () => {
    let productID = "";

    setIsLoading(true);
    if (reduxProductName && reduxProductName !== "") {
      let valid = await checkDuplicateProduct();
      console.log("Check Valid : " + valid);
      if (!valid) {
        console.log("Masuk ke logic tidak valdi");
        setIsProductNameHasExistHasExist(true);
        return;
      }
    }

    // Save Data
    let newProduct = { ...reduxNewProduct };
    newProduct.status = productStatus.drafted;
    newProduct.lastStep = productStep.onBasicProductInformation;
    let clientRef: any;
    const lcClient = lc.getItemLC(lc.LCName.Client);
    if (lcClient) {
      clientRef = createRef("clients", lcClient.id);
      newProduct.client = clientRef;
    }

    const userRef = createRef("user", lc.getItemLC(lc.LCName.UserID));
    if (reduxNewProduct && (!reduxNewProduct.id || reduxNewProduct.id === "")) {
      const id = await createDataProduct(newProduct);
      if (id) {
        newProduct.id = id;
        newProduct.updatedAt = Timestamp.now();
        newProduct.updatedBy = userRef;
      }
      console.log("Saved Product : " + id);
    } else {
      //Update Product
      productID = rd_productID;
      newProduct.updatedAt = Timestamp.now();
      newProduct.updatedBy = refUser;
      actions.updateDataProduct(productID, newProduct);
      actions.deleteMediaDocData(productID);
      actions.deleteShipmentsData(productID);
      actions.deleteCompanyAndMarketplacesData(productID);
      actions.removeDataVariants(productID);
      actions.removeDataProductVariants(productID);
      actions.removeDataCategoryAttributes(productID);
    }
    //update numberofproducts storefront realtime db
    if (
      reduxNewProduct &&
      reduxNewProduct.storeFront &&
      reduxNewProduct.storeFront.id
    ) {
      const storefrontId = reduxNewProduct.storeFront.id.toString();
      //jika punya tempStorefront dan id selected storefront sama tidak perlu update
      //jika temp dan id storefront tidak sama update increment dan decrement
      if (
        rd_tempSelectedStorefront &&
        rd_tempSelectedStorefront !== reduxNewProduct.storeFront.id
      ) {
        const tempStorefrontId = rd_tempSelectedStorefront;
        // updateDecrementNumberOfProductStorefront
        await actions.decrementNumberOfProductStorefront(tempStorefrontId);
        console.log(
          "update decrementNumberOfProductStorefront for storefront id : ",
          tempStorefrontId
        );
        // updateIncrementNumberOfProductStorefront
        await actions.incrementNumberOfProductStorefront(storefrontId);
        console.log(
          "update incrementNumberOfProductStorefront for storefront id : ",
          storefrontId
        );
      }
      // tidak punya tempStorefront maka increment
      if (!rd_tempSelectedStorefront || rd_tempSelectedStorefront === "") {
        // const updateIncrementNumberOfProductStorefront =
        await actions
          .incrementNumberOfProductStorefront(storefrontId)
          .then((res) => {
            console.log(res);
            console.log(
              `update incrementNumberOfProductStorefront for storefront id : ${storefrontId}`
            );
          })
          .catch((err) => {
            console.error(
              `Error update incrementNumberOfProductStorefront for storefrontId ${storefrontId} ${err}`
            );
          });
      }
    }
    /***
     * Process Image
     */
    let resultFirestorageURL: string[] = [];
    let resultPhotosMediaID: string[] = [];
    let resultVideoMediaID: string = "";
    let resultShipmentsId: string[] = [];
    let resultMarketplaceId: string[] = [];
    let resultVariantId: string[] = [];
    let isSuccess = true;
    let resultProductVariantPhoto: string[] = [];
    if (newProduct && newProduct.id && newProduct.id !== "") {
      /***
       * Media Page - Process Save Photos
       */
      if (rd_ProductPhotos && rd_ProductPhotos.length > 0) {
        for (let index = 0; index < rd_ProductPhotos.length; index++) {
          const element = rd_ProductPhotos[index];
          //Upload File to Storage
          const result_FS_URL = await uploadToFirestorage(
            element.url,
            element.type,
            element.name,
            "product/photos/" + newProduct.id
          );
          console.log("Result URL Photos : " + result_FS_URL);
          if (result_FS_URL && result_FS_URL !== "") {
            resultFirestorageURL.push(result_FS_URL);
          } else {
            isSuccess = false;
          }

          //Create Media Collection
          const dataMedia = await actions.formatMediaCollection(
            element.url,
            element.name,
            element.extension,
            element.type,
            element.size,
            result_FS_URL,
            rd_PhotosCoverIndex === index ? true : false,
            "",
            userRef
          );
          const mediaID = await actions.createDataMedia(
            newProduct.id,
            "photos",
            dataMedia
          );
          if (mediaID && mediaID !== "") {
            resultPhotosMediaID.push(mediaID);
          } else {
            isSuccess = false;
          }
        }
      }

      /***
       * Media Page - Process Save Video
       */
      if (rd_ProductVideo && rd_ProductVideo !== "") {
        // if (rd_VideoYoutubeURL && rd_VideoYoutubeURL !== "") {
        //Upload File to Storage
        const result_FS_URL = await uploadToFirestorage(
          rd_ProductVideo.url,
          rd_ProductVideo.type,
          rd_ProductVideo.name,
          "product/videos/" + newProduct.id
        );
        if (result_FS_URL && result_FS_URL !== "") {
          resultFirestorageURL.push(result_FS_URL);
        } else {
          isSuccess = false;
        }

        //Create Media Collection
        const dataMedia = await actions.formatMediaCollection(
          rd_ProductVideo.url,
          rd_ProductVideo.name,
          rd_ProductVideo.extension,
          rd_ProductVideo.type,
          rd_ProductVideo.size,
          result_FS_URL,
          false,
          rd_VideoYoutubeURL,
          userRef
        );
        const mediaID = await actions.createDataMedia(
          newProduct.id,
          "video",
          dataMedia
        );
        if (mediaID && mediaID !== "") {
          resultVideoMediaID = mediaID;
        } else {
          isSuccess = false;
        }
        // }
      }
      //save shipments data
      if (rd_Shipments && rd_Shipments.length > 0) {
        console.log("check redux rd_Shipments", rd_Shipments);
        for (let index = 0; index < rd_Shipments.length; index++) {
          const element = rd_Shipments[index];
          // check isActive and have types
          // save if isActive or not active but have types
          if (
            element.isActive ||
            (!element.isActive && element.types.length > 0)
          ) {
            const shipmentParentRef = createRef("shipments", element.id);
            console.log("parent shipments id : ", shipmentParentRef);
            console.log("element types : ", element.types);
            let typesData: any = [];
            for (let index = 0; index < element.types.length; index++) {
              const type = element.types[index];
              const shipmentChildrenRef = createRef("shipments", type.id);
              console.log("children shipments id : ", shipmentParentRef);
              const dataShipmentsType = await actions.formatShipmentsType(
                shipmentChildrenRef,
                type.name
              );
              typesData = [...typesData, dataShipmentsType];
            }
            console.log("check typesData : ", typesData);
            const dataShipments = await actions.formatShipmentsCollection(
              shipmentParentRef,
              element.name,
              element.isActive,
              typesData,
              refUser
            );
            const shipmentId = await actions.createDataShipments(
              productID,
              dataShipments
            );
            if (shipmentId && shipmentId !== "") {
              resultShipmentsId.push(shipmentId);
            } else {
              isSuccess = false;
            }
          }
        }
      }
      // save company data
      if (rd_SelectedCompanies && rd_SelectedCompanies.length > 0) {
        const companies = rd_SelectedCompanies;
        const marketplaces = rd_marketplaces;

        // create companies
        let newCompanies: any = [];
        console.log("companies : ", companies);
        for (let index = 0; index < companies.length; index++) {
          const company = companies[index];
          const companyRef = createRef("company", company.company);
          newCompanies.push({ ...company, company: companyRef });
        }

        //dataCompanies
        await actions.createDataCompany(newProduct.id, newCompanies);

        // create marketplace collection
        for await (const market of marketplaces) {
          // destructure
          let { company, category, isActive, marketplace } = market;

          // set company ref
          const companyRef = createRef("company", company!);

          // set category to empty array rather than undefined
          const categoryData = { ...(category[0] || []) };
          let arrayData = [];
          arrayData.push({ ...categoryData });
          // const categoryData = category || [];

          const findAccount = await getAccountByCompanyAndMarketplace(
            company,
            marketplace
          );
          const account: any =
            findAccount && findAccount[0] && findAccount[0].id
              ? findAccount[0].id
              : "";
          const accountRef = createRef("account", account ? account : "");
          //find attributes list by last category id
          let attributesList: attributeList[] = [];
          const cat: any[] = Object.values(category[0]);
          console.log(`category : ${cat}`);
          const lastCategory = cat.find(
            (category: any) => category.hasChildren === false
          );
          console.log(`find lastCategory : ${JSON.stringify(lastCategory)}`);
          if (
            rd_categoryAttributes &&
            rd_categoryAttributes.shopee &&
            lastCategory &&
            marketplace &&
            marketplace.toLowerCase() === "shopee"
          ) {
            const attributes = rd_categoryAttributes.shopee;
            const findAttributes = attributes.filter((att: any) => {
              if (att.inputValues && att.inputValues.length > 0) {
                const findCategoryById = att.categoryId?.find((cat: string) => {
                  if (cat === lastCategory?.id) return cat;
                });
                if (findCategoryById) return att;
              }
            });
            console.log(`findAttributes : ${findAttributes}`);
            if (findAttributes && findAttributes.length > 0) {
              for (let index = 0; index < findAttributes.length; index++) {
                const attr = findAttributes[index];
                const inputValues = attr.inputValues!;
                const valueList: attributeValueList[] = [];
                console.log(`inputValues : ${JSON.stringify(inputValues)}`);
                if (
                  attr.inputType === "MULTIPLE_SELECT" ||
                  attr.inputType === "MULTIPLE_SELECT_COMBO_BOX"
                ) {
                  const multipleValues = inputValues[0];
                  if (multipleValues && multipleValues.length > 0) {
                    for (
                      let index = 0;
                      index < multipleValues.length;
                      index++
                    ) {
                      const values = multipleValues[index];
                      console.log(`values for index ${index} : ${values}`);
                      let originalValueName: string = "";
                      if (values && values.value && values?.label) {
                        const getValueByInputType =
                          values?.value === values?.label ? 0 : values?.value;
                        console.log(
                          `getValueByInputType : ${getValueByInputType}`
                        );
                        if (getValueByInputType === 0)
                          originalValueName = values?.label;
                        const createValueList: attributeValueList =
                          await actions.formatAttributeValueList(
                            getValueByInputType,
                            "",
                            originalValueName
                          );
                        valueList.push({ ...createValueList });
                      }
                    }
                    console.log(`multiple : ${JSON.stringify(valueList)}`);
                  }
                } else {
                  const values = inputValues[0];
                  console.log(`values for index ${0} : ${values}`);
                  let getValueByInputType: number = 0;
                  let originalValueName: string = "";
                  if (values !== undefined && values !== null) {
                    getValueByInputType =
                      (attr.inputType === "COMBO_BOX" ||
                        attr.inputType === "DROP_DOWN") &&
                      values
                        ? values.value === values.label
                          ? 0
                          : values.value
                        : 0;
                    if (getValueByInputType === 0) {
                      originalValueName =
                        values && values.label
                          ? values.label
                          : values
                          ? values
                          : "";
                      if (attr.inputValidation === "DATE_TYPE")
                        originalValueName = values.replaceAll("-", "_");
                    }
                  }
                  const valueUnit =
                    attr.formatType === "QUANTITATIVE" &&
                    inputValues[1] &&
                    inputValues[1].value
                      ? inputValues[1].value
                      : "";
                  console.log(`getValueByInputType : ${getValueByInputType}`);
                  console.log(`originalValueName : ${originalValueName}`);
                  console.log(`value unit : ${valueUnit}`);
                  const createValueList: attributeValueList =
                    await actions.formatAttributeValueList(
                      getValueByInputType,
                      valueUnit,
                      originalValueName
                    );
                  if (
                    (createValueList &&
                      createValueList.valueId === 0 &&
                      createValueList.originalValueName !== "" &&
                      createValueList.originalValueName &&
                      createValueList.originalValueName !== null) ||
                    (createValueList &&
                      createValueList.valueId &&
                      createValueList.valueId > 0)
                  ) {
                    console.log(`valueList : ${createValueList}`);
                    valueList.push({ ...createValueList });
                  }
                }
                console.log(`result valueList : ${valueList}`);
                if (valueList && valueList.length > 0) {
                  const createAttributeModel: attributeList =
                    await actions.formatAttributeList(
                      attr.attributeId,
                      valueList
                    );
                  console.log(`attributeModel : ${createAttributeModel}`);
                  attributesList.push({ ...createAttributeModel });
                }
              }
            }
            console.log(
              `result attributesList: ${JSON.stringify(attributesList)}`
            );
          }
          console.log(`attributesList: ${JSON.stringify(attributesList)}`);
          const dataMarketplaces = await actions.formatMarketplacesCollection(
            companyRef,
            isActive,
            marketplace,
            arrayData,
            userRef,
            accountRef,
            attributesList && attributesList.length > 0 ? attributesList : []
          );

          const marketplaceId = await actions.createDataMarketplace(
            newProduct.id,
            dataMarketplaces
          );
          if (marketplaceId && marketplaceId !== "") {
            resultMarketplaceId.push(marketplaceId);
          } else {
            isSuccess = false;
          }
        }
      }

      //if have photo from redux upload then set image in option list
      if (rd_VariantPhotos && rd_VariantPhotos.length > 0) {
        //upload to firestore
        console.log("rd_VariantPhotos : ", rd_VariantPhotos);
        const sortData = [...rd_VariantPhotos];
        const sortVariantPhoto = sortData.sort((a: any, b: any) =>
          a.index > b.index! ? 1 : -1
        );
        console.log("sortVariantPhoto : ", sortVariantPhoto);

        for (let index = 0; index < sortVariantPhoto.length; index++) {
          const element = sortVariantPhoto[index];
          let elem = {
            ...element,
            name: element.name || "variant-photo",
            type: element.type || "jpeg",
          };
          const result_FS_URL = await uploadToFirestorage(
            elem.url,
            elem.type,
            elem.name,
            "product/variant-photos/" + newProduct.id
          );
          console.log("Result URL Variant Photos : " + result_FS_URL);
          if (result_FS_URL && result_FS_URL !== "") {
            resultProductVariantPhoto.push(result_FS_URL);
          } else {
            isSuccess = false;
          }
        }
      }
      //save variant category
      if (
        rd_isProductHaveVariants === "true" &&
        rd_Variants &&
        rd_Variants.length > 0
      ) {
        for (let i = 0; i < rd_Variants.length; i++) {
          const variant: any = rd_Variants[i];
          console.log("VAR, variant", variant);
          const variantName = variant.name ? variant.name.trim() : "";
          const optionList = variant.optionList ? variant.optionList : [];
          let optionListData: any = [];
          if (i === 0) {
            for (let i = 0; i < optionList.length; i++) {
              const opt = optionList[i].option;
              const image = resultProductVariantPhoto[i];
              const optionData = await actions.formatVariantOptionList(
                opt ? opt : "",
                image ? image : ""
              );
              optionListData.push(optionData);
            }
          } else {
            for (let i = 0; i < optionList.length; i++) {
              const opt = optionList[i].option;
              const optionData =
                await actions.formatVariantOptionListOptionOnly(opt ? opt : "");
              optionListData.push(optionData);
            }
          }
          console.log("optionListData : ", optionListData);

          let variantId: any;
          if (variantName !== "" || optionList.length > 0) {
            const dataVariant = await actions.formatVariantCollection(
              variantName,
              variant.index,
              optionListData && optionListData.length > 0
                ? optionListData
                : optionList,
              refUser
            );

            variantId = actions.createDataVariant(productID, dataVariant);
          }

          if (variantId && variantId !== "") {
            console.log("success create data variant");
          } else {
            isSuccess = false;
          }
        }
      }
      //save variant list/matrix
      console.log("RD VAR MATRIX", rd_VariantMatrix);
      if (rd_VariantMatrix && rd_VariantMatrix.length > 0) {
        console.log("VARLIST enter save matrix");
        let variantId: any;
        for await (const variantMatrix of rd_VariantMatrix) {
          const productVariant = variantMatrix;
          console.log("productVariant : ", productVariant);
          const dataProductVariant =
            await actions.formatProductVariantCollection(
              productVariant.tierIndex,
              productVariant.sku ? productVariant.sku : "",
              productVariant.weight ? productVariant.weight : 0,
              productVariant.isActive === true ? true : false,
              productVariant.isMainVariant === true ? true : false,
              productVariant.stock ? productVariant.stock : 0,
              refUser
            );

          variantId = await actions.createDataProductVariant(
            productID,
            dataProductVariant
          );
        }
        if (variantId && variantId !== "") {
          resultVariantId.push(variantId);
          console.log("success create data variant");
        } else {
          isSuccess = false;
        }
      }
      //save category attributes to subCollection categoryAttributes
      console.log(`start process category attributes`);
      if (rd_categoryAttributes && rd_categoryAttributes.shopee) {
        const categoryAttributes = rd_categoryAttributes.shopee;
        for (let index = 0; index < categoryAttributes.length; index++) {
          let attribute = categoryAttributes[index];
          console.log(`attributes id : ${attribute.attributeId}`);
          const categoryId =
            attribute.categoryId && attribute.categoryId.length > 0
              ? attribute.categoryId
              : [];
          //reformat input values to get id value or string
          let inputValues: any[] = [];
          if (
            attribute.inputValues &&
            (attribute.inputValues[0] || attribute.inputValues[1])
          ) {
            if (
              attribute.inputType === "MULTIPLE_SELECT" ||
              attribute.inputType === "MULTIPLE_SELECT_COMBO_BOX"
            ) {
              //check if have value and save in subCollection categoryAttributes
              const multipleValues = attribute.inputValues[0];
              let multiple: any[] = [];
              for (let index = 0; index < multipleValues.length; index++) {
                const value = multipleValues[index];
                multiple.push({ ...value });
              }
              inputValues.push({ ...multiple });
              console.log(
                `input values multiple : ${JSON.stringify(inputValues)}`
              );
            } else {
              if (attribute.inputValues[0] || attribute.inputValues[1]) {
                inputValues.push(attribute.inputValues[0] || null);
              }
              attribute.inputValues[1] &&
                inputValues.push(attribute.inputValues[1]);
              console.log(
                `input values single : ${JSON.stringify(inputValues)}`
              );
            }
            const categoryAttributeModel =
              await actions.formatProductCategoryAttributes(
                attribute.attributeId.toString(),
                inputValues,
                categoryId,
                "shopee",
                refUser
              );
            console.log(
              `categoryAttributeModel : ${JSON.stringify(
                categoryAttributeModel
              )}`
            );
            await actions.createDataProductCategoryAttributes(
              productID,
              attribute && attribute.attributeId
                ? attribute.attributeId.toString()
                : "",
              categoryAttributeModel
            );
          }
        }
      }

      if (isSuccess) {
        console.log("masuk succes");
        dispatch(ReduxProduct.cleanReduxProduct(true));
        setIsLoading(false);
        nav("/product/list");
      } else {
        //Revert Data and Alert Failed Save Data
        actions.revertUploadAndSavedMedia(
          resultFirestorageURL,
          resultPhotosMediaID,
          resultVideoMediaID,
          newProduct.id
        );
        const failedMediaAlertNotif = document.getElementById(
          "failedProcessAlertNotif"
        ) as HTMLDivElement;
        failedMediaAlertNotif.innerHTML = t("AddNewProduct.Alert.SavingFailed");
        const buttonFailedAlert = document.getElementById(
          "AddNewProduct.Alert.SavingFailed"
        );
        if (buttonFailedAlert) {
          buttonFailedAlert.click();
        }
      }
    } else {
      //Modal retry Save
      const failedMediaAlertNotif = document.getElementById(
        "failedProcessAlertNotif"
      ) as HTMLDivElement;
      failedMediaAlertNotif.innerHTML = t("AddNewProduct.Alert.SavingFailed");
      const buttonFailedAlert = document.getElementById(
        "AddNewProduct.Alert.SavingFailed"
      );
      if (buttonFailedAlert) {
        buttonFailedAlert.click();
      }
    }
  };

  const handleUnsavedChanges = () => {
    console.log("masuk ke unsaved changes");
    dispatch(ReduxProduct.cleanReduxProduct(true));
    nav("/product/list");
  };

  const initProduct = () => {
    let newProduct = { ...reduxNewProduct };
    newProduct.createdAt = Timestamp.now();
    newProduct.updatedAt = Timestamp.now();
    const userRef = createRef("user", lc.getItemLC(lc.LCName.UserID));
    newProduct.updatedBy = userRef;
    newProduct.createdBy = userRef;
    newProduct.isActive = true;
    dispatch(ReduxProduct.setNewProduct(newProduct));
  };

  const initBrand = (brandName: string) => {
    let newBrand: Brand = {};
    newBrand.createdAt = Timestamp.now();
    newBrand.updatedAt = Timestamp.now();
    const userRef = createRef("user", lc.getItemLC(lc.LCName.UserID));
    newBrand.updatedBy = userRef;
    newBrand.createdBy = userRef;
    newBrand.isActive = true;
    newBrand.brandName = brandName;
    newBrand.brandNameInsensitive = brandName.toLowerCase();
    return newBrand;
  };

  const uploadToFirestorage = async (
    file: any,
    filetype: string,
    fileName: string,
    path: string
  ) => {
    const inputcontentType = filetype;
    const inputFileName = fileName;
    return await uploadFile(file, inputcontentType, inputFileName, path);
  };

  useEffect(() => {
    dispatch(ReduxProduct.setLastStep(productStep.onBasicProductInformation));
    if (rd_isAddStorefront) {
      dispatch(ReduxProduct.setIsAddStorefront(false));
    }

    console.log("Basic Product Information Check");
    if (!reduxNewProduct) {
      initProduct();
    }
    setIsLoading(true);
    dispatch(ReduxProduct.setProductName(reduxProductName));

    // if (!reduxBrandList || reduxBrandList.length <= 0) {
    // setIsLoading(true);
    // getListBrands((brands: any[], modelBrands: any[]) => {
    //   console.log("Result Get List Brand 1");
    //   if (brands && brands.length > 0) {
    //     dispatch(ReduxProduct.setBrandList(brands));
    //   }
    // });
    // dispatch(ReduxProduct.setBrandList(arrayBrand))
    // }

    if (!reduxStorefrontlist || reduxStorefrontlist?.length <= 0) {
      getListStoreFront((storefronts: any[], modelStorefronst: any[]) => {
        console.log("Result Get List etalase 1");
        if (storefronts && storefronts.length > 0) {
          setListStorefront(storefronts);
        }
      });
    }

    setIsLoading(false);
  }, []);

  //useEffect for editing data
  // useEffect(() => {
  //     if (rd_productID){
  //       let newProduct = {...reduxNewProduct}
  //       const getProduct: any = productList.find((product) =>  product.id === rd_productID);
  //       //Storefront
  //       let dataStorefront:any;
  //       let storefrontName:string;
  //       let storefrontSetter:any;
  //       //Brand
  //       let dataBrand:any;
  //       let brandName:string;
  //       let brandSetter:any;
  //       if(getProduct.name){
  //         if(!reduxNewProduct.name){
  //           console.log("productname that's get: ", getProduct.name);
  //           dispatch(ReduxProduct.setProductName(getProduct.name))
  //           dispatch(ReduxProduct.setProductNameLength(getProduct.name.length))
  //           newProduct.name = getProduct.name;
  //           newProduct.nameInsensitive = getProduct.name.trim().toLowerCase();
  //           dispatch(ReduxProduct.setNewProduct(newProduct))
  //         }else{
  //           console.log("data product name is avaible")
  //         }
  //       }
  //       if(getProduct.storeFront){
  //         if(!reduxNewProduct.storeFront){
  //           console.log("storefront that's get: ", getProduct.storeFront.id);
  //           getStorefrontById(getProduct.storeFront.id).then(
  //             (data) => {
  //             dataStorefront = data;
  //             storefrontName = dataStorefront.data.name;
  //             storefrontSetter = {
  //               label: storefrontName,
  //               value: getProduct.storeFront.id
  //             }
  //             console.log("SRF, DATA SET TO REDUX ", storefrontSetter)
  //             dispatch(ReduxProduct.setSelectedStoreFrontData(storefrontSetter));
  //           })
  //         }else{
  //           console.log("data product storefront is avaible")
  //         }
  //       }
  //       if(getProduct.brand){
  //         if(!reduxNewProduct.brand){
  //           console.log("brand that's get: ", getProduct.brand.id);
  //           getBrandById(getProduct.brand.id).then(
  //             (data) => {
  //             dataBrand = data;
  //             console.log("BRN, DATA ", dataBrand)
  //             brandName = dataBrand.brandName;
  //             brandSetter = {
  //               label: brandName,
  //               value: getProduct.brand.id
  //             }
  //             console.log("BRN, DATA SET TO REDUX ", brandSetter)
  //             dispatch(ReduxProduct.setSelectedBrandData(brandSetter));
  //           })
  //         }else{
  //           console.log("data product brand is avaible")
  //         }
  //       }
  //       if(getProduct.description){
  //         if(!reduxNewProduct.description){
  //           console.log("description that's get: ", getProduct.description);
  //           dispatch(ReduxProduct.setDescriptionProduct(getProduct.description))
  //           dispatch(ReduxProduct.setDescriptionProductLength(getProduct.description.length))
  //           dispatch(ReduxProduct.setNewProduct({...newProduct, description : getProduct.description}))
  //         }else{
  //           console.log("data product description is avaible")
  //         }
  //       }
  //     }
  //   }, [rd_productID])

  useEffect(() => {
    console.log(
      "SUMM useEffect reduxSelectedStoreFrontData",
      reduxSelectedStoreFrontData
    );
    let newProduct = { ...reduxNewProduct };

    if (
      reduxSelectedStoreFrontData &&
      reduxSelectedStoreFrontData !== null &&
      reduxSelectedStoreFrontData.value &&
      reduxSelectedStoreFrontData.value !== null
    ) {
      console.log(
        "masuk if reduxSelectedStoreFrontData set data to new Product storefront"
      );
      const storefrontRef = createRef(
        "storefront",
        reduxSelectedStoreFrontData.value
      );
      newProduct.storeFront = storefrontRef;
      dispatch(ReduxProduct.setNewProduct(newProduct));
    }
  }, [reduxSelectedStoreFrontData]);

  useEffect(() => {
    let newProduct = { ...reduxNewProduct };
    if (
      reduxSelectedBrandData &&
      reduxSelectedBrandData !== null &&
      reduxSelectedBrandData.value &&
      reduxSelectedBrandData.value !== null
    ) {
      const brandRef = createRef("brands", reduxSelectedBrandData.value);
      newProduct.brand = brandRef;
      dispatch(ReduxProduct.setNewProduct(newProduct));
    }
  }, [reduxSelectedBrandData]);

  console.log(`testing find item${[1, 100, 20].find((item) => item >= 5)}`);
  return (
    <>
      {!isSummary && (
        <PageTitle breadcrumbs={UsersBreadcrumbs}>{`${t(
          "AddNewProduct.Title.BPI"
        )}`}</PageTitle>
      )}

      {/* Modal Success & Modal Failed*/}

      {!isSummary && (
        <div>
          <button
            id="triggerSuccessProcess"
            data-bs-toggle="modal"
            data-bs-target="#modal_success_process"
            hidden
          ></button>
          <AlertSuccessProcess />

          <button
            id="triggerFailedProcess"
            data-bs-toggle="modal"
            data-bs-target="#modal_failed_process"
            hidden
          ></button>
          <AlertFaieldProcess />
          {/* End of Modal Success & Modal Failed*/}
        </div>
      )}

      {!isSummary && (
        <>
          <ModalConfirmationMoveToOtherPage
            isDirty={
              reduxProductName.trim().length > 0 ||
              (reduxSelectedStoreFrontData !== null &&
                reduxSelectedStoreFrontData !== "") ||
              (reduxSelectedBrandData !== null &&
                reduxSelectedBrandData !== "") ||
              reduxDescriptionProduct.trim().length > 0
            }
            path={`${URI}/`}
            secondPath="/storefront/create-storefront"
            onSave={() =>
              handleSavedChanges().then(() => {
                setIsLoading(false);
                dispatch(ReduxProductList.cleanReduxProductList(true));
                dispatch(ReduxProduct.cleanReduxProduct(true));
              })
            }
            onUnsave={() => handleUnsavedChanges()}
            isEmpty={
              reduxProductName.trim().length > 0 ||
              (reduxSelectedStoreFrontData !== null &&
                reduxSelectedStoreFrontData !== "") ||
              (reduxSelectedBrandData !== null &&
                reduxSelectedBrandData !== "") ||
              reduxDescriptionProduct.trim().length > 0
                ? false
                : true
            }
          />

          <ModalConfirmationWihtButtonSavedUnsavedChanges
            id="handleCancelButton"
            title={`${t("Alert.Info")}`}
            body={t("Alert.UnsavedChanges")}
            savedOption={`${
              reduxProductName.trim().length > 0 ||
              (reduxSelectedStoreFrontData !== null &&
                reduxSelectedStoreFrontData !== "") ||
              (reduxSelectedBrandData !== null &&
                reduxSelectedBrandData !== "") ||
              reduxDescriptionProduct.trim().length > 0
                ? t("Alert.Button.SaveChanges")
                : ""
            }`}
            unsavedOption={`${
              reduxProductName.trim().length > 0 ||
              (reduxSelectedStoreFrontData !== null &&
                reduxSelectedStoreFrontData !== "") ||
              (reduxSelectedBrandData !== null &&
                reduxSelectedBrandData !== "") ||
              reduxDescriptionProduct.trim().length > 0
                ? t("Alert.Button.LeaveWithoutSave")
                : t("Common.Yes")
            }`}
            cancelOption={`${t("Alert.Button.Cancel")}`}
            onSavedChanges={() =>
              handleSavedChanges().then(() => {
                setIsLoading(false);
                dispatch(ReduxProductList.cleanReduxProductList(true));
                dispatch(ReduxProduct.cleanReduxProduct(true));
                nav("/product/list");
              })
            }
            onUnsavedChanges={() => handleUnsavedChanges()}
          />
        </>
      )}

      {/* <ReactRouterPrompt when={titleEtalaseLength > 0}>
        {({isActive, onConfirm, onCancel}) => isActive && (
          <ModalConfirmationWithStateHandler 
            isModalOpen={isActive}
            id="modalLeave"
            title={`${ t("Alert.Info")}`}
            body={ t("Alert.UnsavedChanges")}
            confirmText="Hello"

            onConfirm={() => console.log("CONF")}
            onClose={() => console.log("Hello")}
          />
        )}
      </ReactRouterPrompt> */}

      {/* { !isTabletOrMobile &&  
        <div className="card mb-1 mb-xl-2 p-3 d-flex flex-row space">
          <div>
            <div className="h2">
              <h2 className="w-100">
                <span
                  className="bi bi-window-plus fs-2x"
                  style={{ marginRight: "10px", marginLeft: "10px" }}
                ></span>
                {t("AddNewProduct.Title.BPI")}
              </h2>
            </div>
          </div>
        </div>
      } */}

      <div
        className={clsx(
          "d-flex flex-column flex-lg-column",
          isSummary ? "" : "h-100"
        )}
      >
        {!isTabletOrMobile &&
          !isSummary &&
          ProductHeaderTitle("AddNewProduct.Title.BPI")}
        <div
          className="d-flex flex-column flex-lg-column"
          style={{ minHeight: isSummary ? "" : "92.5%" }}
        >
          <div
            // className="overflow-hidden card mb-1 mb-xl-2 p-7 h-100"
            className={clsx(
              isSummary ? "" : "overflow-hidden card",
              `h-100 ${isTabletOrMobile ? "" : isSummary ? "" : "p-7"}`
            )}
          >
            {isTabletOrMobile && !isSummary && <ProductMobileIcon />}
            <div
              style={{
                height: "90%",
                // isSummary ? "" : "90%",
              }}
              className={clsx(
                `d-flex flex-column ${
                  isTabletOrMobile && !isSummary ? "p-5" : ""
                }`
              )}
            >
              <div
                className={clsx(
                  // "flex-lg-column-fluid tab-content h-100",
                  "flex-lg-column-fluid tab-content p-5",
                  {
                    "h-100": !isSummary,
                    "overflow-auto": !isTabletOrMobile && !isSummary,
                  }
                )}
              >
                <div className="d-flex flex-column">
                  {/* For Basic Information Page */}
                  {/* For Summary Page */}
                  {isSummary && (
                    <div>
                      <div className="step-sub-title mb-4 fs-1">
                        <label className="form-check-label">
                          {t("AddNewProduct.Info.Summary")}
                        </label>
                      </div>
                      <div className="step-title mt-5 mb-2 fs-7" id="bpi-name">
                        <label className="form-check-label">
                          {t("AddNewProduct.Info.ProductName")}
                        </label>
                      </div>
                      <div className="card mb-1 d-flex flex-row space">
                        <div>
                          {!isInsertProductName && (
                            <div
                              className="btn p-0 d-flex flex-row align-items-center"
                              onClick={() => {
                                setIsInsertProductName(true);
                              }}
                              data-testid="summary-product-name-title"
                              role="campaign-name-title"
                            >
                              {/* Edit Product Name */}
                              {true && (
                                <h1 id="newProductName" className="mb-0">
                                  {reduxProductName}
                                </h1>
                              )}
                              <i
                                className="bi bi-pencil-fill fs-3 align-items-center ms-2 me-2"
                                onClick={() => {
                                  setIsInsertProductName(true);
                                }}
                              ></i>
                            </div>
                          )}

                          {isInsertProductName && (
                            <div className="d-flex flex-row mb-3">
                              <input
                                ref={productNameRef}
                                type="text"
                                maxLength={255}
                                minLength={25}
                                className="form-control form-control-solid w-200px"
                                placeholder={t(
                                  "AddNewProduct.Info.ProductName"
                                )}
                                onChange={onChangeProductName}
                                data-testid="summary-input-product-name"
                                role="input-campaign-name"
                              />
                              <input
                                type="submit"
                                value="OK"
                                className="form-control form-control-solid w-50px ms-3"
                                onClick={() => setIsInsertProductName(false)}
                                data-testid="submit-product-name"
                                role="submit-product-name"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="d-flex"
                        data-testid="summaryProductNameInvalid"
                      >
                        {!isValidProductName && (
                          <div
                            style={{ color: "red" }}
                            id="bpi-error-product-name"
                            role="alert-input-productname"
                          >
                            {t("AddNewProduct.Alert.ProductName")}
                          </div>
                        )}
                        {isProductNameHasExist && isValidProductName && (
                          <span
                            style={{ color: "red" }}
                            id="bpi-error-product-name-exist"
                            role="alert-prodcutname-exist"
                          >
                            {t("AddNewProduct.Alert.ProductName.AlreadyExist")}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  {/* SKU Parent Input */}
                  <div className="step-sub-title mt-5 mb-2 fs-4">
                    <label className="form-check-label">
                      {t("AddNewProduct.TitleField.ParentSKU")}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className={clsx(
                      `"d-flex flex-row mb-2 ${
                        isTabletOrMobile ? "w-100" : "w-75"
                      }`
                    )}
                  >
                    <div className="w-100">
                      <div className="d-flex align-items-center form-control border-2 px-0 py-0">
                        <input
                          data-testid="bpi-input-sku"
                          id="bpi-input-sku"
                          name="bpi-input-sku"
                          maxLength={50}
                          className="form-control border-0"
                          onChange={onChangeProductSKU}
                          value={reduxProductSKU}
                          placeholder={t("AddNewProduct.InputField.ParentSKU")}
                        />
                        <p
                          className="border-0 bg-white my-0"
                          style={{
                            paddingRight: "10px",
                            color: "gray",
                            textAlign: "center",
                          }}
                        >
                          {reduxProductSKULength}/50
                        </p>
                      </div>
                      <div className="d-flex">
                        {isValidProductSKU === false && (
                          <div
                            style={{ color: "red" }}
                            role="alert-input-productname"
                            data-testid="productSKUTitleErrValid"
                          >
                            {t("AddNewProduct.Alert.ProductSKU")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {!isSummary && (
                    <div>
                      {isTabletOrMobile && (
                        <div className="d-flex flex-column mt-5">
                          {ProductStep(1, "AddNewProduct.Title.BPI")}
                        </div>
                      )}
                      {/* Product Name Input */}
                      <div className="step-sub-title mb-2 fs-4">
                        <label className="form-check-label">
                          {t("AddNewProduct.Info.ProductName")}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div
                        className={clsx(
                          `"d-flex flex-row mb-2 ${
                            isTabletOrMobile ? "w-100" : "w-75"
                          }`
                        )}
                      >
                        <div className="w-100">
                          <div className="d-flex align-items-center form-control border-2 px-0 py-0">
                            <input
                              data-testid="bpi-input-product-name"
                              id="bpi-input-product-name"
                              name="bpi-input-product-name"
                              maxLength={255}
                              minLength={25}
                              className="form-control border-0"
                              onChange={onChangeProductName}
                              value={reduxProductName}
                              placeholder={t("AddNewProduct.Input.ProductName")}
                            />
                            <p
                              className="border-0 bg-white my-0"
                              style={{
                                paddingRight: "10px",
                                color: "gray",
                                textAlign: "center",
                              }}
                            >
                              {reduxProductNameLength}/255
                            </p>
                          </div>
                          <div className="d-flex">
                            {!isValidProductName && (
                              <div
                                style={{ color: "red" }}
                                role="alert-input-productname"
                                data-testid="productNameTitleErrValid"
                              >
                                {t("AddNewProduct.Alert.ProductName")}
                              </div>
                            )}
                            {isProductNameHasExist && isValidProductName && (
                              <span
                                style={{ color: "red" }}
                                role="alert-prodcutname-exist"
                              >
                                {t(
                                  "AddNewProduct.Alert.ProductName.AlreadyExist"
                                )}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Call Media Page */}
                  {isSummary && (
                    <MediaUpload
                      isSummary={true}
                      onClickUpload={onClickUpload}
                    />
                  )}

                  {/* Store Front Selection Input */}
                  <div
                    className="step-sub-title mt-5 mb-2 fs-4"
                    id="bpi-storefront"
                  >
                    <label className="form-check-label">
                      {t("AddNewProduct.Info.Storefront")}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className={clsx(
                      `"d-flex flex-row mb-2 ${
                        isTabletOrMobile ? "w-100" : "w-75"
                      }`
                    )}
                  >
                    <div className="w-100">
                      <div className="d-flex align-items-center form-control border-2 px-0 py-0">
                        <Select
                          data-testid="bpi-select-storefront"
                          id="bpi-select-storefront"
                          name="bpi-select-storefront"
                          placeholder={t("AddNewProduct.InputStorefront")}
                          value={reduxSelectedStoreFrontData}
                          options={listStorefront}
                          onChange={onChangeStorefront}
                          className="w-100 border-0"
                          isClearable={true}
                          handleAdd={toAddEtalase}
                          itemCreateText={t(
                            "AddNewProduct.Button.SM.AddNewStorefront"
                          )}
                          components={{ Menu: AddDataButton }}
                          maxMenuHeight={120}
                        />
                      </div>
                      <div
                        className="d-flex"
                        data-testid="storefrontTitleErrValid"
                      >
                        {isNullSelectedStorefront && (
                          <div
                            style={{ color: "red" }}
                            id="bpi-product-storefront"
                            role="alert-select-storefront"
                          >
                            {t("AddNewProduct.Alert.Storefront")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Brand Selection Input */}
                  <BrandSelect
                    isNullBrandValue={isNullBrandValue}
                    selectedBrand={reduxSelectedBrandData}
                    handleCreateBrand={handleCreateBrand}
                    onChangeBrand={onChangeBrand}
                  />

                  {/* Description Input */}
                  <div
                    className="step-sub-title mt-5 mb-2 fs-4"
                    id="bpi-description"
                  >
                    <label className="form-check-label">
                      {t("AddNewProduct.Info.Description")}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className={clsx(
                      `"d-flex flex-row mb-2 ${
                        isTabletOrMobile ? "w-100" : "w-75"
                      }`
                    )}
                  >
                    <div className="w-100">
                      <div
                        className="textarea-container align-items-end form-control border-2 px-1 py-0"
                        style={{
                          position: "relative",
                        }}
                      >
                        <textarea
                          data-testid="bpi-input-description"
                          id="bpi-input-description"
                          name="bpi-input-description"
                          minLength={20}
                          maxLength={3000}
                          className="form-control border-0"
                          onChange={onChangeDescriptionProduct}
                          value={reduxDescriptionProduct}
                          style={{
                            height: isSummary ? "300px" : "calc(35vh - 100px)",
                            resize: "none",
                            boxSizing: "border-box",
                          }}
                          rows={5}
                        />
                        <p
                          className="border-0 bg-white my-5 py-0"
                          style={{
                            position: "absolute",
                            bottom: "5px",
                            right: "5px",
                            paddingRight: "5px",
                            color: "gray",
                            textAlign: "center",
                            top: "95%",
                            transform: "translateY(95%)",
                          }}
                        >
                          {reduxDescriptionProductLength}/3000
                        </p>
                      </div>
                      <div
                        className="d-flex"
                        data-testid="productDescriptionErrValid"
                      >
                        {!isValidProductDescription && (
                          <div
                            style={{ color: "red" }}
                            id="bpi-error-product-description"
                            // eslint-disable-next-line jsx-a11y/aria-role
                            role="alert-input-description"
                          >
                            {t("AddNewProduct.Alert.Description")}
                          </div>
                        )}
                      </div>
                      <div
                        className="d-flex"
                        data-testid="productDescriptionErrValid"
                      >
                        {!isValidLengthProductDescription && (
                          <div
                            style={{ color: "red" }}
                            id="bpi-error-product-description"
                            role="alert-input-description"
                          >
                            {t(
                              "AddNewProduct.Description.Alert.MinimalCharacter"
                            )}
                          </div>
                        )}
                      </div>
                      <Button
                        id="btnValidateDataBPI"
                        data-testid="btnValidateDataBPI"
                        type="button"
                        btnbs="primary"
                        cName="mx-5 "
                        style={{
                          visibility: "hidden",
                          pointerEvents: "none",
                          height: "0px",
                          padding: "0.1px",
                        }}
                        onClick={() => validateData()}
                      >
                        {`${t("AddNewProduct.Button.BPI.Next")}`}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!isSummary && (
              <div
                className={clsx(
                  `d-flex bg-white justify-content-end mt-auto ${
                    isTabletOrMobile ? "mb-5" : ""
                  }`
                )}
              >
                <Link to="/product/list">
                  <Button data-testid="btnCancel" btnbs="primary" type="button">
                    {t("AddNewProduct.Button.BPI.Cancel")}
                  </Button>
                </Link>
                <Button
                  data-testid="btnNext"
                  type="button"
                  btnbs="primary"
                  cName="mx-5"
                  onClick={() => handleNext()}
                >
                  {`${t("AddNewProduct.Button.BPI.Next")}`}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
    </>
  );
};

const BrandSelect: FC<{
  isNullBrandValue: boolean;
  selectedBrand: any;
  handleCreateBrand: (newBrand: Brand) => Promise<void>;
  onChangeBrand: (event: any) => Promise<void>;
}> = ({
  isNullBrandValue,
  selectedBrand,
  handleCreateBrand,
  onChangeBrand,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const [isShowModal, setIsShowModal] = useState(false);
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();

  const handleCreateBrandWithModalClose = async (newBrand: Brand) => {
    await handleCreateBrand(newBrand);
    setIsShowModal(false);
  };

  let timer = useRef<any>();
  const MESSAGE = {
    initial: t("AddNewProduct.Input.Brand.FieldText"),
    noOption: t("AddNewProduct.Input.Brand.AnotherBrand"),
    charLength: t("AddNewProduct.Input.Brand.MinimalCharacter"),
    networkError: t("AddNewProduct.Input.Brand.ErrorNetwork"),
  };

  const messageRef = useRef<string>(MESSAGE.noOption);

  async function getSearchedBrand(inputValue: string) {
    if (!inputValue) {
      return [];
    }
    const dataBrand = await searchBrands(inputValue);
    if (!dataBrand || dataBrand.length < 1) {
      return [];
    }
    return dataBrand.map((item) => ({
      label: item.data.brandName,
      value: item.id,
    }));
  }

  return (
    <>
      <div className="step-sub-title mt-5 mb-2 fs-4" id="bpi-brand">
        <label className="form-check-label">
          {t("AddNewProduct.Info.Brand")}
          <span className="text-danger">*</span>
        </label>
      </div>
      <div
        className={clsx(
          `"d-flex flex-row mb-2 ${isTabletOrMobile ? "w-100" : "w-75"}`
        )}
      >
        <div className="w-100">
          <div
            className="d-flex align-items-center form-control border-2 px-0 py-0"
            data-testid="bpi-select-brand"
          >
            <AsyncSelect
              autoFocus={searchParams.get("focus") === "brand"}
              id="bpi-select-brand"
              name="bpi-select-brand"
              value={selectedBrand}
              loadOptions={(inputValue: string, callback) => {
                if (inputValue.length < 2) {
                  messageRef.current = MESSAGE.charLength;
                  return callback([]);
                }
                clearTimeout(timer.current);
                // debounce 300ms
                timer.current = setTimeout(() => {
                  getSearchedBrand(inputValue)
                    .then((options) => {
                      if (!options.length) {
                        messageRef.current = MESSAGE.noOption;
                      }
                      callback(options);
                    })
                    .catch((err) => {
                      messageRef.current = MESSAGE.networkError;
                      callback([]);
                    });
                }, 1000);
              }}
              onChange={onChangeBrand}
              className="w-100 border-0"
              isClearable={true}
              placeholder={t("AddNewProduct.Input.Brand")}
              filterOption={createFilter({ ignoreAccents: false })}
              noOptionsMessage={({ inputValue }) =>
                inputValue ? messageRef.current : MESSAGE.initial
              }
              styles={{
                menuList: (base) => ({
                  ...base,
                  maxHeight: "10rem",
                }),
              }}
              components={{
                MenuList: ({ children, ...props }) => {
                  return (
                    <components.MenuList {...props}>
                      {children}
                      {props.options.length > 0 ? (
                        <div className="px-4 py-2 fs-7 bg-warning">
                          {t("AddNewProduct.Input.Brand.FieldText2")}
                        </div>
                      ) : null}
                    </components.MenuList>
                  );
                },
                Menu: AddDataButton,
              }}
              itemCreateText={t("AddNewProduct.Button.SM.AddBrand")}
              handleAdd={() => setIsShowModal(true)} //TODO
            />
          </div>
          <div className="d-flex" data-testid="brandTitleErrValid">
            {isNullBrandValue && (
              <div
                style={{ color: "red" }}
                id="bpi-error-product-brand"
                role="alert-select-brand"
              >
                {t("AddNewProduct.Alert.Brand")}
              </div>
            )}
          </div>
        </div>
      </div>

      {isShowModal && (
        <ModalAddBrandProduct
          id={`brand-add`}
          isModalOpen={isShowModal}
          title={t("AddNewProduct.Modal.Title")}
          // subtitle={"tes modal subtitle"}
          cancelOption={t("AddNewProduct.Button.BPI.Cancel")}
          confirmOption={t("Storefront.Button.Save")}
          onConfirm={handleCreateBrandWithModalClose}
          onCancel={() => setIsShowModal(false)}
        />
      )}
    </>
  );
};
export default BasicProductInformation;
