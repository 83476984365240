import clsx from "clsx";
import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAccountByCompanyAndMarketplace } from "../../../../../../actions/account";
import * as actions from "../../../../../../actions/actions";
import { Timestamp, createRef } from "../../../../../../db";
import { RootState } from "../../../../../../setup/redux/store";
import Button from "../../../../../../styles/components/Button";
import {
  attributeList,
  attributeValueList,
  productStatus,
  productStep,
} from "../../../../../models/Product";
import * as lc from "../../../../../modules/localstorage";
import ModalConfirmationMoveToOtherPage from "../../../../../modules/modal/modalConfirmationMoveToOtherPage";
import { ProductHeaderTitle } from "../../../../../modules/product/productHeader/ProductHeaderTitle";
import { ProductStep } from "../../../../../modules/product/productHeader/productStep";
import * as ReduxProductList from "../../../../../modules/product/productList/ProductListSlice";
import * as ReduxProduct from "../../../../../modules/product/redux/ProductSlice";
import { Loading } from "../../../../../modules/util/Loading";
import { uploadFile } from "../../../../../modules/util/Utils";
import { PageLink, PageTitle } from "../../../../core";
import BasicProductInformation from "./BasicProductInformation";
import SelectCompanyAndMarketplace from "./CompanyAndMarketplace";
import ProductMobileIcon from "./ProductMobileIcon";
import ProductVariantsList from "./ProductVariantsList";
import ProductWeight from "./ProductWeight";
import Shipments from "./Shipments";
import ProductCategoryAttributes from "./ProductCategoryAttributes";
import { ShopeeProductCategoryAttributeList } from "../../../../../models/ShopeeProductCategoryAttribute";
import { categoryAttributes } from "../../../../../modules/product/redux/ProductRedux";
import { getAttributesByMarketplaceAndCategoryId } from "../../../../../../actions/marketplaceproductcategory";
import * as ReduxMarketplaceProductCategory from "../../../../../modules/product/marketplaceProductCategory/marketplaceProductCategorySlice";

const ProductSummary: FC = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();
  const nav = useNavigate();
  const id = useParams();

  console.log("PROD EDIT: id", id);

  const reduxProductName = useSelector(
    (state: RootState) => state.Product.productName
  );
  const reduxProductWeight = useSelector(
    (state: RootState) => state.Product.productWeight
  );
  const reduxProductHeight = useSelector(
    (state: RootState) => state.Product.productDimensionHeight
  );
  const reduxProductWidth = useSelector(
    (state: RootState) => state.Product.productDimensionWidth
  );

  const reduxProductLength = useSelector(
    (state: RootState) => state.Product.productDimensionLength
  );

  const reduxSelectedStoreFrontData = useSelector(
    (state: RootState) => state.Product.selectedStoreFrontData
  );

  const reduxSelectedBrandData = useSelector(
    (state: RootState) => state.Product.selectedBrandData
  );

  const rd_tempSelectedStorefront: string = useSelector(
    (state: RootState) => state.Product.tempSelectedStorefront
  );

  const rd_categoryAttributes = useSelector(
    (state: RootState) => state.Product.categoryAttributes
  );

  const reduxShopeeProductCategoryData = useSelector(
    (state: RootState) => state.MarketplaceProductCategory.marketplaceShopee
  );

  const UsersBreadcrumbWithoutVariant = [
    {
      title: t("Storefront.Column.Product"),
      path: "/product/list",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.BPI"),
      path: "/product/create-product/basic-information",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.Media"),
      path: "/product/create-product/media-upload",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Info.Weight"),
      path: "/product/create-product/package-weight",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.Shipment"),
      path: "/product/create-product/shipments",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.SelectCompany&Marketplace"),
      path: "/product/create-product/select-company-and-marketplace",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.VariantsCategory"),
      path: "/product/create-product/variants-category",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const UsersBreadcrumbsWithVariant = [
    {
      title: t("Storefront.Column.Product"),
      path: "/product/list",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.BPI"),
      path: "/product/create-product/basic-information",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.Media"),
      path: "/product/create-product/media-upload",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Info.Weight"),
      path: "/product/create-product/package-weight",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.Shipment"),
      path: "/product/create-product/shipments",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.SelectCompany&Marketplace"),
      path: "/product/create-product/select-company-and-marketplace",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.VariantsCategory"),
      path: "/product/create-product/variants-category",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.VariantList"),
      path: "/product/create-product/variants-list",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const UsersBreadcrumbWithoutVariantWithSpecification = [
    {
      title: t("Storefront.Column.Product"),
      path: "/product/list",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.BPI"),
      path: "/product/create-product/basic-information",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.Media"),
      path: "/product/create-product/media-upload",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Info.Weight"),
      path: "/product/create-product/package-weight",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.Shipment"),
      path: "/product/create-product/shipments",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.SelectCompany&Marketplace"),
      path: "/product/create-product/select-company-and-marketplace",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.Specification"),
      path: `/product/create-product/category-attributes`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.VariantsCategory"),
      path: "/product/create-product/variants-category",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const UsersBreadcrumbsWithVariantWithSpecification = [
    {
      title: t("Storefront.Column.Product"),
      path: "/product/list",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.BPI"),
      path: "/product/create-product/basic-information",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.Media"),
      path: "/product/create-product/media-upload",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Info.Weight"),
      path: "/product/create-product/package-weight",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.Shipment"),
      path: "/product/create-product/shipments",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.SelectCompany&Marketplace"),
      path: "/product/create-product/select-company-and-marketplace",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.Specification"),
      path: `/product/create-product/category-attributes`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.VariantsCategory"),
      path: "/product/create-product/variants-category",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.VariantList"),
      path: "/product/create-product/variants-list",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const OnEditBreadCrumb = [
    {
      title: t("Storefront.Column.Product"),
      path: "/product/list",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const dispatch = useDispatch();

  const [isClickUpload, setIsClickUpload] = useState(false);

  const isAllCompanyNotActive = (company: any[]) => {
    if (company?.length === 0) {
      return true;
    }
    return company.every((company) => company.isActive === false);
  };

  const isCompanyActiveAndHaveAtleastOneMarketplaceSelected = (
    companies: any[],
    marketplaces: any[]
  ) => {
    for (const company of companies) {
      const companyMarketplaces = marketplaces.filter(
        (mp) => mp.company === company.company
      );
      let hasActiveMarketplace = false;
      for (const mp of companyMarketplaces) {
        if (mp.isActive) {
          hasActiveMarketplace = true;
          break;
        }
      }
      if (!hasActiveMarketplace) {
        return false;
      }
    }
    return true;
  };

  const marketplaceValidation = () => {
    let validation: any = [];
    for (let index = 0; index < rd_marketplaces.length; index++) {
      const marketplace = rd_marketplaces[index];
      const findCompany = rd_SelectedCompanies.find(
        (company) => company.company === marketplace.company
      );

      if (
        findCompany.isActive &&
        marketplace &&
        marketplace.isActive === true &&
        marketplace.company === findCompany.company &&
        (marketplace.category === undefined ||
          marketplace.category?.length <= 0)
      ) {
        validation = [...validation, false];
        const errorMarketplace: any =
          document.getElementsByClassName("alert-marketplace");
        errorMarketplace[0]?.scrollIntoView({ behavior: "smooth" });
      } else {
        validation = [...validation, true];
      }
    }
    console.log("validation : ", validation);
    if (validation.includes(false)) {
      return false;
    } else {
      return true;
    }
  };

  const isDataValidVariant = (variantsMatrix: any) => {
    for (const variant of variantsMatrix) {
      if (!variant.sku || typeof variant.sku !== "string") {
        return false;
      }
      if (!variant.weight || variant.weight === 0) {
        return false;
      }
    }
    const optList: any = rd_Variants[0]?.optionList;
    if (rd_VariantPhotos.length < optList.length) {
      return false;
    }
    return true;
  };

  const validateURL = async (url: string) => {
    let src = `https://www.youtube.com/oembed?url=${url}&format=json`;
    try {
      const response = await fetch(src);
      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const categoryAttributeValidation = (
    attributeList: ShopeeProductCategoryAttributeList[]
  ) => {
    for (const att of attributeList) {
      if (
        att.isMandatory &&
        (!att.inputValues ||
          !att.inputValues[0] ||
          (att.inputValues[0] && att.inputValues[0] === "") ||
          (att.inputValues[1] && att.inputValues[1] === ""))
      ) {
        return false;
      }
    }
    return true;
  };

  const validateData = async () => {
    // simulate click
    document.getElementById("btnValidateDataBPI")?.click();
    document.getElementById("btnValidateDataMedia")?.click();
    document.getElementById("btnValidateDataWeight")?.click();

    // validate BPI
    if (
      reduxProductName.trim() === "" ||
      reduxProductName === "" ||
      reduxProductName?.length === 0 ||
      reduxProductName === " "
    ) {
      document
        .getElementById("bpi-name")
        ?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    console.log("STEP 0 -> BPI Name");
    if (
      reduxSelectedStoreFrontData === "" ||
      reduxSelectedStoreFrontData === null
    ) {
      document
        .getElementById("bpi-storefront")
        ?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    console.log("STEP 1 -> BPI Storefront");

    //check is brand selected
    if (reduxSelectedBrandData === "" || reduxSelectedBrandData === null) {
      document
        .getElementById("bpi-brand")
        ?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    console.log("STEP 2 -> Brand");
    // check is description not null
    if (
      reduxDescriptionProduct.trim() === "" ||
      reduxDescriptionProduct === "" ||
      reduxDescriptionProduct?.length === 0
    ) {
      document
        .getElementById("bpi-description")
        ?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    console.log("STEP 3 -> BPI Desc");

    // Validate dimension

    if (
      reduxProductWeight === 0 ||
      reduxProductWeight.toString() === "" ||
      reduxProductWeight < 1 ||
      reduxProductWeight > 300000 ||
      reduxProductLength === 0 ||
      reduxProductLength.toString() === "" ||
      reduxProductLength < 1 ||
      reduxProductLength > 300 ||
      reduxProductWidth === 0 ||
      reduxProductWidth.toString() === "" ||
      reduxProductWidth < 1 ||
      reduxProductWidth > 300 ||
      reduxProductHeight === 0 ||
      reduxProductHeight.toString() === "" ||
      reduxProductHeight < 1 ||
      reduxProductHeight > 300
    ) {
      document
        .getElementById("product-weight")
        ?.scrollIntoView({ behavior: "smooth" });

      return;
    }

    console.log("STEP 4 -> Dimension");

    // Validate Media

    if (rd_VideoYoutubeURL !== "") {
      const isValid = await validateURL(rd_VideoYoutubeURL);
      if (!isValid) {
        document
          .getElementById("media-youtube-input")
          ?.scrollIntoView({ behavior: "smooth" });
        return;
      }
    }

    console.log("STEP 5 -> Youtube URL");

    if (rd_ProductPhotos && rd_ProductPhotos.length <= 0) {
      // adjust scrooll position
      const errorElements = [
        "media-invalid-photo-format",
        "media-invalid-photo-exceed-size",
        "media-invalid-photo-maximum-upload",
        "media-invalid-photo-required",
      ];

      setTimeout(() => {
        errorElements.forEach((id) => {
          const errorElement = document.getElementById(id);
          const imageElement = document.getElementById("section-photo");
          if (errorElement) {
            imageElement?.scrollIntoView({ behavior: "smooth" });
          }
        });
      }, 500);

      return;
    }

    console.log("STEP 6 -> Prod Photo");

    // Validate Shipment

    console.log("STEP Shipment", rd_Shipments);

    const filteredShipment = rd_Shipments.filter((sh) => sh.isActive === true);

    for (const shipment of filteredShipment) {
      if (
        shipment.isActive === true &&
        shipment.types &&
        shipment.types.length === 0
      ) {
        return;
      }
    }

    console.log("STEP 7 -> Shipment");

    if (!rd_SelectedCompanies || rd_SelectedCompanies.length <= 0) return;
    if (isAllCompanyNotActive(rd_SelectedCompanies)) return;

    if (
      !isCompanyActiveAndHaveAtleastOneMarketplaceSelected(
        rd_SelectedCompanies,
        rd_marketplaces
      )
    ) {
      setTimeout(() => {
        const errorMessage: any =
          document.getElementsByClassName("alert-marketplace");
        errorMessage[0]?.scrollIntoView({ behavior: "smooth" });
      }, 200);
      return;
    }
    if (rd_SelectedCompanies.length > 0 && rd_marketplaces.length > 0) {
      const marketplaceIsValid = marketplaceValidation();
      if (!marketplaceIsValid) return;
    }

    console.log("STEP 8 -> Companies");
    if (!rd_isProductHaveVariants || rd_isProductHaveVariants === "") return;
    if (rd_isProductHaveVariants === "true") {
      const dataValid = isDataValidVariant(rd_VariantMatrix);
      if (!dataValid) return;
    }

    console.log("STEP 9 -> Attributes");
    if (
      rd_categoryAttributes &&
      rd_categoryAttributes.shopee &&
      rd_categoryAttributes.shopee.length > 0
    ) {
      const attributesValid = categoryAttributeValidation(
        rd_categoryAttributes.shopee
      );
      if (!attributesValid) return;
    }

    console.log("STEP 10 -> Is Prod Have Var");
    setIsLoading(true);
    handleSavedChanges(true)
      .then(async () => {
        for await (const marketplace of rd_marketplaces) {
          console.log("VAR, marketplace loop,", marketplace);

          const findCompany = rd_SelectedCompanies.find(
            (company) => company.company === marketplace.company
          );
          if (marketplace.isActive === true && findCompany.isActive === true) {
            const findAccount = await getAccountByCompanyAndMarketplace(
              marketplace.company,
              marketplace.marketplace
            );
            console.log("findAccount: ", findAccount);
            console.log(
              "step to come to findAccount && findAccount.length > 0 && newProductID && useruid: ",
              findAccount,
              findAccount.length,
              newProductID,
              useruid
            );
            if (
              findAccount &&
              findAccount.length > 0 &&
              newProductID &&
              useruid
            ) {
              for (let index = 0; index < findAccount.length; index++) {
                const account = findAccount[index];
                console.log(
                  "data to request : ",
                  account.id,
                  newProductID,
                  useruid
                );
                if (account.id) {
                  // const requestProduct =
                  actions
                    .requestCreateProduct(account.id, newProductID, useruid)
                    .catch((error) =>
                      console.error(`product request error ${error}`)
                    );
                }
              }
            }
          }
        }
      })
      .finally(() => {
        console.log("AFTER VALIDATION SUCCESSFUL");
        dispatch(ReduxProduct.cleanReduxProduct(true));
        dispatch(ReduxProductList.cleanReduxProductList(true));
        setIsLoading(false);
        nav("/product/list");
      });
    //request to server side

    // setIsLoading(false);
    // dispatch(ReduxProduct.cleanReduxProduct(true));
    // nav("/product/list");
  };

  const [isLoading, setIsLoading] = useState(false);
  const titleRef: any = useRef(null);
  const [UsersBreadcrumbs, setUsersBreadcrumbs] = useState<any>();
  const useruid = lc.getItemLC(lc.LCName.UserID);
  const refUser = createRef("users", useruid);

  const reduxDescriptionProduct = useSelector(
    (state: RootState) => state.Product.descriptionProduct
  );

  const reduxNewProduct = useSelector(
    (state: RootState) => state.Product.newProduct
  );
  const rd_ProductPhotos = useSelector(
    (state: RootState) => state.Product.productPhotos
  );
  const rd_PhotosCoverIndex = useSelector(
    (state: RootState) => state.Product.coverIndex
  );
  const rd_ProductVideo = useSelector(
    (state: RootState) => state.Product.productVideo
  );
  const rd_VideoYoutubeURL = useSelector(
    (state: RootState) => state.Product.videoYoutubeURL
  );
  const rd_Shipments = useSelector(
    (state: RootState) => state.Product.shipments
  );

  const rd_SelectedCompanies = useSelector(
    (state: RootState) => state.Product.companies
  );

  const rd_marketplaces = useSelector(
    (state: RootState) => state.Product.marketplaces
  );

  const rd_isProductHaveVariants = useSelector(
    (state: RootState) => state.Product.isProductHaveVariants
  );
  const rd_Variants = useSelector((state: RootState) => state.Product.variants);
  const rd_VariantMatrix: any = useSelector(
    (state: RootState) => state.Product.variantsMatrix
  );
  const rd_VariantPhotos: any = useSelector(
    (state: RootState) => state.Product.productVariantPhotos
  );
  const rd_productID: any = useSelector(
    (state: RootState) => state.Product.productID
  );
  const rd_isCreatedProduct: any = useSelector(
    (state: RootState) => state.Product.isCreateProduct
  );
  const rd_isGenerateCategoryAttributes = useSelector(
    (state: RootState) => state.Product.isGenerateCategoryAttributes
  );
  let newProductID: string;
  const [refreshShipments, setRefreshShipments] = useState(false);
  const [refreshCategoryAttributes, setRefreshCategoryAttributes] =
    useState(false);

  useEffect(() => {
    dispatch(ReduxProduct.setLastStep(productStep.onSummary));

    if (rd_isProductHaveVariants === "true") {
      setUsersBreadcrumbs(
        rd_categoryAttributes.shopee.length > 0
          ? UsersBreadcrumbWithoutVariantWithSpecification
          : UsersBreadcrumbsWithVariant
      );
    } else {
      setUsersBreadcrumbs(
        rd_categoryAttributes.shopee.length > 0
          ? UsersBreadcrumbWithoutVariantWithSpecification
          : UsersBreadcrumbWithoutVariant
      );
    }
  }, [rd_isProductHaveVariants]);

  useEffect(() => {
    dispatch(ReduxProduct.setFromSummary(false));
    if (rd_productID !== "") {
      setRefreshShipments(true);
      setRefreshCategoryAttributes(true);
      setIsLoading(true);
    }
  }, []);

  const shipmentRefreshed = refreshShipments === true;
  useEffect(() => {
    setTimeout(() => {
      setRefreshShipments(false);
      setIsLoading(false);
    }, 500);
  }, [shipmentRefreshed]);

  const categoryAttributesRefresher = refreshCategoryAttributes === true;
  useEffect(() => {
    setTimeout(() => {
      setRefreshCategoryAttributes(false);
      setIsLoading(false);
    }, 1000);
  }, [categoryAttributesRefresher]);

  const uploadToFirestorage = async (
    file: any,
    filetype: string,
    fileName: string,
    path: string
  ) => {
    const inputcontentType = filetype;
    const inputFileName = fileName;
    return await uploadFile(file, inputcontentType, inputFileName, path);
  };

  const handleSavedChanges = async (isClickUploadParams?: boolean) => {
    setIsLoading(true);
    let resultFirestorageURL: string[] = [];
    let resultPhotosMediaID: string[] = [];
    let resultVideoMediaID: string = "";
    let isSuccess = true;
    let productID = "";
    let resultShipmentsId: string[] = [];
    let resultMarketplaceId: string[] = [];
    let resultVariantId: string[] = [];
    let resultProductVariantPhoto: string[] = [];
    let newProduct = { ...reduxNewProduct };

    let clientRef: any;
    const lcClient = lc.getItemLC(lc.LCName.Client);
    if (lcClient) {
      clientRef = createRef("clients", lcClient.id);
      newProduct.client = clientRef;
    }

    console.log("SUM Handle Save Change, IsClickUpload", isClickUpload);
    if (isClickUploadParams) {
      console.log("STATUS PRODUCT IS", productStatus.created);
      newProduct.status = productStatus.created;
      newProduct.lastStep = "";
    } else {
      newProduct.status = productStatus.drafted;
      newProduct.lastStep = productStep.onSummary;
    }
    if (!newProduct || !newProduct.id || newProduct.id === "") {
      //Create Product
      productID = await actions.createDataProduct(newProduct);
      console.log("Saved Product : " + productID);
      newProduct.id = productID;
      newProductID = productID;
      console.log("isi dari new newProductID: ", newProductID);
      dispatch(ReduxProduct.setNewProduct(newProduct));
    } else {
      //Update Product
      productID = newProduct.id;
      newProductID = rd_productID;
      newProduct.updatedAt = Timestamp.now();
      newProduct.updatedBy = refUser;
      actions.updateDataProduct(productID, newProduct);
      actions.deleteMediaDocData(productID);
      actions.deleteShipmentsData(productID);
      actions.deleteCompanyAndMarketplacesData(productID);
      actions.removeDataVariants(productID);
      actions.removeDataProductVariants(productID);
      actions.removeDataCategoryAttributes(productID);
    }
    //update numberofproducts storefront realtime db
    if (
      reduxNewProduct &&
      reduxNewProduct.storeFront &&
      reduxNewProduct.storeFront.id
    ) {
      const storefrontId = reduxNewProduct.storeFront.id.toString();
      //jika punya tempStorefront dan id selected storefront sama tidak perlu update
      //jika temp dan id storefront tidak sama update increment dan decrement
      if (
        rd_tempSelectedStorefront &&
        rd_tempSelectedStorefront !== reduxNewProduct.storeFront.id
      ) {
        const tempStorefrontId = rd_tempSelectedStorefront;
        // updateDecrementNumberOfProductStorefront =
        await actions.decrementNumberOfProductStorefront(tempStorefrontId);
        // updateIncrementNumberOfProductStorefront =
        await actions.incrementNumberOfProductStorefront(storefrontId);
        console.log(
          "update decrementNumberOfProductStorefront for storefront id : ",
          tempStorefrontId
        );
        console.log(
          "update incrementNumberOfProductStorefront for storefront id : ",
          storefrontId
        );
      }
      // tidak punya tempStorefront maka increment
      if (!rd_tempSelectedStorefront || rd_tempSelectedStorefront === "") {
        // updateIncrementNumberOfProductStorefront =
        await actions.incrementNumberOfProductStorefront(storefrontId);
        console.log(
          "update incrementNumberOfProductStorefront for storefront id : ",
          storefrontId
        );
      }
    }
    /***
     * Media Page - Process Save Photos
     */
    if (rd_ProductPhotos && rd_ProductPhotos.length > 0) {
      for (let index = 0; index < rd_ProductPhotos.length; index++) {
        const element = rd_ProductPhotos[index];
        //Upload File to Storage
        const result_FS_URL = await uploadToFirestorage(
          element.url,
          element.type,
          element.name,
          "product/photos/" + newProduct.id
        );
        console.log("Result URL Photos : " + result_FS_URL);
        if (result_FS_URL && result_FS_URL !== "") {
          resultFirestorageURL.push(result_FS_URL);
        } else {
          isSuccess = false;
        }

        //Create Media Collection
        const dataMedia = await actions.formatMediaCollection(
          element.url,
          element.name,
          element.extension,
          element.type,
          element.size,
          result_FS_URL,
          rd_PhotosCoverIndex === index ? true : false,
          "",
          refUser
        );
        const mediaID = await actions.createDataMedia(
          newProduct.id,
          "photos",
          dataMedia
        );
        if (mediaID && mediaID !== "") {
          resultPhotosMediaID.push(mediaID);
        } else {
          isSuccess = false;
        }
      }
    }

    /***
     * Media Page - Process Save Video
     */
    if (rd_ProductVideo && rd_ProductVideo !== "") {
      // if (rd_VideoYoutubeURL && rd_VideoYoutubeURL !== "") {
      //Upload File to Storage
      const result_FS_URL = await uploadToFirestorage(
        rd_ProductVideo.url,
        rd_ProductVideo.type,
        rd_ProductVideo.name,
        "product/videos/" + newProduct.id
      );
      if (result_FS_URL && result_FS_URL !== "") {
        resultFirestorageURL.push(result_FS_URL);
      } else {
        isSuccess = false;
      }

      //Create Media Collection
      const dataMedia = await actions.formatMediaCollection(
        rd_ProductVideo.url,
        rd_ProductVideo.name,
        rd_ProductVideo.extension,
        rd_ProductVideo.type,
        rd_ProductVideo.size,
        result_FS_URL,
        false,
        rd_VideoYoutubeURL,
        refUser
      );
      const mediaID = await actions.createDataMedia(
        newProduct.id,
        "video",
        dataMedia
      );
      if (mediaID && mediaID !== "") {
        resultVideoMediaID = mediaID;
      } else {
        isSuccess = false;
      }
    }

    //save shipments data
    if (rd_Shipments && rd_Shipments.length > 0) {
      console.log("check redux rd_Shipments", rd_Shipments);
      for (let index = 0; index < rd_Shipments.length; index++) {
        const element = rd_Shipments[index];
        // check isActive and have types
        // save if isActive or not active but have types
        if (
          element.isActive ||
          (!element.isActive && element.types.length > 0)
        ) {
          const shipmentParentRef = createRef("shipments", element.id);
          console.log("parent shipments id : ", shipmentParentRef);
          console.log("element types : ", element.types);
          let typesData: any = [];
          for (let index = 0; index < element.types.length; index++) {
            const type = element.types[index];
            const shipmentChildrenRef = createRef("shipments", type.id);
            console.log("children shipments id : ", shipmentParentRef);
            const dataShipmentsType = await actions.formatShipmentsType(
              shipmentChildrenRef,
              type.name
            );
            typesData = [...typesData, dataShipmentsType];
          }
          console.log("check typesData : ", typesData);
          const dataShipments = await actions.formatShipmentsCollection(
            shipmentParentRef,
            element.name,
            element.isActive,
            typesData,
            refUser
          );
          const shipmentId = await actions.createDataShipments(
            productID,
            dataShipments
          );
          if (shipmentId && shipmentId !== "") {
            resultShipmentsId.push(shipmentId);
          } else {
            isSuccess = false;
          }
        }
      }
    }

    console.log(
      `handlesavechange rd_SelectedCompanies ${JSON.stringify(
        rd_SelectedCompanies
      )}`
    );
    // create marketplace collection
    if (rd_SelectedCompanies && rd_SelectedCompanies.length > 0) {
      const companies = rd_SelectedCompanies;
      const marketplaces = rd_marketplaces;

      const newCompanies: any = companies.map((company) => {
        const companyRef = createRef("company", company.company);
        return { ...company, company: companyRef };
      });

      // const dataCompanies =
      await actions.createDataCompany(productID, newCompanies);

      // create marketplace collection
      for await (const market of marketplaces) {
        // destructure
        let { company, category, isActive, marketplace } = market;

        // set company ref
        const companyRef = createRef("company", company!);

        // set category to empty array rather than undefined
        const categoryData = { ...(category[0] || []) };
        let arrayData = [];
        arrayData.push({ ...categoryData });
        // const categoryData = category || [];

        const findAccount = await getAccountByCompanyAndMarketplace(
          company,
          marketplace
        );

        const account: string | undefined =
          findAccount.length > 0 && findAccount[0].id
            ? findAccount[0].id
            : undefined;
        const accountRef = account ? createRef("account", account) : undefined;

        //find attributes list by last category id
        let attributesList: attributeList[] = [];
        const cat: any[] = Object.values(category[0]);
        console.log(`category : ${cat}`);
        const lastCategory = cat.find(
          (category: any) => category.hasChildren === false
        );
        console.log(`find lastCategory : ${JSON.stringify(lastCategory)}`);
        if (
          rd_categoryAttributes &&
          rd_categoryAttributes.shopee &&
          lastCategory &&
          marketplace &&
          marketplace.toLowerCase() === "shopee"
        ) {
          const attributes = rd_categoryAttributes.shopee;
          const findAttributes = attributes.filter((att: any) => {
            if (att.inputValues && att.inputValues.length > 0) {
              const findCategoryById = att.categoryId?.find((cat: string) => {
                if (cat === lastCategory?.id) return cat;
              });
              if (findCategoryById) return att;
            }
          });
          console.log(`findAttributes : ${findAttributes}`);
          if (findAttributes && findAttributes.length > 0) {
            for (let index = 0; index < findAttributes.length; index++) {
              const attr = findAttributes[index];
              const inputValues = attr.inputValues!;
              const valueList: attributeValueList[] = [];
              console.log(`inputValues : ${JSON.stringify(inputValues)}`);
              if (
                attr.inputType === "MULTIPLE_SELECT" ||
                attr.inputType === "MULTIPLE_SELECT_COMBO_BOX"
              ) {
                const multipleValues = inputValues[0];
                if (multipleValues && multipleValues.length > 0) {
                  for (let index = 0; index < multipleValues.length; index++) {
                    const values = multipleValues[index];
                    console.log(`values for index ${index} : ${values}`);
                    let originalValueName: string = "";
                    if (values && values.value && values?.label) {
                      const getValueByInputType =
                        values?.value === values?.label ? 0 : values?.value;
                      console.log(
                        `getValueByInputType : ${getValueByInputType}`
                      );
                      if (getValueByInputType === 0)
                        originalValueName = values?.label;
                      const createValueList: attributeValueList =
                        await actions.formatAttributeValueList(
                          getValueByInputType,
                          "",
                          originalValueName
                        );
                      valueList.push({ ...createValueList });
                    }
                  }
                  console.log(`multiple : ${JSON.stringify(valueList)}`);
                }
              } else {
                const values = inputValues[0];
                console.log(`values for index ${0} : ${values}`);
                let getValueByInputType: number = 0;
                let originalValueName: string = "";
                if (values !== undefined && values !== null) {
                  getValueByInputType =
                    (attr.inputType === "COMBO_BOX" ||
                      attr.inputType === "DROP_DOWN") &&
                    values
                      ? values.value === values.label
                        ? 0
                        : values.value
                      : 0;
                  if (getValueByInputType === 0) {
                    originalValueName =
                      values && values.label
                        ? values.label
                        : values
                        ? values
                        : "";
                    if (attr.inputValidation === "DATE_TYPE")
                      originalValueName = values.replaceAll("-", "_");
                  }
                }
                const valueUnit =
                  attr.formatType === "QUANTITATIVE" &&
                  inputValues[1] &&
                  inputValues[1].value
                    ? inputValues[1].value
                    : "";
                console.log(`getValueByInputType : ${getValueByInputType}`);
                console.log(`originalValueName : ${originalValueName}`);
                console.log(`value unit : ${valueUnit}`);
                const createValueList: attributeValueList =
                  await actions.formatAttributeValueList(
                    getValueByInputType,
                    valueUnit,
                    originalValueName
                  );
                if (
                  (createValueList &&
                    createValueList.valueId === 0 &&
                    createValueList.originalValueName !== "" &&
                    createValueList.originalValueName &&
                    createValueList.originalValueName !== null) ||
                  (createValueList &&
                    createValueList.valueId &&
                    createValueList.valueId > 0)
                ) {
                  console.log(`valueList : ${createValueList}`);
                  valueList.push({ ...createValueList });
                }
              }
              console.log(`result valueList : ${valueList}`);
              if (valueList && valueList.length > 0) {
                const createAttributeModel: attributeList =
                  await actions.formatAttributeList(
                    attr.attributeId,
                    valueList
                  );
                console.log(`attributeModel : ${createAttributeModel}`);
                attributesList.push({ ...createAttributeModel });
              }
            }
          }
          console.log(
            `result attributesList: ${JSON.stringify(attributesList)}`
          );
        }
        console.log(`attributesList: ${JSON.stringify(attributesList)}`);
        const dataMarketplaces = await actions.formatMarketplacesCollection(
          companyRef,
          isActive,
          marketplace,
          arrayData,
          refUser,
          accountRef,
          attributesList && attributesList.length > 0 ? attributesList : []
        );
        const marketplaceId = await actions.createDataMarketplace(
          productID,
          dataMarketplaces
        );

        if (!marketplaceId || marketplaceId === "") {
          isSuccess = false;
        }
      }
    }

    //if have photo from redux upload then set image in option list
    if (rd_VariantPhotos && rd_VariantPhotos.length > 0) {
      //upload to firestore
      console.log("rd_VariantPhotos : ", rd_VariantPhotos);
      const sortData = [...rd_VariantPhotos];
      const sortVariantPhoto = sortData.sort((a: any, b: any) =>
        a.index > b.index! ? 1 : -1
      );
      console.log("sortVariantPhoto : ", sortVariantPhoto);

      for (let index = 0; index < sortVariantPhoto.length; index++) {
        const element = sortVariantPhoto[index];
        let elem = {
          ...element,
          name: element.name || `variant-photo-${index}`,
          type: element.type || "jpeg",
        };
        const result_FS_URL = await uploadToFirestorage(
          elem.url,
          elem.type,
          elem.name,
          "product/variant-photos/" + newProduct.id
        );
        console.log("Result URL Variant Photos : " + result_FS_URL);
        if (result_FS_URL && result_FS_URL !== "") {
          resultProductVariantPhoto.push(result_FS_URL);
        } else {
          isSuccess = false;
        }
      }
    }
    //save variant category
    if (
      rd_isProductHaveVariants === "true" &&
      rd_Variants &&
      rd_Variants.length > 0
    ) {
      for (let i = 0; i < rd_Variants.length; i++) {
        const variant: any = rd_Variants[i];
        console.log("VAR, variant", variant);
        const variantName = variant.name ? variant.name.trim() : "";
        const optionList = variant.optionList ? variant.optionList : [];
        let optionListData: any = [];
        if (i === 0) {
          for (let i = 0; i < optionList.length; i++) {
            const opt = optionList[i].option;
            const image = resultProductVariantPhoto[i];
            const optionData = await actions.formatVariantOptionList(
              opt ? opt : "",
              image ? image : ""
            );
            optionListData.push(optionData);
          }
        } else {
          for (let i = 0; i < optionList.length; i++) {
            const opt = optionList[i].option;
            const optionData = await actions.formatVariantOptionListOptionOnly(
              opt ? opt : ""
            );
            optionListData.push(optionData);
          }
        }
        console.log("optionListData : ", optionListData);

        if (variantName !== "" || optionList.length > 0) {
          const dataVariant = await actions.formatVariantCollection(
            variantName,
            variant.index,
            optionListData && optionListData.length > 0
              ? optionListData
              : optionList,
            refUser
          );

          actions.createDataVariant(productID, dataVariant);
        }
      }
    }
    //save variant list/matrix
    console.log("RD VAR MATRIX", rd_VariantMatrix);
    if (rd_VariantMatrix && rd_VariantMatrix.length > 0) {
      console.log("VARLIST enter save matrix");
      let variantId: any;
      for await (const variantMatrix of rd_VariantMatrix) {
        const productVariant = variantMatrix;
        console.log("productVariant : ", productVariant);
        const dataProductVariant = await actions.formatProductVariantCollection(
          productVariant.tierIndex,
          productVariant.sku ? productVariant.sku : "",
          productVariant.weight ? productVariant.weight : 0,
          productVariant.isActive === true ? true : false,
          productVariant.isMainVariant === true ? true : false,
          productVariant.stock ? productVariant.stock : 0,
          refUser
        );

        variantId = await actions.createDataProductVariant(
          productID,
          dataProductVariant
        );
      }
      if (variantId && variantId !== "") {
        resultVariantId.push(variantId);
        console.log("success create data variant");
      } else {
        isSuccess = false;
      }
    }
    //save category attributes to subCollection categoryAttributes
    console.log(`start process category attributes`);
    if (rd_categoryAttributes && rd_categoryAttributes.shopee) {
      const categoryAttributes = rd_categoryAttributes.shopee;
      for (let index = 0; index < categoryAttributes.length; index++) {
        const attribute = categoryAttributes[index];
        console.log(`attributes id : ${attribute.attributeId}`);
        const categoryId =
          attribute.categoryId && attribute.categoryId.length > 0
            ? attribute.categoryId
            : [];
        //reformat input values to get id value or string
        let inputValues: any[] = [];
        if (
          attribute.inputValues &&
          (attribute.inputValues[0] || attribute.inputValues[1])
        ) {
          if (
            attribute.inputType === "MULTIPLE_SELECT" ||
            attribute.inputType === "MULTIPLE_SELECT_COMBO_BOX"
          ) {
            //check if have value and save in subCollection categoryAttributes
            const multipleValues = attribute.inputValues[0];
            let multiple: any[] = [];
            for (let index = 0; index < multipleValues.length; index++) {
              const value = multipleValues[index];
              multiple.push({ ...value });
            }
            inputValues.push({ ...multiple });
            console.log(
              `input values multiple : ${JSON.stringify(inputValues)}`
            );
          } else {
            if (attribute.inputValues[0] || attribute.inputValues[1]) {
              inputValues.push(attribute.inputValues[0] || null);
            }
            attribute.inputValues[1] &&
              inputValues.push(attribute.inputValues[1]);
            console.log(`input values single : ${JSON.stringify(inputValues)}`);
          }
          const categoryAttributeModel =
            await actions.formatProductCategoryAttributes(
              attribute.attributeId.toString(),
              inputValues,
              categoryId,
              "shopee",
              refUser
            );
          console.log(
            `categoryAttributeModel : ${JSON.stringify(categoryAttributeModel)}`
          );
          await actions.createDataProductCategoryAttributes(
            productID,
            attribute && attribute.attributeId
              ? attribute.attributeId.toString()
              : "",
            categoryAttributeModel
          );
        }
      }
    }

    console.log(`check isClickUpload ${isClickUpload} isSuccess ${isSuccess}`);
  };

  const handleUnsavedChanges = () => {
    dispatch(ReduxProduct.cleanReduxProduct(true));
  };

  const isUpdate = rd_productID && rd_productID !== "" && rd_isCreatedProduct;
  const URI = isUpdate ? "/product/update" : "/product/create-product";

  console.log("check redux marketplace in summary : ", rd_marketplaces);

  const getProductCategoryAttributes = async (marketplace: any) => {
    //find if company active
    const findShopee = rd_marketplaces.filter((market) => {
      const isActiveCompany = rd_SelectedCompanies.find((company) => {
        if (company.company === market.company && company.isActive === true)
          return company;
      });
      console.log(`isActiveCompany : ${JSON.stringify(isActiveCompany)}`);
      if (
        isActiveCompany &&
        market.marketplace.toLowerCase() === "shopee" &&
        market.isActive === true
      )
        return market;
    });
    console.log(`findShopee : ${findShopee.length}`);
    if (!findShopee || findShopee.length < 1) {
      dispatch(ReduxProduct.setCategoryAttributes({ shopee: [] }));
      dispatch(ReduxProduct.setIsGeneratedCategoryAttributes(true));
      setRefreshCategoryAttributes(true);
      setIsLoading(true);
      return;
    }
    let findLastCategoryShopee: string[] = [];
    for (let index = 0; index < findShopee.length; index++) {
      const shopee = findShopee[index];
      console.log(`shopee: ${shopee}`);
      const category = shopee.category[0];
      console.log(`category : ${JSON.stringify(category)}`);
      const data = Object.values(category);
      console.log(`data : ${data}`);
      const findLastCategory: any = data.find(
        (cat: any) => cat.hasChildren === false
      );
      console.log(`findLastCategory : ${JSON.stringify(findLastCategory)}`);
      if (findLastCategory)
        findLastCategoryShopee = [
          ...findLastCategoryShopee,
          findLastCategory.id,
        ];
    }
    let categoryAttributesList: ShopeeProductCategoryAttributeList[] = [];
    //get from redux shopee
    for (let index = 0; index < findLastCategoryShopee.length; index++) {
      const lastCategory = findLastCategoryShopee[index];
      const findCategory = reduxShopeeProductCategoryData.find((cat: any) => {
        if (cat.id === lastCategory) {
          console.log(`lastCategory : ${lastCategory}`);
          return cat;
        }
      });
      //get attribute from redux or get from firebase
      let categoryAttributes: ShopeeProductCategoryAttributeList[] = [];
      //jika belum pernah di get, maka get from firebase
      if (
        !findCategory ||
        !findCategory.attributesList ||
        (findCategory && findCategory.isHasAttributes === "")
      ) {
        categoryAttributes = await getAttributesByMarketplaceAndCategoryId(
          "shopee",
          lastCategory
        );
        console.log(
          `getAttributes from fb : ${JSON.stringify(categoryAttributes)}`
        );
        console.log(
          `categoryAttributes length : ${categoryAttributes?.length}`
        );
        //update to redux shopee, set isHasAttributes and save attributesList
        const reduxMarketplaceProductCategory = [
          ...reduxShopeeProductCategoryData,
        ];
        const newReduxMarketplaceProductCategory =
          reduxMarketplaceProductCategory.map((category) => {
            if (
              category.categoryId?.toString() === lastCategory &&
              (!categoryAttributes ||
                (categoryAttributes && categoryAttributes.length < 1))
            ) {
              console.log(`set isHasAttributes false`);
              return { ...category, isHasAttributes: "false" };
            } else if (
              category.categoryId?.toString() === lastCategory &&
              categoryAttributes &&
              categoryAttributes.length > 0
            ) {
              console.log(`set isHasAttributes true`);
              return {
                ...category,
                isHasAttributes: "true",
                attributesList: categoryAttributes,
              };
            } else {
              return category;
            }
          });
        dispatch(
          ReduxMarketplaceProductCategory.setShopeeMarketplaceProductCategory(
            newReduxMarketplaceProductCategory
          )
        );
      }
      if (
        findCategory &&
        findCategory.isHasAttributes === "true" &&
        findCategory.attributesList &&
        findCategory.attributesList.length > 0
      ) {
        console.log(`get attributes from redux`);
        categoryAttributes = findCategory?.attributesList;
      }
      //jika tidak punya attributes, isHasAttributes === "false"
      if (
        (findCategory && findCategory.isHasAttributes === "false") ||
        !categoryAttributes ||
        categoryAttributes.length < 1
      ) {
        console.log(`last category not have attribute`);
        dispatch(ReduxProduct.setCategoryAttributes({ shopee: [] }));
        dispatch(ReduxProduct.setIsGeneratedCategoryAttributes(true));
        setRefreshCategoryAttributes(true);
        setIsLoading(true);
        return;
      }
      console.log(`categoryAttributes : ${JSON.stringify(categoryAttributes)}`);
      //find in categoryAttributesList, if find add categoryId
      for (let index = 0; index < categoryAttributes.length; index++) {
        const attribute: any = categoryAttributes[index];
        console.log(`attribute : ${JSON.stringify(attribute)}`);
        const findAttribute = categoryAttributesList.find(
          (attr: ShopeeProductCategoryAttributeList) =>
            attr.attributeId === attribute.attributeId
        );
        if (!findAttribute) {
          const newAttribute: ShopeeProductCategoryAttributeList = {
            ...attribute,
            categoryId: [lastCategory],
          };
          categoryAttributesList.push(newAttribute);
        } else {
          //add category
          let newCategoryId: any;
          if (findAttribute.categoryId)
            newCategoryId = [...findAttribute.categoryId, lastCategory];
          categoryAttributesList.map(
            (attr: ShopeeProductCategoryAttributeList) => {
              if (attr.attributeId === attribute.attributeId) {
                return { ...attr, categoryId: newCategoryId };
              }
            }
          );
        }
      }
    }
    if (categoryAttributesList.length > 0) {
      categoryAttributesList = categoryAttributesList.sort((a: any, b: any) => {
        if (a.isMandatory && !b.isMandatory) {
          return -1;
        } else if (!a.isMandatory && b.isMandatory) {
          return 1;
        } else {
          return 0;
        }
      });
      const attributes: categoryAttributes = { shopee: categoryAttributesList };
      console.log(
        `attributes to set in company : ${JSON.stringify(attributes)}`
      );
      dispatch(ReduxProduct.setCategoryAttributes(attributes));
      if (rd_isProductHaveVariants === "true") {
        setUsersBreadcrumbs(UsersBreadcrumbWithoutVariantWithSpecification);
      } else {
        setUsersBreadcrumbs(UsersBreadcrumbWithoutVariantWithSpecification);
      }
    } else {
      dispatch(ReduxProduct.setCategoryAttributes({ shopee: [] }));
      if (rd_isProductHaveVariants === "true") {
        setUsersBreadcrumbs(UsersBreadcrumbsWithVariant);
      } else {
        setUsersBreadcrumbs(UsersBreadcrumbWithoutVariant);
      }
    }
    dispatch(ReduxProduct.setIsGeneratedCategoryAttributes(true));
    setRefreshCategoryAttributes(true);
    setIsLoading(true);
  };

  useEffect(() => {
    if (!rd_isGenerateCategoryAttributes) {
      getProductCategoryAttributes(rd_marketplaces);
    }
  }, [rd_marketplaces]);

  return (
    <>
      {!refreshCategoryAttributes && (
        <PageTitle breadcrumbs={isUpdate ? OnEditBreadCrumb : UsersBreadcrumbs}>
          {t(
            isUpdate
              ? "ProductList.Title.EditProduct"
              : "AddNewProduct.Title.Summary"
          )}
        </PageTitle>
      )}

      {!isClickUpload && (
        <ModalConfirmationMoveToOtherPage
          isDirty={true}
          path={URI}
          // secondPath={
          //   rd_productID && rd_isCreatedProduct
          //     ? "/storefront/update-storefront"
          //     : "/storefront/create-storefront"
          // }
          // path="/product/create-product"
          // secondPath="/storefront/create-storefront"
          onSave={() =>
            handleSavedChanges().then(() => {
              setIsLoading(false);
              dispatch(ReduxProduct.cleanReduxProduct(true));
              dispatch(ReduxProductList.cleanReduxProductList(true));
            })
          }
          onUnsave={() => handleUnsavedChanges()}
          isEmpty={false}
        />
      )}
      <div className="d-flex flex-column flex-lg-column h-100">
        {!isTabletOrMobile && ProductHeaderTitle("AddNewProduct.Title.Summary")}
        <div
          className="d-flex flex-column flex-lg-column"
          style={{ minHeight: "92.5%" }}
        >
          <div
            className={clsx(`overflow-hidden card h-100 
          ${isTabletOrMobile ? "" : "p-7"}`)}
          >
            {isTabletOrMobile && <ProductMobileIcon />}
            <div
              style={{
                height: `${isTabletOrMobile ? "100%" : "90%"}`,
              }}
              className={clsx(
                `d-flex flex-column ${isTabletOrMobile ? "" : ""}`
              )}
            >
              <div
                ref={titleRef}
                className={clsx(
                  "flex-lg-column-fluid tab-content h-100",
                  isTabletOrMobile ? "" : "overflow-auto"
                )}
              >
                <BasicProductInformation
                  isSummary={true}
                  onClickUpload={isClickUpload}
                />
                <ProductWeight isSummary={true} onClickUpload={isClickUpload} />
                {!refreshShipments && (
                  <Shipments isSummary={true} onClickUpload={isClickUpload} />
                )}
                {/* company and marketplace selection */}
                <SelectCompanyAndMarketplace
                  isSummary={true}
                  onClickUpload={isClickUpload}
                />
                <div className="d-flex px-5">
                  <Button
                    btnbs="primary"
                    cName={isTabletOrMobile ? "w-100" : ""}
                    data-testid="btnBackToCompanyAndMarketplace"
                    onClick={() => {
                      dispatch(ReduxProduct.setFromSummary(true));
                      nav(`${URI}/select-company-and-marketplace`);
                    }}
                  >
                    +
                    {`${t(
                      "AddNewProduct.Button.Summary.AddCompany&Marketplace"
                    )}`}
                  </Button>
                </div>
                {/* product category attributes */}
                {!refreshCategoryAttributes && (
                  <ProductCategoryAttributes
                    isSummary={true}
                    onClickUpload={isClickUpload}
                  />
                )}
                {/* variants list */}
                {rd_isProductHaveVariants === "true" && (
                  <ProductVariantsList
                    isSummary={true}
                    onClickUpload={isClickUpload}
                  />
                )}
                {rd_isProductHaveVariants === "true" && (
                  <div className="d-flex">
                    <Button
                      btnbs="primary"
                      cName={isTabletOrMobile ? "w-100" : ""}
                      data-testid="btnBackToVariantCategory"
                      onClick={() => {
                        dispatch(ReduxProduct.setFromSummary(true));
                        nav(`${URI}/variants-category`);
                      }}
                    >
                      + {`${t("AddNewProduct.Button.Summary.EditVariant")}`}
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div
              className={clsx(
                `d-flex bg-white mt-auto justify-content-between ${
                  isTabletOrMobile ? "mb-5" : ""
                }`
              )}
            >
              <Link
                to={`${
                  rd_isProductHaveVariants === "true"
                    ? "/product/create-product/variants-list"
                    : "/product/create-product/variants-category"
                }`}
              >
                {!rd_isCreatedProduct && (
                  <Button
                    btnbs="primary"
                    next
                    cName={isTabletOrMobile ? "mx-5" : ""}
                    data-testid="btnBack"
                  >
                    {`${t("Storefront.Button.Back")}`}
                  </Button>
                )}
              </Link>
              <div className="d-flex">
                <Link to="/product/list">
                  <Button data-testid="btnCancel" btnbs="primary" type="button">
                    {t("AddNewProduct.Button.BPI.Cancel")}
                  </Button>
                </Link>
                <Button
                  id="btnNext"
                  data-testid="btnNext"
                  type="button"
                  btnbs="primary"
                  cName="mx-5"
                  onClick={() => {
                    setIsClickUpload(true);
                    validateData();
                    // .then(() => {
                    // console.log("AFTER VALIDATION SUCCESSFUL")
                    // dispatch(ReduxProduct.cleanReduxProduct(true));
                    // dispatch(ReduxProductList.cleanReduxProductList(true));
                    // setIsLoading(false);
                    // nav("/product/list");
                    // });
                  }}
                >
                  {`${t(
                    rd_isCreatedProduct
                      ? "Storefront.Button.Save"
                      : "AddNewProduct.Button.Summary.Upload"
                  )}`}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
    </>
  );
};

export default ProductSummary;
