import React, { useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import {
  checkIsActive,
  KTSVG,
  toAbsoluteUrl,
} from "../../../resources/helpers";
import { useLocation } from "react-router-dom";
import "../../../styles/css/color.css";
import { useTranslation } from "react-i18next";
import i18n from "../../../setup/translations/i18next";
import i18next from "../../../setup/translations/i18next";
import Modal from "../../components/Modal";
import * as lc from "../../modules/localstorage/index";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteUser, setAuth } from "../../modules/auth/redux/AuthSlice";
import { logout } from "../../../api";
import { setUserOffline } from "../../../api/server/connection";
import { deleteMessage } from "../../modules/chat/redux/ChatSlice";
import { cleanReduxCIQ } from "../../modules/ciq/redux/CIQSlice";
import SVG from "react-inlinesvg";
import { cleanReduxContact } from "../../modules/contact/redux/ContactSlice";
import * as Log from "../../../util/SDayslogger";
import { cleanReduxShipment } from "../../modules/product/shipment/redux/ShipmentsSlice";
import { cleanReduxProduct } from "../../modules/product/redux/ProductSlice";
import { cleanReduxMarketplaceProductCategory } from "../../modules/product/marketplaceProductCategory/marketplaceProductCategorySlice";
import { persistor } from "../../../setup/redux/store";
import { cleanReduxTemplate } from "../../modules/template/list/TemplateListSlice";
import { cleanReduxCampaign as cleanReduxCampaignList } from "../../modules/campaign/list/CampaignListSlice";
import { cleanReduxCampaign } from "../../modules/campaign/redux/CampaignSlice";
import { cleanReduxProductList } from "../../modules/product/productList/ProductListSlice";
import { cleanReduxStorefrontList } from "../../modules/product/storefront/storefrontList/StorefrontListSlice";

type Props = {
  to: string;
  subMenu?: string;
  title: string;
  icon?: string;
  fontIcon?: string; //font-awesome icon
  duotoneIcon?: string; //duo-tone icon
  bootstrapIcon?: string; //bootstrap icon
  bbcount?: number;
  bbcolor?:
    | "white"
    | "primary"
    | "light"
    | "secondary"
    | "success"
    | "info"
    | "warning"
    | "danger"
    | "dark";
  id?: string;
  type?: string;
  hasBullet?: boolean;
  onClick?: () => void;
};

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  subMenu,
  type,
  title,
  duotoneIcon,
  bootstrapIcon,
  bbcount = 0,
  bbcolor,
  id,
  hasBullet = false,
  onClick = () => {
    return;
  },
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isActive = checkIsActive(location.pathname, to);
  const isSubActive = checkIsActive(location.pathname, subMenu ? subMenu : "");
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [activeLang, setActiveLang] = useState(i18next.language);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const logoutHandler = () => {
    const currentUser = lc.getItemLC(lc.LCName.User);
    const sessionid = lc.getItemLC(lc.LCName.SessionID);

    if (currentUser === null || sessionid === null) {
      dispatch(setAuth(false));
      nav("/auth");
    } else {
      logout()
        .then(() => {
          setUserOffline(currentUser.uid, sessionid);
          dispatch(deleteUser());
          lc.removeSession();
          dispatch(setAuth(false));
          dispatch(deleteMessage);
          dispatch(cleanReduxCIQ(true));
          dispatch(cleanReduxContact(true));
          dispatch(cleanReduxShipment(true));
          dispatch(cleanReduxProduct(true));
          dispatch(cleanReduxMarketplaceProductCategory(true));
          dispatch(deleteUser());
          dispatch(deleteMessage(true));
          dispatch(cleanReduxContact(true));
          dispatch(cleanReduxTemplate(true));
          dispatch(cleanReduxCampaign(true));
          dispatch(cleanReduxProductList(true));
          dispatch(cleanReduxStorefrontList(true));
          dispatch(cleanReduxCampaignList(true));
          persistor.pause();
          persistor.flush().then(() => {
            return persistor.purge();
          });
          nav("/auth");
        })
        .catch((error) => {
          // Log.SDayslogger(
          //     nav,
          //     "Testing Error Message",
          //     Log.SDLOGGER_INFO,
          //     false,
          //     true
          // );
          console.log("failed logout");
        });
    }
  };

  const changeLanguageHandler = (lang: string) => {
    if (lang === "id") {
      i18n.changeLanguage("id");
      setActiveLang("id");
    } else {
      i18n.changeLanguage("en");
      setActiveLang("en");
    }
  };

  let colorselect: string = "";
  if (isActive || isSubActive) {
    colorselect = "cl-graywhite";
  }

  return (
    <div
      className={clsx("menu-item text-dark", colorselect)}
      // data-bs-dismiss="offcanvas"
      aria-label="Close"
      onClick={() => {
        onClick();
      }}
      id={id}
    >
      {!type || type !== "lang" ? (
        <Link
          className={clsx("menu-link without-sub text-dark", {
            active: isActive || isSubActive,
          })}
          to={to}
        >
          {hasBullet && (
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
          )}
          {duotoneIcon && (
            <div className="text-muted position-relative h-30px w-30px p-1 d-flex align-items-center justify-content-center">
              <span className="text-muted justify-content-center p-0 m-0">
                <SVG
                  src={toAbsoluteUrl(duotoneIcon)}
                  className={clsx(duotoneIcon, "text-muted fs-1 p-0 m-0")}
                />
              </span>
              {bbcount > 0 && (
                <span
                  className={
                    !isActive
                      ? clsx(
                          `position-absolute start-50 translate-middle symbol-badge badge badge-circle bg-${bbcolor}`,
                          "menu-bubble"
                        )
                      : clsx(
                          `symbol-badge badge badge-circle bg-${bbcolor}`,
                          "menu-bubble"
                        )
                  }
                  style={{ top: "5px", marginLeft: "15px" }}
                >
                  {bbcount > 99 ? "99+" : bbcount}
                </span>
              )}
            </div>
          )}
          {bootstrapIcon && (
            <div className="symbol symbol-50px">
              <span className="menu-icon justify-content-center p-0 m-0">
                <i className={clsx(bootstrapIcon, "fs-1 p-0 m-0")} />
              </span>
              {bbcount > 0 && (
                <span
                  data-testid="bootstrap-bubble-value"
                  className={clsx(
                    `symbol-badge badge badge-circle bg-${bbcolor}`,
                    "menu-bubble"
                  )}
                  style={{ top: "5px", marginLeft: "15px" }}
                >
                  {bbcount > 99 ? "99+" : bbcount}
                </span>
              )}
            </div>
          )}
          <span className="menu-title" style={{ marginLeft: "10px", fontSize: "0.95rem" }}>
            {title}
          </span>
          {bbcount > 0 && (
            // <span className="menu-title menu-badge justify-content-end" style={{paddingRight:38}}>
            <span className="menu-title menu-badge justify-content-end">
              <span
                data-testid="bubble-value"
                className={clsx(
                  "badge badge-circle fw-bolder fs-7",
                  `bg-${bbcolor}`
                )}
              >
                {bbcount > 99 ? "99+" : bbcount}
              </span>
            </span>
          )}
        </Link>
      ) : (
        <div
          className={clsx("menu-link without-sub", {
            active: isActive || isSubActive,
          })}
          onClick={(e) => {
            e.stopPropagation();
            changeLanguageHandler(id === "AM_SelectionEn" ? "en" : "id");
          }}
        >
          {hasBullet && (
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
          )}
          {duotoneIcon && (
            <div className="symbol symbol-50px justify-content-center">
              <span className="menu-icon">
                <KTSVG
                  path={duotoneIcon}
                  className="svg-icon-2hx svg-icon-dark"
                />
              </span>
            </div>
          )}
          {bootstrapIcon && (
            <div className="symbol symbol-50px">
              <span className="menu-icon justify-content-end">
                <i className={clsx(bootstrapIcon, "fs-3")} />
              </span>
            </div>
          )}
          <span className="menu-title" style={{ marginLeft: "10px", fontSize: "0.95rem" }}>
            {title}
          </span>
        </div>
      )}
      {children}
    </div>
  );
};

export { AsideMenuItem };
