import moment from "moment";
import socket from "../../../../../../setup/socket/socket";
import { useFirestoreTable } from "../context/TableContext";
import { Order } from "../../../../../models/Order";
import ShippingLimitLabel from "../components/ShippingLimitLabel";
import { useTranslation } from "react-i18next";
import PriorityCell from "../table/columns/PriorityCell";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../setup/redux/store";
import { OrderSNCell } from "../table/columns/OrderSNCell";

const stringToTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const OrderCard: React.FC = () => {
  const { t } = useTranslation();

  const { data: orderData, isLoading, isFetching, tableState, selectedItems, setSelectedItems } = useFirestoreTable();

  const orderList = orderData?.items || [];

  const isAllSelected =
    selectedItems.length > 0 &&
    orderList.every((order) => {
      const shopId = order.accountData?.lazada_sellerID ?? order.accountData?.shopee_shopID;
      const orderKey = `${order.marketplaceType}_${shopId}_${order.ordersn}`;
      return order.id && selectedItems.includes(orderKey);
    });

  const onSelectAll = () => {
    if (isAllSelected) {
      setSelectedItems([]);
    } else {
      setSelectedItems(
        orderList.map((order) => {
          const shopId = order.accountData?.lazada_sellerID ?? order.accountData?.shopee_shopID;
          const orderKey = `${order.marketplaceType}_${shopId}_${order.ordersn}`;
          return orderKey;
        })
      );
    }
  };

  return (
    <>
      <div className="d-flex px-2 pb-3" data-testid="order-card">
        <input
          className="form-check-input my-auto"
          type="checkbox"
          data-kt-check={isAllSelected}
          data-kt-check-target="#kt_table_users .form-check-input"
          checked={isAllSelected}
          onChange={() => onSelectAll()}
        />
        <label className="form-check-label px-3" style={{ fontSize: "18px", fontWeight: "600" }}>
          {t("Storefront.Button.SelectMarketplace.SelectAll")}
        </label>
      </div>

      {orderList.map((order: Order) => (
        <Card key={order.id} order={order} />
      ))}
    </>
  );
};

function Card({ order }: { order: Order }) {
  const { t } = useTranslation();
  const shipmentDeadlines = useSelector((state: RootState) => state.Shipments.shipmentDeadlines);

  const { selectedItems, setSelectedItems, setReadyToShipOrder, checkifAvailableToProcessOrder } = useFirestoreTable();

  const shopId = order.accountData?.lazada_sellerID ?? order.accountData?.shopee_shopID;
  const orderKey = `${order.marketplaceType}_${shopId}_${order.ordersn}`;

  const isSelected = order.id !== undefined && selectedItems.includes(orderKey);

  const isAvailableToProcess = checkifAvailableToProcessOrder(shipmentDeadlines, order);
  const onSelect = (orderKey: string) => {
    if (selectedItems.includes(orderKey)) {
      setSelectedItems(selectedItems.filter((item) => item !== orderKey));
    } else {
      setSelectedItems([...selectedItems, orderKey]);
    }
  };

  return (
    <div
      className="p-2 mb-2 fw-bold card"
      style={{
        backgroundColor: "#C6E7FD",
        fontSize: "14px",
        color: "#6C7288",
      }}
    >
      <div className="d-flex justify-content-between align-items-end border-bottom pb-1">
        <div className="d-flex my-auto">
          <div className="my-auto">
            <input
              className="form-check-input"
              type="checkbox"
              data-kt-check={isSelected}
              data-kt-check-target="#kt_table_users .form-check-input"
              checked={isSelected}
              onChange={() => {
                onSelect(orderKey);
              }}
            />
          </div>

          <span className="my-auto px-2" style={{ fontSize: "1.5rem", fontWeight: "600" }}>
            {stringToTitleCase(order.marketplaceType ?? "")}
          </span>
          <span className="my-auto" style={{ fontSize: "1.25rem" }}>
            {order.accountData?.searchKey ?? order.accountData?.name ?? ""}
          </span>
        </div>

        <div className=" ml-auto" style={{ fontSize: "10px" }}>
          <ShippingLimitLabel shippingTimestamp={order.shipByDate!} />
        </div>
      </div>

      <div className="pt-2 d-flex justify-content-between">
        <span>{t("OrderList.OrderNumber")}</span>
        <OrderSNCell orderData={order} isAvailableToProcess={isAvailableToProcess} />
      </div>

      <div className="d-flex justify-content-between">
        <span>{t("OrderList.OrderTime")}</span>
        <span>{moment(order.marketplaceOrderCreatedAt?.toDate()).format("HH:mm DD//MM/YYYY")}</span>
      </div>

      <div className="d-flex justify-content-between">
        <span>{t("OrderList.Shipper.Mobile")}</span>
        <span>{order.shippingLabel}</span>
      </div>

      <div className="d-flex justify-content-between">
        <span>{t("OrderList.PriorityNumber.Mobile")}</span>
        <PriorityCell isAvailableToProcess={isAvailableToProcess} oldOrder={order} />
      </div>

      <div className="d-flex justify-content-between text-center pt-5">
        <button type="button" className="btn btn-primary btn-sm w-100" style={{ fontSize: "14px" }} onClick={() => setReadyToShipOrder([orderKey])}>
          {t("OrderList.Button.ArrangeShipment")}
        </button>
      </div>
    </div>
  );
}

export default OrderCard;
