import cn from "classnames";
import moment from "moment";
import "moment/locale/id";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Chat from "../../../../actions/chat";
import * as chat from "../../../../app/modules/chat/redux/ChatSlice";
import { CollabsStatus } from "../../../models/Chat";
import * as CIQ from "../../../../app/modules/ciq/redux/CIQSlice";
import * as Sync from "../../../../app/modules/order/SyncSlice";
import { updateStatus as updateCollabStatus } from "../../../../db/serviceCollaborations";
import { createNotificationAnotherUser } from "../../../../db/serviceUser";
import {
  getIconChannelUrl,
  KTSVG,
  toAbsoluteUrl,
} from "../../../../resources/helpers";
import Avatar from "../../../../styles/components/Avatar";
import * as lc from "../../../modules/localstorage/index";
import createContainer from "../createContainer";
import { removeFromNotificationsByCollabID } from "./helper";
import styles from "./Notification.module.css";
import { ReactComponent as Times } from "./times.svg";
// import Notification from "../../../models/Notification"

const container = createContainer();
let timeToDelete = 1000;
let timeToClose = 1000 * 10;

export default function Notification({
  color = Color.info,
  autoClose = false,
  onDelete,
  children,
  notif,
}) {
  const [isClosing, setIsClosing] = React.useState(false);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  // const nav = useNavigate();
  moment.locale(i18n.language);

  React.useEffect(() => {
    if (isClosing) {
      const timeoutId = setTimeout(onDelete, timeToDelete);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isClosing, onDelete]);

  React.useEffect(() => {
    if (autoClose) {
      const timeoutId = setTimeout(() => setIsClosing(true), timeToClose);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [autoClose]);

  //custom notification style
  return createPortal(
    <div className={cn([styles.container, { [styles.shrink]: isClosing }])}>
      <div
        className={cn([
          styles.notification,
          styles[color],
          { [styles.slideIn]: !isClosing },
          { [styles.slideOut]: isClosing },
          `pt-0 pe-0 pb-0 m-3`,
        ])}
      >
        {/* {children}  */}
        <div className="alert mt-0 mb-0" role="alert">
          <div className="d-flex align-items-center">
            {notif.notifType === "SyncOrder" ? (
              <div className="d-flex flex-column">
                <div className="rounded-circle symbol bg-primary">
                  <KTSVG className="bi bi-check-lg fs-2" />
                </div>
              </div>
            ) : (
              <div className="d-flex flex-column">
                <KTSVG
                  path="/media/icons/duotune/communication/com002.svg"
                  className="svg-icon svg-icon-2hx svg-icon-light"
                />
              </div>
            )}
            <div className="px-2 pt-2">
              {notif.notifType === "newMessage" && (
                <h6 className="alert-heading">
                  {t("Notif.Title.Message")} {notif.companyName}
                </h6>
              )}
              {notif.notifType === "CIQMessage" && (
                <h6 className="alert-heading">
                  {t("Notif.Title.Message")} {notif.companyName}
                </h6>
              )}
              {notif.notifType === "newUserMessage" && (
                <h6 className="alert-heading">{t("Notif.Title.UserChat")}</h6>
              )}
              {notif.notifType === "SyncOrder" && (
                <h6 className="alert-heading">{t("OrderList.Sync.Synced")}</h6>
              )}
            </div>
            <div className="ps-3">
              <span className="text-gray-400 fs-6">
                {notif.createdAt
                  ? moment(new Date(notif.createdAt.seconds * 1000)).fromNow()
                  : moment(new Date()).fromNow()}
              </span>
            </div>
          </div>
          {/* <p>
            Aww yeah, you successfully read this important alert message. This
            example text is going to run a bit longer so that you can see how
            spacing within an alert works with this kind of content.
          </p> */}
          <hr className="my-0"></hr>
          {notif.notifType === "SyncOrder" ? (
            <div className="row pt-4">
              <div className="col-md-4">
                <h4 className="text-white">{t("OrderList.Sync.FirstDate")}</h4>
                <div className="fw-bolder text-white">
                  {notif.startTime + " "}
                </div>
                <div className="fw-bold text-muted">{notif.startTime}</div>
              </div>
              <div className="col-md-4">
                <h4 className="text-white">{t("OrderList.Sync.EndDate")}</h4>
                <div className="fw-bolder text-white">
                  {notif.endTime + " "}
                </div>
                <div className="fw-bold text-muted">{notif.endTime}</div>
              </div>
              <div className="col-md-4">
                <h4 className="text-white">{t("OrderList.Sync.TotalSync")}</h4>
                {/* {syncedData && (
                  <div>
                    <div>{totalUpdatedOrder}</div>
                  </div>
                )} */}
                {notif.notifType === "SyncOrder" && (
                  <h5>
                    {notif.syncRes[0].totalSyncedOrder ||
                      notif.syncRes[0].error}
                  </h5>
                )}
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              {/* begin:: Avatar */}
              <div className="symbol symbol-35px me-4">
                <span className="symbol-label bg-light-primary rounded-circle">
                  {notif.notifType === "CIQMessage" && notif.companyAvatar && (
                    <Avatar
                      height="50"
                      width="50"
                      imgRadius="50%"
                      imgSrc={notif.companyAvatar ?? ""}
                    />
                  )}

                  {notif.notifType === "CIQMessage" &&
                    (notif.companyAvatar === undefined ||
                      notif.companyAvatar === "") && (
                      <i className="far fa-building fs-2x cl-gray"></i>
                    )}

                  {(notif.notifType === "newUserMessage" ||
                    notif.notifType === "newMessage") &&
                    notif.gender &&
                    notif.gender === "female" && (
                      <div className="symbol-label fs-3 fw-bold">
                        <img
                          src={
                            notif.avatar
                              ? `${notif.avatar}`
                              : toAbsoluteUrl(
                                  "/media/icons/avatar/f-avatar.png"
                                )
                          }
                          alt={notif.firstName}
                          className="w-75"
                        ></img>
                      </div>
                    )}

                  {(notif.notifType === "newUserMessage" ||
                    notif.notifType === "newMessage") &&
                    notif.gender &&
                    notif.gender === "male" && (
                      <div className="symbol-label fs-3 fw-bold">
                        <img
                          src={
                            notif.avatar
                              ? `${notif.avatar}`
                              : toAbsoluteUrl(
                                  "/media/icons/avatar/m-avatar.png"
                                )
                          }
                          alt={notif.firstName}
                          className="w-75"
                        ></img>
                      </div>
                    )}

                  {(notif.notifType === "newUserMessage" ||
                    notif.notifType === "newMessage") &&
                    (notif.gender === undefined || notif.gender === "") && (
                      <div className="symbol-label fs-3 fw-bold">
                        <img
                          src={
                            notif.avatar
                              ? `${notif.avatar}`
                              : toAbsoluteUrl(
                                  "/media/icons/avatar/def-avatar.png"
                                )
                          }
                          alt={notif.firstName}
                          className="w-75"
                        ></img>
                      </div>
                    )}
                </span>
                {notif.channel && (
                  <span className="symbol-badge badge badge-circle top-100 start-100 bg-light">
                    <Avatar
                      height="18"
                      width="18"
                      imgRadius="0%"
                      imgSrc={getIconChannelUrl(notif.channel)}
                    />
                    {/* <KTSVG path="/media/icons/channel/blibli.png"
                className="svg-icon svg-icon-2hx svg-icon-light" /> */}
                  </span>
                )}

                {/* <a to="#"> */}
                {/* <div className={clsx("symbol-label fs-3")}>tes nama</div>
                  <span className="badge badge-circle bg-danger position-absolute top-100 start-100 translate-middle">3</span> */}
                {/* <span className="badge badge-circle badge-white">5</span> */}
                {/* <span className="badge badge-circle bg-info"></span> */}
                {/* <Icon
                    id="icon-handled-customer"
                    imgSrc="/media/icons/duotune/communication/com002.svg"
                    size="4hx"
                    badgeStyle="bg-danger"
                    
                  ></Icon> */}
                {/* <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                      <span className="visually-hidden">New alerts</span>
                  </span> */}

                {/* </a> */}
              </div>
              <div className="pt-3 d-flex flex-column mt-2">
                <a
                  href="/#"
                  to="/#"
                  className="text-white text-hover-primary mb-0"
                >
                  {notif.name ? notif.name : notif.profileName}
                </a>
                <p>{notif.phoneNumber}</p>
                {/* <p>{notif.profileName}</p> */}
                {/* <span>{contact.email}</span> */}
              </div>
              <div className="align-text-bottom list-inline mb-4 mb-lg-1 d-flex flex-row justify-content-end flex-fill w-auto ">
                {notif.notifType === "newMessage" && (
                  <a
                    href="/handled-customer"
                    onClick={() => {
                      console.log("masuk dispatch");
                      dispatch(chat.setSelectedChat(notif.collaborationID));
                      removeFromNotificationsByCollabID(notif.collaborationID);
                    }}
                    className="btn btn-primary btn-sm align-text-bottom p-7 pt-1 pb-1 mt-2"
                  >
                    {t("Notif.Button.Reply")}
                  </a>
                )}
                {notif.notifType === "CIQMessage" && (
                  <button
                    // onClick={handleColab(true)}
                    onClick={async () => {
                      try {
                        const id = await updateCollabStatus(
                          notif.collaborationID,
                          CollabsStatus.handled,
                          lc.getItemLC(lc.LCName.UserID),
                          t("CIQ.Error.DoubleHandled")
                        );
                        if (id && id !== null) {
                          await Chat.fetchCollaborationByID(
                            notif.collaborationID
                          ).then((collab) => {
                            if (collab) {
                              console.log(
                                "------->>" + collab.lastInteractionMessage
                              );
                              console.log(
                                "response fetchCollaborationByID" +
                                  JSON.stringify(collab)
                              );
                              dispatch(
                                chat.setSelectedChat(notif.collaborationID)
                              );
                              dispatch(chat.updateChatList(collab));
                              console.log(
                                "Process Create Notified Another User"
                              );
                              if (
                                collab.company &&
                                collab.company?.id &&
                                collab.customer
                              ) {
                                //Process Create Notif For Another User
                                console.log(
                                  "masuk Process Create Notified Another User"
                                );
                                createNotificationAnotherUser(collab);
                              }
                              dispatch(
                                CIQ.deleteItemListCIQ(notif.collaborationID)
                              );
                              removeFromNotificationsByCollabID(
                                notif.collaborationID
                              );
                              if (
                                window.location.pathname !== "/handled-customer"
                              ) {
                                window.location.href = "/handled-customer";
                              }
                            }
                          });
                        }
                      } catch (error) {
                        alert("Error : " + error); //alert Failed Handled Message
                      }
                    }}
                    // href="/handled-customer"
                    className="btn btn-primary btn-sm align-text-bottom p-7 pt-1 pb-1 mt-2"
                  >
                    {t("CIQ.Button.Handle")}
                  </button>
                )}
                {notif.notifType === "newUserMessage" && (
                  <a
                    href="/user-conversation"
                    onClick={() => {
                      console.log("masuk dispatch");
                      dispatch(chat.setSelectedUserChat(notif.collaborationID));
                      dispatch(chat.updateSortUserChatList());
                      removeFromNotificationsByCollabID(notif.collaborationID);
                    }}
                    className="btn btn-primary btn-sm align-text-bottom p-7 pt-1 pb-1 mt-2"
                  >
                    {t("Notif.Button.Reply")}
                  </a>
                )}
              </div>
            </div>
          )}
          {/* <p className="mb-0">{children}</p> */}
        </div>
        <button
          onClick={() => setIsClosing(true)}
          // className= "btn btn-info btn-circle translate-middle"
          className={cn([styles.closeButton, `translate-middle`])}
        >
          <Times height={14} />
          {/* <i className="bi bi-x fw-bold fs-6"></i> */}
        </button>
      </div>
    </div>,
    container
  );
}

export const Color = {
  info: "info",
  success: "success",
  warning: "warning",
  error: "error",
};

Notification.propTypes = {
  notificationType: PropTypes.oneOf(Object.keys(Color)),
  autoClose: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  children: PropTypes.string,
  notif: PropTypes.any,
};
