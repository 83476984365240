import db, { createRef, Timestamp } from "../db";
// import firebase from "firebase/compat/app";
import { converter } from "./converter";
import { chatFiles, Files } from "../app/models/Files";

export const addFiles = async (FileData: Files, clientId: string) => {
  if (!FileData) return undefined;

  return await db
    .collection("files")
    .add({
      createdAt: Timestamp.now(),
      client: createRef("clients", clientId),
      ...FileData,
    })
    .then((doc) => {
      console.log("Added Files: " + doc);
    })
    .catch((err) => {
      console.log("Error add files : ", err);
    });
};

export const addExportOrderFiles = async (
  FileData: Files,
  clientId: string
) => {
  if (!FileData) return undefined;

  return await db
    .collection("clients")
    .doc(clientId)
    .collection("exportOrderFormat")
    .add({ ...FileData })
    .then((doc) => {
      console.log(
        "Added Files To Collection Client SubCollection exportOrderFormat: " +
          doc
      );
    })
    .catch((err) => {
      console.log("Error add files : ", err);
    });
};

export const addChatFiles = async (FileDataChat: chatFiles) => {
  console.log("addChatFiles . . ");

  if (!FileDataChat) return undefined;

  try {
    const addFileResult = await db.collection("files").add(FileDataChat);

    console.log("Added Files: " + addFileResult);
    return { ...FileDataChat, id: addFileResult.id };
  } catch (err) {
    console.log("Error add Chat files : ", err);
  }
};

export const getFiles = async (fileId: string) => {
  console.log("get file . . ");

  if (!fileId) return undefined;

  return await db
    .collection("files")
    .doc(fileId)
    .withConverter(converter<chatFiles>())
    .get()
    .then((doc) => {
      console.log("Success get Files: " + doc);
    })
    .catch((err) => {
      console.log("Error get files : ", err);
    });
};

export const getFileByMediaSHA256 = async (
  mediaSHA256: string,
  mediaChannel: string,
  clientId: string
) => {
  try {
    const fileData = await db
      .collection("files")
      .where("client", "==", createRef("clients", clientId))
      .where("media_channel", "==", mediaChannel)
      .where("media_sha256", "==", mediaSHA256)
      .withConverter(converter<chatFiles>())
      .get();
    if (fileData.empty) {
      return;
    } else {
      const lastFile = fileData.docs[fileData.docs.length - 1];
      return { ...lastFile.data(), id: lastFile.id };
    }
  } catch (error: any) {
    console.log(error.toString ? error.toString() : error);
    return;
  }
};
