import firebase from "firebase/compat/app"
import "firebase/compat/auth";

const auth = firebase.auth()
console.log(`use firebase emulator : ${process.env.REACT_APP_USE_FIREBASE_EMU}`)
if (process.env.REACT_APP_USE_FIREBASE_EMU && process.env.REACT_APP_USE_FIREBASE_EMU === "true") { 
  console.log(`auth use emulator 2 ${process.env.USE_FIREBASE_EMU}`);
  auth.useEmulator(`http://${process.env.REACT_APP_FIREBASE_EMU_HOST}:9099`)
}

export default auth