import db, { Timestamp } from ".";

export const updateHandover = async (id: string) => {
  const docRef = db.collection("shipmentHandOver").doc(id);

  const doc = await docRef.get();

  if (!doc.exists) {
    throw new Error("Document not found");
  }

  const data = doc.data();

  if (!data) {
    throw new Error("Document data is undefined");
  }

  const updateData: any = {
    isPrinted: true,
    printedAt: Timestamp.now(),
  };

  if (!data.datePickup) {
    updateData.datePickup = Timestamp.now();
  }

  return await docRef.update(updateData);
};
