import { DocumentReference } from "@firebase/firestore-types";
import firebase from "firebase/compat/app";
import { User } from "./User";
import Customer from "./Customer";
import { Collaboration } from "./Chat";

export default interface Message {
  client?: firebase.firestore.DocumentReference;
  channel: string;
  destination: DestinationStatus["inbound"] | DestinationStatus["outbound"] | DestinationStatus["blast"];
  createdAt: firebase.firestore.Timestamp;
  customer?: DocumentReference;
  customerPhoneNumber?: string;
  conversation?: firebase.firestore.DocumentReference;
  // user?: DocumentReference;
  user?: Array<any>;
  customerModel?: Customer;
  userModel?: User;
  mediaUrl?: string;
  messageType: string | undefined;
  textContent: string;
  updatedAt: firebase.firestore.Timestamp;
  id?: string;
  status?: string;
  resultCode?: string;
  resultMessage?: string;
  errorCode?: string;
  errorDetail?: any;
  errorTitle?: string;
  resultMessageId?: string;
  responseJson?: string;
  responseCode?: string;
  previewurl?: boolean;
  collaboration?: DocumentReference;
  isActive: boolean;
  filename?: string;
  filesize?: string;
  fileId?: string;
  voice: boolean;
  templateName?: string;
  templateLanguage?: string;
  header?: string;
  headerUrl?: string;
  headerParams?: Array<string>;
  body?: string;
  bodyParams?: Array<string>;
  footer?: string;
  buttons?: string[];
  locationName?: string;
  locationAddress?: string;
  locationURL?: string;
  location?: firebase.firestore.GeoPoint;
  collaborationModel?: Collaboration;
  submittedAt?: firebase.firestore.Timestamp;
  sentAt?: firebase.firestore.Timestamp;
  deliveredAt?: firebase.firestore.Timestamp;
  failedAt?: firebase.firestore.Timestamp;
  readAt?: firebase.firestore.Timestamp;
  fromUser?: DocumentReference;
  toUser?: DocumentReference;
  notifiedAt?: firebase.firestore.Timestamp | null;
  isMessageRead?: boolean;
  messageId?: string;
  replyMessageID?: DocumentReference;
  company?: DocumentReference;
  order?: DocumentReference;
  duration?: string;
  interactive?: InteractiveMessage;
  conversation_category?: string;
  oldCost?: number;
  newCost?: number;
  room?: firebase.firestore.DocumentReference;
  product?: Product;
}

export interface Product {
  displayName?: string;
  displayPrice?: string;
  imageUrl?: string;
  marketplaceProductId?: number;
  url?: string;
}

export interface InteractiveMessage {
  type: string;
  components: Array<InteractiveMessageComponent>;
}

export interface InteractiveMessageComponent {
  type: string;
  text?: string;
  buttons?: InteractiveMessageButton[];
  document?: string;
  image?: string;
  video?: string;
  filename?: string;
}

export interface InteractiveMessageButton {
  id: string;
  title: string;
}

export const MessageStatus = {
  sent: "sent", //ceklis 1
  submitted: "submitted", //pending
  delivered: "delivered", //ceklis 2
  failed: "failed", //merah
  read: "read", //ceklis 2 biru
  rejected: "rejected",
};

export type DestinationStatus = {
  inbound: "inbound";
  outbound: "outbound";
  blast: "blast";
};

export const ChatType = {
  allchat: "AllChat",
  mychat: "MyChat",
};
