import { Dispatch, PropsWithChildren, ReactPortal, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import * as ProcessesService from "../../../../db/serviceProcesses";
import SocketConnection from "../../../../setup/socket/socket";
import { badgeColorMap, statusLevelMap, statusMap } from "../../../../util/processOrder";
import Modal from "../../Modal";
import { Process, ProcessOrder } from "../../../models/Process";
import { useFirestoreTable } from "../../../layout/order/shiporder-export/order-list/context/TableContext";
import { RootState } from "../../../../setup/redux/store";
import * as ModalExportAction from "./redux/ModalExportOrderSlice";
import * as lc from "../../../modules/localstorage/index";
interface IProcessSocketEvent {
  status: string;
  process: string;
}

const PROCESS_STATUS = {
  FINISH: "finished",
};

type ModalExportOrderProps = {
  onHide?: () => void;
  onCancel?: () => void;
  onConfirm?: (responseSubmit?: any) => void;
  confirmOption?: string;
  cancelOption?: string;
  isModalOpen?: boolean;
  title?: string;
  message?: string;
};
const ModalExportOrder: React.FC<ModalExportOrderProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modalExportState = useSelector((state: RootState) => state.ModalExportOrder);
  const { isModalOpen, responseExportOrder } = modalExportState;
  const [processOrders, setProcessOrder] = useState<ProcessOrder[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [processId, setProcessId] = useState<string>("");
  const [fileUrl, setFileUrl] = useState<string>();

  const setIsModalOpen = (status: boolean) => {
    dispatch(ModalExportAction.setIsModalOpen(status));
  };

  useEffect(() => {
    async function onProcessChange(data: IProcessSocketEvent) {
      console.log("EVENT FROM SOCKET", data);

      // If the process is not the same as the one we are looking for or the process is not finished
      if (data.process !== processId && data.status !== PROCESS_STATUS.FINISH) {
        return;
      }
      const processes = await ProcessesService.getByProcessId(data.process);
      if (!processes) {
        return;
      }

      setProcessOrder(processes.orders as ProcessOrder[]);
      console.log("FETCH PROCESSES ORDER RESULT ", processes);
      setFileUrl(processes.fileURL);
      setIsLoading(false);
    }

    const socket = SocketConnection();
    socket.on(`process-data-update`, onProcessChange);

    return () => {
      socket.off(`process-data-update`, onProcessChange);
    };
  }, [responseExportOrder]);

  useEffect(() => {
    const onProcessResponse = async (processId: string) => {
      console.log({ processId });
      if (processId) {
        setIsLoading(true);
        setProcessId(processId);
        const processes = await ProcessesService.getByProcessId(processId);
        console.log("FETCH PROCESSES ORDER RESULT ", processes);

        if (!processes) {
          return;
        }

        setProcessOrder(processes.orders as ProcessOrder[]);
      }
    };
    if (responseExportOrder) {
      onProcessResponse(responseExportOrder.processId);
    }
  }, [responseExportOrder?.processId]);

  const modalCloseHandler = () => {
    setIsModalOpen(false);
    setProcessOrder(undefined);
    setFileUrl(undefined);
    dispatch(ModalExportAction.setResponseExportOrder(undefined));
  };
  const downloadCSV = async () => {
    const userID = lc.getItemLC(lc.LCName.UserID);
    const link = document.createElement("a");
    link.href = fileUrl || "";
    link.download = "";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    await ProcessesService.incrementDownloadByIDAndAccID(processId, userID);
  };
  console.log({ isModalOpen, isLoading, processOrders });
  return (
    <Modal
      id="ModalExportOrder"
      title={t("ExportOrder.Title")}
      isModalOpen={isModalOpen}
      onHide={modalCloseHandler}
      // onCancel={() => setIsModalOpen(false)}
      onConfirm={async (data: any) => downloadCSV()}
      confirmOption={fileUrl ? "Download CSV" : undefined}
      // cancelOption="Cancel"
      centered={true}
      size="lg"
      closeBtn={true}
    >
      {isLoading && (
        <div className="text-center">
          <span className="spinner-border"></span>
          <p>{t("Common.Modal.Process.Loading")}</p>
        </div>
      )}
      {!isLoading && processId && !processOrders && (
        <div className="text-center fs-6">
          <span className="spinner-border"></span>
          <p>{t("OrderList.Info.OrderIsOnProcess")}</p>
        </div>
      )}
      {!isLoading && processOrders && <ExportOrderTable data={processOrders} />}
    </Modal>
  );
};

export default ModalExportOrder;

type ExportOrderTableProps = {
  data: ProcessOrder[];
};
const ExportOrderTable: React.FC<ExportOrderTableProps> = ({ data }) => {
  const { t } = useTranslation();
  const headers = [t("OrderList.OrderNumber"), t("Common.Modal.Column.Header.Status"), t("Common.Modal.Column.Header.Marketplace")];

  const sortedData = data.sort((a, b) => (statusLevelMap[b.statusRts] || 0) - (statusLevelMap[a.statusRts] || 0));

  return (
    <div
      className="table-responsive px-2"
      style={{
        maxHeight: "60vh",
        overflowY: "scroll",
      }}
    >
      <table id="kt_table_users" className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
        <thead className="position-sticky top-0 bg-white">
          <tr>
            {headers.map((header, i) => (
              <th key={i} className="text-muted fw-bolder fs-7 text-uppercase gs-0 position-sticky top-0 bg-white text-center">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-gray-800 fw-bolder">
          {sortedData.length > 0 ? (
            sortedData.map((item, itemIndex) => (
              <tr key={itemIndex}>
                <td className="text-center">{item.ordersn}</td>
                <td className="text-center">
                  <span className={`fs-5 badge bg-${badgeColorMap[item.statusRts] || "primary"} d-block`}>
                    {statusMap[item.statusRts] ? t(statusMap[item.statusRts]) : item.statusRts}
                  </span>
                </td>
                <td className="fs-5 text-capitalize text-center">{item.accountData?.searchKey || item.accountData?.businessName || item.marketplaceType}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>
                <div className="d-flex text-center w-100 align-content-center justify-content-center">{t("Common.NoData")}</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
