import clsx from "clsx";
import { FC, PropsWithChildren } from "react";
import Account from "../../../../../../../../models/Account";
import { useTranslation } from "react-i18next";
import { CellProps, Column, HeaderProps } from "react-table";
import MarketplaceDateCell from "./DateCell";
import MarketplaceStatusCell from "./StatusCell";
import MarketplaceActionCell from "./ActionCell";
import CellDate from "../../../../../../../../components/table/columns/CellDate";
import {
  defaultMarketplaceTableState,
  useFirestoreTable,
} from "../../../context/TableContext";
import MarketplaceValueCell from "./SetupProgress";
import MarketplaceAppType from "./AppType";

export type HeaderColumnsProp = PropsWithChildren<HeaderProps<Account>> & {
  field: keyof Account | "action";
};
export type CellColumnsProp = PropsWithChildren<CellProps<Account>> & {
  value: string;
};

const MarketplaceColumn: ReadonlyArray<Column<Account>> = [
  {
    Header: (props: HeaderColumnsProp) => (
      <CustomHeader
        {...props}
        title="Marketplace.Table.Header.Type"
        field="type"
      />
    ),
    id: "marketplaceType",
    Cell: (props: CellColumnsProp) => {
      const data = props.row.original;
      return (
        <td {...props.cell.getCellProps()} className="fw-bolder text-center">
          {data.type}
        </td>
      );
    },
  },
  {
    Header: (props: HeaderColumnsProp) => (
      <CustomHeader
        {...props}
        title="Marketplace.Table.Header.URL"
        field="name"
      />
    ),
    id: "nameURL",
    Cell: (props: CellColumnsProp) => {
      const data = props.row.original;
      return (
        <td {...props.cell.getCellProps()} className="fw-bolder text-center">
          {data.name || data.shopee_shopID}
        </td>
      );
    },
  },
  {
    Header: (props: HeaderColumnsProp) => (
      <CustomHeader
        {...props}
        title="Marketplace.Table.Header.AppType"
        field="credentials"
      />
    ),
    id: "AppType",
    Cell: (props: CellColumnsProp) => {
      const data = props.row.original;
      return (
        <td {...props.cell.getCellProps()} className="text-center">
          <MarketplaceAppType
            type={data.type}
            credentials={data.credentials}
            data={data}
          />
        </td>
      );
    },
  },
  {
    Header: (props: HeaderColumnsProp) => (
      <CustomHeader
        {...props}
        title="Marketplace.Table.Header.Date"
        field="updatedAt"
      />
    ),
    id: "updatedAt",
    Cell: (props: CellColumnsProp) => {
      const data = props.row.original;
      return (
        <td {...props.cell.getCellProps()} className="text-center">
          {/* {data?.connectedAt ? (
            <MarketplaceDateCell timestamp={data.connectedAt} />
          ) : data?.credentials?.erp?.connectedAt ? (
            <MarketplaceDateCell
              timestamp={data?.credentials?.erp?.connectedAt}
            />
          ) : (
            <></>
          )} */}
          <MarketplaceDateCell timestamp={data.updatedAt ? data.updatedAt : data.createdAt} />
        </td>
      );
    },
  },
  // {
  //   Header: (props: HeaderColumnsProp) => (
  //     <CustomHeader
  //       {...props}
  //       title="Marketplace.Table.Header.SetupProgress"
  //       field="isActive"
  //     />
  //   ),
  //   id: "SetupProgress",
  //   Cell: (props: CellColumnsProp) => {
  //     // const data = props.row.original;
  //     let step = 0;
  //     let data = props.row.original;
  //     console.log(data, "check data oklx");
  //     let marketplaceName = data.type.toLowerCase();
  //     let prop_check =
  //       data.type === "lazada"
  //         ? `${marketplaceName}_sellerID`
  //         : `${marketplaceName}_shopID`;
  //     if (data.hasOwnProperty(prop_check)) {
  //       step = 1;
  //     }
  //     if (
  //       (data.hasOwnProperty(prop_check) && data.isActive === true) ||
  //       (data.hasOwnProperty(prop_check) && data.updatedAt)
  //     ) {
  //       step = 2;
  //     }
  //     if (
  //       data.hasOwnProperty(prop_check) &&
  //       data.isActive === true &&
  //       data.updatedAt
  //     ) {
  //       step = 3;
  //     }
  //     return (
  //       <td {...props.cell.getCellProps()} className="text-center">
  //         <MarketplaceValueCell value={step} data={data} />
  //       </td>
  //     );
  //   },
  // },
  // {
  //   Header: (props: HeaderColumnsProp) => <CustomHeader {...props} title="Marketplace.Table.Header.Status" field="isActive" />,
  //   id: "status",
  //   Cell: (props: CellColumnsProp) => {
  //     const data = props.row.original;
  //     // console.log(data.isActive);

  //     return (
  //       <td {...props.cell.getCellProps()} className="text-center">
  //         <MarketplaceStatusCell status={data.isActive} data={data} />
  //         {/* {data.isActive.toString()} */}
  //       </td>
  //     );
  //   },
  // },
  {
    Header: (props: HeaderColumnsProp) => (
      <CustomHeader
        {...props}
        title="Marketplace.Table.Header.Action"
        field="action"
      />
    ),
    id: "action",
    Cell: (props: CellColumnsProp) => {
      const data = props.row.original;
      return (
        <td {...props.cell.getCellProps()} className="text-center min-w-100px">
          <MarketplaceActionCell data={data} />
        </td>
      );
    },
  },
];

type CustomHeaderProps = HeaderColumnsProp & {
  className?: string;
  title?: string;
};

const CustomHeader: FC<CustomHeaderProps> = ({
  className,
  title,
  field,
  column,
}) => {
  const { t } = useTranslation();
  const { marketplaceTableState, updateTableState } = useFirestoreTable();

  const isSelectedSort =
    marketplaceTableState.sorting.field === field
      ? marketplaceTableState.sorting
      : undefined;

  const onClickHandler = () => {
    if (!field || field === "action") {
      return;
    }

    let newSort = defaultMarketplaceTableState.sorting;
    if (!isSelectedSort || (isSelectedSort && isSelectedSort.order !== "asc")) {
      newSort = {
        field: field,
        order: "asc",
      };
    }
    if (isSelectedSort && isSelectedSort.order !== "desc") {
      newSort = {
        field: field,
        order: "desc",
      };
    }
    updateTableState({
      sorting: newSort,
    });
  };
  // console.log("Sorting: ", marketplaceTableState.sorting.field );

  return (
    <th
      {...column.getHeaderProps()}
      key={column.id}
      className={clsx(
        className,
        marketplaceTableState.sorting.field === field &&
          `table-sort-${isSelectedSort ? isSelectedSort.order : ""}`
      )}
      style={{ cursor: "pointer", width: "14%" }}
      onClick={onClickHandler}
    >
      {t(title!)}
    </th>
  );
};

export default MarketplaceColumn;
