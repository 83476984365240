import { Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as ProcessesService from "../../../../../../db/serviceProcesses";
import SocketConnection from "../../../../../../setup/socket/socket";
import {
  badgeColorMap,
  statusLevelMap,
  statusMap,
} from "../../../../../../util/processOrder";
import Modal from "../../../../../components/Modal";
import { Process, ProcessOrder } from "../../../../../models/Process";
import * as lc from "../../../../../modules/localstorage";
import { useFirestoreTable } from "../context/TableContext";

interface IProcessSocketEvent {
  status: string;
  process: string;
}

const PROCESS_STATUS = {
  FINISH: "finished",
};
export default function OrderModal() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    loadingRTS,
    responseRTS,
    resetResponseRTS,
    setSelectedItems,
    refreshData,
  } = useFirestoreTable();
  const [processOrder, setProcessOrder] = useState<Process | undefined>(
    undefined
  );

  console.log("loadingRTS", loadingRTS, "responseRTS", responseRTS);
  const onModalCloseHandler = () => {
    setProcessOrder(undefined);
    setSelectedItems([]);
    refreshData();
    resetResponseRTS();
  };

  useEffect(() => {
    if (!responseRTS) {
      return;
    }

    console.log("WAITING GET EVENT FROM SOCKET");
    const processId = responseRTS?.processId;

    async function onProcessChange(data: IProcessSocketEvent) {
      console.log("EVENT FROM SOCKET", data);
      const processId = data.process;
      const processStatus = data.status;
      if (
        processId !== responseRTS?.processId &&
        processStatus !== PROCESS_STATUS.FINISH
      ) {
        return;
      }
      const processes = await ProcessesService.getByProcessId(processId);
      console.log("FETCH PROCESSES ORDER RESULT ", processes);

      if (!processes) {
        return;
      }

      setProcessOrder(processes ? (processes as Process) : undefined);
    }

    // const clientRef = getClientRef();
    // const clientId = clientRef?.id ?? "";
    const socket = SocketConnection();
    socket.on(`process-data-update`, onProcessChange);

    return () => {
      socket.off(`process-data-update`, onProcessChange);
    };
  }, [responseRTS]);

  const onDownload = async (item?: Process) => {
    const userID = lc.getItemLC(lc.LCName.UserID);
    if (!item || !userID) {
      return undefined;
    }
    const link = document.createElement("a");
    link.href = item.fileURL || "";
    link.download = "Export Order" + item.client;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    await ProcessesService.incrementDownloadByIDAndAccID(item.id, userID);
  };

  const orderRTSList = processOrder?.orders || [];

  return (
    <Modal
      id="ExportOrderModal"
      title={t("ExportOrder.Title")}
      confirmOption={
        processOrder?.status === PROCESS_STATUS.FINISH &&
        processOrder?.fileURL !== undefined
          ? t("Common.Modal.Button.Download")
          : undefined
      }
      cancelOption={
        !!responseRTS?.processId ? t("ExportOrder.Info.Button") : undefined
      }
      closeBtn={!!responseRTS?.processId}
      onHide={
        !!responseRTS?.processId ? () => onModalCloseHandler() : undefined
      }
      // isModalOpen={true}
      isModalOpen={loadingRTS || responseRTS !== undefined}
      onConfirm={() => onDownload(processOrder)}
      onCancel={() => {
        onModalCloseHandler();
        navigate("/order/process");
      }}
      centered={true}
      size={processOrder ? "lg" : undefined}
    >
      {loadingRTS && (
        <div className="text-center">
          <span className="spinner-border"></span>
          <p>{t("Common.Modal.Process.Loading")}</p>
        </div>
      )}
      {!loadingRTS && responseRTS?.processId && !processOrder && (
        <div className="text-center fs-6">
          <span className="spinner-border"></span>
          <p>{t("OrderList.Info.OrderIsOnProcess")}</p>
        </div>
      )}
      {!loadingRTS && processOrder && <ExportOrderTable data={orderRTSList} />}
    </Modal>
  );
}

type ExportOrderTableProps = {
  data: ProcessOrder[];
};

// const typeLabelMap: { [key: string]: string } = {
//   regular: "Regular Order",
//   high: "High Priority Order",
// };

export const ExportOrderTable: React.FC<ExportOrderTableProps> = ({ data }) => {
  const { t } = useTranslation();
  const headers = [
    t("OrderList.OrderNumber"),
    t("Common.Modal.Column.Header.Status"),
    t("Common.Modal.Column.Header.Marketplace"),
  ];

  const sortedData = data.sort(
    (a, b) =>
      (statusLevelMap[b.statusRts] || 0) - (statusLevelMap[a.statusRts] || 0)
  );

  return (
    <div
      className="table-responsive px-2"
      style={{
        maxHeight: "60vh",
        overflowY: "scroll",
      }}
    >
      <table
        id="kt_table_users"
        className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
      >
        <thead className="position-sticky top-0 bg-white">
          <tr>
            {headers.map((header, i) => (
              <th
                key={i}
                className="text-muted fw-bolder fs-7 text-uppercase gs-0 position-sticky top-0 bg-white text-center"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-gray-800 fw-bolder">
          {sortedData.length > 0 ? (
            sortedData.map((item, itemIndex) => (
              <tr key={itemIndex}>
                <td className="text-center">{item.ordersn}</td>
                <td className="text-center">
                  <span
                    className={`fs-5 badge bg-${
                      badgeColorMap[item.statusRts] || "primary"
                    } d-block`}
                  >
                    {statusMap[item.statusRts]
                      ? t(statusMap[item.statusRts])
                      : item.statusRts}
                  </span>
                </td>
                <td className="fs-5 text-capitalize text-center">
                  {item.accountData?.searchKey ||
                    item.accountData?.businessName ||
                    item.marketplaceType}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>
                <div className="d-flex text-center w-100 align-content-center justify-content-center">
                  {t("Common.NoData")}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

// function groupProcessOrders(orders?: ProcessOrder[]) {
//   if (!orders || orders.length <= 0) {
//     return [];
//   }
//   const groupMap: {
//     [key: string]: {
//       type: string;
//       status: string;
//       priorityNumber: number;
//       count: number;
//     };
//   } = {};

//   const priorityNumberMap: { [key: string]: number } = {
//     failed_high: 100,
//     failed_regular: 80,
//     success_high: 60,
//     success_regular: 40,
//   };

//   orders.forEach((order) => {
//     const priorityNumber = order.priorityNumber || 0;
//     const typeLabel = priorityNumber > 60 ? "high" : "regular";
//     const statusLabel = order.statusRts === "success" ? "success" : "failed";
//     const keyMap = `${statusLabel}_${typeLabel}`;
//     if (groupMap[keyMap]) {
//       groupMap[keyMap].count = groupMap[keyMap].count + 1;
//       return;
//     }
//     groupMap[keyMap] = {
//       status: statusLabel,
//       type: typeLabel,
//       count: 1,
//       priorityNumber: priorityNumberMap[keyMap] || 20,
//     };
//     return;
//   });

//   return Object.values(groupMap).sort(
//     (a, b) => b.priorityNumber - a.priorityNumber
//   );
// }

// const dummyOrders = [
//   {
//     priorityNumber: 20,
//     marketplaceType: "shopee",
//     statusRts: "timeout",
//     orderRef: {
//       id: "shopee_61305_230724SAKTHQQM",
//     },
//     shopId: "61305",
//     ordersn: "230724SAKTHQQM",
//     account: {
//       id: "",
//     },
//   },
//   {
//     shopId: "400656576107",
//     marketplaceType: "lazada",
//     statusRts: "success",
//     ordersn: "1279556507169007",
//     priorityNumber: 20,
//     account: {
//       id: "",
//     },
//     orderRef: {
//       id: "lazada_400656576107_1279556507169007",
//     },
//   },
//   {
//     priorityNumber: 70,
//     ordersn: "INV20231009MPL3500452857",
//     shopId: "13763445",
//     account: {
//       id: "",
//     },
//     orderRef: {
//       id: "tokopedia_13763445_INV20231009MPL3500452857",
//     },
//     message:
//       "Error get order detail, Order ID: tokopedia_13763445_INV20231009MPL3500452857",
//     statusRts: "failed",
//     marketplaceType: "tokopedia",
//   },
// ];

// const dummyProc = {
//   updatedAt: Timestamp.now(),
//   orderIds: [
//     "shopee_61305_230724SAKTHQQM",
//     "lazada_400656576107_1279556507169007",
//     "tokopedia_13763445_INV20231009MPL3500452857",
//   ],
//   fileURL:
//     "https://firebasestorage.googleapis.com/v0/b/days-stagging-vita.appspot.com/o/orders%2Fexport_amx_2023-10-10T09%3A42%3A53.977Z_7days-orders.csv?alt=media&token=958f0bc8-7322-4880-b423-64012e3a5f36",
//   createdAt: Timestamp.now(),
//   downloadCount: 0,
//   orders: [
//     ...dummyOrders,
//     ...dummyOrders,
//     ...dummyOrders,
//     ...dummyOrders,
//     ...dummyOrders,
//     ...dummyOrders,
//   ],
//   status: "finished",
//   client: {
//     id: "amx",
//   },
//   id: "rylqoaH9ionrNhOliBei",
// };
