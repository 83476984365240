import React from "react";
import "./ConfirmationModal.css";

interface ConfirmationModalProps {
  message: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  message,
  onConfirm,
  onCancel,
}) => {
  return (
    <div
      className="confirmation-modal-overlay"
      data-testid="confirmation-modal"
    >
      <div className="confirmation-modal-content">
        <p>{message}</p>
        <div className="confirmation-button-row">
          {onCancel && (
            <button onClick={onCancel} className="btn btn-light-primary">
              Cancel
            </button>
          )}
          {"  "}
          <button onClick={onConfirm} className="btn btn-primary">
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
