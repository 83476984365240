import { FC, useState } from "react";
import { ModalInvoicePreview } from "src/app/layout/chat/components/modals/InvoicePreview";
import { Order } from "src/app/models/Order";

type OrderSNCellProps = {
  orderData: Order;
  isAvailableToProcess: boolean;
};

export const OrderSNCell: FC<OrderSNCellProps> = ({
  orderData,
  isAvailableToProcess,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div
        className={`d-flex flex-row align-items-center justify-content-center cursor-pointer`}
        onClick={() => setShowModal(true)}
      >
        <p
          className={`${isAvailableToProcess ? "fw-bolder" : ""} m-0 p-0 me-1`}
          style={{
            color: "#777",
            flexGrow: 1,
            flexShrink: 1,
            minWidth: 0,
            wordBreak: "break-word",
            whiteSpace: "normal",
            overflowWrap: "break-word",
          }}
        >
          {orderData.ordersn}
          <span>
            <i className="bi bi-eye"></i>
          </span>
        </p>
      </div>
      {showModal && (
        <ModalInvoicePreview
          open={showModal}
          close={closeModal}
          marketplaceType={orderData.marketplaceType || ""}
          data={orderData}
        />
      )}
    </>
  );
};
