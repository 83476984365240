import React, { Fragment, useEffect, useState } from "react";
import clsx from "clsx";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { useTranslation } from "react-i18next";
import { KTSVG, toAbsoluteUrl } from "../../../../../../../resources/helpers";
import MultiSelect from "editable-creatable-multiselect";
import "./styles/VariantCard.css";
import { Variant } from "../../../../../../models/Product";
import { useDispatch, useSelector } from "react-redux";
import * as ReduxProduct from "../../../../../../modules/product/redux/ProductSlice";
import { RootState } from "../../../../../../../setup/redux/store";
import { useMediaQuery } from "react-responsive";
import ModalConfirmationChangeVariants from "../../../../../../modules/modal/modalConfirmationChangeVariants";

const customStyles = {
  control: (base: any) => ({
    ...base,
    border: "none",
  }),
};

const AddDataButtoninSelect = (props: any) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <components.Menu {...props}>
        <div>
          {props.selectProps.fetchingData ? (
            <span className="fetching">{t("Common.Pages.Loading")}...</span>
          ) : (
            <div>{props.children}</div>
          )}
          {/* <Link to="/product/list"> */}
          <button
            data-testid="button-to-storefront"
            className={"change-data"}
            // onClick={toAddEtalase}
            // onTouchStart={toAddEtalase}
          >
            <h6 className="text-primary">
              + {t("AddNewProduct.Button.SM.AddNewStorefront")}
            </h6>
          </button>
          {/* </Link> */}
        </div>
      </components.Menu>
    </Fragment>
  );
};

interface VariantCardProps {
  variantNo: number;
  variantType: any[];
  onClickNext: boolean;
  clickNextCounter: number;
}

const VariantCard: React.FC<VariantCardProps> = ({
  variantNo,
  variantType,
  onClickNext,
  clickNextCounter,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const [selectedList, setSelectedList] = useState<any>([]);
  const [isVariantModalOpen, setIsVariantModalOpen] = useState(false);
  const [isVariantOptionModalOpen, setIsVariantOptionModalOpen] =
    useState(false);
  const [isVariantOptionEditModalOpen, setIsVariantOptionEditModalOpen] =
    useState(false);
  const [isVariantTypeModalOpen, setIsVariantTypeModalOpen] = useState(false);
  const [updatedVariantType, setUpdatedVariantType] = useState(null);
  const [updatedVariant, setUpdatedVariant] = useState<any>(null);
  const [isConfirmChanges, setIsConfirmChanges] = useState(false);
  const rd_Variants = useSelector((state: RootState) => state.Product.variants);
  const rd_VariantsType = useSelector(
    (state: RootState) => state.Product.variantsType
  );
  const rd_SelectedVariantsType = useSelector(
    (state: RootState) => state.Product.selectedVariantsType
  );

  const rd_VariantMatrix: any = useSelector(
    (state: RootState) => state.Product.variantsMatrix
  );

  const hasMatrixData = rd_VariantMatrix && rd_VariantMatrix.length > 0;
  // validation
  const initialVariantTypeValidation = {
    isVariantTypeEmpty: false,
    isVariantTypeHasTheSameName: false,
    isVariantTypeLenIsOverLimit: false,
  };

  const initialVariantOptionValidation = {
    isVariantOptionLenIsOverLimit: false,
    isVariantOptionHasTheSameName: false,
    isVariantOptionEmpty: false,
    isVariantOptionCombinationMoreThan50: false,
  };

  const [variantOptionValidationState, setVariantOptionValidationState] =
    useState(initialVariantOptionValidation);

  const [variantTypeValidationState, setVariantTypeValidationState] = useState(
    initialVariantTypeValidation
  );

  const handleChangesVariantType = (event: any) => {
    console.log("masuk on changes : ", event);
    let variantSelected = event;

    // clear validation state before checking
    setVariantTypeValidationState(initialVariantTypeValidation);

    // const variantTypeIsEmpty = variantSelected?.name?.length === 0;

    // if (variantTypeIsEmpty) {
    //   setVariantTypeValidationState((prev: any) => ({
    //     ...prev,
    //     isVariantTypeEmpty: true,
    //   }));
    // }
    // console.log("rd_SelectedVariantsType : ", rd_SelectedVariantsType);

    if (rd_SelectedVariantsType.length <= 0 && variantNo === 0) {
      console.log("masuk add selected variant");
      dispatch(ReduxProduct.setSelectedVariantsType([variantSelected]));
    } else {
      let tempSelectedType: any = [...rd_SelectedVariantsType];
      console.log("rd_SelectedVariantsType before : ", tempSelectedType);
      const findSelected = tempSelectedType[variantNo];
      console.log("variantNo : ", variantNo);
      console.log("findSelected : ", findSelected);
      if (!findSelected) {
        if (variantNo === 0) {
          tempSelectedType = [
            variantSelected !== null ? variantSelected : null,
            tempSelectedType[1] ? tempSelectedType[1] : null,
          ];
        } else {
          tempSelectedType = [
            tempSelectedType[0] ? tempSelectedType[0] : null,
            variantSelected,
          ];
        }
      } else {
        if (variantNo === 0) {
          tempSelectedType = [
            variantSelected !== null ? variantSelected : null,
            tempSelectedType[1] ? tempSelectedType[1] : null,
          ];
          console.log(
            "tempSelectedType when variant no 0 : ",
            tempSelectedType
          );
        } else {
          tempSelectedType = [
            tempSelectedType[0],
            variantSelected !== null ? variantSelected : null,
          ];
          console.log(
            "tempSelectedType when variant no 1 : ",
            tempSelectedType
          );
        }
      }
      console.log("rd_SelectedVariantsType after : ", tempSelectedType);
      dispatch(ReduxProduct.setSelectedVariantsType(tempSelectedType));
    }
    console.log("VAR name : ", event);
    console.log("VAR index : ", variantNo);
    const findVariant = rd_Variants.find(
      (variant: any) => variant.index === variantNo
    );
    const variantData: Variant = {
      name: variantSelected?.value.trim() || "",
      index: variantNo,
      optionList: findVariant?.optionList || [],
    };

    const variants = rd_Variants.filter(
      (variant: any) => variant.index !== variantNo
    );
    console.log("VAR variantData", variantData);
    console.log("VAR variants : ", variants);
    const updated = [...variants, variantData];
    const sortArray = updated.sort((a: any, b: any) =>
      a.index > b.index! ? 1 : -1
    );
    console.log("hasil sorting array : ", sortArray);
    console.log("VAR Upadated using spread,", updated);
    dispatch(ReduxProduct.setVariants(sortArray));
  };

  const handleOnCreateOption = (inputValue: string) => {
    setVariantTypeValidationState(initialVariantTypeValidation);

    // check if value is not existed before in variantType
    const isInputValueAlreadyExist = rd_VariantsType?.find(
      (variant: any) =>
        variant?.value?.toLowerCase() === inputValue.toLowerCase().trim()
    );
    // if exist set validation
    if (isInputValueAlreadyExist) {
      setVariantTypeValidationState((prev: any) => ({
        ...prev,
        isVariantTypeHasTheSameName: true,
      }));
      return;
    }

    if (inputValue.length > 14) {
      setVariantTypeValidationState((prev: any) => ({
        ...prev,
        isVariantTypeLenIsOverLimit: true,
      }));
      return;
    }

    if (hasMatrixData) {
      // setIsVariantOptionModalOpen(true)
    }

    const findVariant = rd_Variants.find(
      (variant: any) => variant.index === variantNo
    );

    setTimeout(() => {
      let newData: any = {
        label: inputValue.trim(),
        value: inputValue.trim(),
      };

      let variantData: Variant = {
        name: inputValue?.trim() || "",
        index: variantNo,
        optionList: findVariant?.optionList || [],
      };

      const variants = rd_Variants.filter(
        (variant: any) => variant.index !== variantNo
      );
      //

      console.log("VARIANT filter on create", variants);
      console.log("VARIANT data", variantData);
      const updated = [...variants, variantData];
      const sortArray = updated.sort((a: any, b: any) =>
        a.index > b.index! ? 1 : -1
      );

      dispatch(ReduxProduct.setVariants(sortArray));

      if (rd_VariantsType.length > 0) {
        let newVariantsType: any = [...rd_VariantsType];
        newVariantsType.push(newData);
        dispatch(ReduxProduct.setVariantsType(newVariantsType));
        if (rd_SelectedVariantsType.length <= 0 && variantNo === 0) {
          console.log("masuk new data to selected");
          dispatch(ReduxProduct.setSelectedVariantsType([newData]));
        } else {
          let tempSelectedType: any = [...rd_SelectedVariantsType];
          console.log("rd_SelectedVariantsType before : ", tempSelectedType);
          const findSelected = tempSelectedType[variantNo];
          console.log("findSelected : ", findSelected);
          if (!findSelected && findSelected !== null) {
            // tempSelectedType = [...rd_SelectedVariantsType, newData];
            if (variantNo === 0) {
              tempSelectedType = [
                newData,
                tempSelectedType[1] ? tempSelectedType[1] : null,
              ];
            } else {
              tempSelectedType = [
                tempSelectedType[0] ? tempSelectedType[0] : null,
                newData,
              ];
            }
          } else {
            if (variantNo === 0) {
              tempSelectedType = [
                newData,
                tempSelectedType[1] ? tempSelectedType[1] : null,
              ];
              console.log(
                "tempSelectedType when variant no 0 : ",
                tempSelectedType
              );
            } else {
              tempSelectedType = [
                tempSelectedType[0] ? tempSelectedType[0] : null,
                newData,
              ];
              console.log(
                "tempSelectedType when variant no 1 : ",
                tempSelectedType
              );
            }
          }
          console.log("rd_SelectedVariantsType after : ", tempSelectedType);
          dispatch(ReduxProduct.setSelectedVariantsType(tempSelectedType));
        }
      }
    }, 1000);
  };

  const handleUpdateVariantOption = (value: any[]) => {
    console.log("masuk handle update variant option : ", value);

    // //check from redux matrix
    // if (rd_VariantMatrix && rd_VariantMatrix.length > 0) {
    //   //find optionList in variant
    //   const findVariant = rd_Variants.find(
    //     (variant: any) => variant.index === variantNo
    //   );
    //   if (findVariant && value.length < findVariant.optionList!.length) {
    //     console.log("deleted");
    //     setIsVariantOptionModalOpen(true);
    //   }
    // }

    // if (rd_VariantMatrix && rd_VariantMatrix.length > 0 && !isConfirmChanges)
    //   return;
    // console.log("masih lanjut");

    // clean state validation before validate on the variant options
    setVariantOptionValidationState(initialVariantOptionValidation);

    // check if its not empty
    const variantOptionIsEmpty = value?.length === 0;

    // (remove unnecessary empty space) trim

    console.log("VALUE OPTIONS", value);
    const variantsData: any[] = value?.map((item: any) => {
      console.log("item : ", item);
      item.name = item?.name ? item?.name?.trim() : "";
      return item;
    });

    // check if char is more than 20
    const variantOptionIsOverLimit = variantsData?.some(
      (item: any) => item?.name?.length > 20
    );

    // check if has duplicate array
    const variantOptionHasDuplicateName = variantsData?.some(
      (item: any, index: number) =>
        variantsData
          ?.slice(index + 1)
          .some(
            (nextItem: any) =>
              nextItem.name.toLowerCase() === item.name.toLowerCase()
          )
    );

    // check if combination is more than 50
    const rd_allVariant = rd_Variants;
    const optionLen: any = rd_allVariant?.map((obj) => obj.optionList?.length);
    const copyOptionLen = [...optionLen];

    // add one data to option len to compense redux initial value according to the value len
    copyOptionLen[variantNo] = value.length; // eslint-disable-line

    // total options cant be more than 50
    const totalCombinations = copyOptionLen.reduce(
      (acc: any, val: any) => acc * val,
      1
    );

    if (variantOptionIsEmpty) {
      setVariantOptionValidationState((prev: any) => ({
        ...prev,
        isVariantOptionEmpty: true,
      }));
      // return;
    }

    if (variantOptionIsOverLimit) {
      setVariantOptionValidationState((prev: any) => ({
        ...prev,
        isVariantOptionLenIsOverLimit: true,
      }));
      return;
    }

    if (variantOptionHasDuplicateName) {
      setVariantOptionValidationState((prev: any) => ({
        ...prev,
        isVariantOptionHasTheSameName: true,
      }));
      return;
    }

    if (
      totalCombinations !== undefined &&
      !Number.isNaN(totalCombinations) &&
      totalCombinations > 50
    ) {
      setVariantOptionValidationState((prev: any) => ({
        ...prev,
        isVariantOptionCombinationMoreThan50: true,
      }));

      return;
    }

    const findVariant = rd_Variants.find(
      (variant: any) => variant.index === variantNo
    );
    const transformValue = value.map((opt: any) => ({
      option: opt?.name.trim(),
      index: opt?.index,
    }));
    const variantData: Variant = {
      name: findVariant?.name || "",
      index: findVariant?.index || variantNo,
      optionList: transformValue || [],
    };
    const variants = rd_Variants.filter(
      (variant: any) => variant.index !== variantNo
    );
    const updated = [...variants, variantData];
    const sortArray = updated.sort((a: any, b: any) =>
      a.index > b.index! ? 1 : -1
    );
    console.log("set selected list : ", value);

    setSelectedList(value);
    dispatch(ReduxProduct.setVariants(sortArray));
  };

  const handleRemoveVariant = (index: number) => {
    const variants = rd_Variants.find(
      (variant: any) => variant.index !== index
    );
    console.log("check variant : ", variants);
    const newVariantData = {
      name: variants?.name?.trim() || "",
      index: 0,
      optionList: variants?.optionList || [],
    };
    dispatch(ReduxProduct.setVariants([newVariantData]));
    const transformValue: any = variants?.optionList?.map((opt: any) => ({
      name: opt?.option,
      index: opt?.index,
    }));
    console.log("transformValue : ", transformValue);
    setSelectedList(transformValue || []);

    if (rd_SelectedVariantsType.length > 0) {
      console.log("rd_SelectedVariantsType : ", rd_SelectedVariantsType);
      let tempSelectedType: any[] = [...rd_SelectedVariantsType];
      console.log("tempSelectedType : ", rd_SelectedVariantsType);
      let newSelected;
      if (index === 0) {
        newSelected = tempSelectedType[1];
      } else {
        newSelected = tempSelectedType[0];
      }
      console.log("newSelected : ", newSelected);
      dispatch(
        ReduxProduct.setSelectedVariantsType(
          newSelected ? [newSelected] : [null]
        )
      );
    } else {
      dispatch(ReduxProduct.setSelectedVariantsType([null]));
    }
  };

  useEffect(() => {
    const variant = rd_Variants[variantNo];
    const option: any = variant?.optionList;
    if (option !== undefined && option.length > 0) {
      const transformValue = option.map((opt: any) => ({
        name: opt?.option,
        index: opt?.index,
      }));
      setSelectedList(transformValue);
    }
    if (rd_VariantsType.length <= 0) {
      console.log("set varian type");
      dispatch(ReduxProduct.setVariantsType(variantType));
    }
    console.log("onclick next : ", onClickNext);
    if (onClickNext) {
      setVariantTypeValidationState(initialVariantTypeValidation);
      const variantTypeIsEmpty =
        rd_Variants[variantNo]?.name?.length === 0 ||
        rd_Variants[variantNo]?.name === undefined;
      if (variantTypeIsEmpty) {
        setVariantTypeValidationState((prev: any) => ({
          ...prev,
          isVariantTypeEmpty: true,
        }));
      }

      const variantOptionList = rd_Variants[variantNo]?.optionList;

      const variantOptionIsEmpty =
        variantOptionList === undefined || variantOptionList?.length === 0;

      if (variantOptionIsEmpty) {
        setVariantOptionValidationState((prev: any) => ({
          ...prev,
          isVariantOptionEmpty: true,
        }));
      }
    }
  }, [onClickNext]);

  const data = [...rd_SelectedVariantsType];
  const test: any = variantNo === 0 ? data[1] : data[0];

  // Error Variant Type Scroll

  useEffect(() => {
    const variantTypeHasError = Object.values(variantTypeValidationState).some(
      (value) => value === true
    );
    const variantOptionHasError = Object.values(
      variantTypeValidationState
    ).some((value) => value === true);

    const variantTypeErrorElement = document.getElementById("errorVariantType");
    const variantOptionErrorElement =
      document.getElementById("errorVariantOption");

    if (variantTypeHasError) {
      setTimeout(() => {
        variantTypeErrorElement?.scrollIntoView({ behavior: "smooth" });
      }, 250);
    }

    if (variantOptionHasError) {
      setTimeout(() => {
        variantOptionErrorElement?.scrollIntoView({ behavior: "smooth" });
      }, 250);
    }
  }, [clickNextCounter]);

  console.log("check selected list value sekarang : ", selectedList);
  useEffect(() => {
    if (rd_Variants?.length > 0) {
      const optionLists: any = rd_Variants?.map((obj) => obj.optionList);
      console.log("BN after deletion", optionLists);
      const selectedListData = optionLists[variantNo];
      if (selectedListData && selectedListData?.length > 0) {
        const transformValue: any = selectedListData?.map((opt: any) => ({
          name: opt?.option,
          index: opt?.index,
        }));
        console.log("check transformValue : ", transformValue);
        setSelectedList(transformValue);
      }
    }
  }, []);

  // if select false, check redux is there any changes in matrix
  // if (variantProduct === false) {
  //   setIsVariantOptionModalOpen(true);

  //   // proceed here
  // }

  const showModalVariant = (event: any) => {
    const findVariant = rd_VariantMatrix.filter(
      (variant: any) => variant.tierIndex[0] === variantNo
    );
    console.log("find : ", findVariant);
    if (findVariant.length > 0) {
      setIsVariantModalOpen(true);
      setUpdatedVariant(event);
    } else {
      handleRemoveVariant(event);
    }
  };

  const handleOnConfirmVariant = () => {
    dispatch(ReduxProduct.setVariantMatrix([]));
    dispatch(ReduxProduct.setProductVariantPhotos([]));
    setIsVariantModalOpen(false);
    handleRemoveVariant(updatedVariant);
  };

  const handleOnCancelVariant = () => {
    setIsVariantModalOpen(false);
    setUpdatedVariant(null);
  };

  const showModalVariantType = (event: any) => {
    const findVariant = rd_VariantMatrix.filter(
      (variant: any) => variant.tierIndex[0] === variantNo
    );
    console.log("find : ", findVariant);

    if (findVariant.length > 0) {
      setIsVariantTypeModalOpen(true);
      setUpdatedVariantType(event);
    } else {
      handleChangesVariantType(event);
    }
  };

  const handleOnConfirmVariantType = () => {
    setIsVariantTypeModalOpen(false);
    handleChangesVariantType(updatedVariantType);
    dispatch(ReduxProduct.setVariantMatrix([]));
    dispatch(ReduxProduct.setProductVariantPhotos([]));
  };

  const handleOnCancelVariantType = () => {
    setIsVariantTypeModalOpen(false);
    setUpdatedVariantType(null);
  };

  // const [previousValue, setPreviousValue] = useState<any>()
  const [deletedValue, setDeletedValue] = useState<any>();
  const [editedValue, setEditedValue] = useState<any>();

  const handleOnConfirmVariantOption = () => {
    handleUpdateVariantOption(selectedList);
    setIsVariantOptionModalOpen(false);
    dispatch(ReduxProduct.setVariantMatrix([]));
    dispatch(ReduxProduct.setProductVariantPhotos([]));
  };

  const handleOnCancelVariantOption = () => {
    selectedList.splice(deletedValue.index, 0, deletedValue);
    handleUpdateVariantOption(selectedList);
    setIsVariantOptionModalOpen(false);
  };

  const handleOnConfirmEditedVariantOption = () => {
    console.log("selectedList : ", selectedList);
    handleUpdateVariantOption(selectedList);
    setIsVariantOptionEditModalOpen(false);
    dispatch(ReduxProduct.setVariantMatrix([]));
    dispatch(ReduxProduct.setProductVariantPhotos([]));
  };

  const handleOnCancelEditedVariantOption = () => {
    const variants = rd_Variants.find(
      (variant: any) => variant.index === variantNo
    );
    const transformValue: any = variants?.optionList?.map((opt: any) => ({
      name: opt?.option,
      index: opt?.index,
    }));
    setSelectedList(transformValue);
    setIsVariantOptionEditModalOpen(false);
  };

  return (
    <div className={clsx("mt-6", `${isTabletOrMobile ? "w-100" : "w-75"}`)}>
      {/* Modal for Update / Change Variants Type */}
      <ModalConfirmationChangeVariants
        id="variantType"
        isModalOpen={isVariantTypeModalOpen}
        onCancel={() => {
          handleOnCancelVariantType();
        }}
        onConfirm={() => {
          handleOnConfirmVariantType();
        }}
      />

      {/* Modal for Delete Variant Options */}
      <ModalConfirmationChangeVariants
        id="variantOption"
        isModalOpen={isVariantOptionModalOpen}
        onCancel={() => {
          handleOnCancelVariantOption();
        }}
        onConfirm={() => {
          handleOnConfirmVariantOption();
        }}
      />
      <ModalConfirmationChangeVariants
        id="variantOptionEdit"
        isModalOpen={isVariantOptionEditModalOpen}
        onCancel={() => {
          handleOnCancelEditedVariantOption();
        }}
        onConfirm={() => {
          handleOnConfirmEditedVariantOption();
        }}
      />

      {/* modal for delete one variant */}
      <ModalConfirmationChangeVariants
        id="variantData"
        isModalOpen={isVariantModalOpen}
        onCancel={() => {
          handleOnCancelVariant();
        }}
        onConfirm={() => {
          handleOnConfirmVariant();
        }}
      />

      <div
        className="bg-primary rounded px-5 pt-5 bg-opacity-20 "
        style={{ minHeight: `${isTabletOrMobile ? "190px" : ""}` }}
      >
        <div className="d-flex w-100 justify-content-between">
          <p className="fw-bolder">
            {t("AddNewProduct.Info.ProductVariants.Variant")} {variantNo + 1}
            <span className="text-danger">*</span>
          </p>
          <div
            role="button"
            data-testid="buttonRemoveVariant"
            onClick={() => showModalVariant(variantNo)}
            style={{ justifySelf: "end" }}
          >
            <KTSVG
              className="svg-icon-2hx svg-icon-gray-900 ml-auto"
              path={toAbsoluteUrl("/media/icons/duotune/arrows/arr088.svg")}
            />
          </div>
        </div>
        <div className="mr-auto">
          <CreatableSelect
            styles={customStyles}
            data-testid="variant-name"
            placeholder={t("AddNewProduct.Info.SelectVariant")}
            id="variant-name"
            name="variant-name"
            value={
              rd_SelectedVariantsType[variantNo] !== ""
                ? rd_SelectedVariantsType[variantNo]
                : undefined
            }
            options={rd_VariantsType.filter(
              (variant: any) => variant?.value !== test?.value
            )}
            className={`${isTabletOrMobile ? "w-75" : "w-50"}`}
            isClearable={true}
            formatCreateLabel={(data) =>
              `${t("AddNewProduct.Button.AddVariant")}... "${data}"`
            }
            // components={{ Menu: AddDataButtoninSelect }}
            onChange={
              rd_SelectedVariantsType[variantNo] !== ""
                ? showModalVariantType
                : handleChangesVariantType
            }
            onCreateOption={handleOnCreateOption}
            maxMenuHeight={120}
          />
        </div>

        {/* Error Container Variant Type */}
        <div
          id="errorVariantType"
          data-testid="errorVariantType"
          className="text-danger"
          style={{ height: `${isTabletOrMobile ? "35px" : "25px"}` }}
        >
          {variantTypeValidationState.isVariantTypeEmpty && onClickNext && (
            <span>{t("AddNewProduct.Alert.SelectVariant")}</span>
          )}
          {variantTypeValidationState.isVariantTypeHasTheSameName && (
            <span>
              {t("AddNewProduct.Alert.VariantOptionNameAlreadyExist")}
            </span>
          )}
          {variantTypeValidationState.isVariantTypeLenIsOverLimit && (
            <span>{t("AddNewProduct.Alert.VariantTypeMaxCharacter")}</span>
          )}
        </div>

        <div style={{ backgroundColor: "white" }} className="rounded">
          <MultiSelect
            data-testid="variantOptionsInput"
            suggestions={[]}
            selectedItems={selectedList}
            updateSelectedItems={(response: any) => {
              console.log("response : ", response);
              const deletedValue = response.removedItem;
              const addedValue = response.addedItem;
              const editedValue = response.editedItem;

              const list = response.list.map((item: any, index: number) => {
                return { ...item, index };
              });

              if (hasMatrixData && !addedValue) {
                if (deletedValue) {
                  // setShowModalFrom("variantOption");
                  setIsVariantOptionModalOpen(true);
                  setDeletedValue(deletedValue);
                  return;
                }
                if (editedValue) {
                  setIsVariantOptionEditModalOpen(true);
                  setEditedValue(editedValue);
                  return;
                }
              }
              // else {
              //   handleUpdateVariantOption(list);
              // }
              handleUpdateVariantOption(list);
            }}
            maxLength={20}
            displayField={"name"}
            maxDisplayedItems={5}
            disabled={false}
            editFieldPosBelow={true}
            autoAddOnBlur={true}
            placeholder={t("AddNewProduct.Info.Input.OptionofVariant")}
          />
        </div>

        {/* This one need also to check clicked next state */}

        {/* Error Container */}
        <div
          id="errorVariantOption"
          className="text-danger"
          data-testid="errorVariantOption"
          style={{ height: `${isTabletOrMobile ? "35px" : "22px"}` }}
        >
          {variantOptionValidationState.isVariantOptionEmpty && onClickNext && (
            <span>{t("AddNewProduct.Alert.AddOptionofVariant")}</span>
          )}
          {variantOptionValidationState.isVariantOptionLenIsOverLimit && (
            <span data-testid="alert-variant-option-max-char">
              {t("AddNewProduct.Alert.VariantOptionMaxCharacter")}
            </span>
          )}
          {variantOptionValidationState.isVariantOptionHasTheSameName && (
            <span>
              {t("AddNewProduct.Alert.VariantOptionNameAlreadyExist")}
            </span>
          )}
          {variantOptionValidationState.isVariantOptionCombinationMoreThan50 && (
            <span>{t("AddNewProduct.Alert.VariantOptionExceedtheLimit")}</span>
          )}
        </div>
      </div>

      {/* Error Mesage */}
    </div>
  );
};

export default VariantCard;
