/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState } from "react";
import "./ModalStyles.css";
import { useTranslation } from "react-i18next";
import { sendRequestShippingMark } from "src/api/server/order";
import ConfirmationModal from "./ConfirmationModal";
import ShipmentHandovermodal from "./ShipmentHandoverModal";

type ChooseAddHandoverProps = {
  onClose: () => void;
};

const ChooseAddHandover: React.FC<ChooseAddHandoverProps> = ({ onClose }) => {
  const [activeView, setActiveView] = useState<string | null>(null);
  const [orderSN, setOrderSN] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isAddHandoverModalOpen, setAddHandoverModalOpen] =
    useState<boolean>(false);

  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleButtonClick = (view: string) => {
    setActiveView(view);
    if (view !== "packingStation") {
      setError(null);
    } else {
      setAddHandoverModalOpen(true);
    }
  };

  const handleSearch = async () => {
    try {
      const result = await sendRequestShippingMark([], 1, orderSN, "", "");
      console.log("Response from server for sales order:", result);

      if (result) {
        setError(null);
        setShowConfirmation(true);
      } else {
        setError(t("OrderList.NoData"));
      }
    } catch (error: any) {
      console.error("Error saving packing:", error.message);
      if (error.message.includes("packing station not found")) {
        setError(t("Shipping.Error.Packing"));
      } else if (error.message.includes("Order with ordersn")) {
        setError(
          t("Shipping.Error.OrderSn") +
            " : " +
            orderSN +
            t("Shipping.Error.NotFound")
        );
      } else if (error.message.includes("Either orderSN")) {
        setError(t("Shipping.Error.OrderPacking"));
      } else if (error.message.includes("qtyCount not")) {
        setError(t("Shipping.Error.Qty"));
      } else {
        setError(t("Shipping.Error.Nothing"));
      }
    }
  };

  const handleConfirm = () => {
    onClose();
  };
  const handleCloseModal = () => {
    setAddHandoverModalOpen(false);
  };
  return (
    <>
      <div className="modal-overlay" data-testid="addd-shipping-mark-modal">
        <div className="modal-content">
          <button className="modal-close-btn" onClick={onClose}>
            X
          </button>
          <h2>{t("Shipping.Handover.Add")}</h2>

          {!activeView ? (
            <div className="button-options">
              <button
                className="option-button"
                onClick={() => handleButtonClick("salesOrder")}
              >
                <span role="img" aria-label="paperclip">
                  📎
                </span>
                {t("Shipping.Mark.So")}
              </button>
              <button
                className="option-button"
                onClick={() => handleButtonClick("packingStation")}
              >
                <span role="img" aria-label="box">
                  📦
                </span>
                {t("Shipping.Handover.Add")}
              </button>
            </div>
          ) : (
            <div className="active-view">
              {activeView === "salesOrder" && (
                <div>
                  <h3>{t("Shipping.Mark.So")}</h3>
                  <div className="button-row">
                    <input
                      type="text"
                      value={orderSN}
                      onChange={(e) => setOrderSN(e.target.value)}
                      placeholder={t("Shipping.Mark.So")}
                    />
                  </div>

                  {error && <p className="error-message">{error}</p>}
                  <div className="button-row">
                    <button className="btn btn-light-primary" onClick={onClose}>
                      {t("Common.Button.Cancel")}
                    </button>
                    <button className="btn btn-primary" onClick={handleSearch}>
                      {t("Common.Button.Save")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {showConfirmation && (
        <ConfirmationModal
          message={t("Shipping.Mark.Success")}
          onConfirm={handleConfirm}
        />
      )}
      {isAddHandoverModalOpen && (
        <ShipmentHandovermodal
          id="AddHandoverModal"
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default ChooseAddHandover;
