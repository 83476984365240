import axios from "axios";
import clsx from "clsx";
import { format } from "date-fns";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import * as XLSX from "xlsx";
import { createRef } from "../../../../../db/connection";
import * as servCustomer from "../../../../../db/serviceCustomer";
import { KTCardBody, KTSVG } from "../../../../../resources/helpers";
import { RootState } from "../../../../../setup/redux/store";
import { usePrevious } from "../../../../../util/customHook";
import { validateURL } from "../../../../../util/validation";
import Language from "../../../../models/Language";
import AlertFailedProcess from "../../../../modules/alert/AlertFailedProcess";
import * as ReduxCampaignTarget from "../../../../modules/campaign/target/redux/CampaignTargetSlice";
import * as ReduxTarget from "../../../../modules/campaign/target/redux/CampaignTargetSlice";
import { TargetModal } from "./CampaignTargetModal";

type TargetData = {
  phoneNumber: string;
  customerId: string;
};

interface TargetProps {
  onChanges: (params: { key: string; value: any }[]) => void;
}

const CampaignTarget: FC<TargetProps> = (props) => {
  const { onChanges } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Campaign
  let campaignID = useSelector(
    (state: RootState) => state.Campaign.selectedCampaignID
  );
  let selectedCampaign = useSelector(
    (state: RootState) => state.Campaign.selectedCampaign
  );
  const selectedCampaignTemplateId = useSelector(
    (state: RootState) => state.Campaign.selectedCampaignTemplateID
  );
  const templateID = useSelector(
    (state: RootState) => state.Campaign.templateID
  );
  const templateLanguage: Language = useSelector(
    (state: RootState) => state.Campaign.selectedTemplateLanguage
  );
  const campaignButtons = useSelector(
    (state: RootState) => state.Campaign.templateButtons
  );

  const clientID = useSelector((state: RootState) => state.Campaign.clientID);
  // Template Param
  const bodyParamsKey: any[] = useSelector(
    (state: RootState) => state.Campaign.templateBodyParams
  );
  const headerParamsKey: any[] = useSelector(
    (state: RootState) => state.Campaign.templateHeaderParams
  );
  let listTarget = useSelector(
    (state: RootState) => state.CampaignTarget.targetList
  ); //list target
  console.log(
    `data getTargetByCompanyID:`,
    "List Redux On Camp Target",
    listTarget?.length
  );
  // Target
  const [hasTarget, setHasTarget] = useState<boolean>(false);
  const [previewHeader, setPreviewHeader] = useState<any[]>([]);
  const [previewData, setPreviewData] = useState<Array<any>>([]);
  const rdColumns = useSelector(
    (state: RootState) => state.CampaignTarget.columns
  );
  const currentCampaignTitle = useSelector(
    (state: RootState) => state.Campaign.newCampaignName
  );
  let targetCount = useSelector(
    (state: RootState) => state.CampaignTarget.selectedTargetCount
  );
  let selectedTarget = useSelector(
    (state: RootState) => state.CampaignTarget.selectedTarget
  );
  // Target CSV File
  const [csvFile, setCsvFile] = useState<File>();
  const [errMessage, setErrMessage] = useState<string[]>([]);
  const [targetFileURL, setTargetFileURL] = useState<string>("");
  const [isEmptyField, setIsEmptyField] = useState<boolean>(false);
  const [targetData, setTargetData] = useState<TargetData[]>([]);
  const selectedPreviewDataCount: number = useSelector(
    (state: RootState) => state.CampaignTarget.previewDataCount
  );
  const prevTargetFileURL = usePrevious(targetFileURL);
  // Others
  const [isLoadData, setIsLoadData] = useState<boolean>(false);
  const prevLoadData = usePrevious(isLoadData);
  let isCreateTemplateFinish = useSelector(
    (state: RootState) => state.Campaign.campaignTemplateFinish
  );
  let validateError: string[] = [];
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const [showTargetDetailHeaderInfo, setShowTargetDetailHeaderInfo] =
    useState(false);
  const [utmURLDynamic, setUtmURLDynamic] = useState<string>("");

  const [targetModalOpen, setTargetModalOpen] = useState(false);
  const onTargetModalClose = () => setTargetModalOpen(false);

  useEffect(() => {
    console.log(
      "Campaign target - Masuk use effect Body Params dan Header Params"
    );
    console.log(`bodyParamsKey useEffect Load Campaign Data (Edit) in Target Tab
      Campaign ID : ${campaignID}
      Campaign    : ${JSON.stringify(selectedCampaign)}
      Campaign Target File URL : ${selectedCampaign.targetFileURL}
    `);
    setHeader();
    console.log(`setHeader done >> ${rdColumns}`);
  }, [bodyParamsKey, headerParamsKey, templateLanguage]);

  useEffect(() => {
    console.log("header got set beacause selected template header is exist");
    if (templateLanguage && templateLanguage.header) setHeader();
  }, [templateLanguage]);

  useEffect(() => {
    console.log(`Check Import CSV - Use Effect Preview Data
    >> Ini Adalah datanya:  ${JSON.stringify(previewData)}
    `);
    //set redux previewData
    dispatch(ReduxTarget.setPreviewData(previewData));

    // Save Preview Data to Redux
    var targetFileHeader = previewHeader.toString() + "\r\n";
    var targetFileData = targetFileHeader;

    for (var i = 0; i < previewData.length; i++) {
      var line = "";
      for (var index in previewData[i]) {
        if (line !== "") line += ",";
        line += previewData[i][index];

        if (previewData[i][index] === "") {
          // console.log("Ada empty field");

          validateError.push("Campaign.Title.EmptyParam");
        }
      }
      if (i !== previewData.length - 1) {
        targetFileData += line + "\r\n";
      } else {
        targetFileData += line;
      }
      // targetFileData += line + (i !== (previewData.length -1) ? "\r\n" : "");
    }
    dispatch(ReduxTarget.setTargetData(targetFileData));

    const emptyField = validateError.find(
      (err) => err === "Campaign.Title.EmptyParam"
    );
    if (emptyField) {
      setIsEmptyField(true);
    }

    console.log(`Check Import CSV - Use Effect Preview Data
    >> Ini Adalah datanya:  ${JSON.stringify(previewData)}
    >> targetData >> ${JSON.stringify(targetFileData)}
    `);
  }, [previewData]);

  // Use Effect Campaign Title
  useEffect(() => {
    console.debug("Campaign target - Masuk use effect Current Campaign title");
    //fix: update preview data on updated campaign name
    if (
      previewData.length > 0 &&
      bodyParamsKey.some((param) => param.paramKey === "campaigns.campaignName")
    ) {
      const updatedPreviewData = previewData.map((element) => {
        let newElement = { ...element };
        newElement["campaigns.campaignName"] = currentCampaignTitle;
        return newElement;
      });
      console.debug(
        `Campaign target - Masuk use effect Current Campaign title updatedPreviewData ${JSON.stringify(
          updatedPreviewData
        )}`
      );
      setPreviewData(updatedPreviewData);
    }
    if (!isCreateTemplateFinish) {
      processingData(true);
    }
  }, [currentCampaignTitle]);

  useEffect(() => {
    console.log(`use effect previewHeader ${JSON.stringify(previewHeader)}`);
    const getTargetFileFromDB = async (url: string) => {
      console.log(
        `URL download : ${url} previewHeader ${JSON.stringify(previewHeader)}`
      );

      console.log(`prev ${prevLoadData} current ${isLoadData}`);
      //const fileData =
      await axios({
        method: "GET",
        url: url,
      })
        .then(async (response) => {
          console.log(`get target from db 1 ${JSON.stringify(previewHeader)}`);

          const docData: string[] = response.data.split("\r\n");

          const tempHeader = docData[0].split(",");

          console.log(`validatecsv response axios download 2 check
        Raw Data >> ${JSON.stringify(response.data)}
        Split Data >> ${JSON.stringify(docData)}
        Header Data >> ${JSON.stringify(tempHeader)}
        Target Count >> ${docData.length - 1}
        `);
          console.log(`get target from db 2 ${JSON.stringify(previewHeader)}`);

          // Target Data (Phone Number)
          let targetFromCSV = [];
          for (let i = 1; i < docData.length; i++) {
            const tempData = docData[i].split(",");
            const tempPhoneNumber = tempData[0];

            const customers =
              await servCustomer.getCustomerDataByPhoneNoAndClient(
                tempPhoneNumber,
                createRef("clients", clientID)
              );
            let customerData = customers.find((customer) => {
              return customer.data.phoneNumber === tempPhoneNumber;
            });
            if (customerData) {
              targetFromCSV.push({
                ...customerData.data,
                id: customerData.id,
              });
            }
          }
          // Select Target
          dispatch(ReduxTarget.setSelectedTarget(targetFromCSV));
          // dispatch(ReduxTarget.setSelectedTargetCount((docData.length-1)));
          console.log(`Validate Data CSV from DB 
        docData >> ${JSON.stringify(docData)}
        Header Data >> ${JSON.stringify(tempHeader)}
        previewHeader >> ${JSON.stringify(previewHeader)}
        bodyParamsKey >> ${JSON.stringify(bodyParamsKey)}
        headerParamsKey >> ${JSON.stringify(headerParamsKey)}
        Target Count >> ${docData.length - 1}
        Target Data Count >> ${JSON.stringify(targetFromCSV.length)}
        Target Data >> ${JSON.stringify(targetFromCSV)}
        `);
          validateCSV(docData);

          return response;
        })
        .catch((error) => {
          console.log(`error get target file >> ${JSON.stringify(error)}`);
        });
    };

    if (!selectedCampaign) {
      console.log(
        `bodyParamsKey useEffect reset body param if !selectedCampaign`
      );
      setPreviewData([]);
      processingData(true);
      return;
    }

    if (selectedCampaignTemplateId === templateID) {
      // console.log(`Check Template Changes when Edit for target URL 2 >> ${JSON.stringify(newCampaignTemplate)}`);
      if (
        selectedCampaign.targetFileURL &&
        selectedCampaign.targetFileURL !== ""
      ) {
        if (selectedCampaign.template)
          setTargetFileURL(selectedCampaign.targetFileURL.toString());
        getTargetFileFromDB(selectedCampaign.targetFileURL as string)
          .catch(console.log)
          .finally(() => console.log("get target from db done"));
        onChanges([
          { key: "targetFileURL", value: selectedCampaign.targetFileURL },
        ]);
      }
    } else {
      console.log(`Check Template Changes when Edit for target URL 3`);
      onChanges([{ key: "targetFileURL", value: "" }]);
      setPreviewData([]);
      processingData(true);
    }
  }, [previewHeader]);

  useEffect(() => {
    console.log(`prev targetFileURL ${prevTargetFileURL}`);
  }, [targetFileURL]);

  const setHeader = () => {
    console.log("setHeader Campaign target - Masuk set Header");
    console.log(`setHeader start
      >> template >> ${JSON.stringify(templateLanguage)}
      >> bodyParamsKey >> ${JSON.stringify(bodyParamsKey)}
      >> headerParamsKey >> ${JSON.stringify(headerParamsKey)}
    `);

    let tempBodyParams: any[] = ["Phone Number"];
    let tempColumns: any[] = ["customers.phoneNumber"];

    // Add Column per Header Parameter
    console.log(`setHeader Campaign Template has header check 1
    --> templateLanguage
      ${JSON.stringify(templateLanguage)}
    `);

    if (
      templateLanguage &&
      templateLanguage.header &&
      templateLanguage.header !== undefined
    ) {
      console.log(`setHeader Campaign Template has header check 2`);
      console.log("header param key is: " + headerParamsKey);
      if (headerParamsKey.length > 0) {
        tempColumns.push("messageHeader");
        tempBodyParams.push("Header");
      }
    }

    // Add Column per Body Parameter
    let tempParamsKey = [...bodyParamsKey];
    tempParamsKey = tempParamsKey.sort((a, b) =>
      a.paramId! > b.paramId ? 1 : -1
    );

    tempParamsKey.forEach((bodyParam) => {
      tempBodyParams.push(
        bodyParam.paramKey.includes("customText")
          ? bodyParam.paramName
            ? bodyParam.paramName
            : // ? `${t("Campaign.Input.CustomizeText")}`
              `${t("Campaign.Input.CustomizeText")} ${bodyParam.paramId}`
          : bodyParam.paramValue
      );
      tempColumns.push(bodyParam.paramKey);
    });

    //check button cta with dynamic url
    if (
      (campaignButtons && campaignButtons.length > 0) ||
      (templateLanguage?.buttons && templateLanguage?.buttons?.length > 0)
    ) {
      const getFromData =
        campaignButtons.length > 0 ? campaignButtons : templateLanguage.buttons;
      const findButtonCTADynamic = getFromData!.find(
        (btn) => btn.type === "URL" && btn.urlType === "DYNAMIC"
      );
      if (findButtonCTADynamic && findButtonCTADynamic.urlDynamic) {
        const utmURL = findButtonCTADynamic.urlDynamic.replace("{{1}}", "");
        setUtmURLDynamic(utmURL);
        tempBodyParams.push("Parameter URL");
        tempColumns.push("urlDynamicValue");
      }
    }

    console.log(`setHeader Ini column preview 
    >> ${JSON.stringify(tempColumns)} 
    -- tempBodyParams : ${JSON.stringify(tempBodyParams)}
    -- headerParamsKey : ${JSON.stringify(headerParamsKey)}`);

    setPreviewHeader(tempBodyParams);
    dispatch(ReduxTarget.setColumns(tempColumns));
    // setColumns(tempColumns);
  };

  // Use Effect Target
  useEffect(() => {
    // Selected Target Count
    console.log(`useEffect target count ${targetCount}`);
    if (targetCount > 0) {
      setHasTarget(true);
      document
        .getElementById("downloadTargetFormat")
        ?.classList.remove("disabled");
      document
        .getElementById("uploadTargetFormat")
        ?.classList.remove("disabled");
      processingData(true);
      document.getElementById(`nextStep`)?.classList.remove("disabled");
    } else {
      console.log(`use effect target <= 0`);
      resetPreviewData();
    }
    removeCsvFile();
  }, [targetCount]);

  // Reset Target
  const resetPreviewData = async () => {
    //set button next step disabled
    console.log("masuk sini kah 1 nox");
    // document.getElementById(`nextStep`)?.classList.add("disabled");
    setHasTarget(false);
    setPreviewData([]);
    validateError = [];
    setIsEmptyField(false);
  };

  // Use Effect CSV Target
  useEffect(() => {
    if (csvFile) {
      const size: number = csvFile.size;

      //Check File Type
      const tempArrFileType: string = csvFile.type;
      const arryFileType = tempArrFileType.split("/");
      // console.log("File Type : " + tempArrFileType);

      // Check File Extension
      if (
        arryFileType === undefined ||
        arryFileType.length < 1 ||
        arryFileType[0] === undefined
      ) {
        // console.log(`${t("HC.Error.InvalidFiletype")}`);
        // return error
        setErrMessage((prevNotifications) => [
          "HC.Error.InvalidFiletype",
          ...prevNotifications,
        ]);
        // setInputFileName(t("HC.Error.InvalidFiletype"));
        // setProgress(100);
        return;
      }

      const reader = new FileReader();
      reader.readAsText(csvFile, "utf-8");
      // reader.readAsBinaryString(csvFile);
      reader.onload = (evt) => {
        if (evt !== null && evt.target !== null) {
          const bstr = evt.target.result;
          // console.log("bstr: " + JSON.stringify(bstr));
          const wb = XLSX.read(bstr, { type: "binary" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_csv(ws, {});
          // data
          // const dataSplit: string[] = data.split("\n");
          //split by new line but not new line in double quote
          const dataSplit: string[] = data.split(/(?!")\r?\n(?!"")/);
          console.log(`Check Import CSV - Get File
          >> Ini Adalah datanya:  ${JSON.stringify(data)}
          >> Ini Adalah data per rownya:  ${JSON.stringify(dataSplit)}
          `);
          validateCSV(dataSplit);
        }
      };
    }
  }, [csvFile]);

  // Use Effect Sub Step 3
  useEffect(() => {
    console.log("Campaign target - Masuk use effect has target");
    if (!hasTarget) {
      if (
        !document.getElementById("nextStep")?.classList.contains("disabled") &&
        selectedPreviewDataCount <= 0
      ) {
        console.log("masuk sini kah 2 nox");
        // document.getElementById("nextStep")?.classList.add("disabled");
      } else {
        if (selectedPreviewDataCount > 0) {
          // console.log("Remove Disable Has Target adn Target > 0");
          document.getElementById("nextStep")?.classList.remove("disabled");
        }
      }
    } else {
      // console.log("Remove Disable Has Target");
      document.getElementById("nextStep")?.classList.remove("disabled");
    }
  }, [hasTarget]);

  useEffect(() => {
    console.log("Campaign target - Masuk use effect isEmptyField");
    // console.log("Has Empty Field "+isEmptyField);

    if (!isEmptyField) {
      validateError = [];
      if (hasTarget) {
        document.getElementById(`nextStep`)?.classList.remove("disabled");
      }
    } else {
      document.getElementById(`nextStep`)?.classList.add("disabled");
    }
  }, [isEmptyField]);

  //Processing target data to show preview
  const processingData = (start: boolean) => {
    let dataColumns = rdColumns;
    console.log(`Columns in Processing Data >> ${dataColumns}`);

    const newTargetData = [...targetData];
    selectedTarget.forEach((customer) => {
      let obj: any = {};
      console.log("Preview Data : -> " + previewData);

      let checkData = previewData.find(
        (target) => target["customers.phoneNumber"] === customer.phoneNumber
      );

      if (!checkData) {
        newTargetData.push({
          phoneNumber: customer.phoneNumber!,
          customerId: customer.id!,
        });
        console.log("Data Column : " + dataColumns);
        console.log("Header params Key >>>" + JSON.stringify(headerParamsKey));

        dataColumns.forEach(async (column) => {
          let field = column.split(".");

          // Header Column
          console.log(`Check value column in rocessing data 1
          >> column >> ${JSON.stringify(column)}
          >> header Params Key >> ${JSON.stringify(headerParamsKey)}`);
          if (column.includes("messageHeader") && headerParamsKey.length > 0) {
            console.log(`Check value column in rocessing data 2
            >> column >> ${JSON.stringify(column)}
            >> header Params Key >> ${JSON.stringify(headerParamsKey)}`);

            // Text Header
            let headerParam = headerParamsKey[0];
            if (templateLanguage?.header?.type.toLowerCase() === "text") {
              console.log(`Check value column in rocessing data 3
              >> column >> ${JSON.stringify(column)}
              >> header Params Key >> ${JSON.stringify(headerParamsKey)}
              >> headerParam[0] >> ${JSON.stringify(headerParam)}`);
              // Header Text without Parameter
              if (!templateLanguage?.header?.paramCount) {
                console.log(`Processing Data if header not using parameter`);
                obj[column] = headerParam.text;
                console.log(`Check value column in rocessing data 4
                >> column >> ${JSON.stringify(column)}
                >> header Params Key >> ${JSON.stringify(headerParamsKey)}
                >> headerParam[0] >> ${JSON.stringify(headerParam)}
                >> object 1 ${JSON.stringify(obj)}
                >> object 2 ${JSON.stringify(obj[column])}`);
              } else if (templateLanguage?.header?.paramCount) {
                let headerText = headerParam.textContent as string;
                let headerKey = headerParam.paramKey;
                let headerValue = headerParam.paramValue;
                let headerType = headerParam.paramType;
                console.log(`Check value column in rocessing data 5
                >> column >> ${JSON.stringify(column)}
                >> header Params Key >> ${JSON.stringify(headerParamsKey)}
                >> headerParam[0] >> ${JSON.stringify(headerParam)}
                >> object 1 ${JSON.stringify(obj)}
                >> object 2 ${JSON.stringify(obj[column])}`);
                // Custom Param for Header
                if (headerType === "customText") {
                  //replace enter to be space
                  let textContent =
                    headerParam.textContent && headerParam.textContent
                      ? headerParam.textContent
                      : "";
                  let textContentReplaceEnter = textContent.replace(
                    /(\r\n|\n|\r)/gm,
                    " "
                  );
                  obj[column] = textContentReplaceEnter
                    ? textContentReplaceEnter
                    : "";
                  console.log(`Check value column in rocessing data 6
                      >> column >> ${JSON.stringify(column)}
                      >> header Params Key >> ${JSON.stringify(headerParamsKey)}
                      >> headerParam[0] >> ${JSON.stringify(headerParam)}
                      >> object 1 ${JSON.stringify(obj)}
                      >> object 2 ${JSON.stringify(obj[column])}`);
                } else {
                  let headerField = headerParam.paramKey.split(`.`);
                  console.log(`Check value column in rocessing data 7
                  >> column >> ${JSON.stringify(column)}
                  >> header Params Key >> ${JSON.stringify(headerParamsKey)}
                  >> headerParam[0] >> ${JSON.stringify(headerParam)}
                  >> object 1 ${JSON.stringify(obj)}
                  >> object 2 ${JSON.stringify(obj[column])}
                  >> headerField ${JSON.stringify(headerField)}`);
                  // Replace Text Content
                  if (headerField[0] === "customers") {
                    console.log(`Check value column in rocessing data 8
                    >> column >> ${JSON.stringify(column)}
                    >> header Params Key >> ${JSON.stringify(headerParamsKey)}
                    >> headerParam[0] >> ${JSON.stringify(headerParam)}
                    >> object 1 ${JSON.stringify(obj)}
                    >> object 2 ${JSON.stringify(obj[column])}
                    >> headerField ${JSON.stringify(headerField)}`);
                    if (headerField[1] === "phoneNumber") {
                      if (customer.phoneNumber) {
                        // headerText = headerText.replace(`{{${headerValue}}}`,customer.phoneNumber)
                        obj[column] = customer.phoneNumber;
                      }
                    } else if (headerField[1] === "name") {
                      let custName =
                        customer.firstName +
                        " " +
                        (customer.lastName ? customer.lastName : "");
                      // headerText = headerText.replace(`{{${headerValue}}}`,custName)
                      obj[column] = custName;
                    } else {
                      // let kolom: string = field[1];
                      let value = Object.entries(customer).find(
                        ([key, value]) => {
                          return key === headerField[1];
                        }
                      )?.[1];
                      // if (value) {
                      // headerText = headerText.replace(`{{${headerValue}}}`,value.toString());
                      obj[column] = value ? value : "";
                      // }
                    }
                    console.log(`Check value column in rocessing data 9
                    >> column >> ${JSON.stringify(column)}
                    >> header Params Key >> ${JSON.stringify(headerParamsKey)}
                    >> headerParam[0] >> ${JSON.stringify(headerParam)}
                    >> object 1 ${JSON.stringify(obj)}
                    >> object 2 ${JSON.stringify(obj[column])}`);
                  }
                  // Campaign Data
                  else if (headerField[0] === "campaigns") {
                    console.log(`Check value column in rocessing data 10
                    >> column >> ${JSON.stringify(column)}
                    >> header Params Key >> ${JSON.stringify(headerParamsKey)}
                    >> headerParam[0] >> ${JSON.stringify(headerParam)}
                    >> object 1 ${JSON.stringify(obj)}
                    >> object 2 ${JSON.stringify(obj[column])}`);
                    if (headerField[1] === "campaignName") {
                      if (currentCampaignTitle)
                        // headerText = headerText.replace(`{{${headerValue}}}`,campaignName);
                        obj[column] = currentCampaignTitle;
                      console.log(`Check value column in rocessing data 11
                      >> column >> ${JSON.stringify(column)}
                      >> header Params Key >> ${JSON.stringify(headerParamsKey)}
                      >> headerParam[0] >> ${JSON.stringify(headerParam)}
                      >> object 1 ${JSON.stringify(obj)}
                      >> object 2 ${JSON.stringify(obj[column])}`);
                    }
                  } else if (headerField[0] === "urlDynamicValue") {
                    if (campaignButtons && campaignButtons.length > 0) {
                      const findButtonCTADynamic = campaignButtons.find(
                        (btn) => btn.type === "URL" && btn.urlType === "DYNAMIC"
                      );
                      if (
                        findButtonCTADynamic &&
                        findButtonCTADynamic.urlDynamic
                      ) {
                        const utmURL = findButtonCTADynamic.urlDynamic.replace(
                          "{{1}}",
                          ""
                        );
                        setUtmURLDynamic(utmURL);
                        const urlDynamic =
                          utmURL + findButtonCTADynamic?.dynamicValue;
                        obj[column] = urlDynamic;
                      }
                    }
                  } else {
                    //Selain customer & campaign
                  }
                }
              }
            } else {
              let headerUrl = headerParam.headerURL;
              obj[column] = headerUrl;
            }
          }

          // if (!column.includes("customText")) {
          //   console.log("masuk ke data.paramValue tidak bisa di get");
          //   return;
          // }

          if (column.includes("customText")) {
            let data = bodyParamsKey.find(
              (bodyParam) => bodyParam.paramKey === column //&& bodyParam.paramId ===
            );

            if (data) {
              if (!data.paramValue) {
                console.log("masuk ke data.paramValue tidak bisa di get");
                return;
              }

              //add double quote inside \n
              let textContent = data.textContent ? data.textContent : "";
              let textContentWithDoubleQuote = textContent.replace(
                /(\r\n|\n|\r)/gm,
                `\\n`
              );
              console.log(
                `check double quoter >> ${textContentWithDoubleQuote}`
              );
              obj[column] = data.paramValue
                ? textContentWithDoubleQuote
                  ? textContentWithDoubleQuote
                  : ""
                : "";
            }
          }

          // Customer Data
          else if (field[0] === "customers") {
            if (field[1] === "phoneNumber") {
              obj[column] = customer.phoneNumber;
            } else if (field[1] === "name") {
              let custName =
                customer.firstName +
                " " +
                (customer.lastName ? customer.lastName : "");
              obj[column] = custName;
            } else {
              // let kolom: string = field[1];
              let value = Object.entries(customer).find(([key, value]) => {
                return key === field[1];
              })?.[1];
              obj[column] = value ? value : "";
            }
          } // Campaign Data
          else if (field[0] === "campaigns") {
            if (field[1] === "campaignName") {
              obj[column] = currentCampaignTitle;
            }
          } else if (field[0] === "urlDynamicValue") {
            if (campaignButtons && campaignButtons.length > 0) {
              const findButtonCTADynamic = campaignButtons.find(
                (btn) => btn.type === "URL" && btn.urlType === "DYNAMIC"
              );
              if (findButtonCTADynamic && findButtonCTADynamic.urlDynamic) {
                const utmURL = findButtonCTADynamic.urlDynamic.replace(
                  "{{1}}",
                  ""
                );
                setUtmURLDynamic(utmURL);
                const urlDynamic = utmURL + findButtonCTADynamic?.dynamicValue;
                obj[column] = urlDynamic;
              }
            }
          } else {
            //Selain customer & campaign
          }
        });
        console.log("Object setPreviewData after : " + JSON.stringify(obj));
        // previews.push(obj);
        setPreviewData((prevData) => [...prevData, obj]);
      }
    });
    console.log(
      "Object newTargetData after : " + JSON.stringify(newTargetData)
    );
    setTargetData(newTargetData);
    // setPreviewData(previews);
  };

  const mapDataTargetCol = (customer: any) => {
    let obj: any = {};
    rdColumns.forEach(async (column) => {
      let field = column.split(".");

      // Header Column
      console.log(`Check value column in rocessing data 1
      >> column >> ${JSON.stringify(column)}
      >> header Params Key >> ${JSON.stringify(headerParamsKey)}`);
      if (column.includes("messageHeader") && headerParamsKey.length > 0) {
        console.log(`Check value column in rocessing data 2
        >> column >> ${JSON.stringify(column)}
        >> header Params Key >> ${JSON.stringify(headerParamsKey)}`);

        // Text Header
        let headerParam = headerParamsKey[0];
        if (templateLanguage?.header?.type.toLowerCase() === "text") {
          console.log(`Check value column in rocessing data 3
          >> column >> ${JSON.stringify(column)}
          >> header Params Key >> ${JSON.stringify(headerParamsKey)}
          >> headerParam[0] >> ${JSON.stringify(headerParam)}`);
          // Header Text without Parameter
          if (!templateLanguage?.header?.paramCount) {
            console.log(`Processing Data if header not using parameter`);
            obj[column] = headerParam.text;
            console.log(`Check value column in rocessing data 4
            >> column >> ${JSON.stringify(column)}
            >> header Params Key >> ${JSON.stringify(headerParamsKey)}
            >> headerParam[0] >> ${JSON.stringify(headerParam)}
            >> object 1 ${JSON.stringify(obj)}
            >> object 2 ${JSON.stringify(obj[column])}`);
          } else if (templateLanguage?.header?.paramCount) {
            let headerText = headerParam.textContent as string;
            let headerKey = headerParam.paramKey;
            let headerValue = headerParam.paramValue;
            let headerType = headerParam.paramType;
            console.log(`Check value column in rocessing data 5
            >> column >> ${JSON.stringify(column)}
            >> header Params Key >> ${JSON.stringify(headerParamsKey)}
            >> headerParam[0] >> ${JSON.stringify(headerParam)}
            >> object 1 ${JSON.stringify(obj)}
            >> object 2 ${JSON.stringify(obj[column])}`);
            // Custom Param for Header
            if (headerType === "customText") {
              //replace enter to be space
              let textContent =
                headerParam.textContent && headerParam.textContent
                  ? headerParam.textContent
                  : "";
              let textContentReplaceEnter = textContent.replace(
                /(\r\n|\n|\r)/gm,
                " "
              );
              obj[column] = textContentReplaceEnter
                ? textContentReplaceEnter
                : "";
              console.log(`Check value column in rocessing data 6
                  >> column >> ${JSON.stringify(column)}
                  >> header Params Key >> ${JSON.stringify(headerParamsKey)}
                  >> headerParam[0] >> ${JSON.stringify(headerParam)}
                  >> object 1 ${JSON.stringify(obj)}
                  >> object 2 ${JSON.stringify(obj[column])}`);
            } else {
              let headerField = headerParam.paramKey.split(`.`);
              console.log(`Check value column in rocessing data 7
              >> column >> ${JSON.stringify(column)}
              >> header Params Key >> ${JSON.stringify(headerParamsKey)}
              >> headerParam[0] >> ${JSON.stringify(headerParam)}
              >> object 1 ${JSON.stringify(obj)}
              >> object 2 ${JSON.stringify(obj[column])}
              >> headerField ${JSON.stringify(headerField)}`);
              // Replace Text Content
              if (headerField[0] === "customers") {
                console.log(`Check value column in rocessing data 8
                >> column >> ${JSON.stringify(column)}
                >> header Params Key >> ${JSON.stringify(headerParamsKey)}
                >> headerParam[0] >> ${JSON.stringify(headerParam)}
                >> object 1 ${JSON.stringify(obj)}
                >> object 2 ${JSON.stringify(obj[column])}
                >> headerField ${JSON.stringify(headerField)}`);
                if (headerField[1] === "phoneNumber") {
                  if (customer.phoneNumber) {
                    // headerText = headerText.replace(`{{${headerValue}}}`,customer.phoneNumber)
                    obj[column] = customer.phoneNumber;
                  }
                } else if (headerField[1] === "name") {
                  let custName =
                    customer.firstName +
                    " " +
                    (customer.lastName ? customer.lastName : "");
                  // headerText = headerText.replace(`{{${headerValue}}}`,custName)
                  obj[column] = custName;
                } else {
                  // let kolom: string = field[1];
                  let value = Object.entries(customer).find(([key, value]) => {
                    return key === headerField[1];
                  })?.[1];
                  // if (value) {
                  // headerText = headerText.replace(`{{${headerValue}}}`,value.toString());
                  obj[column] = value ? value : "";
                  // }
                }
                console.log(`Check value column in rocessing data 9
                >> column >> ${JSON.stringify(column)}
                >> header Params Key >> ${JSON.stringify(headerParamsKey)}
                >> headerParam[0] >> ${JSON.stringify(headerParam)}
                >> object 1 ${JSON.stringify(obj)}
                >> object 2 ${JSON.stringify(obj[column])}`);
              }
              // Campaign Data
              else if (headerField[0] === "campaigns") {
                console.log(`Check value column in rocessing data 10
                >> column >> ${JSON.stringify(column)}
                >> header Params Key >> ${JSON.stringify(headerParamsKey)}
                >> headerParam[0] >> ${JSON.stringify(headerParam)}
                >> object 1 ${JSON.stringify(obj)}
                >> object 2 ${JSON.stringify(obj[column])}`);
                if (headerField[1] === "campaignName") {
                  if (currentCampaignTitle)
                    // headerText = headerText.replace(`{{${headerValue}}}`,campaignName);
                    obj[column] = currentCampaignTitle;
                  console.log(`Check value column in rocessing data 11
                  >> column >> ${JSON.stringify(column)}
                  >> header Params Key >> ${JSON.stringify(headerParamsKey)}
                  >> headerParam[0] >> ${JSON.stringify(headerParam)}
                  >> object 1 ${JSON.stringify(obj)}
                  >> object 2 ${JSON.stringify(obj[column])}`);
                }
              } else {
                //Selain customer & campaign
              }
            }
          }
        } else {
          let headerUrl = headerParam.headerURL;
          obj[column] = headerUrl;
        }
      }

      // if (!column.includes("customText")) {
      //   console.log("masuk ke data.paramValue tidak bisa di get");
      //   return;
      // }

      if (column.includes("customText")) {
        let data = bodyParamsKey.find(
          (bodyParam) => bodyParam.paramKey === column //&& bodyParam.paramId ===
        );

        if (data) {
          if (!data.paramValue) {
            console.log("masuk ke data.paramValue tidak bisa di get");
            return;
          }

          //add double quote inside \n
          let textContent = data.textContent ? data.textContent : "";
          let textContentWithDoubleQuote = textContent.replace(
            /(\r\n|\n|\r)/gm,
            `\\n`
          );
          console.log(`check double quoter >> ${textContentWithDoubleQuote}`);
          obj[column] = data.paramValue
            ? textContentWithDoubleQuote
              ? textContentWithDoubleQuote
              : ""
            : "";
        }
      }

      // Customer Data
      else if (field[0] === "customers") {
        if (field[1] === "phoneNumber") {
          obj[column] = customer.phoneNumber;
        } else if (field[1] === "name") {
          let custName =
            customer.firstName +
            " " +
            (customer.lastName ? customer.lastName : "");
          obj[column] = custName;
        } else {
          // let kolom: string = field[1];
          let value = Object.entries(customer).find(([key, value]) => {
            return key === field[1];
          })?.[1];
          obj[column] = value ? value : "";
        }
      } // Campaign Data
      else if (field[0] === "campaigns") {
        if (field[1] === "campaignName") {
          obj[column] = currentCampaignTitle;
        }
      } else if (field[0] === "urlDynamicValue") {
        const findButton =
          campaignButtons && campaignButtons.length > 0
            ? campaignButtons
            : templateLanguage?.buttons;
        if (findButton && findButton.length > 0) {
          const findButtonCTADynamic = findButton.find(
            (btn) => btn.type === "URL" && btn.urlType === "DYNAMIC"
          );
          if (findButtonCTADynamic) {
            const urlDynamic =
              utmURLDynamic + findButtonCTADynamic?.dynamicValue;
            obj[column] = urlDynamic;
          }
        }
      } else {
        //Selain customer & campaign
      }
    });
    return obj;
  };

  const addDummyToSelectedTargetRedux = async (data: any) => {
    if (data) {
      dispatch(ReduxCampaignTarget.updateSelectedTarget(data));
      // check on selected target
      // let checkTarget = listSelectedTarget.some((target) =>
      //   target.id === findData.id
      // );
    }
  };

  const onDownloadCSVFormat = () => {
    // console.log("Download csv campaign target >>>");

    var columnHeadar = previewHeader.toString() + "\r\n";
    var dataCSV = columnHeadar;

    let dataTargetPreview = previewData;
    console.log(`dataTargetPreview : `, dataTargetPreview);
    if (dataTargetPreview.length === 0) {
      const dummyDataCustomer = {
        isActive: true,
        lastInteractionChannel: "whatsapp",
        phoneNumber: `Ex: 6282101767003 (${t("IC.Info.CSV.DeleteRow")})`,
        firstName: "Ex: Budi(sample name)",
        id: "1n2ggsKPej6oR4dRMeQP",
      };
      // addDummyToSelectedTargetRedux(dummyDataCustomer)
      const dummyData = mapDataTargetCol(dummyDataCustomer);
      dataTargetPreview = [dummyData];
    }
    console.log(dataTargetPreview.length);
    for (var i = 0; i < dataTargetPreview.length; i++) {
      var line = "";
      for (var index in dataTargetPreview[i]) {
        if (line !== "") line += ",";
        line += dataTargetPreview[i][index];
      }
      console.log(`Data CSV
      >> row ${i} >> data >> ${line}
      >> length ${dataTargetPreview.length}
      `);
      if (i !== dataTargetPreview.length - 1) {
        dataCSV += line + "\r\n";
      } else {
        dataCSV += line;
      }
    }
    console.log(`Data CSV
    >> csv ${dataCSV}
    `);

    // console.log("Download file csv >>>> " + dataCSV);
    const url = window.URL.createObjectURL(new Blob([dataCSV]));
    const link = document.createElement("a");
    link.href = url;
    let reportName =
      "Format-CampaignTarget-" +
      currentCampaignTitle +
      format(new Date(), "d-MMM-yyyy[HHmmss]") +
      ".csv";
    // console.log("Report name : " + reportName);
    link.setAttribute("download", reportName);
    document.body.appendChild(link);
    link.click();
    document.getElementById("uploadTargetCSV")?.classList.remove("disabled");
    //commented causing bug when delete all target list data
    // document.body.removeChild(link);
  };

  const onImportCSVFormat = async (event: any) => {
    console.log("Onchange file");
    event.preventDefault();

    if (event.target.files && event.target.files[0]) {
      // console.log("File Import Selected");
      setCsvFile(event.target.files[0]);
    }
    event.target.value = null;
  };

  const removeCsvFile = () => {
    // console.log("Remove CSV File");
    setCsvFile(undefined);
    setErrMessage([]);
  };

  const validateCSV = (data: string[]) => {
    // console.log("Masuk Validate Data");
    const arrError: string[] = [];
    let resultData: any[] = [];
    try {
      // 0 Rows
      if (data.length <= 0) {
        console.log("validateCSV Error: Data not found");

        setErrMessage((prevNotifications) => [
          t("IC.Error.InvalidFormat"),
          ...prevNotifications,
        ]);
        return;
      }

      // Check Total Row = Target
      if (data.length !== previewData.length + 1) {
        // console.log("Additional row found . . .");
        // return;
      }

      const dataHeader: string[] = data[0].split(",");
      console.log(`Check Import CSV - Validate CSV
      >> Ini adalah datanya:  ${JSON.stringify(data)}
      >> Ini adalah data header:  ${JSON.stringify(data[0])}
      >> Ini adalah data header split by comma:  ${JSON.stringify(dataHeader)}
      `);

      console.log(`Check Import CSV - Validate CSV
      >> previewHeader (mandatory Column) ${JSON.stringify(previewHeader)}
      `);
      const mandatoryColumn = previewHeader;

      //check mandatory column
      for (let index = 0; index < mandatoryColumn.length; index++) {
        const manColumn = dataHeader.find(
          (header) => mandatoryColumn[index] === header
        );
        if (manColumn === undefined) {
          arrError.push(mandatoryColumn[index]);
        }
      }

      if (arrError.length > 0) {
        let errColumn = "";
        for (let index = 0; index < arrError.length; index++) {
          const element = arrError[index];
          if (index > 0) {
            errColumn += "," + element;
          } else {
            errColumn += element;
          }
        }
        console.log(`validateCSV Error column:  ${errColumn}`);
        return;
      }

      //Check & save Index of Header
      const column = previewHeader;
      // ["First Name", "Last Name", "Phone Number", "Email"];
      let columnIndex: any[] = [];
      for (let j = column.length; j >= 0; j--) {
        const columnName = column[j];
        for (let index = 0; index < dataHeader.length; index++) {
          const headerColumn = dataHeader[index];
          console.log(`Check Import CSV - Validate CSV
          column >> ~${column}~
          headerColumn >> ~${headerColumn}~
          columnName >> ~${columnName}~
          index >> ${index}
          `);
          if (headerColumn === columnName) {
            columnIndex.push({ index: index, columnName: columnName });
          }
        }
      }
      console.log(`Check Import CSV - Validate CSV
      Column Index before sorting >> ${JSON.stringify(columnIndex)}
      `);

      // Sorting Column Index
      columnIndex = columnIndex.sort((a, b) => {
        if (!a.index || a.index === undefined) return 1;
        if (!b.index || b.index === undefined) return -1;
        return a.index > b.index ? 1 : -1;
      });

      console.log(`Check Import CSV - Validate CSV
      Column Index after sorting >> ${JSON.stringify(columnIndex)}
      `);

      //Read Data & Create Data Customer Model
      let arrPhoneNumber: string[] = [];
      // Only Header / 1 Row
      if (data.length <= 1) {
        console.log("Error : " + t("IC.Error.InvalidFileContent"));

        setErrMessage((prevNotifications) => [
          t("IC.Error.InvalidFileContent"),
          ...prevNotifications,
        ]);
        arrError.push(t("IC.Error.InvalidFileContent"));
        // return;
      } else {
        //Data Row iteration
        for (let i = 1; i < data.length; i++) {
          const element: string[] = data[i].split(","); //element => data each row split by comma (array of string, index => column)
          let obj: any = {};
          //Data column iteration
          for (let j = 0; j < element.length; j++) {
            let isnum = /^\d+$/.test(element[0]);
            console.log(`Check Import CSV - Validate CSV
            Data row ~${i}~ >> ${JSON.stringify(element)}
            Data row ~${i}~ column ${j} >> ${
              element[j]
            } is number only >> ${isnum}
            `);
            if (!isnum) {
              const errorCheck = arrError.find(
                (error) => error === t("IC.Error.InvalidFileContent")
              );
              if (!errorCheck) {
                arrError.push(t("IC.Error.InvalidFileContent"));
              }
            }
            // Column Iteration from Header
            for (let k = 0; k < columnIndex.length; k++) {
              const col = columnIndex[k];
              console.log(`Check Import CSV - Validate CSV
              JSON >> ${JSON.stringify(col)}
              Column ${JSON.stringify(col.columnName)}
              `);
              if (col.index === j) {
                if (element[j] === "") {
                  const findAlert = arrError.find(
                    (err) => err === t("Campaign.ImportCSV.Alert.EmptyRow")
                  );
                  if (!findAlert) {
                    setErrMessage((prevNotifications) => [
                      t("Campaign.ImportCSV.Alert.EmptyRow"),
                      ...prevNotifications,
                    ]);
                    arrError.push(t("Campaign.ImportCSV.Alert.EmptyRow"));
                  }
                }
                console.log(`Check Import CSV - Validate CSV Data Iteration 1`);
                if (col.columnName === "Phone Number") {
                  console.log(
                    `Check Import CSV - Validate CSV Data Iteration 2`
                  );
                  let phoneNumber = element[j].replace(/ /g, "");
                  if (phoneNumber !== "" && phoneNumber!.startsWith("0")) {
                    console.log(
                      `Check Import CSV - Validate CSV Data Iteration 3`
                    );
                    phoneNumber = "62" + phoneNumber!.substring(1);
                  }
                  // cek ke preview data
                  if (arrPhoneNumber.includes(phoneNumber)) {
                    console.log(
                      `Check Import CSV - Validate CSV Data Iteration 4`
                    );
                    // setErrMessage("IC.Error.DuplicatePhoneNumber");
                    const errorCheck = arrError.find(
                      (error) => error === t("IC.Error.DuplicatePhoneNumber")
                    );
                    if (!errorCheck) {
                      console.log(
                        `Check Import CSV - Validate CSV Data Iteration 5`
                      );
                      arrError.push(t("IC.Error.DuplicatePhoneNumber"));
                    }
                  } else {
                    console.log(
                      `Check Import CSV - Validate CSV Data Iteration 6`
                    );
                    arrPhoneNumber.push(phoneNumber);
                    obj["customers.phoneNumber"] = phoneNumber;
                  }
                  // }
                } else if (col.columnName === "Header") {
                  console.log(
                    `Check Import CSV - Validate CSV Data Iteration 7`
                  );
                  // if (field[1] === "campaignName") {
                  //   if (currentCampaignTitle) // headerText = headerText.replace(`{{${headerValue}}}`,campaignName);
                  //   obj[column] = currentCampaignTitle;
                  // }
                  obj["messageHeader"] = element[j];
                } else if (col.columnName === "Parameter URL") {
                  const urlDynamic = element[j]?.trim();
                  //check if urlDynamic include utmURLDynamic
                  //push error if not found or not match utm
                  const findUtmURL = urlDynamic.startsWith(utmURLDynamic, 0);
                  const checkURL = validateURL(urlDynamic);
                  console.log(`findUtmURL `, findUtmURL);
                  let dynamicValue = urlDynamic;
                  if (urlDynamic !== "") {
                    try {
                      let newValue = urlDynamic;
                      if (
                        !urlDynamic.startsWith("http://") &&
                        !urlDynamic.startsWith("https://")
                      ) {
                        newValue = "https://" + urlDynamic; // Prepend "https://" if not present
                      }
                      const parsedUrl = new URL(newValue);
                      const origin =
                        !urlDynamic.startsWith("http://") &&
                        !urlDynamic.startsWith("https://")
                          ? parsedUrl.hostname
                          : parsedUrl.origin;
                      console.log(`origin : `, origin);
                      dynamicValue = dynamicValue.replace(origin, "");
                      dynamicValue = dynamicValue.substring(1);
                    } catch (error) {
                      console.log(`error parsedURL, error `, error);
                    }
                  }
                  if (
                    !findUtmURL ||
                    !checkURL ||
                    dynamicValue === "" ||
                    dynamicValue === utmURLDynamic
                  ) {
                    const findAlert = arrError.find(
                      (err) =>
                        err === t("Campaign.Target.CSVUpload.InvalidDynamicURL")
                    );
                    if (!findAlert) {
                      setErrMessage((prevNotifications) => [
                        t("Campaign.Target.CSVUpload.InvalidDynamicURL"),
                        ...prevNotifications,
                      ]);
                      arrError.push(
                        t("Campaign.Target.CSVUpload.InvalidDynamicURL")
                      );
                    }
                  }
                  if (urlDynamic.length > 2000) {
                    const findAlert = arrError.find(
                      (err) =>
                        err ===
                        t(
                          "Campaign.Content.DynamicURL.Alert.MoreThan2000Character"
                        )
                    );
                    if (!findAlert) {
                      setErrMessage((prevNotifications) => [
                        t(
                          "Campaign.Content.DynamicURL.Alert.MoreThan2000Character"
                        ),
                        ...prevNotifications,
                      ]);
                      arrError.push(
                        t(
                          "Campaign.Content.DynamicURL.Alert.MoreThan2000Character"
                        )
                      );
                    }
                  }
                  obj["urlDynamicValue"] = urlDynamic;
                } else {
                  console.log(
                    `Check Import CSV - Validate CSV Data Iteration 8`
                  );
                  // check if not Phone Number column
                  let fieldName = bodyParamsKey.find((param) => {
                    if (param.paramName && param.paramName === col.columnName) {
                      return true;
                    }
                    if (
                      param.paramValue &&
                      param.paramValue === col.columnName
                    ) {
                      return true;
                    }
                    return false;
                  });
                  console.log(
                    `Check Import CSV - Validate CSV
                    element >> ${element[j]}
                    fieldName >> ${JSON.stringify(fieldName)}
                    bodyParamsKey >> ${JSON.stringify(bodyParamsKey)}
                  `
                  );

                  // if(!fieldName){
                  //   fieldName = bodyParamsKey.find(
                  //     (param) => `${param.paramValue} ${param.paramId}` === col.columnName
                  //     // (param) => param.paramValue === col.columnName
                  //   );
                  // }
                  console.log(
                    `Check Import CSV - Validate CSV
                    element >> ${element[j]}
                    fieldName >>
                      paramValue ${fieldName.paramValue}
                      paramKey ${fieldName.paramKey}
                      paramId ${fieldName.paramId}
                      textContent ${fieldName.textContent}
                  `
                  );
                  obj[fieldName.paramKey] = element[j];
                }
              }
            }
          }

          // console.log("Arr Error: " + arrError);

          //Save Data to List Data Customer
          if (arrError.length <= 0) {
            console.log(`Check Import CSV - Validate CSV Data Iteration 9`);
            console.log(`Check Import CSV - Validate CSV
            >> Push new object >> ${JSON.stringify(obj)}`);
            resultData.push(obj);
            document.getElementById("nextStep")?.classList.remove("disabled");
            // if() { }
          } else {
            console.log(`Check Import CSV - Validate CSV Data Iteration 10`);
            let errormsg = "";
            for (let index = 0; index < arrError.length; index++) {
              const element = t(arrError[index]);
              if (index === arrError.length) {
                errormsg += element;
              } else {
                errormsg += element + "";
              }
            }
            setErrMessage(arrError);
            console.log("Masuk Error: " + errormsg);
            return;
          }
        }
        console.log(`Check Import CSV - Validate CSV Data Iteration 11`);
        setIsEmptyField(false);
        console.log(`Check Import CSV - Validate CSV
        Ini adalah set DATA : ${JSON.stringify(resultData)}
        `);
        setPreviewData(resultData);
      }
    } catch (error) {
      console.log("Error Import CSV catch", error);
    } finally {
      if (arrError.length > 0) {
        let errormsg = "";
        for (let index = 0; index < arrError.length; index++) {
          const element = t(arrError[index]);
          if (index === arrError.length) {
            errormsg += element;
          } else {
            errormsg += element + "";
          }
        }
        setErrMessage(arrError);
        console.log("Masuk Error: " + errormsg);

        // const errorImportCSVAlertNotif = document.getElementById(
        //   "failedImportProcessAlert"
        // ) as HTMLSpanElement;
        // errorImportCSVAlertNotif.innerHTML = errormsg;

        const buttonFailedAlert = document.getElementById(
          "triggerFailedImport"
        );
        if (buttonFailedAlert) {
          buttonFailedAlert.click();
        }
        //set disabled
        console.log("masuk sini kah 4 nox");
        document.getElementById(`nextStep`)?.classList.add("disabled");
      } else {
        setErrMessage([]);
      }
    }
  };

  console.log("Campaign Target Rerender");

  return (
    <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
      {/* Error Import CSV */}
      {/* invisible button*/}
      <button
        id="triggerFailedImport"
        data-bs-toggle="modal"
        data-bs-target="#modal_failed_process"
        hidden
      ></button>
      <AlertFailedProcess title="Failed Import" errorMessage={errMessage} />
      {errMessage.length > 0 ? (
        <div
          className={clsx(
            "alert alert-danger d-flex align-items-center p-5 mb-10"
          )}
        >
          <KTSVG
            path="/media/icons/duotune/general/gen050.svg"
            className="svg-icon-danger svg-icon-2hx pe-5"
          />
          <div className="d-flex flex-column">
            {errMessage.map((error, index) => (
              <div key={index}>{error}</div>
            ))}
          </div>
        </div>
      ) : null}
      <div className={clsx("d-flex", isTabletOrMobile ? "flex-column" : "")}>
        {/* Target */}
        {/* <div className="col-3"> */}
        <div className={clsx(`${isTabletOrMobile ? "col-12" : ""}`)}>
          <div className="mb-5">
            <div className="step-sub-title mb-3">
              {isTabletOrMobile && (
                <h1 className="mx-2">{t("Campaign.Tab.Target")}</h1>
              )}
              {!isTabletOrMobile && t("Campaign.Tab.Target")}
              {hasTarget && !isTabletOrMobile && (
                <span className="bi bi-check-circle-fill text-success ps-3"></span>
              )}
            </div>
            <div className="d-flex flex-row">
              <button
                disabled={
                  selectedCampaign.status !== undefined &&
                  selectedCampaign.status !== "CREATED"
                }
                className={clsx("btn btn-primary")}
                // data-bs-toggle="modal"
                // data-bs-target={`#select_campaign_target`}
                // data-testid="select-target-button"
                // role="select-target-button"
                onClick={() => {
                  // dispatch(ReduxCampaign.setIsLoading(true))
                  // dispatch(ReduxCampaign.setIsLoading(false))
                  setTargetModalOpen(true);
                }}
              >
                <i className="bi bi-person-plus-fill me-1 fs-3"></i>
                {t("Campaign.Button.Addtarget")}
              </button>
              {hasTarget && (
                <span className="text-gray-600 m-3">
                  {t("Campaign.Info.TargetAdded").replace(
                    "<<CountContact>>",
                    previewData.length.toString()
                  )}
                  <label className="btn btn-sm btn-icon-danger btn-text-danger p-0">
                    <span
                      className="ms-2 bi bi-x-lg"
                      onClick={() => {
                        resetPreviewData();
                        dispatch(
                          ReduxTarget.cleanReduxSelectedCampaignTarget(true)
                        );
                      }}
                      data-testid="delete-target-snap"
                    ></span>
                  </label>
                </span>
              )}
            </div>
          </div>
          {/* Download and Upload CSV */}
          <div className="mb-5">
            <div className="step-sub-title">
              {t("Campaign.Title.Uploadcsv")}
              {/* {hasData && <span className="bi bi-check-circle-fill text-success ps-3"></span>} */}
            </div>
            <div>{t("Campaign.Info.Uploadcsv")}</div>

            <div className="d-flex flex-column" style={{ maxWidth: "90%" }}>
              {/* Download CSV */}
              <button
                disabled={
                  selectedCampaign.status !== undefined &&
                  selectedCampaign.status !== "CREATED"
                }
                id="downloadTargetFormat"
                className={clsx(
                  "btn btn-primary",
                  isTabletOrMobile ? "mt-3 w-50" : "m-5"
                  // hasTarget ? "" : "disabled"
                )}
                onClick={onDownloadCSVFormat}
                data-testid="download-target-csv"
                style={{ fontSize: "smaller" }}
              >
                <i className="bi bi-download"></i>
                <span className="ms-2">{t("Campaign.Button.Downloadcsv")}</span>
              </button>
              <label
                // disabled={selectedCampaign.status !== undefined && selectedCampaign.status !== "CREATED"}
                className={clsx(
                  "btn btn-light-primary",
                  isTabletOrMobile ? "mt-3 w-50" : "m-5",
                  hasTarget ? "" : "disabled",
                  selectedCampaign.status !== undefined &&
                    selectedCampaign.status !== "CREATED"
                    ? "disabled"
                    : ""
                )}
                style={{ fontSize: "smaller" }}
                data-testid="upload-target-csv"
                id="uploadTargetCSV"
                // onClick={onImportCSVFormat}
              >
                <span className="bi bi-upload">
                  <span className="ms-2">{t("IC.Title.ImportCSV")}</span>
                </span>
                <input
                  id="target-csv-file"
                  type="file"
                  name="target-csv-file"
                  multiple={false}
                  onChange={onImportCSVFormat}
                  style={{ display: "none" }}
                  accept={".csv"}
                />
              </label>
              {/* Upload CSV */}
              {csvFile && (
                <div className="ms-5 align-items-center">
                  <label className="text-gray-600 mt-4">
                    {csvFile.name.length > 25
                      ? csvFile.name.substring(0, 25) + "..."
                      : csvFile.name}
                  </label>
                  <label className="btn btn-sm btn-icon-danger btn-text-danger p-0">
                    <span
                      className="ms-3 bi bi-x-lg"
                      onClick={() => {
                        removeCsvFile();
                        resetPreviewData();
                        setErrMessage([]);
                        dispatch(
                          ReduxTarget.cleanReduxSelectedCampaignTarget(true)
                        );
                      }}
                    ></span>
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>

        {targetModalOpen && (
          <TargetModal onClose={onTargetModalClose} isOpen={targetModalOpen} />
        )}

        {/* Preview Data */}
        <div
          className={clsx(
            "w-100 overflow-hidden",
            `col-${isTabletOrMobile ? "12" : ""}`
          )}
        >
          <div className="mb-5">
            {/* Previe Title */}
            <div className="step-sub-title">
              {t("Campaign.Title.Preview")}
              {/* {hasSender && <span className="bi bi-check-circle-fill text-success ps-3"></span>} */}
            </div>
            {/* Alert Empty Field */}
            {hasTarget && isEmptyField && (
              <div className="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row pe-5 ps-5 mb-3 mt-3">
                <KTSVG
                  path="/media/icons/duotune/general/gen044.svg"
                  className="svg-icon svg-icon-2hx svg-icon-danger me-4 mb-5 mb-sm-0"
                />
                <div className="d-flex flex-column text-danger pe-0 pe-sm-10">
                  <h5 className="mb-1">{t("Campaign.Title.EmptyParam")}</h5>
                  <span>{t("Campaign.Error.EmptyParam")}</span>
                </div>
                <button
                  type="button"
                  className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
                  data-bs-dismiss="alert"
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-1 svg-icon-danger"
                  />
                </button>
              </div>
            )}
            {/* Preview Table */}
            {!isTabletOrMobile && (
              <KTCardBody className=" h-350px overflow-hidden p-0 m-0">
                <div
                  className="table-responsive"
                  style={{
                    height: "100%",
                    overflow: "scroll",
                  }}
                >
                  <table
                    id="kt_table_users"
                    className="table align-middle table-row-dashed fs-6 gy-5 dataTable bg-light"
                    style={{
                      borderCollapse: "separate",
                      borderSpacing: 0,
                      border: "1px",
                      borderStyle: "solid",
                      borderColor: "black",
                    }}
                    data-testid="table-target"
                  >
                    <thead
                      className="position-sticky top-0 bg-secondary"
                      style={{
                        zIndex: 100,
                        backgroundColor: "#D9D9D9",
                        border: "1px",
                        borderStyle: "solid",
                        borderColor: "black",
                      }}
                    >
                      <tr
                        className="position-sticky top-0"
                        style={{
                          zIndex: 100,
                          backgroundColor: "#D9D9D9",
                          border: "1px",
                          borderStyle: "solid",
                          borderColor: "black",
                        }}
                      >
                        {previewHeader?.map((header, index) => (
                          <th
                            key={header + index}
                            id={index + ""}
                            className={clsx(
                              "p-2",
                              header.toString() === "Header" ? "mw-100px" : ""
                            )}
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody style={{ padding: "10px" }}>
                      {
                        //targets.length > 0 &&/
                        previewData?.map((data, index) => (
                          <tr
                            key={"preview" + index}
                            style={{
                              border: "1px",
                              borderStyle: "solid",
                            }}
                          >
                            {/* td based on bodyParams count */}
                            {rdColumns.map((col: any, indexColumn) => {
                              let value = data[col];
                              // if(col === "urlDynamicValue"){
                              //   value = utmURLDynamic + value;
                              // }
                              if (value && value.length > 70) {
                                value = `${value.substring(0, 67)}...`;
                              }
                              return (
                                <td
                                  key={`${data[col]}${indexColumn}`}
                                  style={{ padding: "10px" }}
                                >
                                  {value}
                                </td>
                              );
                            })}
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </KTCardBody>
            )}
            {isTabletOrMobile && (
              <div className=" p-0 m-0">
                <div
                // style={{
                //   height: "100%",
                //   overflow: "scroll",
                //   maxHeight: "100px"
                // }}
                >
                  {/* {previewData.length > 0 && previewData?.map((data, index) => {

                })} */}
                  {
                    previewData.length <= 0 && (
                      <div id="targetSelectedListMobile">
                        <div
                          className="mt-3 p-3 bg-primary bg-opacity-15 rounded"
                          onClick={() => {
                            setShowTargetDetailHeaderInfo(
                              !showTargetDetailHeaderInfo
                            );
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-end">
                            <h1 className="fs-5 fw-bolder text-gray-700 m-0">
                              {previewHeader[0]}
                            </h1>
                            <h1 className="fs-5 fw-bolder m-0">
                              {/* {"6282144481502"}&#32;&#32;&#32; */}
                              <i
                                className={clsx(
                                  `fs-3 bi bi-chevron-${
                                    showTargetDetailHeaderInfo ? "up" : "down"
                                  } text-bold`
                                )}
                              ></i>
                            </h1>
                          </div>

                          {showTargetDetailHeaderInfo && (
                            <div id="target-detail-null">
                              <hr style={{ borderTop: "1px solid blue" }}></hr>
                              <div className="d-flex flex-column align-items-start mt-5">
                                {previewHeader?.map((header, index) => {
                                  return (
                                    <p className="mb-2 text-gray-700 m-0">
                                      {index === 0 ? "" : header}
                                    </p>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )
                    // {previewDetail}
                  }
                  {previewData.length > 0 && (
                    <div>
                      {previewData?.map((data, index) => (
                        <div id="targetSelectedListMobile">
                          <div
                            className="mt-3 p-3 bg-primary bg-opacity-15 rounded"
                            onClick={() => {
                              const targetDropdown = document.getElementById(
                                `target-detail-${index}`
                              );
                              const targetDropdownHasClass =
                                targetDropdown?.hasAttribute("class");
                              targetDropdownHasClass
                                ? targetDropdown?.classList.toggle("d-none")
                                : targetDropdown?.classList.add("d-none");
                            }}
                          >
                            {/* td based on bodyParams count */}

                            <>
                              <div className="d-flex justify-content-between align-items-end">
                                <h1 className="fs-5 fw-bolder text-gray-700 m-0">
                                  {previewHeader[0]}
                                </h1>
                                <h1 className="fs-5 fw-bolder m-0">
                                  {data["customers.phoneNumber"]}&#32;&#32;&#32;
                                  <i
                                    className={clsx(
                                      `fs-3 bi bi-chevron-down text-bold`
                                    )}
                                  ></i>
                                </h1>
                              </div>

                              <div
                                id={`target-detail-${index}`}
                                className="d-none"
                              >
                                <hr
                                  style={{ borderTop: "1px solid blue" }}
                                ></hr>
                                <div className="d-flex flex-row align-items-start mt-5 w-100">
                                  <div className="w-50">
                                    {previewHeader?.map((header, index) => {
                                      return (
                                        <p className="mb-2 text-gray-700 m-0">
                                          {index === 0 ? "" : header}
                                        </p>
                                      );
                                    })}
                                  </div>
                                  <div className="w-50">
                                    {rdColumns.map((col: any, indexColumn) => {
                                      let value = data[col];
                                      // if(col === "urlDynamicValue"){
                                      //   value = utmURLDynamic + value;
                                      // }
                                      if (value && value.length > 25) {
                                        value = `${value.substring(0, 22)}...`;
                                      }
                                      return (
                                        <p className="mb-2 m-0">
                                          {indexColumn === 0 ? "" : value}
                                        </p>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CampaignTarget);
