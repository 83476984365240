import axios from "axios";
import * as bcrypt from "bcryptjs";
import db, { Timestamp } from "../../db";
import { sendMessage } from "./connection";
import Message from "../../app/models/Message";
import { getClientId } from "../../app/modules/localstorage";
export const sendRequestMessage = async (message: Message, company: string, from: string, to: string, text: string, callback: any) => {
  console.log(`SMFC sendRequestMessage : ${JSON.stringify(message)}`);
  const replyMessageID = message.replyMessageID?.id?.toString();
  const jsonMessageText = `
      "text" : {
        "preview_url" : ${message.previewurl ?? false},
        "text" : "${text}"
      }`;

  const jsonMessageDocument = `
      "document" : {
        "link" : "${message.mediaUrl}",
        "caption" : "${text}",
        "filename" : "${message.filename}"
      }
  `;

  const jsonMessageImage = `
      "image" : {
        "link" : "${message.mediaUrl}",
        "caption" : "${text}"
      }
    `;

  const jsonMessageVideo = `
      "video" : {
        "link" : "${message.mediaUrl}",
        "caption" : "${text}"
      }
      `;

  const jsonMessageAudio = `
      "audio" : {
        "link" : "${message.mediaUrl}"
      }
      `;

  const jsonMessage = `
  {
    "clientId" : "${message.client?.id ?? getClientId()}",
    "type" : "${message.channel}",
    "${message.channel}" : {
      "company" : "${company ?? "*"}",
      "from" : "${from}",
      "to" : "${to}",
      "type" : "${message.messageType}",
      ${message.messageType === "text" ? jsonMessageText : ""}
      ${message.messageType === "document" ? jsonMessageDocument : ""}
      ${message.messageType === "image" ? jsonMessageImage : ""}
      ${message.messageType === "video" ? jsonMessageVideo : ""}
      ${message.messageType === "audio" ? jsonMessageAudio : ""}
      ${message.messageType === "interactive" ? `"interactive" : ${JSON.stringify(message.interactive)}` : ""}
      ${replyMessageID ? `,"replyMessageID" : "${replyMessageID}"` : ""}
    }
  }`;

  let defaultResponse = `
  {
    "responseCode" : <<responseCode>>,
    "response" : <<response>>
  }`;

  await sendMessage(jsonMessage, (rsCode: string, rsJson: JSON) => {
    // callback(responseCode, responseJson);
    console.log(`RequestValue : ${jsonMessage}`);
    console.log(`rsCode : ${rsCode} ---> rsJson : ${JSON.stringify(rsJson)}`);
    callback(rsCode, rsJson);
  });
};
export const sendRequestMarketplaceMessage = async (
  message: Message,
  company: string,
  from: string,
  collaborationId: string,
  roomId: string,
  text: string,
  callback: any
) => {
  console.log("SMFC sendRequestMarketplaceMessage");
  const replyMessageID = message.replyMessageID?.id?.toString();
  const jsonMessageText = `
      "text" : {
        "preview_url" : ${message.previewurl ?? false},
        "text" : "${text}"
      }`;

  const jsonMessageImage = `
      "image" : {
        "link" : "${message.mediaUrl}",
        "caption" : "${text}"
      }
    `;

  const jsonMessageVideo = `
    "video" : {
      "fileId" : "${message.fileId}",
      "link" : "${message.mediaUrl}",
      "caption" : "${text}"
    }
  `;

  const jsonMessage = `
  {
    "type" : "${message.channel}",
    "data" : {
      "company" : "${company}",
      "clientId" : "${message.client?.id ?? getClientId()}",
      "account_id" : "${from}",
      "collaboration_id" : "${collaborationId}",
      "room_id" : "${roomId}",
      "type" : "${message.messageType}",
      ${message.messageType === "text" ? jsonMessageText : ""}
      ${message.messageType === "image" ? jsonMessageImage : ""}
      ${message.messageType === "video" ? jsonMessageVideo : ""}
      ${replyMessageID ? `,"replyMessageID" : "${replyMessageID}"` : ""}
    }
  }`;

  let defaultResponse = `
  {
    "responseCode" : <<responseCode>>,
    "response" : <<response>>
  }`;

  console.log("SMFC sendMessage", jsonMessage);
  await sendMessage(jsonMessage, (rsCode: string, rsJson: JSON) => {
    // callback(responseCode, responseJson);
    console.log(`RequestValue bozzz : ${jsonMessage}`);
    console.log(`bozzz rsCode : ${rsCode} ---> rsJson : ${JSON.stringify(rsJson)}`);
    callback(rsCode, rsJson);
  });
};

export const sendRequestMarkAsReadMessage = async (jsonRequest: any) => {
  const url: string = process.env.REACT_APP_SERVER_URL! + "/messages/markasread";
  const token = await bcrypt.hash(process.env.REACT_APP_SERVER_TOKEN!, 10);

  try {
    const response = await axios.post(url, jsonRequest, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseJson = response.data;
    const responseCode = response.status;
    console.log(`Response Code Mark As Read Message : ${responseCode}`);
    console.log(`Response JSON Mark As Read Message : ${JSON.stringify(responseJson)}`);

  } catch (error: any) {
    console.log("_Error sendRequestMarkAsReadMessage server : ", error)
    if (error.response) {
      console.log('Error response from server : ', error.response.status, error.response.data);
    } else {
      console.log("Error Request server : ", error.message);
    }
  }
};

export const updateIsActiveMessage = async (messageId: string) => {
  db.collection("messages").doc(messageId).update({
    isActive: false,
    updatedAt: Timestamp.now(),
  });
};

export const sendRequestReloadProductMessage = async (jsonRequest: any) => {
  const url: string = process.env.REACT_APP_SERVER_URL! + "/7days-shopee/reload-product-chat";
  const token = await bcrypt.hash(process.env.REACT_APP_SERVER_TOKEN!, 10);

  try {
    const response = await axios.post(url, jsonRequest, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseJson = response.data;
    const responseCode = response.status;
    console.log(`Response Code ReloadProductMessage : ${responseCode}`);
    console.log(`Response JSON ReloadProductMessage : ${JSON.stringify(responseJson)}`);

  } catch (error: any) {
    console.log("_Error sendRequestReloadProductMessage server : ", error)
    if (error.response) {
      console.log('Error response from server : ', error.response.status, error.response.data);
    } else {
      console.log("Error Request server : ", error.message);
    }
  }
};
