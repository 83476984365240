import React from "react";
import { useTranslation } from "react-i18next";

type OrderIDModalProps = {
  orderIDs: String[];
  onClose: () => void;
};

const OrderIDModal: React.FC<OrderIDModalProps> = ({ orderIDs, onClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="modal-backdrop show" data-testid="order-id-modal"></div>
      <div className="modal show d-block">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h5 className="modal-title">{t("Order.List.OrderId")}</h5>
            </div>

            {/* Modal Body */}
            <div className="modal-body">
              {orderIDs.length === 0 ? (
                <div className="alert alert-info text-center">
                  {t("Order.List.Empty")}
                </div>
              ) : (
                <div
                  className="position-relative"
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <ul
                    className="list-group"
                    style={{
                      maxHeight: "300px",
                      overflowY: "auto",
                    }}
                  >
                    {orderIDs.map((orderID, index) => (
                      <React.Fragment key={index}>
                        <li
                          className="list-group-item d-flex justify-content-start align-items-center"
                          style={{
                            border: "none",
                            padding: "10px 15px",
                          }}
                        >
                          <span>{orderID}</span>
                        </li>
                        {index < orderIDs.length - 1 && (
                          <hr
                            style={{
                              margin: "0",
                              borderTop: "3px solid #e0e0e0",
                            }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {/* Modal Footer */}
            <div className="modal-footer">
              <button onClick={onClose} className="btn btn-primary">
                {t("Common.Button.Cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderIDModal;
