import clsx from "clsx";
import { debounce } from "lodash";
import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTable, useExpanded } from "react-table";
import * as CIQRedux from "../../../../modules/ciq/redux/CIQSlice";
import { KTCard, KTSVG, toAbsoluteUrl } from "src/resources/helpers";
import { useFilter, Filter, Sorting, ListProps } from "../../models/Models";
import { useFirestoreTable } from "../../utils/useFirestoreTable";
import SelectionCell from "../checkbox/SelectionCell";
import SelectionHeader from "../checkbox/SelectionHeader";
import FilterModal from "../modal/FilterModal";
import ListPagination from "../pagination/ListPagination";
import { createRef } from "src/db";
import { useMediaQuery } from "react-responsive";
import ColumnResize from "react-table-column-resizer";
import { Loading } from "src/app/modules/util/Loading";
import "../../style/styless.scss";
import { current } from "@reduxjs/toolkit";
import { useLocation } from "react-router-dom";

export default function ListView<T>({
  collection,
  collectionGroup,
  tabs,
  mapper,
  columns: columnsData,
  selection,
  defaultFilters: filters,
  defaultSorting: sorting,
  useFilter,
  searching,
  extra,
  action,
}: ListProps<T>) {
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });
  const dispatch = useDispatch();
  const [sortDirections, setSortDirections] = useState<{
    [key: string]: "asc" | "desc" | undefined;
  }>({});
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [activeTab, setActiveTab] = useState<number>(0);
  const [usingFilter, setUsingFilter] = useState<any>();
  const [idRowDisabled, setIdRowDisabled] = useState<Array<any>>([]);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [hideHeader, setHideHeader] = useState<boolean>(false);

  const searchTermRef = useRef<string>(searchTerm);
  const activeRef = useRef<number>(activeTab);
  const usingFilterRef = useRef<any>(usingFilter);
  const tbodyRef = useRef(null);
  const { t } = useTranslation();
  const {
    data: itemsData = { items: [], allCount: 0 },
    TableState,
    selectedItems,
    setSelectedItems,
    updateTableState,
    setReadyToShipOrder,
    setExportedOrder,
    isLoading,
    isFetching,
    refreshData,
  } = useFirestoreTable();

  // column data
  let columns = useMemo(() => {
    return tabs && tabs[activeTab]?.customColumns
      ? tabs[activeTab]?.customColumns?.map((column: any, index: number) => ({
          Header: column?.title,
          accessor: column?.field || `column-${index}`,
          Cell: column?.value,
        }))
      : columnsData?.map((column: any, index: number) => ({
          Header: column?.title,
          accessor: column?.field || `column-${index}`,
          Cell: column?.value,
        }));
  }, [columnsData, tabs, tabs && tabs[activeTab]?.customColumns]) as any;

  // table data
  let { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: (itemsData.items as any) || [],
    });
  // kondisi ketika seluruh data di list di centang (boolean)
  const isAllSelected =
    selectedItems.length > 0 &&
    itemsData.items
      .filter((item: any) => !idRowDisabled?.includes(item.id))
      .every((item: any) => {
        const itemKey = item.id;
        return item.id && selectedItems.includes(itemKey);
      });

  // function dijalanakan untuk select all atau centang semua
  const onSelectAll = () => {
    if (isAllSelected) {
      setSelectedItems([]);
    } else {
      setSelectedItems(
        itemsData.items
          .filter((item: any) => !idRowDisabled?.includes(item.id))
          .map((item: any) => {
            const itemKey = item.id;
            return itemKey;
          })
      );
    }
  };

  // function dijalanan ketika klik tab untuk berpindah tab
  const handleTabChange = (index: number) => {
    if (tabs) {
      const activeTabData = tabs[index];
      const activeFilter = activeTabData.filter || [];
      const activeSort = activeTabData.sort || [];
      const searchValue = searchTerm;
      const startSearch = {
        label: "start-search",
        field: searching.field,
        type: "greater-than",
        value: searchValue,
      };
      const endSearch = {
        label: "end-search",
        field: searching.field,
        type: "less-than",
        value: searchValue + "\uf8ff",
      };
      const sortingSearch = {
        label: "search",
        field: searching.field,
        direction: "asc",
      };
      if (activeSort.every((sort: any) => typeof sort.field === "string")) {
        let newTableState = {
          ...TableState,
          filters: [
            searchValue !== "" && searchValue.length > 0 && startSearch,
            searchValue !== "" && searchValue.length > 0 && endSearch,
            ...activeFilter.map((filter: any) => ({
              label: filter.label,
              field: filter.field,
              type: filter.type,
              value: filter.value,
            })),
            ...TableState.useFilter
              ?.filter(
                (x: any) =>
                  x?.value !== "option-title" && x?.value !== undefined
              )
              .map((filter: any) => ({
                label: filter.label,
                field: filter.field,
                type: filter.type,
                value:
                  filter.value === "true_boolean"
                    ? true
                    : filter.value === "false_boolean"
                    ? false
                    : filter.value.includes("id_")
                    ? createRef(filter.field, filter.value.replace("id_", ""))
                    : filter.value,
              })),
          ].filter((x: any) => x !== false) as Filter<T>[],
          sorting: [
            searchValue !== "" && searchValue.length > 0 && sortingSearch,
            ...activeSort.map((sort: any) => ({
              label: sort.label,
              field: sort.field,
              direction: sort.direction,
            })),
          ].filter((x: any) => x !== false) as Sorting<T>[],
          tabActive: index,
          currentPage: 1,
        };
        console.log(newTableState, "check newstate irw");
        setSelectedItems([]);
        updateTableState(newTableState as any);
      } else {
        console.error("Invalid sort field type");
      }
    }
  };

  // function dijalankan ketika header kolom di klik untuk mengubah sortingan (asc/desc/default)
  const handleColumnHeaderClick = async (field: string) => {
    const specialTypes = [
      "date-range",
      "greater",
      "less",
      "greater-than",
      "less-than",
      "exception",
    ];
    let filterz = TableState.filters;
    const checkFilters = (filters: any) => {
      for (const filter of filters) {
        if (specialTypes.includes(filter.type)) {
          return true;
        }

        if (filter.type === "or" || filter.type === "and") {
          if (checkFilters(filter.filters)) {
            return true;
          }
        }
      }
      return false;
    };

    const currentDirection = sortDirections[field];
    let newDirection: "asc" | "desc" | undefined = "asc";

    if (currentDirection === "asc") {
      newDirection = "desc";
    } else if (currentDirection === "desc") {
      newDirection = undefined;
    } else {
      newDirection = "asc";
    }
    sortDirections[field] = newDirection;
    const newSortDirections = { [field]: newDirection, ...sortDirections };
    setSortDirections(newSortDirections);

    const activeSort = Object.entries(newSortDirections)
      ?.filter(([_, direction]) => direction !== undefined)
      ?.map(([field, direction]) => ({
        label: "",
        field,
        direction: direction!,
      }));

    const newTableState = {
      ...TableState,
      sorting:
        checkFilters(filters) === true ? activeSort.reverse() : activeSort,
      // sorting: activeSort
    };
    updateTableState(newTableState as any);
  };

  // function dijalankan untuk apply atau menerapkan filter yg sudah dipilih di modal
  const handleApplyFilters = (appliedFilters: useFilter<T>[]) => {
    const updatedFilters = [...TableState.filters];

    appliedFilters?.forEach((filter: any) => {
      const existingIndex = updatedFilters.findIndex(
        (f: any) => f.field === filter.field
      );
      const newValue =
        filter.value === "true_boolean"
          ? true
          : filter.value === "false_boolean"
          ? false
          : filter.value.includes("id_")
          ? createRef(filter.field, filter.value.replace("id_", ""))
          : filter.value;

      if (existingIndex !== -1) {
        updatedFilters[existingIndex] = {
          ...updatedFilters[existingIndex],
          value: newValue,
        } as any;
      } else {
        updatedFilters.push({
          label: filter.label,
          field: filter.field,
          type: filter.type,
          value: newValue,
        } as any);
      }
    });

    const newTableState = {
      ...TableState,
      useFilter: appliedFilters,
      filters: updatedFilters?.filter(
        (x: any) => x?.value !== "option-title" && x?.value !== undefined
      ) as Filter<T>[],
      currentPage: 1,
    };

    updateTableState(newTableState as any);
    setOpenFilter(false);
  };

  // function dijalankan untuk menutup atau hide modal filter
  const handleHideFilters = (appliedFilters: useFilter<T>[]) => {
    setOpenFilter(false);
  };

  // function dijalankan untuk mereset filter yg sudah diterapkan kembali ke default
  const handleResetFilters = (appliedFilters: useFilter<T>[]) => {
    window.document.getElementById("button-refetch")?.click();
    setOpenFilter(false);
  };

  // function dijalankan ketika ada perubahan/typing di input search list
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);
    searchTermRef.current = newValue;
    debouncedSearch(newValue);
  };

  // function dijalankan ketika klik tombol enter ketika di input search list
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleSearchChange(event);
    }
  };

  // funtion dijalankan untuk memfilter berdasarkan search input value
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      if (value && value.length > 0) {
        if (value.startsWith(" ") || value.endsWith(" ")) {
          value = value.trim();
        }
        let newFilters = TableState.filters as Filter<T>[];
        let newSorting = TableState.sorting as Sorting<T>[];

        const startSearchFilterIndex = newFilters.findIndex(
          (filter: any) => filter.label === "start-search"
        );
        const endSearchFilterIndex = newFilters.findIndex(
          (filter: any) => filter.label === "end-search"
        );

        if (startSearchFilterIndex !== -1) {
          newFilters[startSearchFilterIndex] = {
            ...newFilters[startSearchFilterIndex],
            field: searching.field,
            value: value,
          } as Filter<T>;
        } else {
          newFilters.unshift({
            label: "start-search",
            field: searching.field,
            type: "greater-than",
            value: value,
          });
        }

        if (endSearchFilterIndex !== -1) {
          newFilters[endSearchFilterIndex] = {
            ...newFilters[endSearchFilterIndex],
            field: searching.field,
            value: value + "\uf8ff",
          } as Filter<T>;
        } else {
          newFilters.unshift({
            label: "end-search",
            field: searching.field,
            type: "less-than",
            value: value + "\uf8ff",
          });
        }

        let existingSearchSorting = newSorting.find(
          (sorting) => sorting.label === "search"
        ) as any;
        if (existingSearchSorting) {
          existingSearchSorting.field = searching.field;
          existingSearchSorting.direction = "asc";
        } else {
          newSorting = [
            {
              label: "search",
              field: searching.field,
              direction: "asc",
            },
            ...newSorting,
          ] as Sorting<T>[];
        }
        let newTableState = {
          ...TableState,
          filters: newFilters,
          sorting: newSorting,
          searchKey: {
            field: searching.field,
            useFirestore: searching.useFirestore,
            value: value,
            caseSensitive: searching.caseSensitive,
          },
          currentPage: 1,
        };
        if (searching.useFirestore === true) {
          console.log(newTableState, "check t irw");
          updateTableState(newTableState as any);
        }
      } else {
        let newTableState = {
          ...TableState,
          filters: TableState.filters.filter((item: any) => {
            return item.label !== "start-search" && item.label !== "end-search";
          }),
          sorting: TableState.sorting.filter((item: any) => {
            return item.label !== "search";
          }),
          searchKey: {
            ...TableState.searchKey,
            value: "",
          },
        };
        updateTableState(newTableState as any);
      }
    }, 1000),
    [
      TableState?.sorting,
      TableState?.filters,
      TableState?.searchKey,
      searching,
      updateTableState,
    ]
  );

  // variable berisi filter yg sedang aktif
  let activeFilter = TableState.useFilter.filter(
    (item) => item.value !== undefined && item.value !== "option-title"
  );

  // useeffect untuk menghentikan rekursi debounce search input
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // useeffect mengubah value search di state sesuai prop
  useEffect(() => {
    if (TableState.searchKey.value !== searchTermRef.current) {
      setSearchTerm(TableState.searchKey.value);
      searchTermRef.current = TableState.searchKey.value;
    }
  }, [TableState?.searchKey]);

  // useeffect untuk mengubah value tab yg sedang aktif di state sesuai prop
  useEffect(() => {
    if (TableState.tabActive !== activeRef.current) {
      setActiveTab(TableState.tabActive);
      activeRef.current = TableState.tabActive;
    }
  }, [TableState?.tabActive]);

  // useeffect untuk mengubah value usefilter di state sesuai prop
  useEffect(() => {
    if (TableState.useFilter !== usingFilterRef.current) {
      setUsingFilter(TableState.useFilter);
      usingFilterRef.current = TableState.useFilter;
    }
  }, [TableState?.useFilter]);

  // useeffect untuk mengubah value sorting di state sesuai prop
  useEffect(() => {
    if (Array.isArray(TableState?.sorting) && TableState?.sorting.length > 0) {
      const newSortDirections: { [key: string]: "asc" | "desc" | undefined } =
        {};
      TableState.sorting.forEach((sort) => {
        newSortDirections[sort.field as string] = sort.direction;
      });
      setSortDirections(newSortDirections);
    } else {
      setSortDirections({});
    }
  }, [TableState?.sorting]);

  // useeffect untuk menyembunyikan header ketika list sedang di scroll
  useEffect(() => {
    const handleScroll = () => {
      const tbodyElement = tbodyRef.current as any;
      const scrollTop: any = tbodyElement.scrollTop;
      if (scrollTop > 150) {
        setHideHeader(true);
      } else {
        setHideHeader(false);
      }
    };

    const tbodyElement = tbodyRef.current as any;
    if (tbodyElement) {
      tbodyElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tbodyElement) {
        tbodyElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <>
      {!isMobileView ? ( //Desktop view
        <div className="text-muted m-0 p-0 h-100 d-flex flex-column">
          {tabs && (
            <div
              className="row bg-white m-0 mb-3 p-0 rounded"
              style={{
                height: "6%",
                overflowY: "hidden",
                overflowX: "auto",
                WebkitOverflowScrolling: "touch",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                display: "flex",
              }}
            >
              {tabs.length > 1 &&
                tabs.map((tab, index) => (
                  <div
                    key={index}
                    className="h-100 cursor-pointer m-0 d-flex align-items-center justify-content-center"
                    style={{
                      height: "100%",
                      flex: 1,
                      borderBottom:
                        index === activeTab ? "2px solid #009ef7" : "none",
                      minWidth: "50px",
                    }}
                    id="boxTabs"
                    onClick={() => handleTabChange(index)}
                  >
                    <a
                      key={index}
                      className={`fw-bolder p-0 m-0 fs-7 ${
                        index === activeTab && "text-primary"
                      }`}
                      style={
                        index !== activeTab
                          ? {
                              whiteSpace: "nowrap",
                              color: "#777",
                            }
                          : {
                              whiteSpace: "nowrap",
                            }
                      }
                    >
                      {tab.label}
                    </a>
                  </div>
                ))}
            </div>
          )}

          <div
            className="p-0 m-0 d-flex flex-column rounded-top"
            style={{
              height: tabs ? "94%" : "100%",
            }}
          >
            <KTCard className="bg-white d-flex h-100 m-0 p-2">
              {/* header  */}
              <div
                className={
                  hideHeader === true
                    ? "d-none"
                    : "w-100 d-flex flex-row col-gap-2 p-3 m-0 align-items-center"
                }
                style={{
                  overflowX: "auto",
                  overflowY: "hidden",
                  whiteSpace: "nowrap",
                  height: "7%",
                }}
              >
                <div
                  className="d-flex align-items-center position-relative"
                  style={{ minWidth: "25%" }}
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen021.svg"
                    className="svg-icon-1 position-absolute ms-6"
                  />
                  <input
                    type="text"
                    data-testid="search-marketplace"
                    data-kt-user-table-filter="search"
                    className={clsx("form-control form-control-solid ps-14")}
                    placeholder={t("HeaderBar.Input.Search")}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onSubmit={handleSearchChange}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                {selectedItems.length > 0 && (
                  <button className="btn btn-secondary">
                    {selectedItems.length} Items Selected
                    {/* ini dapat id item */}
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-light-primary"
                  data-testid="refetch"
                  id="button-refetch"
                  onClick={() => refreshData()}
                >
                  <i className="bi bi-arrow-clockwise"></i>
                </button>

                {activeFilter && activeFilter.length > 0 ? (
                  <button
                    type="button"
                    id="filter_active"
                    onClick={async () => {
                      await setOpenFilter(true);
                    }}
                    className="btn btn-light-success d-flex flex-row px-3 align-items-center justify-content-center fw-bolder"
                  >
                    <i className="bi bi-funnel-fill fs-3"></i>
                    <p className="p-0 m-0">
                      {activeFilter?.length} Filter Active
                    </p>
                  </button>
                ) : (
                  useFilter.length > 0 && (
                    <button
                      type="button"
                      id="filter"
                      onClick={async () => {
                        await setOpenFilter(true);
                      }}
                      className="btn btn-light-primary d-flex flex-row px-3 align-items-center justify-content-center"
                    >
                      <i className="bi bi-funnel-fill fs-3"></i>
                      <p className="p-0 m-0"> Filter</p>
                    </button>
                  )
                )}

                {TableState?.filters?.findIndex(
                  (filter: any) => filter.label === "start-search"
                ) !== -1 && (
                  <button
                    type="button"
                    id="search_active"
                    className="btn btn-light-success d-flex flex-row px-3 align-items-center justify-content-center fw-bolder"
                  >
                    <i className="bi bi-search fs-3"></i>
                    <p className="p-0 m-0">Search : "{searchTerm}"</p>
                  </button>
                )}

                <div
                  className="d-flex flex-row-reverse col-gap-2 ms-auto"
                  id="end"
                >
                  {tabs && tabs[activeTab]?.customExtra
                    ? tabs[activeTab]?.customExtra?.map(
                        (item: any, index: number) => (
                          <div key={index}>
                            {item.value({
                              data: itemsData,
                              selectedItems,
                              TableState,
                              setReadyToShipOrder,
                            })}
                          </div>
                        )
                      )
                    : extra?.map((item: any, index: number) => (
                        <div key={index}>
                          {item.value({
                            data: itemsData,
                            selectedItems,
                            TableState,
                            setReadyToShipOrder,
                          })}
                        </div>
                      ))}
                </div>
              </div>
              {/* body  */}
              <div
                className="bg-white table-responsive ps-2 m-0 p-0"
                style={{
                  height: hideHeader === true ? "92%" : "86%",
                  overflowY: "scroll",
                }}
                ref={tbodyRef}
              >
                <table
                  id="kt_table_users"
                  className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                  {...getTableProps()}
                >
                  <thead
                    className="position-sticky top-0 bg-white"
                    style={{ zIndex: 10 }}
                  >
                    {headerGroups.map((headerGroup, index) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="fw-bolder text-uppercase gs-0 position-sticky top-0 bg-white justify-content-center py-0"
                        style={{
                          boxShadow: "0px 10px 12px -8px rgba(0,0,0,0.1)",
                          fontSize: "11px",
                          color: "#777",
                        }}
                        key={index}
                      >
                        {tabs && tabs[activeTab]?.customSelection
                          ? tabs[activeTab]?.customSelection?.rowSelection &&
                            tabs[activeTab]?.customSelection
                              ?.headerSelection && (
                              <SelectionHeader
                                key="customSelection"
                                isAllSelected={isAllSelected}
                                onSelectAll={onSelectAll}
                              />
                            )
                          : selection &&
                            selection.rowSelection &&
                            selection.headerSelection && (
                              <SelectionHeader
                                key="selection"
                                isAllSelected={isAllSelected}
                                onSelectAll={onSelectAll}
                              />
                            )}
                        {headerGroup.headers.map((column, indexs) => {
                          return (
                            <React.Fragment key={indexs}>
                              <th
                                {...column.getHeaderProps()}
                                className="cursor-pointer text-start align-content-center"
                                style={{
                                  padding: "13px 0px",
                                }}
                                onClick={() =>
                                  handleColumnHeaderClick(column.id as string)
                                }
                                key={indexs}
                              >
                                {column.render("Header")}
                                {sortDirections[column.id as string] ===
                                  "asc" && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="15"
                                    viewBox="0 0 75 75"
                                    className="p-0 m-0"
                                  >
                                    <path
                                      fill="#22c55e"
                                      d="M13.785 49.574h28.453c4.899 0 7.336-2.437 7.336-7.265V13.69c0-4.828-2.437-7.265-7.336-7.265H13.785c-4.875 0-7.36 2.414-7.36 7.265v28.62c0 4.851 2.485 7.265 7.36 7.265m17.25-28.734l9.656 10.242c.727.797.75 1.922.07 2.625c-.75.773-1.945.797-2.624.023l-9.54-10.054l-9.515 10.055c-.727.773-1.899.75-2.649-.024c-.68-.703-.68-1.828.07-2.625L26.16 20.84c1.523-1.64 3.328-1.64 4.875 0"
                                    />
                                  </svg>
                                )}
                                {sortDirections[column.id as string] ===
                                  "desc" && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="15"
                                    viewBox="0 0 75 75"
                                    className="p-0 m-0"
                                  >
                                    <path
                                      fill="#f43f5e"
                                      d="M13.785 49.574h28.453c4.899 0 7.336-2.437 7.336-7.265V13.69c0-4.828-2.437-7.265-7.336-7.265H13.785c-4.875 0-7.36 2.414-7.36 7.265v28.62c0 4.851 2.485 7.265 7.36 7.265m17.25-14.437c-1.547 1.64-3.352 1.64-4.875 0l-9.656-10.242c-.75-.797-.75-1.899-.07-2.602c.75-.773 1.921-.82 2.648-.047l9.516 10.078l9.539-10.078c.68-.773 1.875-.726 2.625.047c.68.703.656 1.805-.07 2.602Z"
                                    />
                                  </svg>
                                )}
                              </th>
                              <ColumnResize
                                key={`resize-${indexs}`}
                                id={1}
                                resizeEnd={(width) =>
                                  console.log("resize end", width)
                                }
                                resizeStart={() => console.log("resize start")}
                                className="columnResizer m-0 p-0 align-content-center"
                                minWidth={0}
                                maxWidth={null}
                                disabled={false}
                              />
                            </React.Fragment>
                          );
                        })}
                        {tabs && tabs[activeTab]?.customAction
                          ? tabs[activeTab]?.customAction && (
                              <th
                                className="text-center align-content-center"
                                style={{
                                  minWidth: "100px",
                                  width: "auto",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  paddingLeft: 0,
                                }}
                                key="customActionHeader"
                              >
                                <p className="p-0 m-0">
                                  {tabs[activeTab]?.customAction?.headerTitle}
                                </p>
                              </th>
                            )
                          : action && (
                              <th
                                className="text-center"
                                style={{
                                  minWidth: "100px",
                                  width: "auto",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  paddingLeft: 0,
                                }}
                                key="actionHeader"
                              >
                                <p className="p-0 m-0">{action.headerTitle}</p>
                              </th>
                            )}
                      </tr>
                    ))}
                  </thead>
                  <tbody
                    style={{
                      color: "#777",
                    }}
                    className="fw-bold"
                    {...getTableBodyProps()}
                  >
                    {rows?.length > 0 ? (
                      rows?.map((row: any, index) => {
                        prepareRow(row);
                        const itemKey = row?.original?.id;
                        const isSelected =
                          row?.original?.id !== undefined &&
                          selectedItems.includes(itemKey);
                        const onSelect = () => {
                          setSelectedItems((prev) => {
                            if (row?.original?.id === undefined) {
                              return prev;
                            }
                            if (prev?.includes(itemKey)) {
                              return prev?.filter((key) => key !== itemKey);
                            }
                            return [...prev, itemKey];
                          });
                        };
                        const isCheckable =
                          selection &&
                          selection.rowSelection &&
                          selection.headerSelection &&
                          selection.disableRowSelection !== undefined &&
                          selection.disableRowSelection(row.original) !== false;
                        if (
                          !isCheckable &&
                          !idRowDisabled.includes(row.original.id) &&
                          !tabs
                        ) {
                          setIdRowDisabled([...idRowDisabled, row.original.id]);
                        }
                        return (
                          <tr
                            {...row.getRowProps()}
                            className={"table-row w-100 bg-white h-auto py-0"}
                            key={itemKey}
                          >
                            {tabs &&
                            tabs[activeTab]?.customSelection !== undefined
                              ? tabs[activeTab]?.customSelection
                                  ?.rowSelection &&
                                tabs[activeTab]?.customSelection
                                  ?.headerSelection && (
                                  <SelectionCell
                                    isSelected={isSelected}
                                    isCheckable={true}
                                    onSelect={onSelect}
                                    key="customSelectionCell"
                                  />
                                )
                              : selection &&
                                selection.rowSelection &&
                                selection.headerSelection &&
                                (selection.disableRowSelection &&
                                selection.disableRowSelection(row.original) ===
                                  false ? (
                                  <SelectionCell
                                    isSelected={isSelected}
                                    isCheckable={false}
                                    onSelect={onSelect}
                                    key="selectionCell"
                                  />
                                ) : (
                                  <SelectionCell
                                    isSelected={isSelected}
                                    isCheckable={true}
                                    onSelect={onSelect}
                                    key="selectionCell"
                                  />
                                ))}
                            {/*ini untuk isi baris   //kevin coba row dan cell ini nanti di clog lihat hasil*/}

                            {row?.cells?.map((cell: any, cellIndex: any) => {
                              return (
                                <React.Fragment key={cellIndex}>
                                  <td
                                    {...cell.getCellProps()}
                                    className="text-start h-auto my-0"
                                    style={{
                                      fontSize: "12px",
                                      padding: "2px 0px",
                                    }}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                  <td className="column_resizer_body" />
                                </React.Fragment>
                              );
                            })}
                            {/*ini akhir untuk isi baris*/}
                            {tabs && tabs[activeTab]?.customAction
                              ? tabs[activeTab]?.customAction && (
                                  <td
                                    className="d-flex align-items-center justify-content-center py-1 my-0"
                                    style={{
                                      width: "auto",
                                    }}
                                    key="customAction"
                                  >
                                    <div className="p-0 m-0">
                                      {tabs[activeTab]?.customAction?.value({
                                        row,
                                        selectedItems,
                                        TableState,
                                        setReadyToShipOrder,
                                        setExportedOrder,
                                      } as any)}
                                    </div>
                                  </td>
                                )
                              : action && (
                                  <td
                                    className="d-flex align-items-center justify-content-center py-1 my-0"
                                    style={{
                                      width: "auto",
                                    }}
                                    key="action"
                                  >
                                    <div className="p-0 m-0">
                                      {action.value({
                                        row,
                                        selectedItems,
                                        TableState,
                                        setReadyToShipOrder,
                                      } as any)}
                                    </div>
                                  </td>
                                )}
                          </tr>
                        );
                      })
                    ) : !(isLoading || isFetching) ? (
                      <tr key="noData">
                        <td colSpan={20}>
                          <div
                            className="d-flex flex-1 flex-column text-center align-items-center justify-content-center w-100"
                            style={{ minHeight: "30vh" }}
                          >
                            <img
                              width={200}
                              height={200}
                              src={toAbsoluteUrl(
                                "/media/illustrations/sketchy-1/AssetPwEntry.png"
                              )}
                              alt="icon"
                            />
                            <p className="p-0 m-0 pt-5 fw-bolder">
                              " {t("OrderList.NoData")} "
                            </p>
                          </div>
                        </td>
                      </tr>
                    ) : null}
                    {isFetching && (
                      <tr key="loading">
                        <td colSpan={20}>
                          <div
                            className="d-flex flex-1 flex-column text-center align-items-center justify-content-center w-100"
                            style={{ minHeight: "30vh" }}
                          >
                            <Loading />
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* footer  */}
              <div
                style={{
                  height: hideHeader === true ? "1%" : "7%",
                  boxShadow: "0px -10px 12px -8px rgba(0,0,0,0.1)",
                  zIndex: 30,
                }}
                className="p-0 m-0 w-100 rounded-bottom bg-white position-sticky bottom-0 align-items-center justify-content-center"
              >
                <ListPagination />
              </div>
            </KTCard>
          </div>
        </div>
      ) : (
        //Mobile view
        <>
          {isFetching && (
            <div className="d-flex flex-1 h-100 w-100 align-items-center justify-content-center">
              <Loading />
            </div>
          )}
          <div className="m-0 p-0 w-100 h-100 d-flex flex-column align-items-center justify-content-start">
            {tabs && (
              <div
                className="bg-white m-0 mt-2 mb-3 p-0 rounded d-flex flex-row align-items-center justify-content-between"
                style={{
                  width: "100%",
                  maxHeight: "1.5%",
                  minHeight: "1.5%",
                  overflowY: "hidden",
                  overflowX: "auto",
                  WebkitOverflowScrolling: "touch",
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                  boxShadow: "0px 6px 20px -2px rgba(0,0,0,0.1)",
                }}
              >
                {tabs.length > 1 &&
                  tabs.map((tab: any, index: number) => (
                    <div
                      key={index}
                      className="h-100 p-4 cursor-pointer m-0 d-flex align-items-center justify-content-center"
                      style={{
                        height: "100%",
                        borderBottom:
                          index === activeTab ? "2px solid #009ef7" : "none",
                        minWidth: "100px",
                        flexShrink: 0,
                      }}
                      id="boxTabs"
                      onClick={() => handleTabChange(index)}
                    >
                      <a
                        key={index}
                        className={`fw-bolder p-0 m-0 fs-5 ${
                          index === activeTab && "text-primary"
                        }`}
                        style={
                          index !== activeTab
                            ? {
                                whiteSpace: "nowrap",
                                color: "#999999",
                              }
                            : {
                                whiteSpace: "nowrap",
                              }
                        }
                      >
                        {tab.label}
                      </a>
                    </div>
                  ))}
              </div>
            )}

            <div
              className="p-0 m-0 d-flex flex-1 flex-column rounded-top"
              style={{
                width: "100%",
              }}
            >
              <KTCard className="bg-white d-flex flex-1 w-100 h-100 m-0 p-4 shadow-sm mb-3">
                {activeFilter && activeFilter.length > 0 ? (
                  <button
                    type="button"
                    id="filter_active"
                    onClick={async () => {
                      await setOpenFilter(true);
                    }}
                    className="btn btn-light-success d-flex flex-row px-3 align-items-center justify-content-center fw-bolder mb-3"
                  >
                    <i className="bi bi-funnel-fill fs-3"></i>
                    <p className="p-0 m-0">
                      {activeFilter?.length} Filter Active
                    </p>
                  </button>
                ) : (
                  useFilter.length > 0 && (
                    <button
                      type="button"
                      id="filter"
                      onClick={async () => {
                        await setOpenFilter(true);
                      }}
                      className="btn btn-light-primary d-flex flex-row px-3 align-items-center justify-content-center mb-3"
                    >
                      <i className="bi bi-funnel-fill fs-3"></i>
                      <p className="p-0 m-0"> Filter</p>
                    </button>
                  )
                )}

                <div
                  className="d-flex align-items-center position-relative mb-3"
                  style={{
                    width: "100%",
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen021.svg"
                    className="svg-icon-1 position-absolute ms-6"
                  />
                  <input
                    type="text"
                    data-testid="search-marketplace"
                    data-kt-user-table-filter="search"
                    className={clsx("form-control form-control-solid ps-14")}
                    placeholder={"Search ..."}
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </div>

                {TableState?.filters?.findIndex(
                  (filter: any) => filter.label === "start-search"
                ) !== -1 && (
                  <button
                    type="button"
                    id="search_active"
                    className="btn btn-light-success d-flex flex-row px-3 align-items-center justify-content-center fw-bolder mb-3"
                  >
                    <i className="bi bi-search fs-3"></i>
                    <p className="p-0 m-0">Search : "{searchTerm}"</p>
                  </button>
                )}

                {selectedItems.length > 0 && (
                  <button className="btn btn-secondary mb-3">
                    {selectedItems.length} Items Selected
                  </button>
                )}

                <button
                  type="button"
                  className="btn btn-light-primary d-flex flex-row px-3 align-items-center justify-content-center mb-3"
                  data-testid="refetch-marketplace"
                  id="button-refetch"
                  onClick={() => refreshData()}
                >
                  <i className="bi bi-arrow-clockwise"></i>
                  <p className="p-0 m-0"> Refresh</p>
                </button>

                <div className="w-100 d-flex flex-column">
                  {tabs && tabs[activeTab]?.customExtra
                    ? tabs[activeTab]?.customExtra?.map(
                        (item: any, index: number) => (
                          <div key={index} className="w-100 mb-3">
                            {item.value({
                              data: itemsData,
                              selectedItems,
                              TableState,
                            })}
                          </div>
                        )
                      )
                    : extra?.map((item: any, index: number) => (
                        <div key={index} className="w-100 mb-3">
                          {item.value({
                            data: itemsData,
                            selectedItems,
                            TableState,
                          })}
                        </div>
                      ))}
                </div>

                {tabs && tabs[activeTab]?.customSelection
                  ? tabs[activeTab]?.customSelection?.rowSelection &&
                    tabs[activeTab]?.customSelection?.headerSelection &&
                    selectedItems.length > 0 && (
                      <div className="d-flex flex-row column-gap-2 align-items-center mb-3">
                        <SelectionHeader
                          isAllSelected={isAllSelected}
                          onSelectAll={onSelectAll}
                        />
                        <p className="p-0 m-0 fw-bolder">Select All</p>
                      </div>
                    )
                  : selection &&
                    selection.rowSelection &&
                    selection.headerSelection &&
                    selectedItems.length > 0 && (
                      <div className="d-flex flex-row column-gap-2 align-items-center mb-3">
                        <SelectionHeader
                          isAllSelected={isAllSelected}
                          onSelectAll={onSelectAll}
                        />
                        <p className="p-0 m-0 fw-bolder">Select All</p>
                      </div>
                    )}

                <div
                  className="bg-white table-responsive w-100 h-auto d-flex flex-1 m-0 p-0"
                  style={{
                    overflowX: "hidden",
                    overflowY: "auto",
                    WebkitOverflowScrolling: "touch",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    flexDirection: "column",
                  }}
                >
                  {rows?.length > 0 ? (
                    rows?.map((row: any, index: number) => {
                      prepareRow(row);
                      const itemKey = row?.original?.id;
                      const isSelected =
                        row?.original?.id !== undefined &&
                        selectedItems.includes(itemKey);
                      const onSelect = () => {
                        setSelectedItems((prev) => {
                          if (row?.original?.id === undefined) {
                            return prev;
                          }
                          if (prev?.includes(itemKey)) {
                            return prev?.filter((key) => key !== itemKey);
                          }
                          return [...prev, itemKey];
                        });
                      };
                      return (
                        <div
                          key={index}
                          className="card mb-3 bg-white rounded shadow-sm"
                          style={{
                            border: isSelected
                              ? "2px solid #009ef7"
                              : "0.5px solid #009ef7",
                          }}
                        >
                          <div className="card-body p-0">
                            <div className="p-3 m-2 d-flex justify-content-between align-items-start">
                              <div className="w-auto m-0 p-0">
                                {tabs && tabs[activeTab]?.customSelection
                                  ? tabs[activeTab]?.customSelection
                                      ?.rowSelection &&
                                    tabs[activeTab]?.customSelection
                                      ?.headerSelection && (
                                      <SelectionCell
                                        isSelected={isSelected}
                                        isCheckable={true}
                                        onSelect={onSelect}
                                      />
                                    )
                                  : selection &&
                                    selection.rowSelection &&
                                    selection.headerSelection && (
                                      <SelectionCell
                                        isSelected={isSelected}
                                        isCheckable={true}
                                        onSelect={onSelect}
                                      />
                                    )}
                              </div>
                              <div className="d-flex flex-column w-100">
                                {row?.cells?.map((cell: any) => (
                                  <div
                                    key={cell.getCellProps().key}
                                    className="d-flex flex-row w-100 align-items-start justify-content-between mb-2"
                                  >
                                    <div
                                      style={{
                                        width: "50%",
                                      }}
                                    >
                                      <strong>{cell.column.Header}</strong>
                                    </div>
                                    <div
                                      className="text-end"
                                      style={{
                                        width: "50%",
                                      }}
                                    >
                                      {cell.render("Cell")}
                                    </div>
                                  </div>
                                ))}
                              </div>
                              {tabs && tabs[activeTab]?.customAction ? (
                                <div className="ms-2 w-auto">
                                  {tabs[activeTab]?.customAction?.value({
                                    row,
                                    selectedItems,
                                    TableState,
                                    setReadyToShipOrder,
                                    setExportedOrder,
                                  } as any)}
                                </div>
                              ) : (
                                action && (
                                  <div className="ms-2 w-auto">
                                    {action.value({
                                      row,
                                      selectedItems,
                                      TableState,
                                      setReadyToShipOrder,
                                    } as any)}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : !(isLoading || isFetching) ? (
                    <div
                      className="d-flex flex-1 flex-column text-center align-items-center justify-content-center w-100"
                      style={{ minHeight: "50vh" }}
                    >
                      <img
                        width={200}
                        height={200}
                        src={toAbsoluteUrl(
                          "/media/illustrations/sketchy-1/AssetPwEntry.png"
                        )}
                        alt="icon"
                      />
                      <p className="p-0 m-0 pt-5 fw-bolder">
                        " {t("OrderList.NoData")} "
                      </p>
                    </div>
                  ) : null}
                </div>
                <div
                  style={{
                    boxShadow: "0px -10px 12px -8px rgba(0,0,0,0.1)",
                    zIndex: 30,
                  }}
                  className="p-0 m-0 h-auto w-100 rounded-bottom bg-white position-sticky bottom-0 align-items-center justify-content-center"
                >
                  <ListPagination />
                </div>
              </KTCard>
            </div>
          </div>
        </>
      )}
      <FilterModal
        show={openFilter}
        onHide={handleHideFilters as any}
        filters={TableState.useFilter as any}
        applyFilters={handleApplyFilters as any}
        resetFilters={handleResetFilters as any}
      />
    </>
  );
}
