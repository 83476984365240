import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as PrepackService from "src/db/servicePrepack";

// Type definition for component props
type ScanLocatorModalProps = {
  onClose: () => void;
  onSubmit?: (data: { locator: string; sku: string }) => void;
};

/**
 * ScanLocatorModal Component
 *
 * A modal component for scanning and managing locator and SKU inputs
 * with robust input handling, validation, and state management.
 */
const ScanLocatorModal: React.FC<ScanLocatorModalProps> = ({
  onClose,
  onSubmit,
}) => {
  // Translation hook
  const { t } = useTranslation();

  // Input reference hooks
  const locatorInputRef = useRef<HTMLInputElement>(null);
  const skuInputRef = useRef<HTMLInputElement>(null);

  // State management
  const [locator, setLocator] = useState<string>("");
  const [sku, setSku] = useState<string>("");
  const [isLocatorDisabled, setIsLocatorDisabled] = useState<boolean>(false);
  const [error, setError] = useState<{
    locator?: string;
    sku?: string;
  }>({});
  const [isScanning, setIsScanning] = useState<boolean>(false);
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Auto-focus locator input on component mount
  useEffect(() => {
    locatorInputRef.current?.focus();
  }, []);

  // Locator input change handler
  const handleLocatorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLocator(value);
    // Clear previous locator errors
    setError((prev) => ({ ...prev, locator: undefined }));
  };

  // Locator validation and lock handler
  const handleLocatorBlur = async () => {
    if (!locator.trim()) {
      setError((prev) => ({
        ...prev,
        locator: t("Value.Pack.Enter.Locator"),
      }));
      return;
    }

    try {
      // Todo: Add locator validation logic
      // const isValidLocator = await PrepackService.validateLocator(locator);

      // Lock locator and focus SKU input
      setIsLocatorDisabled(true);
      setTimeout(() => {
        skuInputRef.current?.focus();
      }, 0);
    } catch (error) {
      setError((prev) => ({
        ...prev,
        locator: t("Value.Pack.Enter.Locator"),
      }));
    }
  };

  // Edit locator handler
  const handleEditLocator = () => {
    setIsLocatorDisabled(false);
    setSku("");
    setError({});
    locatorInputRef.current?.focus();
  };

  // SKU input handler
  const handleSkuInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const enteredSku = e.target.value;
    setSku(enteredSku);
    setError((prev) => ({ ...prev, sku: undefined }));
  };

  // Form submission handler
  const handleSubmit = () => {
    // Validate inputs before submission
    if (!locator) {
      setError((prev) => ({
        ...prev,
        locator: t("Validation.Locator.Required"),
      }));
      locatorInputRef.current?.focus();
      return;
    }

    if (!sku) {
      setError((prev) => ({ ...prev, sku: t("Validation.SKU.Required") }));
      skuInputRef.current?.focus();
      return;
    }

    // Call external submit handler if provided
    onSubmit?.({ locator, sku });
    console.log(`Submitted: ${locator} - ${sku}`);
    setSku("");
    skuInputRef.current?.focus();
  };

  return (
    <>
      <div
        className="modal-backdrop show"
        data-testid="scan-locator-modal"
      ></div>
      <div className="modal show d-block">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h5 className="modal-title">{t("Value.Pack.Scan.Locator")}</h5>
            </div>

            {/* Modal Body */}
            <div className="modal-body">
              {/* Locator Input */}
              <div className="mb-3">
                <label htmlFor="locator-input" className="form-label">
                  {t("Value.Pack.Locator")}
                </label>
                <div className="input-group">
                  <input
                    id="locator-input"
                    ref={locatorInputRef}
                    type="text"
                    className={`form-control ${
                      error.locator ? "is-invalid" : ""
                    }`}
                    value={locator}
                    onChange={handleLocatorChange}
                    onBlur={handleLocatorBlur}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleLocatorBlur();
                      }
                    }}
                    disabled={isLocatorDisabled}
                    placeholder={t("Value.Pack.Enter.Locator")}
                  />
                  {isLocatorDisabled && (
                    <button
                      className="btn btn-transparent"
                      onClick={handleEditLocator}
                    >
                      <i className="bi bi-pencil ms-2 fs-3" />
                    </button>
                  )}
                  {error.locator && (
                    <div className="invalid-feedback">{error.locator}</div>
                  )}
                </div>
              </div>

              {/* SKU Input */}
              <div className="mb-3">
                <label htmlFor="sku-input" className="form-label">
                  {t("Value.Pack.SKU")}
                </label>
                <input
                  id="sku-input"
                  ref={skuInputRef}
                  type="text"
                  className={`form-control ${error.sku ? "is-invalid" : ""}`}
                  value={sku}
                  onChange={handleSkuInput}
                  onBlur={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSubmit();
                    }
                  }}
                  disabled={!isLocatorDisabled}
                  placeholder={t("Value.Pack.Enter.SKU")}
                />
                {error.sku && (
                  <div className="invalid-feedback">{error.sku}</div>
                )}
              </div>
            </div>

            {/* Modal Footer */}
            <div className="modal-footer">
              <button
                onClick={handleSubmit}
                id="submit-button"
                className="btn btn-primary"
                disabled={!isLocatorDisabled || !sku}
              >
                {t("Common.Button.Submit")}
              </button>
              <button onClick={onClose} className="btn btn-secondary">
                {t("Common.Button.Cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScanLocatorModal;
