import clsx from "clsx";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import {
  getCustomerByEmailAndClient,
  getCustomerByPhoneNoAndClient,
  storage,
} from "../../../../../../src/db";
import { createRef } from "../../../../../db/connection";
import { toAbsoluteUrl } from "../../../../../resources/helpers";
import Loading from "../../../../../resources/helpers/components/Loading";
import { RootState } from "../../../../../setup/redux/store";
import CountryList from "../../../../lists/CountryList";
import GenderList from "../../../../lists/GenderList";
import MaritalStatus from "../../../../lists/MaritalStatusList";
import * as chat from "../../../../modules/chat/redux/ChatSlice";
import * as ContactRedux from "../../../../modules/contact/redux/ContactSlice";
import { LCName, getItemLC } from "../../../../modules/localstorage";
import { useListView } from "../core/ListViewProvider";
import { useQueryResponse } from "../core/QueryResponseProvider";
import Customer, { initialContact } from "../../../../models/Customer";
import { createContact, updateContact } from "../core/_requests";
import CreateableSelect from "../../../../../styles/components/CreateableSelect";
import firebase from "firebase/compat/app";
import { UserCompanies } from "../../../../models/User";
import { ChatType } from "src/app/models/Message";
import { Room } from "src/app/models/Chat";

type Props = {
  isUserLoading: boolean;
  contact: Customer;
  onClose?: () => void;
  onSave?: (contactId?: string) => void;
};

interface Option {
  value: string;
  label: string;
  date?: number;
}

const ContactEditModalForm: FC<Props> = ({
  contact,
  isUserLoading,
  onClose,
  onSave,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setItemIdForUpdate } = useListView();
  const { refetch } = useQueryResponse();
  const client = getItemLC(LCName.Client);
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [genderList, setGenderList] = useState<any>([]);
  const [maritalStatusList, setMaritalStatusList] = useState<any>([]);
  const [listCompany, setListCompany] = useState<any[]>([]);
  const [listFacebook, setListFacebook] = useState<Option[]>([]);
  const [listInstagram, setListInstagram] = useState<Option[]>([]);
  const [listTiktok, setListTiktok] = useState<Option[]>([]);
  const [listShopee, setListShopee] = useState<Option[]>([]);
  const [listTokopedia, setListTokopedia] = useState<Option[]>([]);
  const [listLazada, setListLazada] = useState<Option[]>([]);
  const [listBukalapak, setListBukalapak] = useState<Option[]>([]);
  const [listBlibli, setListBlibli] = useState<Option[]>([]);
  let { listContact: listContactData } = useSelector(
    (state: RootState) => state.Contact
  );
  let {
    selectedChat,
    selectedRoom,
    selectedCollaboration,
    allChatSelectedChat,
    allChatSelectedRoom,
    allChatSelectedCollaboration,
    chatState,
  } = useSelector((state: RootState) => state.Chat);

  let collaboration =
    chatState === ChatType.mychat
      ? selectedCollaboration
      : allChatSelectedCollaboration;
  let chatSelected =
    chatState === ChatType.mychat ? selectedChat : allChatSelectedChat;
  let roomSelected =
    chatState === ChatType.mychat ? selectedRoom : allChatSelectedRoom;
  let activeRoom: Room = selectedCollaboration?.roomsModel?.filter(
    (itemRoom: Room) => {
      return itemRoom.id === selectedRoom;
    }
  )[0];

  let clientRef = createRef("clients", client?.id);
  const editContactSchema = Yup.object().shape({
    email: Yup.string()
      .email(`${t("Contact.Edit.Alert.InvalidEmailAddress")}`)
      .min(3, `${t("Contact.Alert.MinimumCharacter")}`)
      .max(50, `${t("Contact.Alert.MaximumCharacter")}`)
      .test(
        "test-validateEmail",
        t("CD.Error.ExistEmail"),
        async function (value) {
          if (value) {
            if (await checkEmail(value, clientRef)) {
              return false;
            } else {
              return true;
            }
          }
          return Promise.resolve(true);
        }
      ),
    firstName: Yup.string()
      .min(3, `${t("Contact.Alert.MinimumCharacter")}`)
      .max(50, `${t("Contact.Alert.MaximumCharacter")}`)
      .required("CD.Error.FirstName"),
    phoneNumber: Yup.string()
      .matches(
        /^(^\+628|628|^08)\d{7,11}$/,
        `${t("Contact.Edit.Alert.InvalidPhoneNumber")}`
      )
      .test(
        "test-validatePhoneNo",
        t("CD.Error.ExistPhoneNumber"),
        async function (value) {
          if (value) {
            let check: string = value;
            if (check !== "" && check.startsWith("0")) {
              check = "62" + check.substring(1);
            }
            if (await checkPhoneNo(check!, clientRef)) {
              return false;
            } else {
              return true;
            }
          }
          return Promise.resolve(true);
        }
      ),
    birthdate: Yup.date().max(new Date(), `${t("CD.Error.Birthdate")}`),
    district: Yup.string().max(100),
    city: Yup.string().max(100),
    province: Yup.string().max(100),
    facebook: Yup.array().of(Yup.string()),
    instagram: Yup.array().of(Yup.string()),
    tiktok: Yup.array().of(Yup.string()),
    shopee: Yup.array().of(
      Yup.object().shape({
        // value: Yup.string().required(),
        // label: Yup.string().required(),
        accountId: Yup.string().required(),
        roomId: Yup.string().required(),
        name: Yup.string().required(),
        userSearchKey: Yup.string().required(),
      })
    ),
    tokopedia: Yup.array().of(
      Yup.object().shape({
        // value: Yup.string().required(),
        // label: Yup.string().required(),
        accountId: Yup.string().required(),
        roomId: Yup.string().required(),
        name: Yup.string().required(),
        userSearchKey: Yup.string().required(),
      })
    ),
    lazada: Yup.array().of(
      Yup.object().shape({
        // value: Yup.string().required(),
        // label: Yup.string().required(),
        accountId: Yup.string().required(),
        roomId: Yup.string().required(),
        name: Yup.string().required(),
        userSearchKey: Yup.string().required(),
      })
    ),
    // shopee: Yup.array().of(Yup.string()),
    // tokopedia: Yup.array().of(Yup.string()),
    // lazada: Yup.array().of(Yup.string()),
    bukalapak: Yup.array().of(Yup.string()),
    blibli: Yup.array().of(Yup.string()),
  });

  useEffect(() => {
    if (listCompany.length <= 0) {
      const arrCompany: UserCompanies[] = getItemLC(LCName.CompanyList) ?? [];
      let companyList = arrCompany.map((companyAccess) => ({
        value: companyAccess.id,
        label: companyAccess.companyName,
      }));
      setListCompany(companyList);
    }

    if (genderList.length <= 0) {
      const genderList = GenderList.map((item) => {
        return {
          ...item,
          label: t(item.label),
        };
      });
      setGenderList(genderList);
    }
    if (maritalStatusList.length <= 0) {
      const list = MaritalStatus.map((item: any) => {
        return {
          ...item,
          label: t(item.label),
        };
      });
      setMaritalStatusList(list);
    }
  }, []);

  const [contactForEdit] = useState({
    avatar: contact.avatar || initialContact.avatar,
    phoneNumber: contact.phoneNumber || initialContact.phoneNumber || "",
    firstName: contact.firstName || initialContact.firstName,
    birthdate: "",
    email: contact.email || initialContact.email || "",
    isActive: true,
    firstNameInsensitive: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    gender: "",
    zipcode: "",
    country: "",
    maritalStatus: "",
    city: "",
    streetName: "",
    district: "",
    province: "",
    facebook: [],
    instagram: [],
    tiktok: [],
    shopee: [],
    tokopedia: [],
    lazada: [],
    bukalapak: [],
    blibli: [],
    followFacebook: initialContact.followFacebook || null,
    followInstagram: initialContact.followInstagram || null,
    followTiktok: initialContact.followTiktok || null,
    followShopee: initialContact.followShopee || null,
    followTokopedia: initialContact.followTokopedia || null,
    followLazada: initialContact.followLazada || null,
    followBukalapak: initialContact.followBukalapak || null,
    followBlibli: initialContact.followBlibli || null,
  });

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    if (onClose) {
      onClose();
    }
    setItemIdForUpdate(undefined);
  };

  const [file, setFile] = useState(null);
  const [picture, setPicture] = useState("");

  const setPreviewImage = (event: any) => {
    if (event.target.files[0]) {
      setPicture(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
    }
  };

  const uploadAvatar = async () => {
    let fileURL = "";
    if (file !== null) {
      const uuid = uuidv4();
      const task = storage
        .ref(getItemLC("CID") + "/images/avatar/customers")
        .child(uuid)
        .put(file);
      await task
        .then(async (snapshot) => {
          return storage
            .ref(getItemLC("CID") + "/images/avatar/customers")
            .child(uuid)
            .getDownloadURL()
            .then((url) => {
              fileURL = url;
            });
        })
        .catch((error) => {
          console.log("error : " + error.message);
        });
    }
    return fileURL;
  };

  const checkPhoneNo = async (value: string, client: any) => {
    let isExistData = await getCustomerByPhoneNoAndClient(value, client);
    return isExistData;
  };

  const checkEmail = async (value: string, client: any) => {
    let isExistData = await getCustomerByEmailAndClient(value, client);
    return isExistData;
  };

  const formik = useFormik({
    initialValues: contactForEdit,
    validationSchema: editContactSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (Object.values(values).includes("id")) {
          await updateContact(values);
        } else {
          if (
            values.phoneNumber !== "" &&
            values.phoneNumber!.startsWith("0")
          ) {
            values.phoneNumber = "62" + values.phoneNumber!.substring(1);
          }
          const fnameInsensitive = values.firstName!.toLowerCase();
          const now = new Date();
          values.firstNameInsensitive = fnameInsensitive;
          values.createdAt = now;
          values.updatedAt = now;
          values.avatar = await uploadAvatar();
          values.district = values?.district?.toLowerCase();
          values.city = values?.city?.toLowerCase();
          values.province = values?.province?.toLowerCase();
          //social media
          values.facebook = values?.facebook;
          values.instagram = values?.instagram;
          values.tiktok = values?.tiktok;
          //marketplace
          values.bukalapak = values?.bukalapak;
          values.blibli = values?.blibli;

          if (activeRoom.accountData.type === "tokopedia") {
            values.tokopedia = [
              {
                accountId: String(activeRoom.account?.id),
                roomId: activeRoom.id,
                name: String(activeRoom.profileName),
                userSearchKey: String(activeRoom.userSearchKey),
              },
            ] as any;
          } else if (activeRoom.accountData.type === "lazada") {
            values.lazada = [
              {
                accountId: String(activeRoom.account?.id),
                roomId: activeRoom.id,
                name: String(activeRoom.profileName),
                userSearchKey: String(activeRoom.userSearchKey),
              },
            ] as any;
          } else if (activeRoom.accountData.type === "shopee") {
            values.shopee = [
              {
                accountId: String(activeRoom.account?.id),
                roomId: activeRoom.id,
                name: String(activeRoom.profileName),
                userSearchKey: String(activeRoom.userSearchKey),
              },
            ] as any;
          }

          if (values.facebook?.length !== 0) {
            values.followFacebook = new Date();
          }
          if (values.instagram?.length !== 0) {
            values.followInstagram = new Date();
          }
          if (values.tiktok?.length !== 0) {
            values.followTiktok = new Date();
          }
          if (values.shopee?.length !== 0) {
            values.followShopee = new Date();
          }
          if (values.tokopedia?.length !== 0) {
            values.followTokopedia = new Date();
          }
          if (values.lazada?.length !== 0) {
            values.followLazada = new Date();
          }
          if (values.bukalapak?.length !== 0) {
            values.followBukalapak = new Date();
          }
          if (values.blibli?.length !== 0) {
            values.followBlibli = new Date();
          }

          if (
            values.avatar === null ||
            values.avatar === "" ||
            values.avatar === undefined
          ) {
            if (selectedGender === "male")
              values.avatar = toAbsoluteUrl("/media/icons/avatar/m-avatar.png");
            else if (selectedGender === "female")
              values.avatar = toAbsoluteUrl("/media/icons/avatar/f-avatar.png");
            else
              values.avatar = toAbsoluteUrl(
                "/media/icons/avatar/def-avatar.png"
              );
          }
          let selectedCompanyRef: any = [];
          if (selectedCompanies.length > 0) {
            selectedCompanies.forEach((company: any) => {
              const companyRef = createRef("company", company.value);
              selectedCompanyRef.push(companyRef);
            });
          }
          let jsonValue = {
            ...values,
            companies: selectedCompanyRef.length > 0 ? selectedCompanyRef : [],
            client: clientRef,
          };
          await createContact(
            jsonValue,
            async (newContact: Customer | undefined) => {
              if (newContact) {
                let newCollab = { customerModel: newContact, ...collaboration };
                dispatch(ContactRedux.setContactData(newContact));
                if (chatState === ChatType.mychat) {
                  dispatch(chat.setSelectedCollaboration(newCollab as any));
                } else {
                  dispatch(
                    chat.setAllChatSelectedCollaboration(newCollab as any)
                  );
                }
                dispatch(ContactRedux.updateListContact(newContact));
                const newContactList = [...listContactData];
                newContactList.push(newContact);
                dispatch(ContactRedux.setListContact(newContactList));
                if (onSave) {
                  onSave(newContact.id);
                  window.location.reload();
                }
              }
            }
          );
          setSubmitting(true);
          cancel(true);
        }
      } catch (ex) {
        console.error(ex);
      }
    },
  });

  const genderHandler = (e: any) => {
    const selectedGender = e.value;
    setSelectedGender(selectedGender);
    formik.setFieldValue("gender", selectedGender);
  };

  const maritalHandler = (e: any) => {
    const selectedMaritalStatus = e.value;
    formik.setFieldValue("maritalStatus", selectedMaritalStatus);
  };

  const countryHandler = (e: any) => {
    const selectedCountry = e.value;
    formik.setFieldValue("country", selectedCountry);
  };

  const onChangeSelectedCompanies = (event: any) => {
    const selectedCompaniesData = event;
    setSelectedCompanies(selectedCompaniesData);
  };

  return (
    <>
      <form
        id="kt_modal_add_user_form"
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          {/* begin::Input group avatar */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="d-block fw-bold fs-6 mb-5">
              {t("CD.Input.Avatar")}
            </label>
            {/* end::Label */}

            {/* begin::Image input */}
            <div
              className="image-input image-input-outline"
              data-kt-image-input="true"
            >
              {/* begin::Preview existing avatar */}
              <div
                className="image-input-wrapper w-125px h-125px"
                style={{ backgroundImage: `url('${picture}')` }}
              ></div>
              {/* end::Preview existing avatar */}

              {/* begin::Label */}
              <label
                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                data-kt-image-input-action="change"
                data-bs-toggle="tooltip"
                title="Change avatar"
              >
                <i className="bi bi-pencil-fill fs-7"></i>

                <input
                  id="contact-avatar"
                  onChange={setPreviewImage}
                  type="file"
                  name="avatar"
                  accept=".png, .jpg, .jpeg"
                />
              </label>
              {/* end::Label */}

              {/* begin::Cancel */}
              <span
                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                data-kt-image-input-action="cancel"
                data-bs-toggle="tooltip"
                title="Cancel avatar"
              >
                <i className="bi bi-x fs-2"></i>
              </span>
              {/* end::Cancel */}
            </div>
            {/* end::Image input */}

            {/* begin::Hint */}
            <div className="form-text">{t("CD.Info.AvatarFormat")}</div>
            {/* end::Hint */}
          </div>
          {/* end::Input group avatar */}

          {/* begin::Input group select company */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">
              {t("Common.Input.CompanySelect")}
            </label>
            {/* end::Label */}
            <Select
              data-testid="select-company"
              defaultValue={[]}
              isMulti
              name="company"
              options={listCompany}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={t("Common.Input.CompanySelect")}
              onChange={(e) => onChangeSelectedCompanies(e)}
              onKeyDown={(e) => {
                if (e.key === "enter" || e.keyCode === 13) {
                  e.preventDefault();
                  return false;
                }
              }}
            />
            {/* end::Input */}
          </div>
          {/* end::Input group select company */}

          <div className="fv-row fw-bolder fs-6 mb-2">
            {t("Contact.EditContact.Subtitle.ProfileDetails")}
          </div>

          {/* begin::Input group firstname */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="required fw-bold fs-6 mb-2">
              {t("CD.Input.FirstName")}
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              data-testid="input-firstname"
              placeholder={t("CD.Input.FirstName")}
              {...formik.getFieldProps("firstName")}
              type="text"
              name="firstName"
              className={clsx(
                "form-control border-2 mb-3 mb-lg-0",
                {
                  "is-invalid":
                    formik.touched.firstName && formik.errors.firstName,
                },
                {
                  "is-valid":
                    formik.touched.firstName && !formik.errors.firstName,
                }
              )}
              autoComplete="off"
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{t("CD.Error.FirstName")}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group firstname */}

          {/* begin::Input group lastname */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">
              {t("CD.Input.LastName")}
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              data-testid="input-lastname"
              placeholder={t("CD.Input.LastName")}
              {...formik.getFieldProps("lastName")}
              type="text"
              name="lastName"
              className={clsx("form-control border-2 mb-3 mb-lg-0")}
              autoComplete="off"
              disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
          </div>
          {/* end::Input group lastname */}

          {/* begin::Input group phone number */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">
              {t("Common.Column.PhoneNumber")}
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              data-testid="input-phoneNumber"
              placeholder={t("Contact.EditContact.InputPhoneNumber")}
              {...formik.getFieldProps("phoneNumber")}
              className={clsx(
                "form-control border-2 mb-3 mb-lg-0",
                {
                  "is-invalid":
                    formik.touched.phoneNumber && formik.errors.phoneNumber,
                },
                {
                  "is-valid":
                    formik.touched.phoneNumber &&
                    !formik.errors.phoneNumber &&
                    formik.getFieldProps("phoneNumber").value.length > 0,
                }
              )}
              type="text"
              name="phoneNumber"
              autoComplete="off"
              disabled={
                formik.isSubmitting ||
                isUserLoading ||
                (!contact.id && !!contact.phoneNumber)
              }
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  formik.setFieldValue("phoneNumber", e.target.value);
                }
              }}
            />
            {/* end::Input */}
            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.phoneNumber}</span>
                </div>
              </div>
            )}
            {!formik.errors.phoneNumber && (
              <div className="d-flex flex-row gap-2 text-warning">
                <i className="bi bi-exclamation-triangle text-warning"></i>
                <span>{t("Contact.AddCustomer.Alert.PhoneNumber")}</span>
              </div>
            )}
          </div>
          {/* end::Input group phone number */}

          {/* begin::Input group email */}
          <div className="fv-row mb-7">
            <label className="fw-bold fs-6 mb-2">
              {t("Common.Column.Email")}
            </label>
            <input
              data-testid="input-email"
              placeholder={t("Contact.EditContact.InputEmailAddress")}
              {...formik.getFieldProps("email")}
              className={clsx(
                "form-control border-2 mb-3 mb-lg-0",
                { "is-invalid": formik.touched.email && formik.errors.email },
                {
                  "is-valid":
                    formik.touched.email &&
                    !formik.errors.email &&
                    formik.getFieldProps("email").value.length > 0,
                }
              )}
              type="email"
              name="email"
              autoComplete="off"
              disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
            {formik.touched.email && formik.errors.email && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.email}</span>
                </div>
              </div>
            )}
            {!formik.errors.email && (
              <div className="d-flex flex-row gap-2 text-warning">
                <i className="bi bi-exclamation-triangle text-warning"></i>
                <span>{t("Contact.AddCustomer.Warning.EmailAddress")}</span>
              </div>
            )}
          </div>
          {/* end::Input group email */}

          {/* begin::Input group gender */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">{t("CD.Input.Gender")}</label>
            {/* end::Label */}
            <Select
              data-testid="inputGender"
              defaultValue={selectedGender}
              name="gender"
              options={genderList ? genderList : []}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={t("CD.PH.Gender")}
              onChange={(e) => {
                genderHandler(e);
              }}
              onKeyDown={(e) => {
                console.log("e :", e);
                if (e.key === "enter" || e.keyCode === 13) {
                  e.preventDefault();
                  return false;
                }
              }}
            />
          </div>
          {/* end::Input group gender */}

          {/* begin::Input group birthdate */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">
              {t("CD.Input.Birthdate")}
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              data-testid="input-birthdate"
              type="date"
              className="form-control border-2 mb-3 mb-lg-0"
              id="birthdate"
              {...formik.getFieldProps("birthdate")}
            />
            {/* end::Input */}
            {formik.touched.birthdate && formik.errors.birthdate && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{t("CD.Error.Birthdate")}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Input group birthdate */}

          {/* begin::Input group marital */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">
              {t("CD.Input.MaritalStatus")}
            </label>
            {/* end::Label */}
            <Select
              data-testid="inputMaritalStatus"
              name="maritalStatus"
              options={maritalStatusList ? maritalStatusList : []}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={t("CD.PH.MarietalStatus")}
              onChange={(e) => {
                maritalHandler(e);
              }}
              onKeyDown={(e) => {
                console.log("e :", e);
                if (e.key === "enter" || e.keyCode === 13) {
                  e.preventDefault();
                  return false;
                }
              }}
            />
          </div>
          {/* end::Input group marital */}

          <div className="fv-row fw-bolder fs-6 mb-2">
            {t("Contact.EditContact.Subtitle.Address")}
          </div>

          {/* begin::Input group stretname */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">
              {t("Contact.EditContact.Address.StreetName")}
            </label>
            {/* end::Label */}
            <div className="d-flex form-control border-2 px-0 py-0">
              {/* begin::Input */}
              <textarea
                data-testid="input-address"
                placeholder={t("Contact.EditContact.Address.Field.StreetName")}
                {...formik.getFieldProps("address")}
                name="address"
                className={clsx(
                  "form-control form-control-solid border-0 bg-white"
                )}
                autoComplete="off"
                disabled={formik.isSubmitting || isUserLoading}
                maxLength={255}
                style={{
                  height: "100%",
                  resize: "none",
                }}
                rows={5}
              />
              <p
                className="border-0 bg-white my-0"
                style={{
                  paddingRight: "5px",
                  color: "gray",
                  textAlign: "center",
                  paddingTop: "100px",
                }}
              >
                {formik.getFieldProps("address").value &&
                formik.getFieldProps("address").value.length
                  ? formik.getFieldProps("address").value.length
                  : 0}
                /255
              </p>
            </div>
            {/* end::Input */}
          </div>
          {/* end::Input group streetname */}

          {/* begin::Input group district */}
          <div className="fv-row mb-7">
            <label className="fw-bold fs-6 mb-2">
              {t("Contact.EditContact.Address.District")}
            </label>
            <div className="d-flex form-control border-2 p-0">
              <input
                data-testid="input-district"
                placeholder={t("Contact.EditContact.Address.Field.District")}
                {...formik.getFieldProps("district")}
                type="text"
                name="district"
                autoComplete="off"
                disabled={formik.isSubmitting || isUserLoading}
                className="form-control border-0"
                maxLength={100}
              />
              <p
                className="d-flex align-items-center border-0 bg-white my-0"
                style={{
                  paddingRight: "5px",
                  color: "gray",
                  textAlign: "center",
                  marginTop: "100%",
                }}
              >
                {formik.getFieldProps("district").value &&
                formik.getFieldProps("district").value.length
                  ? formik.getFieldProps("district").value.length
                  : 0}
                /100
              </p>
            </div>
          </div>
          {/* end::Input group district */}

          {/* begin::Input group city */}
          <div className="fv-row mb-7">
            <label className="fw-bold fs-6 mb-2">{t("CD.Input.City")}</label>
            <div className="d-flex form-control border-2 p-0">
              <input
                data-testid="input-city"
                placeholder={t("Contact.EditContact.Address.Field.City")}
                {...formik.getFieldProps("city")}
                type="text"
                name="city"
                className="form-control border-0"
                autoComplete="off"
                disabled={formik.isSubmitting || isUserLoading}
                maxLength={100}
              />
              <p
                className="d-flex align-items-center border-0 bg-white my-0"
                style={{
                  paddingRight: "5px",
                  color: "gray",
                  textAlign: "center",
                  marginTop: "100%",
                }}
              >
                {formik.getFieldProps("city").value &&
                formik.getFieldProps("city").value.length
                  ? formik.getFieldProps("city").value.length
                  : 0}
                /100
              </p>
            </div>
          </div>
          {/* end::Input group city */}

          {/* begin::Input group province */}
          <div className="fv-row mb-7">
            <label className="fw-bold fs-6 mb-2">
              {t("Contact.EditContact.Address.Province")}
            </label>
            <div className="d-flex form-control border-2 p-0">
              <input
                data-testid="input-province"
                placeholder={t("Contact.EditContact.Address.Field.Province")}
                {...formik.getFieldProps("province")}
                type="text"
                name="province"
                className="form-control border-0"
                autoComplete="off"
                disabled={formik.isSubmitting || isUserLoading}
                maxLength={100}
              />
              <p
                className="d-flex align-items-center border-0 bg-white my-0"
                style={{
                  paddingRight: "5px",
                  color: "gray",
                  textAlign: "center",
                  marginTop: "100%",
                }}
              >
                {formik.getFieldProps("province").value &&
                formik.getFieldProps("province").value.length
                  ? formik.getFieldProps("province").value.length
                  : 0}
                /100
              </p>
            </div>
          </div>
          {/* end::Input group province */}

          {/* begin::Input group country */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">{t("CD.Input.Country")}</label>
            {/* end::Label */}
            <Select
              data-testid="inputCountry"
              // defaultValue={customer.country}
              name="country"
              options={CountryList ? CountryList : []}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={t("CD.PH.Country")}
              onChange={(e) => {
                countryHandler(e);
              }}
              onKeyDown={(e) => {
                console.log("e :", e);
                if (e.key === "enter" || e.keyCode === 13) {
                  e.preventDefault();
                  return false;
                }
              }}
              menuPlacement="top"
            />
          </div>
          {/* end::Input group country */}

          {/* begin::Input group zipcode */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">{t("CD.Input.ZipCode")}</label>
            {/* end::Label */}
            {/* begin::Input */}
            <input
              data-testid="input-zipcode"
              placeholder={t("Contact.EditContact.Address.Field.ZipCode")}
              {...formik.getFieldProps("zipcode")}
              type="text"
              name="zipcode"
              className={clsx("form-control border-2 mb-3 mb-lg-0")}
              autoComplete="off"
              disabled={formik.isSubmitting || isUserLoading}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  formik.setFieldValue("zipcode", e.target.value);
                }
              }}
            />
            {/* end::Input */}
          </div>
          {/* end::Input group zipcode */}

          <div className="fv-row fw-bolder fs-6 mb-2">
            {t("CD.Input.SocialMedia")}
          </div>

          {/* Facebook */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">
              {t("Contact.EditContact.SocialMedia.Facebook")}
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <CreateableSelect
              value={listFacebook}
              placeholder={t("Contact.EditContact.SocialMedia.PH.Facebook")}
              testId="inputFacebook"
              onDataUpdate={(selected: any[]) => {
                setListFacebook(selected);
                const values = selected.map((option) => `${option.value}`);
                formik.setFieldValue("facebook", values);
              }}
              name="facebook"
            />
            {/* end::Input */}
          </div>

          {/* Instagram */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">
              {t("Contact.EditContact.SocialMedia.Instagram")}
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <CreateableSelect
              value={listInstagram}
              placeholder={t("Contact.EditContact.SocialMedia.PH.Instagram")}
              testId="inputInstagram"
              onDataUpdate={(selected: any[]) => {
                setListInstagram(selected);
                const values = selected.map((option) => `${option.value}`);
                formik.setFieldValue("instagram", values);
              }}
              name="instagram"
            />
            {/* end::Input */}
          </div>

          {/* Tiktok */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">
              {t("Contact.EditContact.SocialMedia.Tiktok")}
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <CreateableSelect
              value={listTiktok}
              placeholder={t("Contact.EditContact.SocialMedia.PH.Tiktok")}
              testId="inputTiktok"
              onDataUpdate={(selected: any[]) => {
                setListTiktok(selected);
                const values = selected.map((option) => `${option.value}`);
                formik.setFieldValue("tiktok", values);
              }}
              name="tiktok"
            />
            {/* end::Input */}
          </div>

          {/* Shopee */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">
              {t("Contact.EditContact.SocialMedia.Shopee")}
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              data-testid="input-shopee"
              placeholder={t("Contact.EditContact.SocialMedia.PH.Shopee")}
              onChange={(e) => {
                let value = e.target.value;
                let valueShopee =
                  activeRoom.accountData.type === "shopee"
                    ? [
                        {
                          accountId: String(activeRoom.account?.id),
                          roomId: activeRoom.id,
                          name:
                            value !== ""
                              ? value
                              : String(activeRoom.profileName),
                          userSearchKey: String(activeRoom.userSearchKey),
                        },
                      ]
                    : [
                        {
                          accountId: "",
                          roomId: "",
                          name: value,
                          userSearchKey: "",
                        },
                      ];
                formik.setFieldValue("shopee", valueShopee);
              }}
              value={
                activeRoom.accountData.type === "shopee"
                  ? String(activeRoom.profileName)
                  : ([formik.values.shopee] as any).name
              }
              type="text"
              name="shopee"
              className={clsx("form-control border-2 mb-3 mb-lg-0")}
              autoComplete="off"
              disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
          </div>

          {/* Tokopedia */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">
              {t("Contact.EditContact.SocialMedia.Tokopedia")}
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              data-testid="input-tokopedia"
              placeholder={t("Contact.EditContact.SocialMedia.PH.Tokopedia")}
              onChange={(e) => {
                let value = e.target.value;
                let valueTokopedia =
                  activeRoom.accountData.type === "tokopedia"
                    ? [
                        {
                          accountId: String(activeRoom.account?.id),
                          roomId: activeRoom.id,
                          name:
                            value !== ""
                              ? value
                              : String(activeRoom.profileName),
                          userSearchKey: String(activeRoom.userSearchKey),
                        },
                      ]
                    : [
                        {
                          accountId: "",
                          roomId: "",
                          name: value,
                          userSearchKey: "",
                        },
                      ];
                formik.setFieldValue("tokopedia", valueTokopedia);
              }}
              value={
                activeRoom.accountData.type === "tokopedia"
                  ? String(activeRoom.profileName)
                  : ([formik.values.tokopedia] as any).name
              }
              type="text"
              name="tokopedia"
              className={clsx("form-control border-2 mb-3 mb-lg-0")}
              autoComplete="off"
              disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
          </div>

          {/* Lazada */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">
              {t("Contact.EditContact.SocialMedia.Lazada")}
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              data-testid="input-lazada"
              placeholder={t("Contact.EditContact.SocialMedia.PH.Lazada")}
              onChange={(e) => {
                let value = e.target.value;
                let valueLazada =
                  activeRoom.accountData.type === "lazada"
                    ? [
                        {
                          accountId: String(activeRoom.account?.id),
                          roomId: activeRoom.id,
                          name:
                            value !== ""
                              ? value
                              : String(activeRoom.profileName),
                          userSearchKey: String(activeRoom.userSearchKey),
                        },
                      ]
                    : [
                        {
                          accountId: "",
                          roomId: "",
                          name: value,
                          userSearchKey: "",
                        },
                      ];
                formik.setFieldValue("lazada", valueLazada);
              }}
              value={
                activeRoom.accountData.type === "lazada"
                  ? String(activeRoom.profileName)
                  : ([formik.values.lazada] as any).name
              }
              type="text"
              name="lazada"
              className={clsx("form-control border-2 mb-3 mb-lg-0")}
              autoComplete="off"
              disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
          </div>

          {/* Bukalapak */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">
              {t("Contact.EditContact.SocialMedia.Bukalapak")}
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <CreateableSelect
              value={listBukalapak}
              placeholder={t("Contact.EditContact.SocialMedia.PH.Bukalapak")}
              testId="inputBukalapak"
              onDataUpdate={(selected: any[]) => {
                setListBukalapak(selected);
                const values = selected.map((option) => `${option.value}`);
                formik.setFieldValue("bukalapak", values);
              }}
              name="bukalapak"
            />
            {/* end::Input */}
          </div>

          {/* Blibli */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">
              {t("Contact.EditContact.SocialMedia.Blibli")}
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <CreateableSelect
              value={listBlibli}
              placeholder={t("Contact.EditContact.SocialMedia.PH.Blibli")}
              testId="inputBlibli"
              onDataUpdate={(selected: any[]) => {
                setListBlibli(selected);
                const values = selected.map((option) => `${option.value}`);
                formik.setFieldValue("blibli", values);
              }}
              name="blibli"
            />
            {/* end::Input */}
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className="text-center pt-15">
          <button
            type="reset"
            onClick={() => cancel()}
            className="btn btn-light me-3"
            data-kt-users-modal-action="cancel"
            disabled={formik.isSubmitting || isUserLoading}
          >
            {t("CD.Button.Discard")}
          </button>

          <button
            type="submit"
            className="btn btn-primary"
            data-testid="btnFormSubmit"
            data-kt-users-modal-action="submit"
            onClick={() => {
              console.log(formik.values);
            }}
            disabled={isUserLoading || formik.isSubmitting || !formik.touched}
          >
            <span className="indicator-label">
              {t("Contacts.Button.AddUser")}
            </span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className="indicator-progress">
                {t("Login.Button.Loading")}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <Loading />}
    </>
  );
};

export { ContactEditModalForm };
