import React, { useEffect, useState } from "react";
import axios from "axios";
import * as bcrypt from "bcryptjs";
import { toast } from "react-toastify";

const RealtimeInfo = () => {
  const [latency, setLatency] = useState("Unknown");
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const interval = setInterval(async () => {
      // Update latency
      const connection =
        (navigator as any).connection ||
        (navigator as any).mozConnection ||
        (navigator as any).webkitConnection;
      if (connection) {
        const rtt = connection.rtt;
        setLatency(rtt);
        // if (rtt >= 1000) {
        //   toast("Bad Network Detected, Check Your Connection", {
        //     type: "error",
        //     position: "top-right",
        //     autoClose: 500,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //   });
        // } else if (rtt >= 500) {
        //   toast("Slow Network Detected, Check Your Connection", {
        //     type: "warning",
        //     position: "top-right",
        //     autoClose: 500,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //   });
        // }
      }
    }, 1000); // Update every second

    return () => clearInterval(interval);
  }, []);

  // Determine class names based on latency
  let bgColorClass = "bg-light-primary";
  let textColorClass = "text-primary";

  const rtt = parseFloat(latency);
  if (latency !== "Unknown") {
    if (rtt !== 0) {
      if (rtt <= 50) {
        bgColorClass = "bg-light-success";
        textColorClass = "text-success";
      } else if (rtt <= 100) {
        bgColorClass = "bg-light-warning";
        textColorClass = "text-warning";
      } else {
        bgColorClass = "bg-light-danger";
        textColorClass = "text-danger";
      }
    } else {
      bgColorClass = "bg-danger";
      textColorClass = "text-white";
    }
  }

  return (
    <div
      className={`rounded-pill d-flex flex-row align-items-center justify-content-center col-gap-1 py-1 px-2 ${bgColorClass} cursor-pointer`}
      onClick={() => setIsHovered(!isHovered)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        viewBox="0 0 14 14"
        className={`p-0 m-0 ${textColorClass} icon animate__animated animate__wobble animate__infinite`}
      >
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.5 13.5a3 3 0 1 0-2.15-5.09A4 4 0 1 0 4.5 13.5Zm-.02-12.3a9 9 0 0 0-7 0m5.7 2.16a6.78 6.78 0 0 0-4.36 0"
        />
      </svg>
      {isHovered && (
        <div>
          {latency !== "Unknown" ? (
            parseFloat(latency) > 0 ? (
              <p className={`p-0 m-0 ${textColorClass}`}>{latency} ms</p>
            ) : (
              <p className={`p-0 m-0 ${textColorClass}`}>Offline</p>
            )
          ) : (
            <p className={`p-0 m-0 ${textColorClass}`}>Calculating</p>
          )}
        </div>
      )}
    </div>
  );
};

export default RealtimeInfo;
