import React, { useContext, useEffect, useRef, useState } from "react";
import { KTSVG } from "src/resources/helpers";
import { useTranslation } from "react-i18next";
import { PageLink, PageTitle } from "../../core";
import { useMediaQuery } from "react-responsive";
import { ActionsCell } from "./components/ActionsCell";
import ListComponent from "src/app/components/list/NewList";
import ShippingHandover from "src/app/models/ShippingHandover";
import "src/styles/css/product.css";
import ChooseAddHandover from "./modal/ChooseAddHandover";

type Columns<T> = {
  title: string;
  field: keyof T;
  value: (props: { value: any }) => JSX.Element | Promise<JSX.Element>;
};

type Action<T> = {
  headerTitle: string;
  value: (props: { value: any }) => JSX.Element | Promise<JSX.Element>;
};

const ShippinghandoverPage = () => {
  const { t } = useTranslation();
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });
  const usersBreadcrumbs = undefined;
  const [isAddHandoverModalOpen, setAddHandoverModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    window.document.getElementById("button-refetch")?.click();
  }, [isAddHandoverModalOpen]);

  // kolom default tabel di product page
  const defaultColumns: Columns<ShippingHandover>[] = [
    {
      field: "id",
      title: t("Shipping.Handover.Id"),
      value: (props: any) => {
        return (
          <>
            <div className="d-flex  position-sticky">
              <p className="p-0 m-0 fw-bolder" style={{ color: "#999999" }}>
                {props.row?.original.id}
              </p>
            </div>
          </>
        );
      },
    },
    {
      field: "shipper",
      title: t("Shipping.Handover.Shipper"),
      value: (props: any) => {
        return (
          <div
            className={
              isMobileView
                ? "m-0 p-0 d-flex justify-content-end position-sticky"
                : "m-0 p-0 h-auto d-flex align-items-center justify-content-start fw-bolder position-sticky"
            }
          >
            {props.row?.original.shipper}
          </div>
        );
      },
    },
    {
      field: "datePickup",
      title: t("Shipping.Handover.Date"),
      value: (props: any) => {
        const { datePickup } = props.row?.original;
        if (
          datePickup &&
          datePickup.seconds !== undefined &&
          datePickup.nanoseconds !== undefined
        ) {
          const totalMilliseconds =
            datePickup.seconds * 1000 + datePickup.nanoseconds / 1000000;
          const formattedDate = new Date(totalMilliseconds).toLocaleDateString(
            "id-ID"
          );
          return (
            <div className="d-flex text-center align-self-center align-items-center justify-content-center mx-auto px-auto">
              {formattedDate}
            </div>
          );
        }
        return <div className="text-center">N/A</div>;
      },
    },
    {
      field: "pickupTime",
      title: t("Shipping.Handover.Time"),
      value: (props: any) => {
        const { datePickup } = props.row?.original;
        if (
          datePickup &&
          datePickup.seconds !== undefined &&
          datePickup.nanoseconds !== undefined
        ) {
          const totalMilliseconds =
            datePickup.seconds * 1000 + datePickup.nanoseconds / 1000000;
          const dateObj = new Date(totalMilliseconds);
          const formattedTime = dateObj.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });
          return (
            <div className="d-flex text-center align-self-center align-items-center justify-content-center mx-auto px-auto">
              {formattedTime}
            </div>
          );
        }
        return <div className="text-center">N/A</div>;
      },
    },
    {
      field: "driverName",
      title: t("Shipping.Handover.Driver"),
      value: (props: any) => {
        return (
          <div
            className={
              "d-flex text-center align-self-center align-items-center justify-content-center mx-auto px-auto"
            }
          >
            {props.row?.original.driverName}
          </div>
        );
      },
    },
    {
      field: "vehicleNumber",
      title: t("Shipping.Handover.Vehicle"),
      value: (props: any) => {
        return (
          <div
            className={
              "d-flex text-center align-self-center align-items-center justify-content-center mx-auto px-auto"
            }
          >
            {props.row?.original.vehicleNumber}
          </div>
        );
      },
    },
    {
      field: "printedAt",
      title: t("Shipping.Handover.Printed"),
      value: (props: any) => {
        const { printedAt } = props.row?.original;
        if (
          printedAt &&
          printedAt.seconds !== undefined &&
          printedAt.nanoseconds !== undefined
        ) {
          const totalMilliseconds =
            printedAt.seconds * 1000 + printedAt.nanoseconds / 1000000;
          const formattedDate = new Date(totalMilliseconds).toLocaleDateString(
            "id-ID"
          );
          const dateObj = new Date(totalMilliseconds);
          const formattedTime = dateObj.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });
          return (
            <div className="d-flex text-center align-self-center align-items-center justify-content-center mx-auto px-auto">
              {formattedDate} || {formattedTime}
            </div>
          );
        }
        return <div className="text-center">N/A</div>;
      },
    },
  ];

  const defaultAction: Action<ShippingHandover> = {
    headerTitle: t("Common.Action"),
    value: (props: any) => {
      return (
        <div className="p-0 m-2">
          <ActionsCell data={props.row?.original} />
        </div>
      );
    },
  };

  const handleCloseModal = () => {
    setAddHandoverModalOpen(false);
  };
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {t("Shipping.Handover.Title")}
      </PageTitle>
      <ListComponent<ShippingHandover>
        collection="shipmentHandOver"
        collectionGroup={false} //default false
        columns={defaultColumns}
        selection={{
          headerSelection: false,
          rowSelection: false,
        }}
        subcollection={["shippingMark"]}
        defaultFilters={[]}
        defaultSorting={[
          {
            field: "shipper",
            label: t("Shipping.Handover.Shipper"),
            direction: "asc",
          },
        ]}
        useFilter={[]}
        searching={{
          field: "shipper",
          useFirestore: true,
          value: "",
          caseSensitive: true, //default false
        }}
        extra={[
          {
            label: "add_handover",
            value: (props: any) => {
              return (
                <>
                  <button
                    type="button"
                    data-testid="btnAddHandover"
                    className="btn btn-primary"
                    onClick={() => {
                      setAddHandoverModalOpen(true);
                      console.log("add handover clicked");
                    }}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-2"
                    />
                    {t("Shipping.Handover.Add")}
                  </button>
                </>
              );
            },
          },
        ]}
        action={defaultAction}
      />
      {isAddHandoverModalOpen && (
        <ChooseAddHandover onClose={handleCloseModal} />
      )}
    </>
  );
};

export default ShippinghandoverPage;
