import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import "../../../../../styles/css/margin.scss";
import "../../../../../styles/css/campaign.scss";
import "../../../../../styles/css/toggle.scss";
import "../../../../../styles/css/color.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as ReduxTemplate from "../../../../modules/template/redux/TemplateSlice";
import * as ReduxCampaign from "../../../../modules/campaign/redux/CampaignSlice";
import { RootState } from "../../../../../setup/redux/store";
import { useMediaQuery } from "react-responsive";
import InteractiveSelection from "../../template-create/component-template/category-component/InteractiveSelection"; // Import teka lawasane (template-create)
import { templateState } from "../../../../models/Template";
import Account from "../../../../models/Account";

interface TemplateProps {
  onChanges: (params: { key: string; value: any }[]) => void;
}

const TemplateCategory: FC<TemplateProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [selectedTemplateCategory, setSelectedTemplateCategory] = useState<string>("");
  const selectedTemplateCategory = useSelector(
    (state: RootState) => state.Template.templateCategory
  );
  const campaignTemplateFinish = useSelector(
    (state: RootState) => state.Campaign.campaignTemplateFinish
  );
  const [hasTemplateCategory, setHasTemplateCategory] =
    useState<boolean>(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const templateEmptyCategoryAlert = useSelector(
    (state: RootState) => state.Template.templateEmptyCategoryAlert
  );
  const accountsID = useSelector(
    (state: RootState) => state.Template.accountsID
  );
  const companiesAccounts: Account[] = useSelector(
    (state: RootState) => state.Template.companiesAccounts
  );
  const [nonWhatsappAccount, setNonWhatsappAccount] = useState<number>(0);
  const reduxTemplateState = useSelector(
    (state: RootState) => state.Template.templateState
  );
  type TemplateProps = {
    id: string;
    title: string;
    description: string;
    icon?: string;
    isDisabled?: boolean;
    onCategoryChanges?: () => void;
  };

  useEffect(() => {
    console.log(`TempalateCategory Rendered`);
    if (selectedTemplateCategory && selectedTemplateCategory !== "") {
      setHasTemplateCategory(true);
      document.getElementById("nextStep")?.classList.remove("disabled");
    } else {
      setHasTemplateCategory(false);
    }
  }, []);

  useEffect(() => {
    let nonWhatsapps = 0;
    if (accountsID.length > 0) {
      for (let i = 0; i < accountsID.length; i++) {
        const findAccountNonWhatsapp = companiesAccounts.find(
          (account) =>
            account.id === accountsID[i] && account.type !== "whatsapp"
        );
        console.log(`account get: `, findAccountNonWhatsapp);
        if (findAccountNonWhatsapp) {
          nonWhatsapps++;
        }
      }
      console.log(`nonWhatsapps : ${nonWhatsapps}`);
      setNonWhatsappAccount(nonWhatsapps);
      console.log(`nonWhatsappAccount : ${nonWhatsappAccount}`);
      if (nonWhatsapps > 0 && selectedTemplateCategory === "interactive") {
        dispatch(ReduxTemplate.setTemplateCategory(""));
        setHasTemplateCategory(false);
      }
    }
  }, [accountsID]);

  useEffect(() => {
    if (selectedTemplateCategory !== "interactive") {
      dispatch(ReduxTemplate.setSelectedInteractiveMessage(""));
    }
  }, [selectedTemplateCategory]);

  useEffect(() => {
    if (campaignTemplateFinish) {
      document.getElementById("utility")?.classList.add("disabled");
      document.getElementById("marketing")?.classList.add("disabled");
      document.getElementById("authentication")?.classList.add("disabled");
      document.getElementById("interactive")?.classList.add("disabled");
      setHasTemplateCategory(true);
    }
  }, [campaignTemplateFinish]);

  const TempCategory: FC<TemplateProps> = (props) => {
    // console.log("props get: ",props);
    const { id, title, description, icon, isDisabled } = props;

    const onClickHandler = (id: string) => {
      //generate data
      if (!campaignTemplateFinish) {
        dispatch(ReduxTemplate.setTemplateCategory(id));
        setHasTemplateCategory(true);
        if (id === "interactive") {
          setTimeout(() => {
            document
              .getElementById("interactive-section")
              ?.scrollIntoView({ behavior: "smooth", block: "end" });
          }, 100);
        }
      }
    };
    console.log(
      `id : ${id}, isDisabled : ${isDisabled}, nonWhatsapp :${nonWhatsappAccount}}`
    );

    return (
      <div
        id={id}
        className={clsx(
          "btn btn-light template-div mt-5",
          !isDisabled ? "btn-active-primary" : "",
          {
            active: selectedTemplateCategory === id,
            "ms-5 me-5 mb-5 w-20": !isTabletOrMobile,
            "w-100 d-flex": isTabletOrMobile,
          }
        )}
        onClick={() => {
          if (!isDisabled) {
            onClickHandler(id);
          } else {
            return;
          }
        }}
        data-testid={`templateCategory-` + id}
      >
        <div className={clsx(icon, { "p-3 me-5": isTabletOrMobile })}></div>
        <div
          className={clsx("", {
            "d-flex flex-column align-items-start": isTabletOrMobile,
          })}
        >
          <div className="step-sub-title">{title}</div>
          <div className={clsx("desc", { "text-start": isTabletOrMobile })}>
            {description}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (hasTemplateCategory) {
      document.getElementById("nextStep")?.classList.remove("disabled");
      dispatch(ReduxTemplate.setTemplateEmptyCategoryAlert(false));
    }
  }, [hasTemplateCategory]);

  console.log(selectedTemplateCategory, "____coba state");

  return (
    <>
      <div
        className={clsx("tab-pane fade")}
        id="kt_tab_pane_1"
        role="tabpanel"
        data-testid="tab-pane-templateCategory"
      >
        {/* New Template */}
        <div className="d-flex" data-testid="flex-templateCategory">
          {/* 1 */}
          <div className="col-12" data-testid="col-templateCategory">
            {/* Select Template Category */}
            {/* <div className="mb-5 nav-line-tabs"> */}
            <div className="mb-5 ">
              <div className="step-sub-title mt-5 mb-5 fs-2">
                <label
                  className="form-check-label"
                  data-testid="form-check-label"
                >
                  {t("Campaign.Title.TemplateCategory")}
                </label>
                {hasTemplateCategory && (
                  <span
                    id="filledCategory"
                    data-testid="filledCategory"
                    className="bi bi-check-circle-fill text-success ps-3"
                  ></span>
                )}
                {!hasTemplateCategory && (
                  <span
                    id="filledCategory"
                    className="bi bi-check-circle-fill text-success ps-3"
                    style={{ display: "none" }}
                  ></span>
                )}
              </div>
              <div className="mb-1 mt-1">
                {t("Campaign.Info.TemplateCategory")}
              </div>
              {templateEmptyCategoryAlert && (
                <div
                  className="text-danger"
                  data-testid="error-templatecategory"
                >
                  {t("CreateTemplate.TemplateCategory.Alert.SelectTemplate")}
                </div>
              )}
              <div
                className={clsx("d-flex", {
                  "justify-content-center": !isTabletOrMobile,
                  "flex-column": isTabletOrMobile,
                })}
              >
                <TempCategory
                  id="utility"
                  icon="bi bi-handbag-fill fs-4x"
                  title={t("Campaign.Title.Utility")}
                  description={t("Campaign.Info.Utility")}
                  isDisabled={false}
                />
                <TempCategory
                  id="marketing"
                  icon="bi bi-shop-window fs-4x"
                  title={t("Campaign.Title.Marketing")}
                  description={t("Campaign.Info.Marketing")}
                  isDisabled={false}
                />
                <TempCategory
                  id="authentication"
                  icon="bi bi-key fs-4x"
                  title={t("Campaign.Title.OneTimePassword")}
                  description={t("Campaign.Info.OneTimePassword")}
                  isDisabled={false}
                />
                <TempCategory
                  id="interactive"
                  icon="bi bi-chat-left-dots-fill fs-4x"
                  title={t(
                    "Campaign.TemplateCategory.IMS.InteractiveButton.Title"
                  )}
                  description={t(
                    "Campaign.TemplateCategory.IMS.InteractiveButton.Body"
                  )}
                  isDisabled={
                    nonWhatsappAccount > 0
                      ? true
                      : reduxTemplateState === templateState.campaigncreated
                      ? true
                      : false
                  }
                />
              </div>
              <div
                id="interactive-section"
                className={clsx("d-flex mt-3", {
                  "justify-content-center flex-column": !isTabletOrMobile,
                  "flex-column": isTabletOrMobile,
                })}
              >
                {selectedTemplateCategory === "interactive" ? (
                  <InteractiveSelection />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateCategory;
