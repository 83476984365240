import { useTranslation } from "react-i18next";

const Loading = () => {
  const { t } = useTranslation();
  const styles = {
    position: "absolute",
    borderRadius: "0.475rem",
    boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
    backgroundColor: "#fff",
    color: "#7e8299",
    fontWeight: "500",
    margin: "0",
    width: "auto",
    padding: "1rem 2rem",
    top: "calc(50% - 2rem)",
    left: "calc(50% - 4rem)",
    zIndex: "99",
  };

  return (
    <div
      data-testid="loading-modal"
      className="d-flex flex-row"
      style={{ ...styles, position: "absolute", textAlign: "center" }}
    >
      <span
        className="indicator-progress m-0 p-0 me-2"
        style={{ display: "block" }}
      >
        <span className="spinner-border spinner-border-sm align-middle m-0 p-0"></span>
      </span>
      {t("Common.Pages.Loading")}
    </div>
  );
};

export default Loading;
