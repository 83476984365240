import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import Notification from "./Notification";

export default function NotificationsManager({ setNotify }) {
  let [notifications, setNotifications] = React.useState([]);
  var notifyCount = 0;


  console.log('notif manager');

  const createNotification = useCallback(
    ({ color, autoClose, children, notif }) => {
      console.log("DEBUG NOTIFICATION MULTIPLE", "ON Notification Manager, createNotification:", notif.id, notif); // correct
      setNotifications((prevNotifications) => [
        {
          children,
          color,
          autoClose,
          notif,
          // id: prevNotifications.length,
          id: notif.id,
        },
        ...prevNotifications,
      ]);
    },
    [notifications]
  );

  React.useEffect(() => {
    if (notifyCount < 1) {
      setNotify(({ color, autoClose, children, notif }) => createNotification({ color, autoClose, children, notif }));

      notifyCount++;
    }
  }, [setNotify]);

  const deleteNotification = useCallback(
    (id) => {
      // Check if notification with given ID exists
      const notificationIndex = notifications.some((notification) => notification.id === id);
      if (!notificationIndex) {
        return;
      }

      setNotifications((prevNotifications) => {
        // Create a new array excluding the notification with the specified ID
        return prevNotifications.filter((notification) => notification.id !== id);
      });
    },
    [notifications]
  );

  return Array.from(new Set(notifications.map(({ notif }) => notif.id)))
    .map((notifId) => {
      return notifications.find((notif) => notif.id === notifId);
    })
    .map(({ id, ...props }, index) => <Notification key={id} onDelete={() => deleteNotification(id)} {...props} />);
}

NotificationsManager.propTypes = {
  setNotify: PropTypes.func.isRequired,
};
