import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DetailShippinngMarkModal from "./DetailShippingMarkModal";
import "./ShippingMark.css";
interface ViewShippinngMarkModalProps {
  onClose: () => void;
  defaultData?: any[];
  shipper?: string;
}

const ViewShippinngMarkModal: React.FC<ViewShippinngMarkModalProps> = ({
  onClose,
  defaultData = [],
  shipper,
}) => {
  const { t } = useTranslation();

  const [shippingMarkData, setShippingMarkData] = useState<any[]>([]);
  const [isShowShippingModalOpen, setShowShippingModalOpen] =
    useState<boolean>(false);
  const [selectedShippingMarkIndex, setSelectedShippingMarkIndex] =
    useState<number>(0);

  const handleClose = () => {
    onClose();
  };

  const handleCloseModal = () => {
    setShowShippingModalOpen(false);
  };

  const handleViewShippingMark = (index: number) => {
    setSelectedShippingMarkIndex(index);
    setShowShippingModalOpen(true);
  };

  const formatDate = (date: Date) => {
    if (!date || isNaN(date.getTime())) return "";
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatTime = (date: Date) => {
    if (!date || isNaN(date.getTime())) return "";
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    const fetchOrderData = async (defaultData: any[]) => {
      const arrayData = await Promise.all(
        defaultData.map(async (item: any) => {
          const arrayRef = item?.orderRef;
          const mappedData = await Promise.all(
            arrayRef?.map(async (docRef: any) => {
              const docSnapshot = await docRef.get();
              return { id: docSnapshot.id, ...docSnapshot.data() };
            })
          );
          return { ...item, orderRef: mappedData };
        })
      );

      if (arrayData.length > 0) {
        setShippingMarkData(arrayData);
      }
    };

    if (defaultData) {
      fetchOrderData(defaultData);
    }
  }, [defaultData]);

  return (
    <>
      <div className="modal-overlay" data-testid="view-shipping-mark-modal">
        <div className="modal-content">
          <div className="button-row">
            <h2>
              {t("Shipping.Handover.Aside")} - {shipper}
            </h2>
            <button className="button-c" onClick={handleClose}>
              X
            </button>
          </div>

          {defaultData.length === 0 ? (
            <div style={{ color: "red", textAlign: "center", margin: "20px" }}>
              {t("Shipping.Mark.No")}
            </div>
          ) : (
            <table className="form-table">
              <thead>
                <tr>
                  <th>{t("Shipping.Mark.ID")}</th>
                  <th>{t("Shipping.Mark.Packing")}</th>
                  <th>{t("Shipping.Mark.Printed")}</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {defaultData.map((item: any, index: number) => {
                  const firebaseTimestamp = item.printed;
                  const totalMilliseconds =
                    (firebaseTimestamp?.seconds || 0) * 1000 +
                    (firebaseTimestamp?.nanoseconds || 0) / 1000000;
                  const date = new Date(totalMilliseconds);

                  const formattedDate = formatDate(date);
                  const formattedTime = formatTime(date);

                  return (
                    <React.Fragment key={item.id}>
                      {!item?.packingStations ||
                      item.packingStations.length === 0 ? (
                        <tr>
                          <td>{item.id}</td>
                          <td>
                            <div
                              style={{
                                color: "red",
                                textAlign: "center",
                                margin: "20px",
                              }}
                            >
                              {t("Shipping.Mark.No")}
                            </div>
                          </td>
                          <td>
                            {formattedDate} || {formattedTime}
                          </td>
                          <td>
                            <button
                              className="btn btn-primary"
                              onClick={() => handleViewShippingMark(index)}
                            >
                              {t("Shipping.Mark.View")}
                            </button>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td>{item.id}</td>
                          <td>
                            {item.packingStations.map((x: any, idx: number) => (
                              <div key={idx}>
                                {x}
                                <br />
                              </div>
                            ))}
                          </td>
                          <td>
                            {formattedDate} || {formattedTime}
                          </td>
                          <td>
                            <button
                              className="btn btn-primary"
                              onClick={() => handleViewShippingMark(index)}
                            >
                              {t("Shipping.Mark.View")}
                            </button>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {isShowShippingModalOpen && (
        <DetailShippinngMarkModal
          onClose={handleCloseModal}
          defaultData={
            shippingMarkData[selectedShippingMarkIndex] ??
            defaultData[selectedShippingMarkIndex]
          }
        />
      )}
    </>
  );
};

export default ViewShippinngMarkModal;
