import clsx from "clsx";
import { log } from "console";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { getByID } from "../../../../../api/firebase/message";
import { toAbsoluteUrl } from "../../../../../resources/helpers";
import { RootState } from "../../../../../setup/redux/store";
import Customer from "../../../../models/Customer";
import Message, {
  InteractiveMessageComponent,
} from "../../../../models/Message";
import * as lc from "../../../../modules/localstorage/index";
import { MessageLocation } from "../chat-message/ChatLocation";
import { convertTextStyle } from "../chat-util/ChatUtil";

interface MessageProps {
  replyMessageID: string;
  bgChat?: string;
  txChat?: string;
  children?: any;
  customer?: Customer;
  phoneNumber?: string;
}

const ChatMessageReplyView: FC<MessageProps> = (props) => {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  let { replyMessageID, bgChat, txChat, children, customer, phoneNumber } =
    props;
  let listMessagess = useSelector((state: RootState) => state.Chat.listMessage); //list Collaboration
  const user = lc.getItemLC(lc.LCName.UserData);
  let customerName;
  // = replyMessage && replyMessage.customerPhoneNumber? replyMessage.customerPhoneNumber : "";
  if (customer) {
    customerName =
      customer?.firstName +
      (customer?.lastName ? " " + customer?.lastName : "");
    if (customerName.length > 15) {
      customerName = customerName.substring(0, 11) + "...";
    }
  }
  const [replyMessageData, setReplyMessageData] = useState<Message>();

  useEffect(() => {
    console.log("masuk use effect reply message");
    if (replyMessageID === undefined) return;
    let findMessage: Message | undefined = listMessagess.find(
      (msg: any) => msg?.id === replyMessageID
    );
    if (findMessage) {
      console.log("reply message found in listmessage");
      setReplyMessageData(findMessage);
    } else {
      getByMessageID();
    }
  }, [replyMessageID, listMessagess]);

  const getByMessageID = async () => {
    if (replyMessageID) {
      const getMessage = await getByID(replyMessageID);
      if (getMessage) {
        setReplyMessageData(getMessage);
      }
    }
  };
  let header: InteractiveMessageComponent | undefined =
    (replyMessageData?.messageType === "interactive" ||
      replyMessageData?.messageType === "template") &&
    replyMessageData?.interactive?.components
      ? //find in replyMessageData.components if type header
        replyMessageData?.interactive?.components?.find(
          (component) => component.type === "header"
        )
      : undefined;

  return (
    <div
      className={clsx(
        "rounded fw-bold  d-flex flex-column gap-3 p-3",
        isTabletOrMobile ? "mw-200px" : "mw-lg-300px",
        bgChat ? bgChat : "cl-7days"
      )}
      style={{ backgroundColor: `${bgChat}` }}
      data-testid="reply-message"
    >
      <div
        className={clsx(
          "d-flex flex-column rounded bg-opacity-50",
          bgChat ? bgChat : "bg-primary",
          bgChat ? bgChat.replace("cl", "border-reply") : "border-reply-7days"
        )}
        data-testid={`reply-message-${replyMessageData?.messageType}`}
        style={{
          borderRadius: "8px",
          borderLeftWidth: "6px",
          borderLeftStyle: "solid",
        }}
      >
        <div className="d-flex flex-row justify-content-between">
          <div
            className="p-5 cursor-pointer"
            onClick={() => {
              document
                .getElementById(`message_menu_${replyMessageData?.id}`)
                ?.scrollIntoView();
            }}
          >
            <div className="fw-bolder mb-2" data-testid="reply-message-from">
              {replyMessageData?.destination === "inbound"
                ? customerName
                  ? customerName
                  : phoneNumber
                : t("HandledCustomer.RoomChat.ReplyMessage.You")}
            </div>
            {(replyMessageData?.messageType === "text" ||
              replyMessageData?.messageType === "image" ||
              replyMessageData?.messageType === "video" ||
              replyMessageData?.messageType === "document" ||
              replyMessageData?.messageType === "audio" ||
              replyMessageData?.messageType === "location" ||
              replyMessageData?.messageType === "interactive" ||
              replyMessageData?.messageType === "template") && (
              <div
                className={clsx("d-flex", {
                  "flex-column align-items-left":
                    replyMessageData?.messageType === "interactive" ||
                    replyMessageData?.messageType === "template",
                  "flex-row align-items-center":
                    replyMessageData?.messageType !== "interactive" &&
                    replyMessageData?.messageType !== "template",
                })}
              >
                {(replyMessageData.messageType === "image" ||
                  ((replyMessageData?.messageType === "interactive" ||
                    replyMessageData?.messageType === "template") &&
                    header &&
                    header.image)) && (
                  <div className="d-flex flex-row align-items-center">
                    <i
                      data-testid="reply-message-image-icon"
                      className="bi bi-image me-3"
                    ></i>
                    {(replyMessageData?.messageType === "interactive" ||
                      replyMessageData?.messageType === "template") &&
                      header &&
                      header.image && (
                        <div>{t("AddNewProduct.Title.Photo")}</div>
                      )}
                  </div>
                )}
                {(replyMessageData.messageType === "video" ||
                  ((replyMessageData?.messageType === "interactive" ||
                    replyMessageData?.messageType === "template") &&
                    header &&
                    header.video)) && (
                  <div className="d-flex flex-row align-items-center">
                    <i
                      data-testid="reply-message-video-icon"
                      className="bi bi-play-btn me-3"
                    ></i>
                    {(replyMessageData?.messageType === "interactive" ||
                      replyMessageData?.messageType === "template") &&
                      header &&
                      header.video && (
                        <div>{t("AddNewProduct.Title.Video")}</div>
                      )}
                  </div>
                )}
                <div className="d-flex flex-row align-items-center">
                  {(replyMessageData.messageType === "document" ||
                    ((replyMessageData?.messageType === "interactive" ||
                      replyMessageData?.messageType === "template") &&
                      header &&
                      header.document)) && (
                    <i
                      data-testid="reply-message-document-icon"
                      className="bi bi-file-earmark-text me-3"
                    ></i>
                  )}
                  {((replyMessageData.messageType === "document" &&
                    replyMessageData.textContent.length === 0) ||
                    ((replyMessageData?.messageType === "interactive" ||
                      replyMessageData?.messageType === "template") &&
                      header &&
                      header.document)) && (
                    <div data-testid="reply-message-document-filename">
                      {(replyMessageData?.messageType === "interactive" ||
                        replyMessageData?.messageType === "template") &&
                      header
                        ? header.filename
                          ? header.filename
                          : t("HandledCustomer.Attachment.Document")
                        : replyMessageData.filename}
                    </div>
                  )}
                </div>
                {replyMessageData.messageType === "location" && (
                  <div>
                    <img
                      alt=""
                      src={toAbsoluteUrl(
                        "/media/icons/duotune/general/gen018.svg"
                      )}
                    />
                  </div>
                )}
                {replyMessageData.textContent !== "" &&
                  replyMessageData?.messageType !== "interactive" &&
                  replyMessageData?.messageType !== "template" && (
                    <div
                      data-testid="reply-message-text-content"
                      dangerouslySetInnerHTML={{
                        __html: convertTextStyle(
                          replyMessageData.textContent &&
                            replyMessageData.textContent.length > 200
                            ? isTabletOrMobile
                              ? replyMessageData.textContent.substring(0, 50) +
                                "..."
                              : replyMessageData.textContent.substring(0, 150) +
                                "..."
                            : replyMessageData.textContent
                        ).replaceAll("\n", "<br/>"),
                      }}
                    ></div>
                  )}
                {!replyMessageData.textContent &&
                  replyMessageData.messageType === "image" && (
                    <div>{t("AddNewProduct.Title.Photo")}</div>
                  )}
                {!replyMessageData.textContent &&
                  replyMessageData.messageType === "video" && (
                    <div>{t("AddNewProduct.Title.Video")}</div>
                  )}
                {!replyMessageData.textContent &&
                  replyMessageData.messageType === "location" && (
                    <div>{t("HandledCustomer.Attachment.Location")}</div>
                  )}
                {replyMessageData.messageType === "audio" && (
                  <div>
                    <i
                      data-testid="reply-message-audio-icon"
                      className="bi bi-mic-fill me-3"
                    ></i>
                    {t("HandledCustomer.Attachment.VoiceMessage")}
                    <span>
                      {replyMessageData?.duration !== ""
                        ? "(" + replyMessageData?.duration + ")"
                        : ""}
                    </span>
                  </div>
                )}
                {(replyMessageData?.messageType === "interactive" ||
                  replyMessageData?.messageType === "template") && (
                  <div className="mt-2">
                    {replyMessageData.header && (
                      <div className="fw-bolder">{replyMessageData.header}</div>
                    )}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: convertTextStyle(
                          replyMessageData.textContent &&
                            replyMessageData.textContent.length > 200
                            ? isTabletOrMobile
                              ? replyMessageData.textContent.substring(0, 50) +
                                "..."
                              : replyMessageData.textContent.substring(0, 150) +
                                "..."
                            : replyMessageData.textContent
                        ).replaceAll("\\n", "<br/>"),
                      }}
                    />

                    {replyMessageData.footer && (
                      <div className="fw-lighter">
                        {replyMessageData.footer}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          {/* for image, video */}

          {(replyMessageData?.messageType === "image" ||
            ((replyMessageData?.messageType === "interactive" ||
              replyMessageData?.messageType === "template") &&
              header &&
              header.image)) && (
            <div className="d-flex">
              <img
                alt=""
                data-testid="reply-message-image-view"
                src={
                  header && header.image
                    ? header.image
                    : replyMessageData?.mediaUrl
                }
                // className="h-100 w-100"
                height={"75px"}
                width={"75px"}
                style={{ maxHeight: "75px", maxWidth: "75px" }}
              />
            </div>
          )}
          {(replyMessageData?.messageType === "video" ||
            ((replyMessageData?.messageType === "interactive" ||
              replyMessageData?.messageType === "template") &&
              header &&
              header.video)) && (
            <div className="d-flex">
              <video
                data-testid="reply-message-video-view"
                src={
                  header && header.video
                    ? header.video
                    : replyMessageData?.mediaUrl
                }
                // className="h-100 w-100"
                height={"75px"}
                width={"75px"}
                style={{ maxHeight: "75px", maxWidth: "75px" }}
              ></video>
            </div>
          )}
          {replyMessageData?.messageType === "location" && (
            <div className="d-flex">
              <a
                href={`https://maps.google.com/maps?q=${replyMessageData.location?.latitude},${replyMessageData.location?.longitude}&z=17`}
              >
                <div>
                  <MessageLocation
                    item={replyMessageData}
                    width="75px"
                    height="75px"
                  />
                </div>
              </a>
            </div>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

export default ChatMessageReplyView;
