import { DocumentReference } from "@firebase/firestore-types";
import { toAbsoluteUrl, Response } from "../../resources/helpers";

interface Option {
  value: string;
  label: string;
  date?: number;
}

interface MarketplaceCustomer {
  accountId: string;
  roomId: string;
  name: string;
  userSearchKey: string;
}

interface Customer {
  friendListID?: string; //id of friendList collection in users
  friendId?: string; //friend user id
  id?: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  avatar?: string;
  email?: string;
  phoneNumber?: string;
  address?: string;
  streetName?: string;
  district?: string;
  city?: string;
  province?: string;
  country?: string;
  state?: string;
  zipcode?: string;
  client?: DocumentReference;
  companies?: DocumentReference[];
  companyName?: string;
  companyAvatar?: string;
  lastInteractionAgent?: string;
  lastInteractionChannel?: string;
  lastInteractionAt?: Date;
  isActive?: boolean;
  broadcastFailedCounter?: number;
  occupation?: string;
  birthdate?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  maritalStatus?: string;
  numberOfChildren?: number;
  isBroadcastConsent?: string;
  role?: string;
  selectedMaritalStatus?: {};
  selectedGender?: {};
  selectedCountry?: {};
  initials?: {
    label: string;
    state: string;
  };
  facebook?: string[];
  selectedFacebook?: Option[];
  followFacebook?: Date;
  instagram?: string[];
  selectedInstagram?: Option[];
  followInstagram?: Date;
  tiktok?: string[];
  selectedTiktok?: Option[];
  followTiktok?: Date;
  shopee?: MarketplaceCustomer[];
  selectedShopee?: Option[];
  followShopee?: Date;
  tokopedia?: MarketplaceCustomer[];
  selectedTokopedia?: Option[];
  followTokopedia?: Date;
  lazada?: MarketplaceCustomer[];
  selectedLazada?: Option[];
  followLazada?: Date;
  bukalapak?: string[];
  selectedBukalapak?: Option[];
  followBukalapak?: Date;
  blibli?: string[];
  selectedBlibli?: Option[];
  followBlibli?: Date;
  firstNameInsensitive?: string;
}

export const CustomerField: Customer = {
  firstName: "",
  lastName: "",
  avatar: "",
  id: "",
  gender: "",
  phoneNumber: "",
};

export const initialContact: Customer = {
  avatar: toAbsoluteUrl("/media/icons/avatar/def-avatar.png"),
  firstName: "",
  email: "",
  // role: '',
  phoneNumber: "",
};

export interface itemCSV {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  createdAt: Date;
  updatedAt: Date;
  imported: boolean;
  company: DocumentReference;
}

export type ContactsQueryResponse = Response<Customer[]>;

export let customerField = {} as Customer;

export default Customer;
