import { getMessaging, getToken, isSupported } from "firebase/messaging";
import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../resources/helpers";
import "../../../../styles/css/requestnotification.css";
import * as lc from "../../../modules/localstorage";
import db from "../../../../db";
import firebase from "firebase/compat/app";
import { useTheme } from "../../../../setup/theme/useTheme";
import * as UserService from "src/db/serviceUser";
import browserDetector from "src/util/browserDetect";

const NotificationPermissionPrompt = () => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const currentTheme = useTheme();

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        console.log(`Received message from service worker: ${event.data}`);
        const notificationProps = event.data.data;
        const title = notificationProps.title;
        const options: NotificationOptions = {
          icon: "/media/logos/png-Icon-Logo.ico",
          badge: "/media/logos/png-Icon-Logo.ico",
          body: notificationProps.body,
        };
        new Audio("/media/misc/audio/notif.wav").play();
        new Notification(title, options);
      });
    }
  }, []);

  useEffect(() => {
    if (Notification.permission === "granted") {
      let notificationToken = lc.getItemLC(lc.LCName.NotificationToken);
      if (notificationToken === null) {
        (async () => {
          try {
            const messaging = getMessaging();
            const tokenSupported = await isSupported();
            if (!tokenSupported || browserDetector() === "safari") {
              console.log("Firebase Messaging is not supported in this browser.");
              return;
            }
            
            const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_WEB_PUSH_CERTIFICATE });
            lc.setItemLC(lc.LCName.NotificationToken, currentToken);
            lc.setItemLC(lc.LCName.isHaveNotif, true);
          } catch (error) {
            console.error("Error getting notification token: ", error);
          }
        })();
      }
    } else if (Notification.permission === "default" || Notification.permission === "denied") {
      lc.removeNotification();
    }
  }, []);

  useEffect(() => {
    navigator.permissions.query({ name: "notifications" }).then((permissionStatus) => {
      permissionStatus.onchange = () => {
        if (permissionStatus.state === "granted") {
          if (isLoggedIn) {
            let userID = lc.getItemLC(lc.LCName.UserID);
            let notificationToken = lc.getItemLC(lc.LCName.NotificationToken);
            db.collection("users").doc(userID).update({
              firebaseNotificationToken: notificationToken,
            });
          }
        } else if (permissionStatus.state === "denied") {
          lc.removeNotification();
        } else {
          lc.setItemLC(lc.LCName.isHaveNotif, false);
          lc.removeLC(lc.LCName.NotificationToken);
        }
      };
    });
  }, [isLoggedIn]);

  useEffect(() => {
    const isUserLoggedIn = lc.getItemLC(lc.LCName.UserID);
    if (isUserLoggedIn !== null) {
      setIsLoggedIn(true);
      let firebaseNotificationToken = lc.getItemLC(lc.LCName.NotificationToken);
      let userID = lc.getItemLC(lc.LCName.UserID);
      db.collection("users").doc(userID).update({
        firebaseNotificationToken,
      });
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleAllowNotification = () => {
    setShowPrompt(false);
    Notification.requestPermission().then(async (permission) => {
      if (permission === "granted") {
        lc.setItemLC(lc.LCName.isHaveNotif, true);
        try {
          const messaging = getMessaging();
          const tokenSupported = await isSupported();
          if (!tokenSupported || browserDetector() === "safari") {
            console.log("Firebase Messaging is not supported in this browser.");
            return;
          }
          const notificationToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_WEB_PUSH_CERTIFICATE });
          const userId = lc.getItemLC(lc.LCName.UserID);
          await UserService.createUpdate(userId, { notificationToken: notificationToken });
          lc.setItemLC(lc.LCName.NotificationToken, notificationToken);
        } catch (err) {
          console.log("Token Failed To Retrieve", err);
        }
      } else if (permission === "denied") {
        lc.removeNotification();
      }
    });
  };

  const handleMaybeNotification = () => {
    setShowPrompt(false);
  };

  return showPrompt ? (
    <div id="onesignal-popover-container" className="onesignal-popover-container onesignal-reset slide-down">
      <div id="onesignal-popover-dialog" className="onesignal-popover-dialog">
        <div id="normal-popover">
          <div className="popover-body d-flex flex-row align-items-center">
            <div>
              <img alt="notification icon" className="mw-100px h-70px" src={toAbsoluteUrl(currentTheme.urlLogo)} />
            </div>
            <div className="popover-body-message">{currentTheme.name} would like to show you notifications for messages, mentions, etc. </div>
            <div className="clearfix"></div>
          </div>
          <div className="popover-footer">
            <button id="onesignal-popover-allow-button" className="align-right primary popover-button" onClick={handleAllowNotification}>
              Allow
            </button>
            <button id="onesignal-popover-cancel-button" className="align-right secondary popover-button" onClick={handleMaybeNotification}>
              Maybe later
            </button>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default NotificationPermissionPrompt;
