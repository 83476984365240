import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../setup/theme/useTheme";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { RootState } from "../../../../setup/redux/store";
import moment from "moment";
import { resendEmail } from "../../../../api/server/registration";
import * as RegisterRedux from "../redux/RegisterSlice";

export const EmailVerification = () => {
  const { t } = useTranslation();
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [resend, setResend] = useState<number>(0);
  const [resendDate, setResendDate] = useState<any>(null);
  const dispatch = useDispatch();
  const currentTheme = useTheme();
  const registerState = useSelector((state: RootState) => state.Registration);
  useEffect(() => {
    if (
      !registerState ||
      registerState?.userfullname === "" ||
      registerState?.email === "" ||
      registerState?.password === ""
    ) {
      nav("/auth");
    }
  }, [registerState]);

  const handleresend = async () => {
    await resendEmail(
      registerState.email,
      String(registerState?.uid),
      registerState.userfullname,
      async (responseCode: any, response: any) => {
        console.log(response, "ini response resend");
        if (response) {
          dispatch(RegisterRedux.setVerifyExpired(response?.dateFormatted));
          setResend(resend + 1);
          setResendDate(moment(new Date()).format("DD MMMM YYYY, HH:mm"));
        }
      }
    );
  };

  console.log(registerState.verifyExpiredAt, "_state");

  return (
    <div
      className={!isMobileView ? "d-flex p-6" : ""}
      style={{
        backgroundColor: "#EDEFF4",
        height: "100vh",
      }}
      data-testid="emaiverif-page"
    >
      <div
        className="w-100 h-100 d-flex align-items-center justify-content-center"
        style={{
          backgroundColor: "#ffffff",
          // flex: 1,
          borderRadius: "15px",
          overflow: "hidden",
        }}
      >
        <div
          className="w-50 h-75 d-flex flex-column align-items-center justify-content-center"
          style={{
            backgroundColor: "#ECFDFF",
            borderRadius: "15px",
            overflow: "hidden",
          }}
        >
          <img
            src="/media/images/envelope-paper-fill.png"
            style={{
              height: "25%",
            }}
          />
          <h3 className="pt-10">{t("Register.Verifyemail.Title")}</h3>
          <p
            data-testid="user-email"
            className="pt-7 w-75 text-center"
            dangerouslySetInnerHTML={{
              __html: `<p>${t("Register.Verifyemail.Info")
                .replace(
                  "<<email>>",
                  `<span style="color: #0275d8 !important">${registerState.email}</span>`
                )
                .replace(
                  "<<date>>",
                  `<span style="color: #0275d8 !important">${registerState?.verifyExpiredAt}</span>`
                )}</p>`,
            }}
          ></p>
          <h6 className="pt-10">
            {t("Register.Verifyemail.NotReceiveEmail")}
            <span
              onClick={() => {
                handleresend();
              }}
              className="text-primary cursor-pointer"
            >
              {t("Register.Verifyemail.Resend")}
            </span>
          </h6>
          {resend > 0 && (
            <p>
              {t("Register.Verifyemail.ResendInfo")}
              {resendDate}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
