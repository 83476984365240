import clsx from "clsx";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import { useQuery } from "react-query";
import { useMediaQuery } from "react-responsive";
import * as XLSX from "xlsx-js-style";
import * as actions from "../../../../../actions/actions";
import { createRef } from "../../../../../db/connection";
import { getBrandByNameInsensitive } from "../../../../../db/serviceBrand";
import {
  getProductBySkuOrParentSkuOrVariantSku,
  getShipmentChildren,
  getShipmentParent,
} from "../../../../../db/serviceProduct";
import {
  getAllChildParentCategoryById,
  getMarketplaceProductCategoryById,
  getMarketplaceProductCategoryByMarketplace,
} from "../../../../../db/serviceStorefront";
import { toAbsoluteUrl } from "../../../../../resources/helpers";
import Loading from "../../../../../resources/helpers/components/Loading";
import * as fileUtil from "../../../../../util/FileUtil";
import Account from "../../../../models/Account";
import { UserCompanies } from "../../../../models/User";
import Company from "../../../../models/Company";
import {
  ImportProduct,
  ImportProductTemplate,
  Variant,
  VariantOption,
  excelStyle,
} from "../../../../models/Product";
import Shipment from "../../../../models/Shipment";
import * as lc from "../../../../modules/localstorage";
import { getAccessibleAccount } from "../../../../../db/serviceAccount";
// import fs from 'fs';

type ModalImportProductProp = {
  id: string;
  title?: string;
  subtitle?: string;
  body?: string | JSX.Element;
  onCancel?: () => void;
  onConfirm?: (responseSubmit: any) => void;
  confirmOption?: string;
  cancelOption?: string;
  isModalOpen?: boolean;
};

const ModalImportProduct: React.FC<ModalImportProductProp> = ({
  id,
  title,
  subtitle,
  body,
  onCancel,
  cancelOption,
  onConfirm,
  confirmOption,
  isModalOpen = false,
  ...props
}) => {
  // State
  const [isLoading, setIsLoading] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const [csvFile, setCsvFile] = useState<File>();
  const [showErrorMessage, setErrorMessage] = useState<string>();
  const [isHaveFile, setIsHaveFile] = useState(true);

  const useruid = lc.getItemLC(lc.LCName.UserID);
  let companyList: Company[] = lc.getItemLC("CompanyList");
  console.log(`company List ${JSON.stringify(companyList)}`);
  const refUser = createRef("users", useruid);

  const { data: companiesWithAccount, isLoading: accountLoading } = useQuery(
    ["active-accounts", companyList],
    () => getCompanyAndAccount()
  );
  const [selectedAccounts, setSelectedAccounts] = useState<Account[]>([]);

  const removeCsvFile = () => {
    // console.log("Remove CSV File");
    setCsvFile(undefined);
  };
  const handleImportFileChange = async (event: any) => {
    console.log("Onchange file");
    event.preventDefault();

    if (event.target.files && event.target.files[0]) {
      // console.log("File Import Selected");
      console.log(event.target.files[0]);
      setCsvFile(event.target.files[0]);
    }
    event.target.value = null;
  };
  const handleTemplateDownload = async () => {
    setIsLoading(true);
    const blobFile = await generateExampleFile();

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blobFile);
      link.setAttribute("href", url);
      link.setAttribute("download", "template-import-product.xlsx");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    setIsLoading(false);
  };

  const handleSave = async () => {
    // setIsLoading(true);
    console.log(`handle save csvFile`, csvFile);
    if (!csvFile) {
      //show error if file not exists
      // setIsLoading(false);
      return;
    }

    const checkFileType = fileUtil.validateFile(csvFile, {
      fileType: [
        "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "vnd.ms-excel",
      ],
    });
    if (checkFileType) {
      console.log(`checkFileType | ${checkFileType}`);
      // setIsLoading(false);
      return;
    }

    const readFileResult = await fileUtil.read_csv_as_json<ImportProduct>(
      csvFile
    );

    if (readFileResult.error) {
      setErrorMessage(readFileResult.error);
      return;
    }

    if (readFileResult.result) {
      const { header, data, description, worksheet } = readFileResult.result;
      // setIsLoading(true);
      console.log(
        `onReadFinished sliced ${data.length} ${JSON.stringify(data)} `
      );
      console.log(`onReadFinished ${header.length} ${JSON.stringify(header)} `);
      console.log(
        `onReadFinished ${header.length} ${JSON.stringify(description)} `
      );
      console.log(
        `onReadFinished ${header.length} ${JSON.stringify(worksheet)} `
      );

      const validate = await validateCsvData(data, header);
      console.log(
        `valideate return before firebase ${JSON.stringify(validate)}`
      );

      if (validate && validate?.errors.length !== 0) {
        if (
          validate.errors[0] === t("ImportProduct.Error.XLSX.MissingHeaders")
        ) {
          setErrorMessage(
            `${t("ImportProduct.Error.XLSX.MissingHeaders")} & ${t(
              "ImportProduct.ErrorAlert.Redownload"
            )}`
          );
          // setIsLoading(false);
          return;
        }
        // handel delete ekspedisi
        // const deletedExpedision = validate.validatedCsvData
        const importProducts = validate.validatedCsvData.map((item) => {
          const { expeditions, variantData, serverLog, brand, ...rest } = item;
          return rest;
        });
        console.log(
          `importProduct delete expeditions ${JSON.stringify(importProducts)}`
        );
        // TRY
        // generic util
        const createUrl = fileUtil.write_json_to_sheet(
          importProducts,
          description,
          { header: header },
          worksheet
        );
        console.log(`generated url ${createUrl}`);
        const downloadLink = document.createElement("a");
        if (createUrl) {
          downloadLink.href = createUrl;
          downloadLink.download = "ImportProductLog.xlsx";
          downloadLink.click();
        }

        setErrorMessage(
          `Something Error in validation CSV , please cek the log in CSV`
        );
        // setIsLoading(false);
        return;
      }

      let dataWithVariant: ImportProduct[] = [];

      if (validate && validate.validatedCsvData) {
        const { data, errors } = mapImportedProductVariant(
          validate.validatedCsvData
        );
        if (errors.length > 0) {
          const importProducts: ImportProduct[] = validate.validatedCsvData.map(
            (item) => {
              const log = errors.find((error) =>
                error.parentSKU
                  ? `${error.parentSKU}` === `${item.parentSKU}`
                  : `${error.variantSKU}` === `${item.variantSKU}`
              );
              const { expeditions, variantData, serverLog, brand, ...rest } =
                item;
              return { ...rest, log: log?.error || "" };
            }
          );
          console.log(
            `importProduct delete expeditions ${JSON.stringify(importProducts)}`
          );
          // TRY
          // generic util
          const createUrl = fileUtil.write_json_to_sheet(
            importProducts,
            description,
            { header: header },
            worksheet
          );
          console.log(`generated url ${createUrl}`);
          const downloadLink = document.createElement("a");
          if (createUrl) {
            downloadLink.href = createUrl;
            downloadLink.download = "ImportProductLog.xlsx";
            downloadLink.click();
          }

          setErrorMessage(
            `Something Error in validation CSV , please cek the log in CSV`
          );
          // setIsLoading(false);
          return;
        }

        dataWithVariant = data;
      }

      // Upload to firebase
      if (validate && validate.validatedCsvData && dataWithVariant.length > 0) {
        const convToCsv = [...dataWithVariant]
          ? parseCSVToData(dataWithVariant)
          : [];
        console.log(`data to update parsed ${JSON.stringify(convToCsv)}`);
        const uploadImportToFirebase = await actions.importDataProduct(
          convToCsv,
          refUser,
          selectedAccounts
        );
        console.log(
          `upload Import To firebase handle save${JSON.stringify(
            uploadImportToFirebase.logArray
          )}`
        );
        console.log(
          `upload Import To firebase handle LOG : ${JSON.stringify(
            uploadImportToFirebase
          )}`
        );

        console.log(
          `valideate return after firebase ${JSON.stringify(validate)}`
        );

        const afterFirebaseValidate: ImportProduct[] =
          validate.validatedCsvData.map((dataImp) => {
            const prodServerLog = uploadImportToFirebase.dataProductLog.find(
              (data) => data.product.parentSKU === dataImp.parentSKU
            );
            return {
              ...dataImp,
              serverLog: prodServerLog?.log || "",
            };
          });
        console.log(
          `afterFirebaseValidate return ${JSON.stringify(
            afterFirebaseValidate
          )}`
        );

        if (afterFirebaseValidate) {
          const importProducts = afterFirebaseValidate.map((item) => {
            const { expeditions, variantData, brand, ...rest } = item;
            return rest;
          });
          console.log(
            `importProduct delete expeditions ${JSON.stringify(importProducts)}`
          );
          // TRY
          // genertic util
          const createUrl = fileUtil.write_json_to_sheet(
            importProducts,
            description,
            { header: header },
            worksheet
          );
          console.log(`generated url ${createUrl}`);
          const downloadLink = document.createElement("a");
          if (createUrl) {
            downloadLink.href = createUrl;
            downloadLink.download = "ImportProductLog.xlsx";
            downloadLink.click();
          }
          if (uploadImportToFirebase.errorArray.length !== 0) {
            setErrorMessage(
              `Something Error in validation CSV , please cek the log in CSV`
            );
            // setIsLoading(false);
            return;
          }
          onConfirm && onConfirm(createUrl);
        }
      }
      // setIsLoading(false);
    }

    // setIsLoading(false);
  };

  const onChangeAccountCheck = (checked: boolean, account: Account) => {
    setSelectedAccounts((prev) => {
      const filtered = prev.filter((item) => item.id !== account.id);
      if (checked) {
        return [...filtered, account];
      }
      return filtered;
    });
  };

  useEffect(() => {
    if (!csvFile) return;

    //Check File Type
    const tempArrFileType: string = csvFile.type;
    const arryFileType = tempArrFileType.split("/");
    console.log(`FileType : ${arryFileType}`);

    // Check File Extension
    if (
      arryFileType === undefined ||
      arryFileType.length < 1 ||
      arryFileType[0] === undefined ||
      (arryFileType[1] !==
        "vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
        arryFileType[1] !== "vnd.ms-excel")
    ) {
      console.log(`${t("HC.Error.InvalidFiletype")}`);
      setErrorMessage(`${t("HC.Error.InvalidFiletype")}`);
      removeCsvFile();
      return;
    }

    setErrorMessage(``);
  }, [csvFile]);

  console.log("selectedAccounts", selectedAccounts);

  return (
    <div
      {...props}
      hidden={!isModalOpen}
      className="h-100 w-100 d-flex align-items-center justify-content-center"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 99997,
        backgroundColor: "rgba(0, 0, 0, 0.15)",
      }}
      key={`${id}`}
    >
      {(isLoading || accountLoading) && <Loading />}
      {/* {<Loading />} */}

      <div id={id} className="">
        <div
          className="modal-dialog modal-dialog-centered overflow-auto"
          style={{
            width: isTabletOrMobile ? "100vw" : "50vw", // Limit the maximum width to 90% of the viewport
          }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="d-flex align-items-center">
                <div className="ms-6 d-flex flex-column justify-content-between">
                  <h3 className="modal-title align-items-start fs-3">
                    {title}
                  </h3>
                </div>
              </div>
            </div>

            <div className="modal-body">
              <div className="mb-8">
                <p className="mb-2 fs-5 required fw-bolder text-gray-700">
                  Select Accounts
                </p>
                <div
                  className="accordion accordion-flush"
                  id="accordionCompany"
                >
                  {companiesWithAccount &&
                    companiesWithAccount.map((item) => {
                      return (
                        <div
                          key={`selection-${item.company.id}`}
                          className="accordion-item"
                        >
                          <h2
                            className="accordion-header"
                            id={`heading-${item.company.id}`}
                          >
                            <button
                              className="accordion-button p-3 fs-6"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse-${item.company.id}`}
                              aria-controls={`collapse-${item.company.id}`}
                            >
                              {item.company.companyName}
                            </button>
                          </h2>
                          <div
                            id={`collapse-${item.company.id}`}
                            className="accordion-collapse collapse"
                            aria-labelledby={`heading-${item.company.id}`}
                            data-bs-parent="#accordionCompany"
                          >
                            <div className="accordion-body p-3">
                              {item.accounts.length > 0 ? (
                                item.accounts.map((account) => (
                                  <div
                                    className="form-check form-check-custom form-check-sm d-flex align-items-end"
                                    style={{ gap: "0.5rem" }}
                                    key={`account-${account.type}`}
                                  >
                                    <input
                                      type="checkbox"
                                      id={account.id}
                                      className="form-check-input"
                                      onChange={(e) => {
                                        onChangeAccountCheck(
                                          e.target.checked,
                                          account
                                        );
                                      }}
                                    />
                                    <label
                                      htmlFor={account.id}
                                      className="form-check-label fw-bold text-capitalize d-flex align-items-end"
                                      style={{ gap: "0.5rem" }}
                                    >
                                      <img
                                        style={{ width: "1.75rem" }}
                                        src={toAbsoluteUrl(
                                          `/media/logos/marketplace/${account.type.toLowerCase()}.png`
                                        )}
                                        alt={`${account.type}.png`}
                                      />
                                      {account.name ??
                                        account.businessName ??
                                        account.type}
                                    </label>
                                  </div>
                                ))
                              ) : (
                                <div className="text-muted">
                                  No Active Account
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              {!isHaveFile ? (
                <div className="mb-8">
                  <p className="mb-3 fs-6 text-gray-700">
                    {t("ImportProduct.Info.Don'tHaveXLSXFile.Download")}
                  </p>

                  <button
                    id="download-template"
                    className={clsx("btn btn-primary px-8")}
                    onClick={handleTemplateDownload}
                    data-testid="download-target-csv"
                  >
                    <i className="bi bi-download"></i>
                    <span>{t("ImportProduct.Buttown.DownloadXlsx")}</span>
                  </button>
                </div>
              ) : null}

              <div className={clsx(isHaveFile ? "pb-8" : "")}>
                {isHaveFile ? (
                  <p className="mb-3 fs-6 text-gray-700">
                    {t("ImportProduct.Info.HaveXLSXFile.1")}
                    <br />
                    {t("ImportProduct.Info.HaveXLSXFile.Don'tHaveFile")}
                    <span
                      data-testid="not-have-file"
                      className="text-primary cursor-pointer"
                      onClick={() => setIsHaveFile(false)}
                    >
                      {t("ImportProduct.Info.HaveXLSXFile.FollowThisStep")}
                    </span>
                  </p>
                ) : (
                  <p className="mb-3 fs-6 text-gray-700">
                    {t("ImportProduct.Info.Don'tHaveXLSXFile.Upload")}
                  </p>
                )}

                <div className="input-group border border-black rounded">
                  <div className={`d-flex align-items-center px-4 flex-grow-1`}>
                    {csvFile ? (
                      <>
                        <label className="flex-grow-1 text-gray-600 text-truncate">
                          {csvFile.name.length > 25
                            ? csvFile.name.substring(0, 25) + "..."
                            : csvFile.name}
                        </label>
                        <span
                          className="ms-3 bi bi-x-lg btn btn-sm btn-icon-danger btn-text-danger p-0"
                          onClick={() => {
                            removeCsvFile();
                            setErrorMessage("");
                          }}
                        ></span>
                      </>
                    ) : (
                      <label className="flex-grow-1 text-gray-600 text-truncate">
                        {t("Common.NoFileChosen")}
                      </label>
                    )}
                  </div>
                  <label
                    className={clsx("btn btn-secondary fs-6")}
                    data-testid="upload-target-csv"
                    id="uploadTargetCSV"
                  >
                    <span className="ms-2">
                      {t("Common.Modal.Button.Browse")}
                    </span>
                    <input
                      id="target-csv-file"
                      data-testid="target-csv-file"
                      type="file"
                      name="target-csv-file"
                      multiple={false}
                      onChange={handleImportFileChange}
                      style={{ display: "none" }}
                      accept={
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet , application/vnd.ms-excel"
                      }
                    />
                  </label>
                </div>
              </div>
              <div>
                {showErrorMessage && (
                  <div
                    id="media-invalid-photo-format"
                    style={{ color: "#F1416C" }}
                    // role="alert-select-storefront"
                    data-testid="MediaUploadErrorPhotosInvalidFormat"
                  >
                    {/* {t("AddNewProduct.Alert.Photo.Invalidformatfile")} */}
                    {showErrorMessage}
                  </div>
                )}
                {/* //show file extension error / mandatory column not exists */}
              </div>
              {/* <div className="d-flex justify-content-center flex-direction-column px-2 py-2">

              </div> */}
            </div>

            <div className="modal-footer">
              {cancelOption && (
                <button
                  data-testid="cancel-modal"
                  type="button"
                  className="btn btn-light-primary mx-2"
                  onClick={onCancel}
                >
                  {cancelOption}
                </button>
              )}
              {confirmOption && (
                <button
                  data-testid="submit-modal"
                  type="submit"
                  className="btn btn-primary mx-2"
                  disabled={!csvFile || selectedAccounts.length === 0}
                  onClick={async () => {
                    setIsLoading(true);
                    await handleSave();
                    setIsLoading(false);
                  }}
                >
                  {confirmOption}
                </button>
              )}
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalImportProduct;

export { mapImportedProductVariant, validateCsvData };
const validateCsvData = async (csvData: any[], header: any[]) => {
  let errors: string[] = [];
  let resultData: ImportProduct[] = [];

  try {
    console.log(`validate csv data ${JSON.stringify(csvData)}`);
    // Check header
    // setup header mandatory check
    const fieldNames: ImportProduct = {
      parentSKU: "ParentSKU",
      productName: "Product Name",
      productDescription: "Product Description",
      categoryId: "Product Description",
      brandName: "",
      imageURL_1: "",
      imageURL_2: "",
      imageURL_3: "",
      imageURL_4: "",
      imageURL_5: "",
      imageURL_6: "",
      imageURL_7: "",
      imageURL_8: "",
      imageURL_9: "",
      imageURL_10: "",
      isDangerousGoods: true,
      packageWeight: 0,
      packageLength: 0,
      packageWidth: 0,
      packageHeight: 0,
      hasVariant: false,
      variantCode: "",
      variantName1: "",
      variantOption1: "",
      variantImage1: "",
      variantName2: "",
      variantOption2: "",
      variantSKU: "",
      expeditionName: "",
    };

    const mandatoryHeader = Object.keys(fieldNames);
    console.log(`mandatory header ${mandatoryHeader}`);
    const dataHeader = header;
    console.log(
      `data header ${dataHeader}|| split data header ${dataHeader} || ${dataHeader.includes(
        mandatoryHeader[3]
      )} ||mand ${mandatoryHeader[3]} || mandatory length ${
        mandatoryHeader.length
      }`
    );

    for (let index = 0; index < mandatoryHeader.length; index++) {
      console.log(` Mandatory Header: ${mandatoryHeader[index]}`);
      if (!dataHeader.includes(mandatoryHeader[index])) {
        errors.push(`${mandatoryHeader[index]}`);
        console.log(`Invalid Header: ${mandatoryHeader[index]}`);
      }
    }

    if (errors.length > 0) {
      // Handle the case where mandatory headers are missing
      // For example, you can throw an error or return an error message

      // return setErrorMessage(
      //   t("ImportProduct.Error.XLSX.MissingHeaders") ${errors.join(", ")}`
      // );
      return {
        validatedCsvData: [],
        errors: [t("ImportProduct.Error.XLSX.MissingHeaders")],
      };
    }

    if (!dataHeader.includes("log")) {
      csvData.forEach((product) => (product.log = ""));
      console.log(`if no log || data header ${dataHeader}`);
    }

    if (!dataHeader.includes("serverLog")) {
      csvData.forEach((product) => (product.serverLog = ""));
      console.log(`if no log || data header ${dataHeader}`);
    }

    const validatedList = await Promise.all(
      csvData.map((product, i) => {
        const data = product;
        Object.keys(data).forEach((key) => {
          if (typeof data[key] === "string") {
            data[key] = data[key].trim();
          }
        });
        return getValidatedData(data, i, csvData);
      })
    );

    csvData = validatedList.map((data) => {
      if (data.errors) {
        errors.push(...data.errors);
      }
      // console.log("Variant Data", data.variantData);
      return data.product;
    });
    // const variantList = validatedList.map((data) => data.variantData);

    resultData = [...csvData];

    return { validatedCsvData: resultData, errors };
  } catch (error: any) {
    console.log("Validation Error", error);
    return { validatedCsvData: [], errors: ["Service Error"] };
    // Handle error if necessary
    // return { validatedCsvData: csvData, errors };
  }
};

async function getActiveShipments() {
  const parents = await getShipmentParent();
  if (!parents) {
    return undefined;
  }
  console.log("Shipment Parents", parents);
  const childs = await Promise.all(
    parents.map((parent) => getShipmentChildren(parent.id))
  );
  if (!childs || childs.length < 0) {
    return undefined;
  }

  const flatChilds = childs.reduce((acc, curr) => {
    if (!curr) return acc;
    if (acc && curr) {
      return [...acc, ...curr];
    }
    if (!acc) {
      return [...curr];
    }
  }, []);

  console.log("Shipment Childs", flatChilds);

  const parentChildMap = parents.reduce((acc, curr) => {
    if (!curr?.name) {
      return acc;
    }
    return {
      ...acc,
      [curr.name]: !flatChilds
        ? []
        : flatChilds.filter((data) => data.parentId?.id === curr.id),
    };
  }, {});

  return childs.reduce((acc, curr) => {
    if (!curr) return acc;
    if (acc && curr) {
      return [...acc, ...curr];
    }
    if (!acc) {
      return [...curr];
    }
  }, []);
}

async function generateExampleFile() {
  const shipments = await getActiveShipments();
  const shipmentNames = shipments
    ? shipments.map((shipment) => shipment.name).join("\n")
    : "";
  console.log(shipmentNames);
  const fieldNames: ImportProduct = {
    parentSKU: "ParentSKU",
    productName: "Product Name",
    productDescription: "Product Description",
    categoryId: "Product Description",
    brandName: "",
    imageURL_1: "",
    imageURL_2: "",
    imageURL_3: "",
    imageURL_4: "",
    imageURL_5: "",
    imageURL_6: "",
    imageURL_7: "",
    imageURL_8: "",
    imageURL_9: "",
    imageURL_10: "",
    isDangerousGoods: true,
    packageWeight: 0,
    packageLength: 0,
    packageWidth: 0,
    packageHeight: 0,
    hasVariant: false,
    variantCode: "",
    variantName1: "",
    variantOption1: "",
    variantImage1: "",
    variantName2: "",
    variantOption2: "",
    variantSKU: "",
    expeditionName: "",
  };

  // const headerRow = Object.keys(fieldNames);
  const headerRow = Object.keys(fieldNames).map((fieldName) => {
    const comment = { t: t(`Import.Product.${fieldName}`) }; // Set the "hidden" property to true
    return {
      v: fieldName,
      c: [comment],
      t: "s",
      s: excelStyle.styleHeader,
    };
  });

  const desc1Row = Object.keys(fieldNames).map((fieldName) => {
    const value = t(`Import.productDescription1.${fieldName}`);
    return {
      v: value,
      t: "s",
      s: excelStyle.styleDescription,
    };
  });

  const desc2Row = Object.keys(fieldNames).map((fieldName) => {
    const value = t(`Import.productDescription2.${fieldName}`);
    return {
      v: value,
      t: "s",
      s: excelStyle.styleOptional,
    };
  });

  const exampleRow = Object.keys(fieldNames).map((fieldName) => {
    let value;
    if (fieldName === "expeditionName") {
      value = shipmentNames;
    } else {
      value = t(`Import.ProductExample.${fieldName}`);
    }
    return {
      v: value,
      t: "s",
      s: excelStyle.styleDescription,
    };
  });

  const warningRow = [
    {
      v: `${t(
        `ImportProduct.Error.XLSX.Don'tDelete`
      )}                                                       |                                                             ${t(
        `ImportProduct.Error.XLSX.Don'tDelete`
      )}`,
      t: "s",
      s: excelStyle.styleHeader,
    },
  ];

  const sheetData = [headerRow, desc1Row, desc2Row, exampleRow, warningRow];
  // const sheetData = [headerRow, desc1Row, desc2Row, exampleRow];
  const sheet = XLSX.utils.aoa_to_sheet(sheetData);
  // example sheet
  const examples: any[] = [];
  // Length of  is look into translation
  for (let index = 1; index <= 7; index++) {
    const example = Object.keys(fieldNames).map((fieldName) => {
      const value = t(`Import.Example${index}.${fieldName}`);
      return {
        v: value,
        t: "s",
        s: excelStyle.styleContent,
      };
    });

    examples.push(example);
  }
  const sheetDataExample = [
    headerRow,
    desc1Row,
    desc2Row,
    exampleRow,
    warningRow,
    ...examples,
  ];
  const sheetExample = XLSX.utils.aoa_to_sheet(sheetDataExample);

  const range = XLSX.utils.decode_range(sheet["!ref"] || "");

  // Iterate through each cell in the range of the first row
  for (let col = range.s.c; col <= range.e.c; col++) {
    const cellRef = XLSX.utils.encode_cell({ r: range.s.r, c: col });
    sheet[cellRef].s = { ...sheet[cellRef].s, locked: true };
    const cell = sheet[cellRef];
    const cellExample = sheetExample[cellRef];

    // Create comment object if not already present
    if (!cell.c) {
      cell.c = {};
    }
    if (!cellExample.c) {
      cellExample.c = {};
    }

    // Set the comment to hidden for the cell
    cell.c.hidden = true;
    cellExample.c.hidden = true;
  }

  // Auto-fit columns
  const columnWidths: number[] = [];
  for (let i = range.s.c; i <= range.e.c; i++) {
    let maxCellTextLength = 0;
    for (let j = range.s.r + 1; j <= range.e.r; j++) {
      const cellRef = XLSX.utils.encode_cell({ r: j, c: i });
      const cell = sheet[cellRef];
      if (cell && cell.v) {
        const cellTextLength = cell.v.toString().length;
        maxCellTextLength = Math.max(maxCellTextLength, cellTextLength);
      }
    }
    const columnHeaderRef = XLSX.utils.encode_cell({ r: range.s.r, c: i });
    const columnHeaderCell = sheet[columnHeaderRef];
    const columnHeaderTextLength = columnHeaderCell?.v.toString().length || 0;
    const maxColumnTextLength = Math.max(
      maxCellTextLength,
      columnHeaderTextLength
    );
    columnWidths[i] = Math.min(20, Math.max(10, maxColumnTextLength + 2));
  }

  sheet["!cols"] = columnWidths.map((width) => ({ wch: width }));
  sheetExample["!cols"] = columnWidths.map((width) => ({ wch: width }));
  // make row header height 60px
  sheet["!rows"] = sheet["!rows"] || [];
  sheet["!rows"][0] = { hpx: 60 };
  sheetExample["!rows"] = sheetExample["!rows"] || [];
  sheetExample["!rows"][0] = { hpx: 60 };
  // merge row 4 of warning content into header length
  if (!sheet["!merges"]) {
    sheet["!merges"] = [];
  }
  sheet["!merges"].push({
    s: { r: 4, c: 0 },
    e: { r: 4, c: headerRow.length - 1 },
  });
  if (!sheetExample["!merges"]) {
    sheetExample["!merges"] = [];
  }
  sheetExample["!merges"].push({
    s: { r: 4, c: 0 },
    e: { r: 4, c: headerRow.length - 1 },
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(
    workbook,
    sheet,
    t("ImportProduct.XLSX.Template")
  );
  XLSX.utils.book_append_sheet(
    workbook,
    sheetExample,
    t("ImportProduct.XLSX.ExampleUpload")
  );

  const fileBuffer = XLSX.write(workbook, {
    type: "buffer",
    bookType: "xlsx",
  });
  const blob = new Blob([fileBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  return blob;
}

function parseCSVToData(data: ImportProduct[]) {
  const parsedData: ImportProduct[] = [];

  for (let i = 0; i < data.length; i++) {
    const row = data[i];

    const importProduct: ImportProduct = {
      parentSKU: row.parentSKU.toString(),
      productName: row.productName,
      productDescription: row.productDescription,
      categoryId: row.categoryId,
      imageURL_1: row?.imageURL_1 || undefined,
      imageURL_2: row?.imageURL_2 || undefined,
      imageURL_3: row?.imageURL_3 || undefined,
      imageURL_4: row?.imageURL_4 || undefined,
      imageURL_5: row?.imageURL_5 || undefined,
      imageURL_6: row?.imageURL_6 || undefined,
      imageURL_7: row?.imageURL_7 || undefined,
      imageURL_8: row?.imageURL_8 || undefined,
      imageURL_9: row?.imageURL_9 || undefined,
      imageURL_10: row?.imageURL_10 || undefined,
      isDangerousGoods:
        row.isDangerousGoods?.toString().toUpperCase() === "Y"
          ? true
          : row.isDangerousGoods?.toString().toUpperCase() === "N"
          ? false
          : undefined,
      packageWeight: row.packageWeight,
      packageLength:
        row.packageLength !== undefined ? row.packageLength : undefined,
      packageWidth:
        row.packageWidth !== undefined ? row.packageWidth : undefined,
      packageHeight:
        row.packageHeight !== undefined ? row.packageHeight : undefined,
      hasVariant:
        row.hasVariant?.toString().toUpperCase() === "Y"
          ? true
          : row.hasVariant?.toString().toUpperCase() === "N"
          ? false
          : undefined,
      variantSKU: row.variantSKU !== undefined ? row.variantSKU : undefined,
      variantName1:
        row.variantName1 !== undefined ? row.variantName1 : undefined,
      variantOption1:
        row.variantOption1 !== undefined ? row.variantOption1 : undefined,
      variantImage1:
        row.variantImage1 !== undefined ? row.variantImage1 : undefined,
      variantName2:
        row.variantName2 !== undefined ? row.variantName2 : undefined,
      variantOption2:
        row.variantOption2 !== undefined ? row.variantOption2 : undefined,
      expeditionName:
        row.expeditionName !== undefined ? row.expeditionName : undefined,
      expeditions: row.expeditions !== undefined ? row.expeditions : undefined,
      variantData: row.variantData !== undefined ? row.variantData : undefined,
      brandName: row.brandName,
      brand: row.brand !== undefined ? row.brand : undefined,
      log: row.log ? row.log : undefined,
    };

    parsedData.push(importProduct);
  }

  return parsedData;
}

async function validateImage(imageURL: string, fieldName: string) {
  console.log(
    `image url ${imageURL} | type ${typeof imageURL} | ${fileUtil.isURL(
      imageURL.toString()
    )}`
  );
  let error: string | undefined;
  if (!fileUtil.isURL(imageURL)) {
    error = `Invalid ${fieldName}`;
    return error;
  }

  const validationResult = await fileUtil.validateImage(imageURL);
  if (validationResult) {
    error = `Invalid ${fieldName} ${validationResult}`;
    return error;
  }
}

async function getValidatedData(
  product: ImportProduct,
  i: number,
  csvData: ImportProduct[]
) {
  const errorLog = [];
  const errors = [];
  const variantData: {
    variants: {
      index: number;
      name: string;
      optionList: {
        image?: string;
        option: string;
      }[];
    }[];
    productVariants: {
      isActive: boolean;
      isMainVariant: boolean;
      sku: string;
      tierIndex: number[];
    }[];
  } = {
    variants: [],
    productVariants: [],
  };

  console.log(`cek header parentSKU ${product.parentSKU}`);
  if (!product.parentSKU) {
    errors.push(`Missing parentSKU in row ${i + 1}`);
    errorLog.push(t("ImportProduct.Error.XLSX.MissingParentSKU"));
  } else if (
    `${product.parentSKU}`.length < 1 ||
    `${product.parentSKU}`.length > 50
  ) {
    errors.push(`Invalid parentSKU length in row ${i + 1}`);
    errorLog.push(t("ImportProduct.Error.XLSX.InvalidParentSKULength"));
  } else {
    // Check if parentSKU is duplicate with another product
    const isDuplicate = csvData.some((p, index) => {
      if (product.variantCode) {
        return (
          `${p.parentSKU}` === `${product.parentSKU}` &&
          index !== i &&
          `${p.variantCode}` !== `${product.variantCode}`
        );
      } else {
        return `${p.parentSKU}` === `${product.parentSKU}` && index !== i;
      }
    });

    const productSameSKU = await getProductBySkuOrParentSkuOrVariantSku(
      product.parentSKU
    );

    if (isDuplicate) {
      errors.push(`Duplicate parentSKU found in row ${i + 1}`);
      errorLog.push(
        t("ImportProduct.Error.XLSX.DuplicateParentSKU", {
          productparentSKU: product.parentSKU,
        })
      );
    }
    if (productSameSKU && productSameSKU.length > 0) {
      errors.push(
        `Invalid sku ${product.parentSKU} in row ${i + 1} already exist`
      );
      errorLog.push(
        t("ImportProduct.Error.XLSX.SKUAlreadyExist", {
          productparentSKU: product.parentSKU,
        })
      );
    }
  }

  if (!product.productName) {
    errors.push(`Missing productName in row ${i + 1}`);
    errorLog.push(t("ImportProduct.Error.XLSX.MissingProductName"));
  } else if (
    `${product.productName}`.length < 1 ||
    `${product.productName}`.length > 70
  ) {
    errors.push(`Invalid productName length in row ${i + 1}`);
    errorLog.push(t("ImportProduct.Error.XLSX.ProductNameLength"));
  }

  if (!product.productDescription) {
    errors.push(`Missing productDescription in row ${i + 1}`);
    errorLog.push(t("ImportProduct.Error.XLSX.MissingProductDescription"));
  } else if (
    `${product.productDescription}`.length < 20 ||
    `${product.productDescription}`.length > 1000
  ) {
    errors.push(`Invalid productDescription length in row ${i + 1}`);
    errorLog.push(t("ImportProduct.Error.XLSX.InvalidProductDescription"));
  } else {
    console.log(
      `des ${product.productDescription}||length des ${product.productDescription.length}`
    );
    product.productDescription = product.productDescription.replace(
      /\\n/g,
      "\n"
    );
  }

  if (!product.categoryId) {
    errors.push(`Missing categoryId in row ${i + 1}`);
    errorLog.push(`Missing CategoryId`);
  } else {
    const selectedCategory = await getMarketplaceProductCategoryById(
      "shopee",
      `${product.categoryId}`
    );
    console.log("selected marketplace product category", selectedCategory);

    // const selectedCategoryAll = await getAllChildParentCategoryById(
    //   "shopee",
    //   `${product.categoryId}`
    // );
    // console.log(
    //   "selected all marketplace product category",
    //   selectedCategoryAll
    // );
    if (!selectedCategory) {
      errors.push(`Invalid categoryId in row ${i + 1}`);
      errorLog.push(`Invalid CategoryId`);
    } else if (selectedCategory.hasChildren) {
      errors.push(
        `Invalid categoryId in row ${i + 1}, category is not the last child`
      );
      errorLog.push(`Invalid CategoryId, category is not the last child`);
    }
  }

  let errorImage;

  if (!product.imageURL_1) {
    errors.push(`Missing ImageURL_1 in row ${i + 1}`);
    errorLog.push(t("ImportProduct.Error.XLSX.MissingImage1"));
  }
  {
    errorImage = await validateImage(product.imageURL_1 || "", "imageURL_1");
    if (errorImage && product.imageURL_1 !== undefined) {
      errors.push(errorImage);
      errorLog.push(errorImage);
    }
  }
  errorImage = await validateImage(product.imageURL_2 || "", "imageURL_2");
  if (errorImage && product.imageURL_2 !== undefined) {
    errors.push(errorImage);
    errorLog.push(errorImage);
  }
  errorImage = await validateImage(product.imageURL_3 || "", "imageURL_3");
  if (errorImage && product.imageURL_3 !== undefined) {
    errors.push(errorImage);
    errorLog.push(errorImage);
  }
  errorImage = await validateImage(product.imageURL_4 || "", "imageURL_4");
  if (errorImage && product.imageURL_4 !== undefined) {
    errors.push(errorImage);
    errorLog.push(errorImage);
  }
  errorImage = await validateImage(product.imageURL_5 || "", "imageURL_5");
  if (errorImage && product.imageURL_5 !== undefined) {
    errors.push(errorImage);
    errorLog.push(errorImage);
  }
  errorImage = await validateImage(product.imageURL_6 || "", "imageURL_6");
  if (errorImage && product.imageURL_6 !== undefined) {
    errors.push(errorImage);
    errorLog.push(errorImage);
  }
  errorImage = await validateImage(product.imageURL_7 || "", "imageURL_7");
  if (errorImage && product.imageURL_7 !== undefined) {
    errors.push(errorImage);
    errorLog.push(errorImage);
  }
  errorImage = await validateImage(product.imageURL_8 || "", "imageURL_8");
  if (errorImage && product.imageURL_8 !== undefined) {
    errors.push(errorImage);
    errorLog.push(errorImage);
  }
  errorImage = await validateImage(product.imageURL_9 || "", "imageURL_9");
  if (errorImage && product.imageURL_9 !== undefined) {
    errors.push(errorImage);
    errorLog.push(errorImage);
  }
  errorImage = await validateImage(product.imageURL_10 || "", "imageURL_10");
  if (errorImage && product.imageURL_10 !== undefined) {
    errors.push(errorImage);
    errorLog.push(errorImage);
  }

  const hasDangerousGoods = product.isDangerousGoods?.toString().toUpperCase();
  console.log(`validate csv data has DangerousGoods ${hasDangerousGoods}`);
  let isDangerous: boolean | undefined;
  if (hasDangerousGoods === "Y") {
    isDangerous = true;
  } else if (hasDangerousGoods === "N") {
    isDangerous = false;
  } else {
    isDangerous = undefined;
  }

  if (product.isDangerousGoods !== undefined && isDangerous === undefined) {
    errors.push(`Invalid isDangerousGoods in row ${i + 1}`);
    errorLog.push(t("ImportProduct.Error.XLSX.InvalidDangerousGood"));
  }

  if (
    product.packageWeight === undefined ||
    isNaN(product.packageWeight) ||
    product.packageWeight <= 0 ||
    product.packageWeight > 300000
  ) {
    errors.push(`Invalid packageWeight in row ${i + 1}`);
    errorLog.push(t("ImportProduct.Error.XLSX.InvalidPackageWeight"));
  }

  if (
    product.packageLength !== undefined &&
    (isNaN(product.packageLength) ||
      product.packageLength <= 0 ||
      product.packageLength > 300)
  ) {
    errors.push(`Invalid packageLength in row ${i + 1}`);
    errorLog.push(t("ImportProduct.Error.XLSX.InvalidPackageLength"));
  }

  if (
    product.packageWidth !== undefined &&
    (isNaN(product.packageWidth) ||
      product.packageWidth <= 0 ||
      product.packageWidth > 300)
  ) {
    errors.push(`Invalid packageWidth in row ${i + 1}`);
    errorLog.push(t("ImportProduct.Error.XLSX.InvalidPackageWidth"));
  }

  if (
    product.packageHeight !== undefined &&
    (isNaN(product.packageHeight) ||
      product.packageHeight <= 0 ||
      product.packageHeight > 300)
  ) {
    errors.push(`Invalid packageHeight in row ${i + 1}`);
    errorLog.push(t("ImportProduct.Error.XLSX.InvalidPackageHeight"));
  }

  const hasVariant = product.hasVariant?.toString().toUpperCase();
  console.log(`validate csv data has variant ${hasVariant}`);
  let isVariant: boolean | undefined;
  if (hasVariant === "Y") {
    isVariant = true;
  } else if (hasVariant === "N") {
    isVariant = false;
  } else {
    isVariant = undefined;
  }
  console.log(`validate csv data isVariant ${isVariant}`);
  if (product.hasVariant !== undefined && isVariant === undefined) {
    errors.push(`Invalid hasVariant in row ${i + 1}`);
    errorLog.push(t("ImportProduct.Error.XLSX.InvalidHasVariant"));
  }

  if (isVariant) {
    if (!product.variantCode) {
      errors.push(`Invalid variantCode in row ${i + 1}`);
      errorLog.push(t("ImportProduct.Error.XLSX.InvalidVariantCode"));
    } else if (
      `${product.variantCode}`.length < 1 ||
      `${product.variantCode}`.length > 100
    ) {
      errors.push(`Invalid variantCode length in row ${i + 1}`);
      errorLog.push(t("ImportProduct.Error.XLSX.InvalidVariantCodeCharacter"));
    } else {
      // cek duplicate variantCode

      const isDuplicate = csvData.some((p, index) => {
        console.log(
          `cek param duplicae variantCode ${p.parentSKU} | ${
            product.parentSKU
          } || ${p.variantCode} | ${product.variantCode} || ${
            `${p.parentSKU}` !== `${product.parentSKU}` &&
            `${p.variantCode}` === `${product.variantCode}`
          }`
        );
        return (
          `${p.parentSKU}` !== `${product.parentSKU}` &&
          index !== i &&
          `${p.variantCode}` === `${product.variantCode}`
        );
      });

      if (isDuplicate) {
        errors.push(
          `Duplicate VariantCode already Used by another parentSKU found in row ${
            i + 1
          }`
        );
        errorLog.push(
          t("ImportProduct.Error.XLSX.DuplicateVariantCode", {
            productvariantCode: product.variantCode,
          })
        );
      }
    }

    if (!product.variantSKU) {
      errors.push(`Invalid variantSKU in row ${i + 1}`);
      errorLog.push(t("ImportProduct.Error.XLSX.InvalidSKUVariant"));
    } else if (
      `${product.variantSKU}`.length < 1 ||
      `${product.variantSKU}`.length > 50
    ) {
      errors.push(`Invalid variantSKU length in row ${i + 1}`);
      errorLog.push(t("ImportProduct.Error.XLSX.InvalidVariantCodeCharacter2"));
    } else {
      // cek duplicate variantSKU
      const isDuplicate = csvData.some((p, index) => {
        return (
          (`${p.variantSKU}` === `${product.variantSKU}` ||
            `${p.parentSKU}` === `${product.variantSKU}`) &&
          index !== i
        );
      });

      const productSameSKU = await getProductBySkuOrParentSkuOrVariantSku(
        product.variantSKU
      );

      if (isDuplicate) {
        errors.push(`Duplicate variantSKU found in row ${i + 1}`);
        errorLog.push(t("ImportProduct.Error.XLSX.DuplicateSKUVariant"), {
          productvariantSKU: product.variantSKU,
        });
      }
      if (productSameSKU && productSameSKU.length > 0) {
        errors.push(
          `Invalid variant sku ${product.variantSKU} in row ${
            i + 1
          } already exist`
        );
        errorLog.push(
          t("ImportProduct.Error.XLSX.InvalidSKUAlreadyExist", {
            productvariantSKU: product.variantSKU,
          })
        );
      }
    }

    if (!product.variantImage1) {
      errors.push(`Invalid variantName1 in row ${i + 1}`);
      errorLog.push(t("ImportProduct.Error.XLSX.InvalidVariantImage"));
    } else {
      errorImage = await validateImage(
        product.variantImage1 || "",
        `variantImage1 ${i + 1}`
      );
      if (errorImage && product.variantImage1 !== undefined) {
        errors.push(errorImage);
        errorLog.push(errorImage);
      }
    }

    if (!product.variantName1) {
      errors.push(`Invalid variantName1 in row ${i + 1}`);
      errorLog.push(t("ImportProduct.Error.XLSX.InvalidVariantName1"));
    } else if (`${product.variantName1}`.length > 14) {
      errors.push(`Invalid variantName1 length in row ${i + 1}`);
      errorLog.push(t("ImportProduct.Error.XLSX.InvalidVariantName1Character"));
    }

    if (!product.variantOption1) {
      errors.push(`Invalid variantOption1 in row ${i + 1}`);
      errorLog.push(t("ImportProduct.Error.XLSX.InvalidVariantOption1"));
    } else if (`${product.variantOption1}`.length > 20) {
      errors.push(`Invalid variantOption1 length in row ${i + 1}`);
      errorLog.push(
        t("ImportProduct.Error.XLSX.InvalidVariantOption1Character")
      );
    }

    if (
      product.variantName2 !== undefined &&
      `${product.variantName2}`.length > 14
    ) {
      errors.push(`Invalid variantName2 length in row ${i + 1}`);
      errorLog.push(t("ImportProduct.Error.XLSX.InvalidVariantName2Character"));
    }

    if (
      product.variantOption2 !== undefined &&
      `${product.variantOption2}`.length > 20
    ) {
      errors.push(`Invalid variantOption2 length in row ${i + 1}`);
      errorLog.push(
        t("ImportProduct.Error.XLSX.InvalidVariantOption2Character")
      );
    } else {
      // cek option duplicate
      const isDuplicate = csvData.some((p, index) => {
        if (product.variantOption2) {
          console.log(
            `if variant 2 exist ${
              `${p.variantOption1}` === `${product.variantOption1}` &&
              `${p.variantOption2}` === `${product.variantOption2}` &&
              index !== i &&
              `${p.variantCode}` === `${product.variantCode}`
            } ${p.variantOption1} === ${product.variantOption1} &&
            ${p.variantOption2} === ${product.variantOption2} &&
            ${p.variantCode} === ${product.variantCode}`
          );
          return (
            `${p.variantOption1}` === `${product.variantOption1}` &&
            `${p.variantOption2}` === `${product.variantOption2}` &&
            index !== i &&
            `${p.variantCode}` === `${product.variantCode}`
          );
        } else {
          return (
            `${p.variantOption1}` === `${product.variantOption1}` &&
            index !== i &&
            `${p.variantCode}` === `${product.variantCode}`
          );
        }
      });

      if (isDuplicate) {
        errors.push(`Duplicate variantOption found in row ${i + 1}`);
        errorLog.push(
          t("ImportProduct.Error.XLSX.DuplicateVariant", {
            productvariantOption1: product.variantOption1,
            productvariantOption2: product.variantOption2,
          })
        );
      }
    }
  }

  if (product.expeditionName !== undefined) {
    console.log("expeditionString", product.expeditionName);
    const expeditionNameArray = product.expeditionName
      .split(",")
      .map((string) => string.trim());
    console.log(
      `ekspedisi array validation ${JSON.stringify(expeditionNameArray)}`
    );

    const uniqueExpeditionNames = new Set(expeditionNameArray);

    if (uniqueExpeditionNames.size !== expeditionNameArray.length) {
      errors.push(`Duplicate expeditionName found in row ${i + 1}`);
      errorLog.push(t("ImportProduct.Error.XLSX.DuplicateExpeditionName"));
    }

    const importedShipment = await getImportedShipments(expeditionNameArray, i);

    if (importedShipment.error) {
      errors.push(importedShipment.error);
    }
    if (importedShipment.errorLog) {
      errorLog.push(importedShipment.errorLog);
    }

    product.expeditions =
      importedShipment.shipments.length > 0
        ? importedShipment.shipments
        : undefined;
  }

  if (!product.brandName) {
    errors.push(`Missing brandName in row ${i + 1}`);
    errorLog.push("Missing brandName");
  } else if (
    `${product.brandName}`.length < 1 ||
    `${product.brandName}`.length > 62
  ) {
    errors.push(`Invalid brandName length in row ${i + 1}`);
    errorLog.push("Invalid brandName");
  } else {
    // TODO Handle multiple variants
    const existedBrand = await getBrandByNameInsensitive(product.brandName);
    console.log(`get Brand ${JSON.stringify(existedBrand)}`);
    if (existedBrand.length > 0) {
      if (
        !existedBrand[0].marketplaceRegistered ||
        Object.keys(existedBrand[0].marketplaceRegistered).length <= 0
      ) {
        errorLog.push("Brand not registered But OK");
        console.log(`EXISTED ${product.brandName}`);
      }
      product.brand = createRef("brands", existedBrand[0].id);
    } else if (errors.length <= 0) {
      errorLog.push("Brand not registered But OK");
    }
    // } else if (errors.length <= 0) {
    //   const newBrand = await actions.createBrand(product.brandName, undefined);
    //   if (!newBrand || !newBrand.id) {
    //     errors.push(
    //       `Failed create new brand with brandName ${
    //         product.brandName
    //       } length in row ${i + 1}`
    //     );
    //     errorLog.push(
    //       `Failed create new brand with brandName ${product.brandName}`
    //     );
    //   } else {
    //     console.log(`CREATE BRAND ${product.brandName}`);
    //     product.brand = createRef("brands", newBrand.id);
    //     errorLog.push("Brand not registered");
    //   }
  }

  product.log = errorLog.length === 0 ? "OK" : errorLog.join(" | ");

  return {
    product: product as ImportProduct,
    errors,
  };
}

async function getImportedVariants(
  i: number,
  variantOption1?: string,
  variantOption2?: string,
  variantImage1?: string,
  variantName1?: string,
  variantName2?: string,
  variantSKU?: string,
  csvData?: ImportProduct[]
) {
  let errors: string[] = [];
  let errorLogs: string[] = [];
  let imageVariantsMap: {
    option: string;
    url: string;
  }[] = [];
  let variantDataList: {
    index: number;
    name: string;
    optionList: {
      image?: string;
      option: string;
    }[];
  }[] = [];
  let productVariantList: {
    isActive: boolean;
    isMainVariant: boolean;
    sku: string;
    tierIndex: number[];
  }[] = [];

  const variantOption1Array = variantOption1?.split(",");
  const variantImage1Array = variantImage1?.split(",");
  const variantOption2Array = variantOption2?.split(",");

  if (variantOption1 !== undefined && variantOption1Array?.length === 0) {
    errors.push(`Invalid variantOption1 in row ${i + 1}`);
    errorLogs.push("Invalid variantOption1");
  }

  if (variantOption1Array && variantOption1Array.length > 0) {
    if (!variantOption2Array) {
      if (variantOption1Array.length > 50) {
        errors.push(`Invalid variantOption1 length in row ${i + 1}`);
        errorLogs.push("Invalid variantOption1 length (max 50)");
      }
    } else if (variantOption2Array.length === 0) {
      errors.push(`Invalid variantOption2 in row ${i + 1}`);
      errorLogs.push("Invalid variantOption2");
    } else {
      const combinationLength =
        variantOption1Array.length * variantOption2Array.length;
      if (combinationLength > 50) {
        errors.push(`Invalid combination length in row ${i + 1}`);
        errorLogs.push("Invalid combination length (max 50)");
      }
    }

    const uniqueVariants = new Set(variantOption1Array);
    if (uniqueVariants.size !== variantOption1Array.length) {
      errors.push(`Duplicate variantOption1 found in row ${i + 1}`);
      errorLogs.push("Duplicate variantOption1");
    }
  }

  if (variantOption2Array) {
    const uniqueVariantOptions2 = new Set(variantOption2Array);
    if (uniqueVariantOptions2.size !== variantOption2Array.length) {
      errors.push(`Duplicate variantOption2 found in row ${i + 1}`);
      errorLogs.push("Duplicate variantOption2");
    }
  }

  if (
    variantOption1 !== undefined &&
    variantImage1 !== undefined &&
    variantOption1Array?.length !== variantImage1Array?.length
  ) {
    errors.push(`Invalid variantOption1 and variantImage1 in row ${i + 1}`);
    errorLogs.push("Invalid, all variantOption1 must have variantImage1");
  }

  if (variantImage1 !== undefined && variantImage1Array) {
    const filteredArray = variantImage1Array.filter(
      (imageUrl) => imageUrl !== undefined
    );
    await Promise.all(
      filteredArray.map(async (varImageStr, index) => {
        const selectedVariantRgx = varImageStr.match(/{{(.*?)}}/);
        if (
          !selectedVariantRgx ||
          !selectedVariantRgx[0] ||
          !selectedVariantRgx[1]
        ) {
          errors.push(`Invalid variantImage format ke-${index}`);
          errorLogs.push(`Invalid variantImage format ke-${index}`);
          return;
        }
        const selectedVariantOption = selectedVariantRgx[1];
        if (
          variantOption1Array &&
          variantOption1Array.length > 0 &&
          !variantOption1Array?.includes(selectedVariantOption)
        ) {
          errors.push(`Invalid variantImage format ke-${index}`);
          errorLogs.push(`Invalid variantImage format ke-${index}`);

          return;
        }
        const imageUrl = varImageStr.slice(
          varImageStr.indexOf(selectedVariantRgx[0]) +
            selectedVariantRgx[0].length
        );
        if (!imageUrl) {
          errors.push(`Invalid variantImage format ke-${index}`);
          errorLogs.push(`Invalid variantImage format ke-${index}`);
          return;
        }
        console.log(`index variant image ${index} || imageUrl ${imageUrl}`);
        const errorImage = await validateImage(
          imageUrl || "",
          `variantImage1 ke-${index}`
        );
        if (errorImage) {
          errors.push(errorImage);
          errorLogs.push(errorImage);
          return;
        }
        imageVariantsMap.push({
          option: selectedVariantOption,
          url: imageUrl,
        });
      })
    );
  }

  if (!variantSKU) {
    errors.push(`Invalid variantSKU in row ${i + 1}`);
    errorLogs.push("Invalid variantSKU");
  }

  if (variantSKU && variantOption1Array && variantOption1Array.length > 0) {
    const arraySKU = variantSKU.split(",");
    if (
      variantOption2Array &&
      variantOption2Array.length > 0 &&
      arraySKU.length !==
        variantOption1Array.length * variantOption2Array.length
    ) {
      errors.push(`Invalid variantSKU in row ${i + 1}`);
      errorLogs.push(
        "Invalid variantSKU, all combination variant options must have variantSKU"
      );
    }
    await Promise.all(
      arraySKU.map(async (strSku, index) => {
        const skuMap = parseVariantSku(
          strSku,
          variantOption1Array,
          variantOption2Array
        );

        if (!skuMap) {
          errors.push(`Invalid variantSKU format ke-${index} row ${i + 1}`);
          errorLogs.push(`Invalid variantSKU format ke-${index} row ${i + 1}`);
          return;
        }

        const isDuplicate = csvData?.some((p, rowData) => {
          if (p.parentSKU === skuMap.sku && rowData !== i) {
            return true;
          }

          const arrSKUDuplicate = p.variantSKU?.split(",");
          const arrOption1Duplicate = p.variantOption1?.split(",");
          const arrOption2Duplicate = p.variantOption2?.split(",");
          if (!arrOption1Duplicate) {
            return false;
          }
          const cekDuplicate = arrSKUDuplicate?.map((data) => {
            const skuDuplicate = parseVariantSku(
              data,
              arrOption1Duplicate,
              arrOption2Duplicate
            );
            return skuDuplicate?.sku;
          });

          if (cekDuplicate?.includes(skuMap.sku)) {
            if (rowData === i) {
              if (cekDuplicate.filter((sku) => sku === skuMap.sku).length > 1) {
                return true;
              }
              return false;
            }
            return true;
          }
          return false;
        });

        const productSameSKU = await getProductBySkuOrParentSkuOrVariantSku(
          skuMap.sku
        );

        if (isDuplicate) {
          errors.push(`Invalid variantSKU ${skuMap.sku} Duplicate`);
          errorLogs.push(`Invalid variantSKU ${skuMap.sku} Duplicate`);
          return;
        }

        if (productSameSKU && productSameSKU.length > 0) {
          errors.push(`Invalid sku ${skuMap.sku} already exist`);
          errorLogs.push(`Invalid sku ${skuMap.sku} already exist`);
          return;
        }

        if (
          variantOption2Array &&
          variantOption2Array.length &&
          skuMap.option2
        ) {
          productVariantList.push({
            isActive: true,
            isMainVariant: index === 0,
            tierIndex: [
              variantOption1Array.indexOf(skuMap.option1),
              variantOption2Array.indexOf(skuMap.option2),
            ],
            sku: skuMap.sku,
          });
        } else {
          productVariantList.push({
            isActive: true,
            isMainVariant: index === 0,
            tierIndex: [variantOption1Array.indexOf(skuMap.option1)],
            sku: skuMap.sku,
          });
        }
      })
    );
  }

  if (variantName1 && imageVariantsMap && imageVariantsMap.length > 0) {
    variantDataList.push({
      index: 0,
      name: variantName1,
      optionList: imageVariantsMap.map((data) => ({
        image: data.url,
        option: data.option,
      })),
    });
  }

  if (variantName2 && variantOption2Array && variantOption2Array.length > 0) {
    variantDataList.push({
      index: 1,
      name: variantName2,
      optionList: variantOption2Array.map((option) => ({
        option: option,
      })),
    });
  }

  return {
    errors,
    errorLogs,
    productVariantList,
    variantDataList,
  };
}

async function getImportedShipments(expeditions: string[], row: number) {
  let shipments: Shipment[] = [];
  let error = "";
  let errorLog = "";
  let errorFlag: string[] = [];
  let errorLogFlag: string[] = [];
  await Promise.all(
    expeditions.map(async (expedition, index) => {
      console.log(
        `ini data before shipment validation ${JSON.stringify(expedition)}`
      );
      const childSipments = await actions.getDataChildShipmentsByInsName(
        expedition
      );
      console.log(
        `ini data after shipment validation ${JSON.stringify(childSipments)}`
      );

      if (childSipments.length > 0) {
        shipments.push(childSipments[0]);
        return;
      }

      const parentShipments = await actions.getDataParentShipmentsByInsName(
        expedition
      );

      if (parentShipments && parentShipments.length > 0) {
        shipments = shipments.filter(
          (data) => data.parentId?.id !== parentShipments[0].id
        );
        const corelatedChilds = await getShipmentChildren(
          parentShipments[0].id
        );
        if (corelatedChilds) {
          shipments.push(...corelatedChilds);
        }
        return;
      }

      errorFlag.push(`Invalid Expedition Name in row ${row + 1}`);
      errorLogFlag.push(
        t("ImportProduct.Error.XLSX.InvalidExpeditionName", { indexOf: index })
      );
      error = errorFlag.join(" | ");
      errorLog = errorLogFlag.join(" | ");
    })
  );

  return {
    shipments,
    error,
    errorLog,
  };
}

function parseVariantSku(
  strSku: string,
  option1: string[],
  option2?: string[]
) {
  const variantRgx = strSku.match(/{{(.*?)}}/);
  if (!variantRgx || !variantRgx[0] || !variantRgx[1]) {
    return undefined;
  }

  const variants = variantRgx[1].split("/");

  if (!option1.includes(variants[0])) {
    return undefined;
  }
  if (option2 && option2.length && !option2.includes(variants[1])) {
    return undefined;
  }

  const sku = strSku.slice(
    strSku.indexOf(variantRgx[0]) + variantRgx[0].length
  );

  if (variants.length === 1) {
    return {
      option1: variants[0],
      sku: sku,
    };
  }
  if (variants.length === 2) {
    return {
      option1: variants[0],
      option2: variants[1],
      sku: sku,
    };
  }
  return undefined;
}

function mapImportedProductVariant(importProducts: ImportProduct[]) {
  const errors: {
    parentSKU?: string;
    variantSKU?: string;
    error: string;
  }[] = [];
  let data: ImportProduct[] = [];
  importProducts.forEach((imProduct, i) => {
    const currentErrors: string[] = [];
    const row = i + 1;
    if (!imProduct.variantCode) {
      data.push(imProduct);
      return;
    }

    const parentIndex = data.findIndex(
      (imP) => `${imP.variantCode}` === `${imProduct.variantCode}`
    );
    const parentProduct = parentIndex < 0 ? imProduct : data[parentIndex];

    if (!imProduct.variantName1) {
      imProduct.variantName1 = t("ImportProduct.Error.XLSX.Invalid(empty)");
    }
    if (!imProduct.variantImage1) {
      imProduct.variantImage1 = t("ImportProduct.Error.XLSX.Invalid(empty)");
    }
    if (!imProduct.variantOption1) {
      imProduct.variantOption1 = t("ImportProduct.Error.XLSX.Invalid(empty)");
    }
    if (!imProduct.variantSKU) {
      imProduct.variantSKU = t("ImportProduct.Error.XLSX.Invalid(empty)");
    }

    if (parentProduct) {
      const whitelist = [
        "variantName1",
        "variantImage1",
        "variantOption1",
        "variantName2",
        "variantOption2",
        "variantSKU",
        "variantData",
      ];
      let errorFlagKey: string[] = [];
      Object.keys(parentProduct).forEach((key) => {
        if (whitelist.includes(key)) {
          return;
        }
        console.log(
          `${parentProduct[key as keyof typeof parentProduct]}`,
          `${imProduct[key as keyof typeof imProduct]}`
        );
        if (
          `${parentProduct[key as keyof typeof parentProduct]}` !==
          `${imProduct[key as keyof typeof imProduct]}`
        ) {
          errorFlagKey.push(key);
        }
      });
      if (errorFlagKey.length > 0)
        currentErrors.push(
          t("ImportProduct.Error.XLSX.SameVariantbutDiffrent", {
            keyHeader: errorFlagKey.join(","),
            variantCode: imProduct.variantCode,
          })
        );
    }

    console.log("currentErrors", currentErrors);

    if (currentErrors.length > 0) {
      errors.push({
        variantSKU: imProduct.variantSKU,
        error: currentErrors.join(" | "),
      });
      return;
    }

    // if (currentErrors.length > 0) {
    //   errors.push(...currentErrors);
    //   return;
    // }

    const dataOption1 = {
      option: imProduct.variantOption1,
      image: imProduct.variantImage1,
    };
    const dataVariant1 = {
      index: 0,
      name: imProduct.variantName1,
      optionList: [dataOption1],
    };

    let tierIndex = [0];

    if (parentProduct.variantData) {
      if (parentProduct.variantData.variants) {
        const existedVariant = parentProduct.variantData.variants.find(
          (variant) => variant.name === imProduct.variantName1
        );
        if (existedVariant) {
          if (existedVariant.optionList) {
            const existedOptionIndex = existedVariant.optionList.findIndex(
              (data) => data.option === imProduct.variantOption1
            );
            const existedOption = existedVariant.optionList[existedOptionIndex];
            if (existedOptionIndex < 0 || !existedOption) {
              const lengthOption = existedVariant.optionList.push(dataOption1);
              tierIndex = [lengthOption - 1];
            } else {
              tierIndex = [existedOptionIndex];
            }
          } else {
            existedVariant.optionList = [dataOption1];
          }
        } else {
          parentProduct.variantData.variants.push(dataVariant1);
        }

        if (imProduct.variantName2 && imProduct.variantOption2) {
          tierIndex = [...tierIndex, 0];
          const dataOption2 = {
            option: imProduct.variantOption2,
          };
          const dataVariant2 = {
            index: 1,
            name: imProduct.variantName2,
            optionList: [dataOption2],
          };
          const existedVariant2 = parentProduct.variantData.variants.find(
            (variant) => variant.name === imProduct.variantName2
          );
          if (existedVariant2) {
            if (existedVariant2.optionList) {
              const existedOption2Index = existedVariant2.optionList.findIndex(
                (data) => data.option === imProduct.variantOption2
              );
              const existedOption2 =
                existedVariant2.optionList[existedOption2Index];
              if (existedOption2Index < 0 || !existedOption2) {
                const lengthOption2 =
                  existedVariant2.optionList.push(dataOption2);
                tierIndex.splice(1, 1, lengthOption2 - 1);
              } else {
                tierIndex.splice(1, 1, existedOption2Index);
              }
            } else {
              existedVariant2.optionList = [dataOption2];
            }
          } else {
            parentProduct.variantData.variants.push(dataVariant2);
          }
        }
      } else {
        parentProduct.variantData.variants = [dataVariant1];
      }

      if (
        !parentProduct.variantData.variants &&
        imProduct.variantName2 &&
        imProduct.variantOption2
      ) {
        const dataOption2 = {
          option: imProduct.variantOption2,
        };
        const dataVariant2 = {
          index: 1,
          name: imProduct.variantName2,
          optionList: [dataOption2],
        };
        parentProduct.variantData.variants = [dataVariant2];
      }
    } else {
      if (imProduct.variantName2 && imProduct.variantOption2) {
        tierIndex = [0, 0];
        const dataOption2 = {
          option: imProduct.variantOption2,
        };
        const dataVariant2 = {
          index: 1,
          name: imProduct.variantName2,
          optionList: [dataOption2],
        };

        parentProduct.variantData = {
          variants: [dataVariant1, dataVariant2],
        };
      } else {
        parentProduct.variantData = {
          variants: [dataVariant1],
        };
      }
    }

    const dataProductVariant = {
      isActive: true,
      isMainVariant: tierIndex[1]
        ? tierIndex[0] === 0 && tierIndex[1] === 0
        : tierIndex[0] === 0,
      tierIndex: tierIndex,
      sku: imProduct.variantSKU,
    };

    if (
      parentProduct.variantData.productVariants &&
      parentProduct.variantData.productVariants.length > 0
    ) {
      parentProduct.variantData.productVariants.push(dataProductVariant);
    } else {
      parentProduct.variantData.productVariants = [dataProductVariant];
    }

    if (parentIndex < 0 || !parentProduct) {
      data.push(imProduct);
    } else {
      data.splice(parentIndex, 1, parentProduct);
    }
  });

  data.forEach((prod) => {
    if (prod.variantData?.variants) {
      const varCombination = prod.variantData.variants.reduce((acc, curr) => {
        if (acc === 0) {
          return curr.optionList ? curr.optionList.length : 0;
        }
        return curr.optionList ? curr.optionList.length * acc : acc;
      }, 0);

      console.log("Variant Combination", varCombination);
      console.log("Variant ProdVar", prod.variantData.productVariants);

      if (
        !prod.variantData.productVariants ||
        prod.variantData.productVariants.length < varCombination
      ) {
        errors.push({
          parentSKU: prod.parentSKU,
          error: t("ImportProduct.Error.XLSX.InvalidCombinationLength", {
            productparentSKU: prod.parentSKU,
          }),
        });
      }
    }
  });

  return {
    data,
    errors,
  };
}

// async function getCompanyAndAccount() {
//   const companyList: Company[] = lc.getItemLC("CompanyList");
//   const companyWithAccountActive: { company: Company; accounts: Account[] }[] =
//     await Promise.all(
//       companyList.map(async (company) => {
//         const accounts = await getListAccountByCompany(company.id);
//         const availableProductCateghoryAccount: Account[] = [];
//         for (const account of accounts) {
//           const maketProdCategory =
//             await getMarketplaceProductCategoryByMarketplace(account.type);
//           console.log("maketProdCategory", maketProdCategory);
//           if (maketProdCategory && maketProdCategory !== undefined) {
//             availableProductCateghoryAccount.push(account);
//           }
//         }
//         return {
//           company: company,
//           accounts: availableProductCateghoryAccount,
//         };
//       })
//     );

//   console.log("companyWithAccountActive", companyWithAccountActive);
//   return companyWithAccountActive;
// }

async function getCompanyAndAccount() {
  const companyList: UserCompanies[] = lc.getItemLC("CompanyList");
  const client = lc.getItemLC(lc.LCName.Client);

  const companyWithAccountActive: { company: Company; accounts: Account[] }[] =
    [];

  /*
  (await getAccessibleAccount(client.id, companyList)).


    const accounts = await getAccessibleAccount(client.id, companyList)
    await Promise.all(
      companyList.map(async (company) => {
        const accounts = await getListAccountByCompany(company.id);
        const availableProductCateghoryAccount: Account[] = [];
        for (const account of accounts) {
          const maketProdCategory =
            await getMarketplaceProductCategoryByMarketplace(account.type);
          console.log("maketProdCategory", maketProdCategory);
          if (maketProdCategory && maketProdCategory !== undefined) {
            availableProductCateghoryAccount.push(account);
          }
        }
        return {
          company: company,
          accounts: availableProductCateghoryAccount,
        };
      })
    );
    */

  console.log("companyWithAccountActive", companyWithAccountActive);
  return companyWithAccountActive;
}
