import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConnectedProps, connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as actAccount from "../../../../actions/account";
import * as Chat from "../../../../actions/chat";
import { RootState } from "../../../../setup/redux/store";
import Account from "../../../models/Account";
import { Collaboration, Room } from "../../../models/Chat";
import Customer from "../../../models/Customer";
import Message, {
  ChatType,
  InteractiveMessage,
  InteractiveMessageButton,
} from "../../../models/Message";
import User from "../../../models/User";
import * as chat from "../../../modules/chat/redux/ChatSlice";
import ChatMessage from "./ChatMessage";
import { useMediaQuery } from "react-responsive";
import db, {
  addChatFiles,
  getFileByMediaSHA256,
  realtimedb,
} from "../../../../../src/db";
import { subsDRMessage } from "../../../../api/firebase/message";
import { Timestamp } from "../../../../db";
import { createRef } from "../../../../db/connection";
import "../../../../styles/css/chat.scss";
import "../../../../styles/css/dropdown.scss";
import "../../../../styles/css/margin.scss";
import {
  formatTemplate,
  getTemplateButtonText,
} from "../../../../util/Formatter";
import firebase from "firebase/compat/app";
import { useQuery } from "react-query";
import { getListTemplateInteractive } from "../../../../db/serviceTemplate";
import * as Log from "../../../../util/SDayslogger";
import Language from "../../../models/Language";
import Template from "../../../models/Template";
import * as lc from "../../../modules/localstorage/index";
import { ChatFileView } from "./chat-modal/ChatFileView";
import InteractiveTemplate from "./chat-modal/InteractiveTemplate";
import ChatMessageToReply from "./chat-replymessage/ChatMessageToReply";
import { checkFile, formatSize } from "./chat-util/ChatUtil";
import { ChatMessageOrderInitiateView } from "./chat-orderinitiate/ChatMessageOrderInitiateView";
import { chatFiles } from "../../../models/Files";
import Mime from "mime";
import { uploadFileToStorage } from "../../../../api/firebase/storage";
import { calculateFileSHA256 } from "../../../../util/FileUtil";
import {
  createFileDataByUploadResult,
  getVideoCover,
  getVideoDetails,
} from "../../../../util/video";
import {
  findRoomById,
  setStatusToHandled as setStatusRoomsToHandled,
} from "../../../../db/serviceRoom";
import { getAccountByID } from "../../../../db/serviceAccount";
import moment from "moment";
import { Order } from "src/app/models/Order";
import { subsToNewMessageCollaboration } from "src/api/firebase";
import { getItemLC } from "../../../modules/localstorage/index";
import Notification from "src/app/models/Notification";
import { DocumentSnapshot } from "firebase-admin/firestore";
import { debounce } from "lodash";
import { setStatusToHandled as setStatusCollabsToHandled } from "src/db/serviceCollaborations";
import { uniqueById } from "./ChatList";

const mapState = (state: RootState) => ({ chat: state.Chat });
const connector = connect(mapState, chat.ChatSlice.actions);

type Props = {
  type?: string;
  isDrawer?: boolean;
  propsredux?: ConnectedProps<typeof connector>;
  customer?: Customer;
  collaborations?: Collaboration;
  user?: User;
  handleAllChat?: (collabs: Collaboration) => void;
};

// function digunakan untuk update status isMessageRead menjadi true
const markAsRead = async (
  selectedChat: string,
  selectedRoom: string,
  messages: any[],
  companyId: string
) => {
  const clientId = lc.getItemLC(lc.LCName.Client)?.id;
  const userId = lc.getItemLC(lc.LCName.User)?.uid;
  if (selectedChat !== "") {
    try {
      const fetch_room = await db
        .collection("collaborations")
        .doc(selectedChat)
        .collection("rooms")
        .doc(selectedRoom)
        .get();
      const room = { id: fetch_room.id, ...fetch_room.data() } as Room;

      let fieldName = `${room?.accountData?.type}/unreadMessages`;
      const updates = {
        [fieldName]: 0,
      };
      const path = `/collaborations/${selectedChat}`;
      const dataRef = realtimedb.ref(path);
      await dataRef.update(updates);

      const batch = db.batch();
      messages.forEach((item) => {
        batch.update(createRef("messages", item), { isMessageRead: true });
      });
      await batch.commit();

      let accountData = await (
        await db.collection("account").doc(room?.account?.id).get()
      ).data();
      let waba_phoneNumber = accountData?.whatsappNumber;

      let arr_message_ids: string[] = messages;

      const json_request: any = {
        uid: userId,
        type: "whatsapp",
        client: clientId,
        company: companyId,
        phoneNumber: waba_phoneNumber || "",
        messages: arr_message_ids.length > 0 ? arr_message_ids : [],
      };

      console.log(json_request, "<<< json_request markasread dani");

      if (json_request.messages.length > 0) {
        await Chat.requestMarkAsReadMessage(json_request);
      }
    } catch (error) {
      console.error("Error marking messages as read:", error);
    }
  }
};

const ChatInner: FC<Props> = ({
  isDrawer = false,
  type = "current",
  customer,
  collaborations,
  handleAllChat,
}) => {
  const userId = getItemLC("UID");
  const nav = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const attachmentRef = useRef<HTMLInputElement>(null);
  const sendButtonRef = useRef<HTMLButtonElement | null>(null);
  const isMountedRef = useRef<boolean>(true);
  const chatBodyRef = useRef<HTMLDivElement>(null);

  let { replyToMessage, orderInitiateMessage, chatInput } = useSelector(
    (state: RootState) => state.Chat
  );
  let {
    selectedChat,
    selectedRoom,
    selectedCollaboration,
    allChatSelectedChat,
    allChatSelectedRoom,
    allChatSelectedCollaboration,
    chatState,
  } = useSelector((state: RootState) => state.Chat);

  let collaboration =
    chatState === ChatType.mychat
      ? selectedCollaboration
      : allChatSelectedCollaboration;
  let chatSelected =
    chatState === ChatType.mychat ? selectedChat : allChatSelectedChat;
  let roomSelected =
    chatState === ChatType.mychat ? selectedRoom : allChatSelectedRoom;
  let roomsModel = collaboration?.roomsModel?.filter(
    (room: any) => room?.status === "handled" && room.id === roomSelected
  ) as Room[];
  let channel = String(
    roomsModel?.length > 0
      ? roomsModel[0].accountData?.type
      : collaboration?.lastInteractionChannel
  );

  const [hasMore, setHasMore] = useState<boolean>(true);
  const [lastVisible, setLastVisible] = useState<any>(null);
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false);
  const [loadingSendMessage, setLoadingSendMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [file, setFile] = useState<File>();
  const [fileSize, setFileSize] = useState<string>("");
  const [inputFileName, setInputFileName] = useState<string>("");
  const [messageType, setmessageType] = useState<string | undefined>("text");
  const [isLoadingMessage, setIsLoadingMessage] = useState(false);
  const [isLoadingCollaboration, setIsLoadingCollaboration] = useState(false);
  const [showScrollBottom, setShowScrollBottom] = useState(false);

  // const state_redux = useSelector((state: RootState) => state);
  let listMediaUrl: string[] = [];

  // function dijalankan untuk menampilkan preview file
  const setPreviewFile = async (event: any) => {
    if (event.target.files[0]) {
      setFile(event.target.files[0]);
      if (attachmentRef.current) {
        if (attachmentRef.current.value) {
          attachmentRef.current.value = "";
        }
      }
    }
  };

  // function digunakan untuk Upload File To Storage
  const uploadFile = async (companyID: string) => {
    if (!file) {
      return;
    }

    const clientID = lc.getItemLC(lc.LCName.Client)?.id;
    if (!clientID) {
      alert("client Id not found");
      setInputFileName("client Id not found");
      setFile(undefined);
      return;
    }

    const fileSHA256 = await calculateFileSHA256(file);
    const fileChannel = channel;

    // Check if file already exist
    if (!fileSHA256 || !fileChannel) {
      return;
    }
    const fileExist = await getFileByMediaSHA256(
      fileSHA256,
      fileChannel,
      clientID
    );
    if (fileExist) {
      return fileExist;
    }

    const fileName = `${file.name.split(".")[0]}_${Date.now()}`;
    const fileMimeType = file.type;
    const fileExtension = Mime.getExtension(fileMimeType);
    const fileTypeRaw = file.type.split("/")[0];
    const fileType =
      fileTypeRaw === "image" ||
      fileTypeRaw === "video" ||
      fileTypeRaw === "audio"
        ? fileTypeRaw
        : "document";

    const storagePath = `${clientID}/${fileType}s/sent/${fileName}${
      fileExtension ? `.${fileExtension}` : ""
    }`;
    const metadata = {
      contentType: fileMimeType,
      fileName: fileName,
    };

    const uploadResult = await uploadFileToStorage(storagePath, file, metadata);

    if (!uploadResult) {
      alert(t("HC.Error.FailedUpload"));
      setInputFileName(t("HC.Error.FailedUpload"));
      setFile(undefined);

      return;
    }

    const FileDataChat: chatFiles = {
      client: createRef("clients", clientID),
      name: uploadResult.metaData.name,
      url: uploadResult.mediaUrl,
      storage_path: uploadResult.metaData.fullPath,
      size: uploadResult.metaData.size,
      type: fileType,
      media_channel: fileChannel,
      media_sha256: fileSHA256,
      mime_type: uploadResult.metaData.contentType ?? fileMimeType ?? "",
      extension: !!fileExtension ? fileExtension : "",
      createdAt: firebase.firestore.Timestamp.now(),
      updatedAt: firebase.firestore.Timestamp.now(),
    };
    if (fileType === "video") {
      const videoDetail = await getVideoDetails(file);
      const videoCover = await getVideoCover(file);
      if (videoCover) {
        const coverFile = await createFileDataByUploadResult(videoCover, {
          clientId: clientID,
          channel: FileDataChat.media_channel,
        });
        if (coverFile) {
          FileDataChat.media_preview_id = coverFile.id;
          FileDataChat.media_width = videoDetail.width;
          FileDataChat.media_height = videoDetail.height;
        }
      }
    }
    const addFileResult = await addChatFiles(FileDataChat);
    return { ...FileDataChat, id: addFileResult?.id };
  };

  // function dijalankan setelah proses send message
  const callback = async (responseMessage: Message, error: string) => {
    if (!responseMessage) {
      return;
    }
    // setNewMessage(responseMessage);
    if (collaboration) {
      let newCollaboration = collaboration;
      // let newCollabList: Collaboration[] = [];
      // let newCollabListCurrent: Collaboration[] = [...currentCollaborationlist];
      // collablist.forEach((item) => {
      //   if (item.id !== selectedChat) {
      //     newCollabList.push(item);
      //     return;
      //   }
      //   if (item.id !== selectedChat) {
      //     newCollabListCurrent.push(item);
      //     return;
      //   }
      //   if (!responseMessage.messageType) {
      //     newCollabList.push(item);
      //     return;
      //   }
      //   let lastmessage = responseMessage.textContent;
      //   if (
      //     (responseMessage.textContent === "" ||
      //       responseMessage.textContent === undefined) &&
      //     responseMessage.filename !== undefined
      //   ) {
      //     lastmessage = responseMessage.filename;
      //   }
      //   item = {
      //     ...item,
      //     lastInteractionAt: responseMessage.createdAt,
      //     lastInteractionChannel: responseMessage.channel,
      //     lastInteractionMessage: lastmessage,
      //     lastInteractionType: responseMessage.messageType,
      //     lastUserInteractionAt: item.lastUserInteractionAt,
      //   };

      //   if (type === "all") {
      //     let arrToUser = item.toUser!.slice();
      //     if (arrToUser.length > 0) {
      //       let userAlreadyExist = arrToUser.find(
      //         (user) => user.id === lc.getItemLC("UID")
      //       );
      //       if (!userAlreadyExist) {
      //         const refUser = createRef("users", lc.getItemLC("UID"));
      //         arrToUser?.push(refUser);
      //       }
      //     }
      //     item = {
      //       ...item,
      //       toUser: arrToUser,
      //     };
      //   }

      //   newCollaboration = item;
      //   Chat.updateLastInteraction(selectedChat, responseMessage, selectedRoom);
      //   newCollabList.push(item);
      //   if (type === "all") newCollabListCurrent.push(item);
      // });

      // setcollabs(newCollaboration);

      if (type === ChatType.allchat) {
        if (handleAllChat) {
          dispatch(chat.setCurrentChatState("myChatList"));
          dispatch(chat.setSelectedChat(newCollaboration?.id));
          dispatch(
            chat.setSelectedRoom(
              newCollaboration?.roomsModel !== undefined &&
                newCollaboration?.roomsModel[0].id
            )
          );
          dispatch(chat.setReplyToMessage(""));
          handleAllChat(newCollaboration);
          await setStatusCollabsToHandled(chatSelected, userId);
          await setStatusRoomsToHandled(chatSelected, roomSelected, userId);
        }
      }
    }

    if (
      responseMessage.messageType &&
      (responseMessage.messageType === "image" ||
        responseMessage.messageType === "video") &&
      responseMessage.mediaUrl !== undefined &&
      responseMessage.id !== undefined &&
      responseMessage.filename !== undefined
    ) {
      messages.forEach((obj) => {
        if (
          obj.messageType !== undefined &&
          (obj.messageType === "video" || obj.messageType === "image")
        ) {
          listMediaUrl.push(`kt_modal_${obj.messageType}_${obj.id}`);
        }
      });

      let currentMedia = `kt_modal_${responseMessage.messageType}_${responseMessage.id}`;
      let nextMedia = "";
      let previousMedia = "";
      for (let index = 0; index < listMediaUrl.length; index++) {
        if (listMediaUrl[index] === currentMedia) {
          nextMedia = listMediaUrl[index + 1];
          previousMedia = listMediaUrl[index - 1];
        }
      }

      <ChatFileView
        messageType={responseMessage.messageType}
        mediaURL={responseMessage.mediaUrl}
        messageId={responseMessage.id}
        mediaName={responseMessage.filename}
        previousMedia={previousMedia}
        nextMedia={nextMedia}
        currentMedia={currentMedia}
      />;
    }
  };

  // function dijalankan ketika mengirim pesan berupa text
  const sendMessageFromChat = async () => {
    let fileMedia: (chatFiles & { id?: string }) | undefined = undefined;
    let firebaseMediaURL: string | undefined = undefined;

    console.log(messageType, message, "messtype opi");
    console.log(orderInitiateMessage, "orinitiate opi");

    if (messageType !== "text") {
      fileMedia = await Promise.resolve(
        uploadFile(String(collaborations?.company?.id))
      );
      firebaseMediaURL = fileMedia?.url;
      if (!firebaseMediaURL || firebaseMediaURL === "") {
        alert(t("HC.Error.FailedUpload"));
        setInputFileName(t("HC.Error.FailedUpload"));
        setFile(undefined);
        if (sendButtonRef.current) {
          sendButtonRef.current.removeAttribute("data-kt-indicator");
        }
        return;
      }
    }

    if (messageType === "text" && message === "") {
      if (sendButtonRef.current) {
        sendButtonRef.current.removeAttribute("data-kt-indicator");
      }
      if (
        orderInitiateMessage &&
        orderInitiateMessage.marketplaceType &&
        orderInitiateMessage.id 
        // &&
        // orderInitiateMessage.marketplaceType === "shopee"
      ) {
        await Chat.createMessageInitiateChatMarketplace(
          orderInitiateMessage.marketplaceType.toString(),
          orderInitiateMessage.id
        );
      }
      return;
    }

    if (
      orderInitiateMessage &&
      orderInitiateMessage.marketplaceType &&
      orderInitiateMessage.id 
      // &&
      // orderInitiateMessage.marketplaceType === "shopee"
    ) {
      await Chat.createMessageInitiateChatMarketplace(
        orderInitiateMessage.marketplaceType.toString(),
        orderInitiateMessage.id
      );
    }

    const client = lc.getItemLC(lc.LCName.Client);
    const clientID: string = client?.id;

    const roomRef: firebase.firestore.DocumentReference = db
      .collection("collaborations")
      .doc(chatSelected)
      .collection("rooms")
      .doc(roomSelected);

    const room = await findRoomById(chatSelected, roomSelected);
    let newMessage: Message = {
      client: createRef("clients", clientID),
      channel: channel,
      createdAt: Timestamp.now(),
      destination: "outbound",
      customer: collaboration?.customer,
      customerPhoneNumber: collaboration?.phoneNumber,
      user: collaboration?.toUser,
      mediaUrl: firebaseMediaURL,
      isActive: true,
      messageType: messageType,
      textContent: message,
      previewurl: true,
      updatedAt: Timestamp.now(),
      voice: false,
      filename: inputFileName,
      filesize: fileSize,
      collaboration: createRef("collaborations", chatSelected),
      room: roomRef,
      replyMessageID: replyToMessage
        ? createRef("messages", replyToMessage.id)
        : undefined,
    };

    if (fileMedia) {
      newMessage.fileId = fileMedia.id;
    }

    const onFetchAccountFinished = async (accountChat: Account | undefined) => {
      if (!accountChat) {
        return;
      }
      await Chat.createCollaborationMessage(
        newMessage,
        String(collaboration?.company?.id),
        chatSelected,
        accountChat,
        collaboration?.customerModel,
        (respMessage: Message, responseJson: string) => {
          callback(respMessage, responseJson);
        },
        false
      );
    };

    if (room && room.account?.id) {
      const account = (await getAccountByID(room.account.id)) as Account;
      if (account) {
        await onFetchAccountFinished(account);
      }
    }

    toggleChatUpdateFlat(!chatUpdateFlag);
    setMessage("");
    dispatch(
      chat.setChatInput(
        chatInput.filter((item: any) => {
          return item.id !== roomSelected;
        })
      )
    );
    setFile(undefined);
    setFileSize("");
    setInputFileName("");
    setmessageType("text");
    setLoadingSendMessage(false);
  };

  // function dijalankan ketika mengirim pesan interaktif
  const sendInteractiveMessage = async (templateLanguage: Language) => {
    const client = lc.getItemLC(lc.LCName.Client);
    const clientID: string = client?.id;
    let interactive: InteractiveMessage | undefined = undefined;
    interactive = {
      type: "button",
      components: [],
    };

    const formattedTemplate = formatTemplate(templateLanguage);

    if (templateLanguage.header) {
      const header = templateLanguage.header;
      let headerObj: {
        type: "header";
        text?: string;
        image?: string;
        document?: string;
        video?: string;
        filename?: string;
      } = { type: "header" };

      if (header.type === "text") headerObj.text = formattedTemplate.header;
      if (header.type === "media") {
        if (header.mediaType === "image") headerObj.image = header.headerURL;
        if (header.mediaType === "video") headerObj.video = header.headerURL;
        if (header.mediaType === "document")
          headerObj.document = header.headerURL;
        if (header.mediaType === "document")
          headerObj.filename = header.fileName || header.filename;
      }

      interactive.components.push(headerObj);
    }

    let textContent = "";
    if (templateLanguage.body) {
      interactive.components.push({
        type: "body",
        text: formattedTemplate.body,
      });
      if (formattedTemplate && formattedTemplate?.body) {
        textContent = formattedTemplate?.body?.replaceAll("\n", `</br>`);
      }
    }

    if (templateLanguage.footer) {
      interactive.components.push({
        type: "footer",
        text: templateLanguage.footer,
      });
    }

    if (templateLanguage.buttons) {
      const buttons: InteractiveMessageButton[] = [];
      buttons.push(
        ...templateLanguage.buttons.map((button) => {
          return {
            id: `${button.index!}`,
            title: button.text,
          };
        })
      );
      interactive.components.push({
        type: "button",
        buttons,
      });
    }

    if (!interactive) return;
    const roomRef: firebase.firestore.DocumentReference = db
      .collection("collaborations")
      .doc(chatSelected)
      .collection("rooms")
      .doc(roomSelected);

    let newMessage: Message = {
      client: createRef("clients", clientID),
      channel,
      createdAt: Timestamp.now(),
      destination: "outbound",
      customer: collaboration?.customer,
      customerPhoneNumber: collaboration?.phoneNumber,
      user: collaboration?.toUser,
      isActive: true,
      messageType: "interactive",
      interactive,
      textContent,
      header: formattedTemplate.header,
      footer: formattedTemplate.footer,
      buttons: formattedTemplate.buttons,
      previewurl: true,
      updatedAt: Timestamp.now(),
      voice: false,
      collaboration: createRef("collaborations", chatSelected),
      room: roomRef,
      replyMessageID: replyToMessage
        ? createRef("messages", replyToMessage.id)
        : undefined,
    };

    const onFetchAccountFinished = async (accountChat: Account | undefined) => {
      if (!accountChat) {
        return;
      }

      await Chat.createCollaborationMessage(
        newMessage,
        String(collaborations?.company?.id),
        chatSelected,
        accountChat,
        collaboration?.customerModel,
        (respMessage: Message, responseJson: string) => {
          callback(respMessage, responseJson);
        },
        false
      ).catch((error) => {
        Log.SDayslogger(
          nav,
          "Failed to Create Collaboration message : " + chatSelected,
          Log.SDLOGGER_INFO,
          false,
          true
        );
      });
    };

    actAccount
      .fetchAccountByCompanyAndChannel(
        String(collaborations?.company?.id),
        channel
      )
      .then(async (response) => {
        const temAccount = response.find((obj) => {
          return obj;
        });
        onFetchAccountFinished(temAccount);
        setLoadingSendMessage(false);
      })
      .catch((error) => {
        Log.SDayslogger(
          nav,
          "Failed to Fetch Account Chat by Company And Channel : " +
            chatSelected,
          Log.SDLOGGER_INFO,
          false,
          true
        );
      });
  };

  // function dijalankan ketika klik send button
  const sendMessage = async (templateLanguage?: Language) => {
    setLoadingSendMessage(true);
    if (sendButtonRef) {
      sendButtonRef.current?.setAttribute("data-kt-indicator", "on");
    }
    if (collaboration?.toUser) {
      for (let index = 0; index < collaboration?.toUser.length; index++) {
        const element = collaboration?.toUser[index];
        // Proses element jika diperlukan
      }
    }
    console.log(templateLanguage, "template language opi"); 
    if (!templateLanguage) sendMessageFromChat();
    if (templateLanguage) sendInteractiveMessage(templateLanguage);
    if (sendButtonRef) {
      sendButtonRef.current?.removeAttribute("data-kt-indicator");
    }
    dispatch(chat.setReplyToMessage(""));
    dispatch(chat.setOrderInitiateMessage());
    dispatch(chat.setReplyToMessageAudioDuration(""));
  };

  // function dijalankan ketika klik enter di input text message
  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false && message.trim() !== "") {
      e.preventDefault();
      sendMessage();
    }
  };

  // function dijalankan ketika mengetik di input text message
  const onTextMessage = (e: any) => {
    let textValue = e.target.value;
    setMessage(textValue);
    let chatTyping = chatInput.slice();
    let isRoomFound = false;

    chatTyping = chatTyping.map((item: any) => {
      if (item.id === roomSelected) {
        isRoomFound = true;
        return { ...item, message: textValue };
      }
      return item;
    });

    if (!isRoomFound) {
      chatTyping.push({
        id: roomSelected,
        message: textValue,
      });
    }
    dispatch(chat.setChatInput(chatTyping));
  };

  // function dijalankan untuk remove file attachment message
  const removeFile = () => {
    setMessage("");
    dispatch(
      chat.setChatInput(
        chatInput.filter((item: any) => {
          return item.id !== roomSelected;
        })
      )
    );
    setFile(undefined);
    setFileSize("");
    setInputFileName("");
    setmessageType("text");
  };

  // function dijalankan ketika ada new notif message baru
  const onNewData = useCallback(
    async (Notif: Notification, data: Message | undefined | Order | any) => {
      const message = data;
      if (
        message &&
        message?.room &&
        message?.channel === "whatsapp" &&
        message?.room?.id === roomSelected &&
        Notif?.collaborationID
      ) {
        // dispatch(chat.setSelectedRoom(roomSelected + "S"));
        // dispatch(chat.setSelectedRoom(roomSelected.slice(0, -1)));
      } else {
        dispatch(chat.addIncomingMessages(message));
      }
    },
    [roomSelected, dispatch]
  );

  // function dijalankan ketika klik tombol panah bawah scroll bawah
  const scrollToBottom = () => {
    const chatBody = chatBodyRef.current;
    if (chatBody) {
      chatBody.scrollTop = chatBody.scrollHeight - chatBody.clientHeight;
    }
  };

  // function dijalankan untuk melakukan fetch data message di collection meessage firestore
  const fetchMessages = useCallback(
    async (selectedChat: string, selectedRoom: string, type: string) => {
      if (chatSelected !== "" && roomSelected !== "") {
        const query = db
          .collection("messages")
          .where(
            "room",
            "==",
            db.doc(`collaborations/${selectedChat}/rooms/${selectedRoom}`)
          )
          .where("isActive", "==", true)
          .orderBy("createdAt", "desc")
          .limit(15);

        const querySnapshot = await query.get();
        const messages = querySnapshot.docs.reverse().map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Message[];
        return messages;
      }
    },
    [chatSelected, roomSelected, type]
  );

  // function dijalankan ketika message discroll ke atas untuk load message yg lebih lama
  const fetchNextPage = useCallback(
    debounce(async () => {
      const initialHeight: number = Number(chatBodyRef?.current?.scrollHeight);
      if (isLoadingMessage || !hasMore) return;
      setIsLoadingMessage(true);

      try {
        let query = db
          .collection("messages")
          .where(
            "room",
            "==",
            db.doc(`collaborations/${chatSelected}/rooms/${roomSelected}`)
          )
          .where("isActive", "==", true)
          .orderBy("createdAt", "desc") // Urutkan descending
          .startAfter(lastVisible)
          .limit(10);

        const querySnapshot = await query.get();

        if (querySnapshot.empty) {
          setIsLoadingMessage(false);
          setHasMore(false);
          return;
        }

        const docs = querySnapshot.docs.reverse(); // Reverse untuk ascending di UI
        const data = docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Message[];

        if (isMountedRef.current) {
          setLastVisible(docs[0]); // Update last visible
          setMessages(
            (prevMessages) => uniqueById([...data, ...prevMessages]) as any
          );
          if (chatBodyRef.current) {
            if (hasMore) {
              if (chatBodyRef.current.scrollHeight !== initialHeight) {
                chatBodyRef.current.scrollTop =
                  chatBodyRef.current.scrollHeight - initialHeight;
              }
            } else {
              chatBodyRef.current.scrollTop = 0;
            }
          }
        }
      } catch (error) {
        console.error("Error fetching next page: ", error);
      } finally {
        setIsLoadingMessage(false);
      }
    }, 300), // debounce delay
    [isLoadingMessage, hasMore, lastVisible, chatSelected, roomSelected]
  );

  // function dijalankan ketika melakukan scroll di list message
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight } = event.currentTarget;
    if (hasMore && scrollTop < 3) {
      fetchNextPage();
    }
    if (scrollHeight) {
      setShowScrollBottom(scrollHeight - scrollTop >= 800);
    }
  };

  // fuction digunakan untuk mengarahkan ke bagian paling bawah chat ketika awal load
  const scrollBottom = () => {
    const chatBody = chatBodyRef.current;
    if (chatBody) {
      chatBody.scrollTo({
        top: chatBody.scrollHeight - chatBody.clientHeight,
        behavior: "smooth",
      });
    }
  };

  // function digunakan untuk menipulasi tinggi textarea ketika melakukan enter
  const OnInput = (event: Event) => {
    const target = event.target as HTMLTextAreaElement;
    if (target) {
      target.style.height = "auto";
      target.style.height = target.scrollHeight + "px";
    }
  };
  const tx = document.getElementsByTagName("textarea");
  for (let i = 0; i < tx.length; i++) {
    let scHeight = tx[i].scrollHeight;
    const maxHeightTx = 100;

    if (tx[i].scrollHeight < maxHeightTx) {
      tx[i].setAttribute(
        "style",
        "height:" +
          scHeight +
          "px;overflow-y:scroll;max-height:100px; z-index:99; background-color:white"
      );
      tx[i].addEventListener("input", OnInput, false);
    } else {
      tx[i].setAttribute(
        "style",
        "height:" +
          maxHeightTx +
          "px;overflow-y:scroll;max-height:100px; z-index:99; background-color:white"
      );
      tx[i].addEventListener("input", OnInput, false);
    }
  }

  // usequery digunakan untuk fetch data message di awal load halaman
  useQuery(
    ["messages", chatSelected, roomSelected],
    () => fetchMessages(chatSelected, roomSelected, type),
    {
      enabled: !!chatSelected && !!roomSelected && chatSelected !== "",
      onSuccess: (data) => {
        if (data) {
          setIsLoadingMessage(true);
          setHasMore(true);
          setMessages([]);
          setMessages(uniqueById(data) as any);
          if (data?.length > 0) {
            setLastVisible(data[data.length - 1]);
          }
          scrollToBottom();
          setIsLoadingMessage(false);
        }
      },
    }
  );

  // useeffect digunakan untuk listen atau memantau update notif new message
  useEffect(() => {
    const unsubsNewMessage = subsToNewMessageCollaboration(userId, onNewData);

    return () => {
      unsubsNewMessage();
    };
  }, [userId, onNewData]);

  // useeffect digunakan untuk listen atau memantau collection message jika ada message baru
  useEffect(() => {
    setIsLoadingMessage(true);
    if (
      chatSelected &&
      chatSelected !== "" &&
      roomSelected &&
      roomSelected !== ""
    ) {
      const unsubscribe = db
        .collection("messages")
        .where(
          "room",
          "==",
          db.doc(`collaborations/${chatSelected}/rooms/${roomSelected}`)
        )
        .where("isActive", "==", true)
        .orderBy("createdAt", "desc")
        .limit(15)
        .onSnapshot((snapshot) => {
          if (!snapshot.empty) {
            const newMessages = snapshot.docs.reverse().map((doc) => ({
              id: doc.id,
              ...doc.data(),
            })) as Message[];
            setMessages([]);
            setHasMore(true);
            setMessages(uniqueById(newMessages) as any);
            if (newMessages.length > 0) {
              setLastVisible(newMessages[newMessages.length - 1]);
            }
            scrollToBottom();
            setIsLoadingMessage(false);
          }
        });

      return () => {
        setHasMore(true);
        setMessages([]);
        unsubscribe();
        document.getElementById("chatTextMessage")?.focus();
      };
    }
  }, [chatSelected, roomSelected, type]);

  // useeffect digunakan untuk markAsRead
  useEffect(() => {
    const updateReadMark = async () => {
      if (messages.length === 0) return;
      const firstMessage = messages[0];
      const companyRef = firstMessage?.company;
      if (!companyRef) return;

      const companyId = companyRef.id;

      let messagesUnread = messages
        .filter(
          (x) =>
            x?.isMessageRead === false &&
            x?.destination === "inbound" &&
            x?.isActive === true
        )
        .map((item) => item?.id);

      if (messagesUnread?.length > 0) {
        await markAsRead(chatSelected, roomSelected, messagesUnread, companyId);
      }
    };

    updateReadMark().catch((error) => {
      console.error("Error updating read mark:", error);
    });
  }, [messages, roomSelected]);

  // useeffect digunakan untuk update data input chat di redux
  useEffect(() => {
    if (chatInput.length !== 0) {
      let finding = chatInput.find((item: any) => {
        return item.id === roomSelected;
      });
      if (finding) {
        setMessage(finding.message);
      } else {
        setMessage("");
      }
    }
  }, [roomSelected, chatInput]);

  // useeffect digunakan untuk Get Attachment
  useEffect(() => {
    if (file) {
      //Set File Attribut
      const size: number = file.size;
      setInputFileName(file.name);
      setFileSize(formatSize(size));
      //Check File Type
      const tempArrFileType: string = file.type;
      const arryFileType = tempArrFileType.split("/");
      if (
        arryFileType === undefined ||
        arryFileType.length < 1 ||
        arryFileType[0] === undefined
      ) {
        //return error
        alert(t("HC.Error.InvalidFiletype"));
        setInputFileName(t("HC.Error.InvalidFiletype"));
        setFile(undefined);
        return;
      } else {
        //Check File Extension
        const fileType = arryFileType[0];
        const fileExtension = arryFileType[1];

        const [result, error, maxSize] = checkFile(
          fileType,
          fileExtension,
          size,
          channel
        );

        if (error !== undefined) {
          if (maxSize !== 0) {
            alert(t("HC.File." + error).replace("<<size>>", maxSize + " MB"));
            setInputFileName(
              t("HC.File." + error).replace("<<size>>", maxSize + " MB")
            );
            setFile(undefined);
          } else {
            alert(t("HC.Error." + error));
            setInputFileName(t("HC.Error." + error));
            setFile(undefined);
          }
          return;
        }
        if (result !== undefined) {
          setmessageType(result.toString());
        }
      }
    }
  }, [file]);

  console.log(messages, "messages zio");

  return (
    <div
      className="d-flex w-100 h-100 flex-column align-items-center justify-content-between m-0 p-0"
      style={{
        border: "top bottom 10px solid #9899AC",
        overflow: "hidden",
      }}
      key={"chatInner"}
      id={isDrawer ? "kt_drawer_chat_messenger_body" : "kt_chat_messenger_body"}
    >
      {/* list message  */}
      <div
        id="chat-body"
        ref={chatBodyRef}
        className="d-flex flex-column w-100"
        style={{
          height: "100%",
          overflowX: "hidden",
          overflowY: "scroll",
        }}
        onScroll={handleScroll}
      >
        {showScrollBottom && (
          <div
            className="d-flex position-absolute rounded-circle p-3 bg-primary cursor-pointer"
            style={{
              right: isTabletOrMobile ? 5 : 2,
              bottom: isTabletOrMobile ? "12%" : "15%",
              zIndex: 200,
            }}
            onClick={() => {
              scrollBottom();
              setShowScrollBottom(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              className="text-light-primary"
              transform="scale(1, -1)"
              transform-origin="center"
            >
              <path
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                d="M9 19v-7H5.483a.2.2 0 0 1-.142-.341L12 5l6.659 6.659a.2.2 0 0 1-.142.341H15v7"
              />
            </svg>
          </div>
        )}
        {isLoadingCollaboration && (
          <div className="h-100 text-gray-700" style={{ textAlign: "center" }}>
            <span style={{ fontStyle: "italic" }}>{t("HC.Info.LoadChat")}</span>
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </div>
        )}
        {isLoadingMessage && (
          <div className="d-flex justify-content-center p-3">
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </div>
        )}
        {(!isLoadingCollaboration || !isLoadingMessage) &&
          messages?.reduce(
            (acc: any, message: any, index) => {
              const messageDate = new Date(message?.createdAt?.seconds * 1000);
              let formattedDate = moment(messageDate).format("D MMMM YYYY");
              const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
              const todayDate = new Date();
              const today = todayDate.getTime();
              const isThisWeek =
                Math.abs(today - messageDate.getTime()) <= 6 * DAY_IN_MS;
              if (moment(messageDate).isSame(moment(), "day")) {
                formattedDate = "T";
              } else if (
                moment(messageDate).isSame(moment().subtract(1, "day"), "day")
              ) {
                formattedDate = "Y";
              } else if (isThisWeek) {
                formattedDate = moment(messageDate).format("dddd"); // 10/January at 10:20 | 10/January 10:20
              }

              if (!acc.dates.has(formattedDate)) {
                acc.dates.add(formattedDate);
                acc.uniqueMessages.push(
                  <>
                    <div className="d-flex flex-row justify-content-center align-items-center position-sticky top-0 py-0 mt-0 h-auto bg-white">
                      <p className="text-center bg-secondary px-3 py-2 rounded-pill">
                        {formattedDate === "T"
                          ? t("Date.Today")
                          : formattedDate === "Y"
                          ? t("Date.Yesterday")
                          : formattedDate}
                      </p>
                    </div>
                    <ChatMessage
                      message={message}
                      key={message.id}
                      index={index}
                      isDrawer={isDrawer}
                      customer={collaboration?.customerModel}
                      lastUserInteraction={collaboration?.lastUserInteractionAt}
                      user={message?.userModel}
                      messages={messages}
                      collaborationId={chatSelected}
                      companyID={String(collaborations?.company?.id)}
                      phoneNumber={
                        collaboration?.phoneNumber
                          ? collaboration?.phoneNumber
                          : ""
                      }
                    />
                  </>
                );
              } else {
                acc.uniqueMessages.push(
                  <ChatMessage
                    message={message}
                    key={message.id}
                    index={index}
                    isDrawer={isDrawer}
                    customer={collaboration?.customerModel}
                    lastUserInteraction={collaboration?.lastUserInteractionAt}
                    user={message?.userModel}
                    messages={messages}
                    collaborationId={chatSelected}
                    companyID={String(collaborations?.company?.id)}
                    phoneNumber={
                      collaboration?.phoneNumber
                        ? collaboration?.phoneNumber
                        : ""
                    }
                  />
                );
              }
              return acc;
            },
            { dates: new Set(), uniqueMessages: [] }
          ).uniqueMessages}
      </div>

      {/* footer message (textinput, attachment dsb.) */}
      <div
        className="d-flex flex-column w-100 bg-secondary p-0 m-0 row-gap-1 px-2 py-2"
        id={"kt_chat_messenger_footer"}
      >
        {/* interactive message  */}
        <InteractiveMessageList
          companyId={String(collaborations?.company?.id)}
          sendMessage={sendMessage}
          collaboration={collaboration}
        />

        {/* order initiative message  */}
        {(!replyToMessage || replyToMessage === "") && orderInitiateMessage && (
          <div
            id="quoted-order-wrapper"
            data-testid="quoted-order-wrapper"
            className="d-flex flex-row align-items-center fade-in m-0 w-100 py-2 ps-3"
            style={{
              backgroundColor: "#F0F3F7",
            }}
          >
            <div
              id="quoted-order"
              className="d-flex w-100"
              style={{
                margin: "auto",
                width: "100%",
                height: "auto",
                backgroundColor: "#C9C9C9",
                paddingRight: "0px",
                borderRadius: "8px",
                borderLeftWidth: "6px",
                borderLeftStyle: "solid",
                borderLeftColor: "#034CA3",
              }}
            >
              <ChatMessageOrderInitiateView order={orderInitiateMessage} />
            </div>
            <div
              className="d-flex align-items-center mx-3 cursor-pointer"
              style={{ right: 0 }}
            >
              <button
                id="sendButton"
                data-testid="send-button"
                ref={sendButtonRef}
                type="button"
                data-kt-element="send"
                className="btn btn-icon btn-sm btn-icon btn-active-light-primary m-0 p-0"
                onClick={() => {
                  dispatch(chat.setReplyToMessage(""));
                }}
              >
                <div
                  className="d-flex align-items-center mx-3 bi bi-x-lg cursor-pointer"
                  style={{ right: 0 }}
                ></div>
              </button>
            </div>
          </div>
        )}

        {/* reply to message  */}
        {replyToMessage !== "" && (
          <div
            id="quoted-reply-wrapper"
            data-testid="quoted-reply-wrapper"
            className="d-flex flex-row align-items-center fade-in m-0 w-100 py-2 ps-3"
          >
            <div
              id="quoted-reply"
              className="d-flex w-100"
              style={{
                margin: "auto",
                width: "100%",
                height: "auto",
                backgroundColor: "#C9C9C9",
                paddingRight: "0px",
                borderRadius: "8px",
                borderLeftWidth: "6px",
                borderLeftStyle: "solid",
                borderLeftColor: "#034CA3",
              }}
            >
              {replyToMessage &&
              (replyToMessage.messageType === "text" ||
                replyToMessage.messageType === "button" ||
                replyToMessage.messageType === "image" ||
                replyToMessage.messageType === "video" ||
                replyToMessage.messageType === "audio" ||
                replyToMessage.messageType === "document" ||
                replyToMessage.messageType === "location" ||
                replyToMessage?.messageType === "interactive") ? (
                <ChatMessageToReply
                  messageToReply={replyToMessage}
                  customer={collaboration?.customerModel}
                  phoneNumber={
                    collaboration?.phoneNumber ? collaboration?.phoneNumber : ""
                  }
                />
              ) : (
                <>
                  <div className="py-7 px-2" style={{ color: "#034CA3" }}>
                    ONPROCEESS
                  </div>
                </>
              )}
            </div>
            <div
              className="d-flex align-items-center mx-3 cursor-pointer"
              style={{ right: 0 }}
            >
              <button
                id="sendButton"
                data-testid="send-button"
                ref={sendButtonRef}
                type="button"
                data-kt-element="send"
                className="btn btn-icon btn-sm btn-icon btn-active-light-primary m-0 p-0"
                onClick={() => {
                  dispatch(chat.setReplyToMessage(""));
                }}
              >
                <div
                  className="d-flex align-items-center mx-3 bi bi-x-lg cursor-pointer"
                  style={{ right: 0 }}
                ></div>
              </button>
            </div>
          </div>
        )}

        {/* input message  */}
        <div className="d-flex flex-row p-0 m-0 w-100">
          <div className="d-flex align-items-center me-3">
            {!file && (
              <label
                className="btn btn-sm btn-icon btn-active-light-primary m-0"
                data-bs-toggle="tooltip"
                data-kt-image-input-action="change"
                title={t("HC.Info.Upload")}
                data-testid="uploadfile-button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="text-primary fs-1 p-0 m-0"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M12.948 7.308L7.42 12.835a2.607 2.607 0 1 0 3.689 3.688l5.982-5.982a4.548 4.548 0 0 0 0-6.452a4.549 4.549 0 0 0-6.4.065l-6.034 5.918a6.517 6.517 0 0 0 9.215 9.214l7.377-7.312"
                  />
                </svg>

                <input
                  data-testid="chat-media"
                  ref={attachmentRef}
                  id="chat-media"
                  onChange={setPreviewFile}
                  type="file"
                  name="attachment"
                  multiple={false}
                  style={{ display: "none" }}
                />
              </label>
            )}
            {file && (
              <label data-testid="file-uploaded-label">{inputFileName}</label>
            )}
            {file && (
              <label
                className="btn btn-sm btn-icon-danger btn-text-danger p-2 m-0 btn-active-light-primary"
                title={t("HC.File.RemoveFile")}
              >
                <i
                  className="bi bi-x-circle fs-1 p-0 m-0"
                  onClick={removeFile}
                ></i>
              </label>
            )}
          </div>

          <div
            className="bg-white w-100"
            style={{ overflowY: "scroll", borderRadius: "10px" }}
          >
            {messageType !== "document" && messageType !== "audio" && (
              <textarea
                data-testid="chatTextMessage"
                id="chatTextMessage"
                className="form-control form-control-flush px-2 py-auto"
                style={{
                  height: "60%",
                  maxHeight: "120px",
                  backgroundColor: "white",
                  zIndex: 99,
                }}
                maxLength={4096}
                rows={1}
                data-kt-element="input"
                placeholder={t("HC.Input.TextMessage")}
                value={message}
                onChange={onTextMessage}
                onKeyDown={onEnterPress}
                autoFocus
              ></textarea>
            )}
          </div>

          <div className="d-flex align-items-center ms-3" style={{ right: 0 }}>
            <button
              id="sendButton"
              data-testid="send-button"
              ref={sendButtonRef}
              type="button"
              data-kt-element="send"
              className="btn btn-icon btn-sm btn-icon btn-active-light-primary m-0 p-0"
              onClick={() => sendMessage()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 14 14"
                className="text-primary"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M11.821.098a1.62 1.62 0 0 1 2.077 2.076l-3.574 10.712a1.62 1.62 0 0 1-1.168 1.069a1.599 1.599 0 0 1-1.52-.434l-1.918-1.909l-2.014 1.042a.5.5 0 0 1-.73-.457l.083-3.184l7.045-5.117a.625.625 0 1 0-.735-1.012L2.203 8.088l-1.73-1.73a1.6 1.6 0 0 1-.437-1.447a1.62 1.62 0 0 1 1.069-1.238h.003L11.82.097Z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const InteractiveMessageList: FC<{
  companyId: string;
  sendMessage: (templateLanguage?: Language) => void;
  templateList?: Template[];
  collaboration?: Collaboration;
}> = ({ companyId, sendMessage, templateList, collaboration }) => {
  const client = lc.getItemLC(lc.LCName.Client);
  const { i18n, t } = useTranslation();

  const { data: interactiveTemplates, isLoading } = useQuery(
    ["interactive-templates", i18n, companyId],
    async () => {
      if (!companyId || !client) {
        return [];
      }

      const templates = await getListTemplateInteractive(
        companyId,
        client.id,
        i18n.language,
        3
      );

      if (!templates) {
        return [];
      }
      return templates;
    },
    {
      enabled: !templateList || templateList.length <= 0,
    }
  );

  const templateInteractiveList =
    templateList && templateList.length > 0
      ? templateList
      : interactiveTemplates && interactiveTemplates.length > 0
      ? interactiveTemplates
      : [];

  if (isLoading) {
    return <div>loading...</div>;
  }

  if (templateInteractiveList && templateInteractiveList.length > 0) {
    return (
      <div
        data-testid="interactive-message-quick-options"
        className="position-relative bg-opacity-0 d-flex justify-content-start align-items-center p-0 m-0"
        style={{ gap: "0.5rem" }}
      >
        {templateInteractiveList.slice(0, 3).map(
          (template) =>
            template.languagesModel &&
            template.languagesModel.length > 0 && (
              <React.Fragment key={`${template.id}`}>
                <button
                  type="button"
                  className="button-interactive-chat p-1"
                  key={`interactive-chat-detail-${template.id}`}
                  data-testid={`interactive-chat-detail-${template.id}`}
                  data-bs-target={`#InteractiveTemplateModal${template.id}`}
                  data-bs-toggle="modal"
                >
                  <span>
                    {getTemplateButtonText(template.languagesModel[0], t)}
                  </span>
                </button>
                <InteractiveTemplate
                  list={templateInteractiveList}
                  template={template}
                  sendMessage={sendMessage}
                  collaboration={collaboration}
                />
              </React.Fragment>
            )
        )}

        <button
          type="button"
          key="others"
          className="button-interactive-chat p-1"
          data-testid={`interactive-chat-detail-other`}
          data-bs-target={`#InteractiveTemplateModalOther`}
          data-bs-toggle="modal"
        >
          <span>{t("Common.Others")}</span>
          <i className="bi bi-chevron-right"></i>
        </button>
        <InteractiveTemplate
          list={templateInteractiveList}
          sendMessage={sendMessage}
          collaboration={collaboration}
        />
      </div>
    );
  }

  return null;
};

export default connector(ChatInner);
function playAudio() {
  throw new Error("Function not implemented.");
}
