import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { ApiSettingState } from "../../../../../modules/api-setting/redux/ApiSettingRedux";
import clsx from "clsx";

const IS_SANDBOX =
  process.env.REACT_APP_ENVIRONMENT === "sandbox" ||
  process.env.REACT_APP_ENVIRONMENT === "stagging-sandbox";

console.log(
  "process.env.REACT_APP_ENVIRONMENT",
  process.env.REACT_APP_ENVIRONMENT
);

const ApiOverviewPage = () => {
  const { t } = useTranslation();
  const apiSetting: ApiSettingState = useSelector(
    (state: any) => state.ApiSetting
  );
  const accessToken: string = apiSetting.access.accessToken;
  const secretKey: string = apiSetting.access.secretKey;
  const sandboxAccessToken: string = apiSetting.accessSandbox.accessToken;
  const sandboxSecretKey: string = apiSetting.accessSandbox.secretKey;
  const ipAdress: string[] = apiSetting.ipwhitelist;
  const phoneNumber: string[] = apiSetting.phoneNumberWhitelist;

  const [displayToken, setDisplayToken] = useState(false);
  const [displaySecret, setDisplaySecret] = useState(false);
  const [displaySandboxToken, setDisplaySandboxToken] = useState(false);
  const [displaySandboxSecret, setDisplaySandboxSecret] = useState(false);
  // const divRef = useRef<HTMLDivElement>(null);

  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });
  const handleCopyClick = (payload: string, toastTitle: string) => {
    navigator.clipboard.writeText(payload);
    toast(toastTitle, {
      type: "success",
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  return (
    <div>
      <div className="container mt-10">
        <div className="row">
          <h3>{t("API.Setting.Overview.APP.Key")}</h3>
        </div>
        <div className="row text-break align-items-center">
          <div className={isMobileView ? "col-4" : "col-2"}>
            {t("API.Setting.Overview.Access.Token")}
            {displayToken && (
              <button
                className="btn fa fa-eye viewpass text-muted"
                onClick={() => setDisplayToken(!displayToken)}
              ></button>
            )}
            {!displayToken && (
              <button
                className="btn fa fa-eye-slash viewpass text-muted"
                onClick={() => setDisplayToken(!displayToken)}
              ></button>
            )}
            {" :"}
          </div>
          <div className="col">
            {!displayToken && "******************************"}
            {displayToken && (
              <>
                {accessToken}
                <span
                  className="text-primary ms-3"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleCopyClick(
                      accessToken,
                      t("API.Setting.Overview.Access.Token.Copied")
                    )
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-copy text-primary"
                    style={{ cursor: "pointer" }}
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                    />
                  </svg>
                  <span className="ms-1 fw-bold">Copy</span>
                </span>
              </>
            )}
          </div>
        </div>
        <div className="row text-break">
          <div className={isMobileView ? "col-4" : "col-2 my-auto"}>
            {t("API.Setting.Overview.Secret.Key")}
            {displaySecret && (
              <button
                className="btn fa fa-eye viewpass text-muted"
                onClick={() => setDisplaySecret(!displaySecret)}
              ></button>
            )}
            {!displaySecret && (
              <button
                className="btn fa fa-eye-slash viewpass text-muted"
                onClick={() => setDisplaySecret(!displaySecret)}
              ></button>
            )}
            {" :"}
          </div>
          <div className="col my-auto">
            {!displaySecret && "******************************"}
            {displaySecret && (
              <>
                {secretKey}
                <span
                  className="text-primary ms-3"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleCopyClick(
                      secretKey,
                      t("API.Setting.Overview.Secret.Key.Copied")
                    )
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-copy text-primary"
                    style={{ cursor: "pointer" }}
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                    />
                  </svg>
                  <span className="ms-1 fw-bold">Copy</span>
                </span>
              </>
            )}
          </div>
        </div>
      </div>

      {!IS_SANDBOX && !!sandboxAccessToken && !!sandboxSecretKey ? (
        <div className="container mt-15">
          <div className="row">
            <h3>{"Sandbox APP Key"}</h3>
          </div>
          <div className="row text-break align-items-center">
            <div className={isMobileView ? "col-4" : "col-2"}>
              {t("API.Setting.Overview.Access.Token")}
              <button
                className={clsx(
                  "btn fa viewpass text-muted",
                  displaySandboxToken ? "fa-eye" : "fa-eye-slash"
                )}
                onClick={() => setDisplaySandboxToken(!displaySandboxToken)}
              ></button>
              {" :"}
            </div>
            <div className="col">
              {!displaySandboxToken && "******************************"}
              {displaySandboxToken && (
                <span>
                  {sandboxAccessToken}
                  <span
                    className="text-primary ms-3"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleCopyClick(
                        sandboxAccessToken,
                        t("API.Setting.Overview.Access.Token.Copied")
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-copy text-primary"
                      style={{ cursor: "pointer" }}
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                      />
                    </svg>
                    <span className="ms-1 fw-bold">Copy</span>
                  </span>
                </span>
              )}
            </div>
          </div>
          <div className="row text-break">
            <div className={isMobileView ? "col-4" : "col-2 my-auto"}>
              {t("API.Setting.Overview.Secret.Key")}
              <button
                className={clsx(
                  "btn fa viewpass text-muted",
                  displaySandboxSecret ? "fa-eye" : "fa-eye-slash"
                )}
                onClick={() => setDisplaySandboxSecret(!displaySandboxSecret)}
              ></button>
              {" :"}
            </div>
            <div className="col my-auto">
              {!displaySandboxSecret && "******************************"}
              {displaySandboxSecret && (
                <>
                  {sandboxSecretKey}
                  <span
                    className="text-primary ms-3"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleCopyClick(
                        sandboxSecretKey,
                        t("API.Setting.Overview.Secret.Key.Copied")
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-copy text-primary"
                      style={{ cursor: "pointer" }}
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                      />
                    </svg>
                    <span className="ms-1 fw-bold">Copy</span>
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      ) : null}

      {/* IP WHITELIST */}
      <div className="container mt-15">
        <div className="row">
          <h3>{t("API.Setting.Overview.IP.Whitelist")}</h3>
        </div>
        <div className="row mt-5">
          <div className={isMobileView ? "col-3" : "col-2"}>
            <p>{t("API.Setting.Overview.IP.Adress")}</p>
          </div>
          <div className="col">
            {ipAdress &&
              ipAdress.map((ip: string) => (
                <span className="badge badge-primary px-3 mx-1 my-1" key={ip}>
                  {ip}
                </span>
              ))}
          </div>
        </div>
      </div>

      {/* NUMBER WHITELIST */}
      <div className="container mt-15">
        <div className="row">
          <h3>{t("API.Setting.Overview.Phone.Whitelist")}</h3>
        </div>
        <div className="row mt-5">
          <div className={isMobileView ? "col-3" : "col-2"}>
            <p>{t("API.Setting.Overview.Phone.Number")}</p>
          </div>
          <div className="col">
            {phoneNumber &&
              phoneNumber.map((ip: string) => (
                <span
                  className="badge badge-primary px-3 mx-1 my-1 fs-7"
                  key={ip}
                >
                  {ip}
                </span>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiOverviewPage;
