import { Link, useNavigate } from "react-router-dom";
// import ReactRouterPrompt from "react-router-prompt";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getImageSize } from "react-image-size";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { getAccountByCompanyAndMarketplace } from "../../../../../../actions/account";
import * as actions from "../../../../../../actions/actions";
import { Timestamp } from "../../../../../../db";
import { createRef } from "../../../../../../db/connection";
import { RootState } from "../../../../../../setup/redux/store";
import Button from "../../../../../../styles/components/Button";
import ButtonUploadMedia from "../../../../../../styles/components/ButtonUploadMedia";
// import ButtonUploadMediaV3, {IError, IProductMediaUpload} from "../../../../../../styles/components/ButtonUploadMediaRefV3";
import UploadImageThumbnail from "../../../../../../styles/components/UploadImageThumbnail";
import UploadedVideoThumbnail from "../../../../../../styles/components/UploadedVideoThumbnail";
import "../../../../../../styles/css/color.css";
import "../../../../../../styles/css/select.css";
import {
  Media,
  attributeList,
  attributeValueList,
  productStatus,
  productStep,
} from "../../../../../models/Product";
import AlertFaieldProcess from "../../../../../modules/alert/AlertFailedProcess";
import AlertSuccessProcess from "../../../../../modules/alert/AlertSuccessProcess";
import * as lc from "../../../../../modules/localstorage/index";
import ModalConfirmationMoveToOtherPage from "../../../../../modules/modal/modalConfirmationMoveToOtherPage";
import ModalConfirmationWihtButtonSavedUnsavedChanges from "../../../../../modules/modal/modalConfirmationWithButtonSavedUnsavedChanges";
import { ProductHeaderTitle } from "../../../../../modules/product/productHeader/ProductHeaderTitle";
import * as ReduxProductList from "../../../../../modules/product/productList/ProductListSlice";
import * as ReduxProduct from "../../../../../modules/product/redux/ProductSlice";
import { Loading } from "../../../../../modules/util/Loading";
import {
  formatSize,
  productMediaCheckFile,
  productMediaUpload,
  uploadFile,
} from "../../../../../modules/util/Utils";
import { PageLink, PageTitle } from "../../../../core";
import ProductMobileIcon from "./ProductMobileIcon";
import { ProductStep } from "../../../../../modules/product/productHeader/productStep";

interface MediaOption {
  isSummary?: boolean;
  onClickUpload?: boolean;
}

const MediaUpload: React.FC<MediaOption> = ({
  isSummary = false,
  onClickUpload = false,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();

  /***
   * Media Page - Redux Data
   */
  const rd_productID = useSelector(
    (state: RootState) => state.Product.productID
  );
  const reduxNewProduct = useSelector(
    (state: RootState) => state.Product.newProduct
  );
  const rd_ProductPhotos = useSelector(
    (state: RootState) => state.Product.productPhotos
  );
  const rd_ProductVideo = useSelector(
    (state: RootState) => state.Product.productVideo
  );
  const rd_VideoYoutubeURL = useSelector(
    (state: RootState) => state.Product.videoYoutubeURL
  );
  const rd_PhotosCoverIndex = useSelector(
    (state: RootState) => state.Product.coverIndex
  );
  const rd_dataProduct = useSelector(
    (state: RootState) => state.Product.newProduct
  );
  const rd_Shipments = useSelector(
    (state: RootState) => state.Product.shipments
  );
  const rd_SelectedCompanies = useSelector(
    (state: RootState) => state.Product.companies
  );

  const rd_marketplaces = useSelector(
    (state: RootState) => state.Product.marketplaces
  );

  const rd_isProductHaveVariants = useSelector(
    (state: RootState) => state.Product.isProductHaveVariants
  );

  const rd_Variants = useSelector((state: RootState) => state.Product.variants);

  const rd_VariantMatrix: any = useSelector(
    (state: RootState) => state.Product.variantsMatrix
  );
  const rd_VariantPhotos: any = useSelector(
    (state: RootState) => state.Product.productVariantPhotos
  );
  const reduxSelectedBrandData = useSelector(
    (state: RootState) => state.Product.selectedBrandData
  );
  const rd_tempSelectedStorefront: string = useSelector(
    (state: RootState) => state.Product.tempSelectedStorefront
  );
  const rd_categoryAttributes = useSelector(
    (state: RootState) => state.Product.categoryAttributes
  );

  const URI = rd_productID ? "/product/update" : "/product/create-product";

  const UsersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Storefront.Column.Product"),
      path: `${URI}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.BPI"),
      path: `${URI}basic-information`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  /***
   * Media Page - Variable State
   */
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [isInvalidPhotoFileFormat, setInvalidPhotoFileFormat] =
    useState<boolean>(false);
  const [isInvalidPhotoFileSize, setInvalidPhotoFileSize] =
    useState<boolean>(false);
  const [isValidPhoto, setValidPhoto] = useState<boolean>(true);
  const [isInvalidVideoFileFormat, setInvalidVideoFileFormat] =
    useState<boolean>(false);
  const [isInvalidVideoFileSize, setInvalidVideoFileSize] =
    useState<boolean>(false);
  const [isVideoDurationToLong, setVideoDurationToLong] =
    useState<boolean>(false);
  const [isVideoDurationToShort, setVideoDurationToShort] =
    useState<boolean>(false);

  const [isInvalidVideoURL, setInvalidVideoURL] = useState<boolean>(false);
  const [isMaximumPhotos, setMaximumPhotos] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);
  const imageExtention: string = "image/png, image/jpg, image/jpeg"; //jpg, jpeg, png
  const videoExtention: string = "video/mp4"; //mp4
  const [buttonUploadPhotos, setButtonUploadPhotos] = useState<number[]>([
    1, 1, 1, 1, 1,
  ]);
  const [dataYT, setDataYT] = useState<any>("");
  const arrInputRefImage: any[] = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const InputRefVideo: any = useRef(null);
  const [videoDuration, setVideoDuration] = useState<number>(0);

  const useruid = lc.getItemLC(lc.LCName.UserID);
  const refUser = createRef("users", useruid);

  const videoRef = useRef<HTMLVideoElement>(null);

  const cleanErrPhotos = async () => {
    setInvalidPhotoFileFormat(false);
    setInvalidPhotoFileSize(false);
    setValidPhoto(true);
    setMaximumPhotos(false);
  };

  const checkErrPhotos = () => {
    if (
      isInvalidPhotoFileFormat ||
      isInvalidPhotoFileSize ||
      !isValidPhoto ||
      isMaximumPhotos
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkErrVideo = () => {
    if (
      isVideoDurationToShort ||
      isVideoDurationToLong ||
      isInvalidVideoFileFormat ||
      isInvalidVideoFileSize
    ) {
      return true;
    } else {
      return false;
    }
  };

  /***
   * Media Page - process selected image as cover
   */

  const onSelectedImageAsCover = (index: number) => {
    dispatch(ReduxProduct.setCoverIndex(index));
  };

  /***
   * Media Page - Process Upload Media
   */
  const resetUploadProductMedia = async (uploadType: string, index: number) => {
    if (uploadType === productMediaUpload.image) {
      if (rd_ProductPhotos.length < 5) {
        arrInputRefImage[index].current.value = null;
      }
    } else {
      if (InputRefVideo && InputRefVideo !== null) {
        InputRefVideo.current.value = null;
      }
    }
  };

  const handleLoadedMetadata = () => {
    const video = videoRef.current;
    let videoDuration = video?.duration;
    if (!video) return;
    setVideoDuration(video.duration);
    console.log(`The video is ${video.duration} seconds long.`);
    const videoDurationSecond = videoDuration?.toString().split(".")[0];
    console.log("video Duration in Second is: " + videoDurationSecond);
  };

  /***
   * Media Page - Process Upload Media
   */
  const uploadProductMedia = async (event: any, uploadType: string) => {
    if (event.target.files && event.target.files.length > 0) {
      //Reset Error
      if (uploadType === productMediaUpload.image) {
        setInvalidPhotoFileFormat(false);
        setInvalidPhotoFileSize(false);
        setValidPhoto(true);
        setMaximumPhotos(false);
      } else if (uploadType === productMediaUpload.video) {
        setInvalidVideoFileFormat(false);
        setInvalidVideoFileSize(false);
        setVideoDurationToLong(false);
        setVideoDurationToShort(false);
        setInvalidVideoURL(false);
        console.log("Check duration : " + event.target.files[0].duration);
      }
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index] as File;

        console.log(
          "Size: " +
            element.size +
            " - Name : " +
            element.name +
            " - type : " +
            element.type
        );
        // const URLObj = URL.createObjectURL(element);
      }

      let productPhotoLength = rd_ProductPhotos.length;
      console.log("Check 1 : " + event.target.files.length);
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        productPhotoLength++;
        if (uploadType === productMediaUpload.image && productPhotoLength > 5) {
          console.log("Masuk Maximmum");
          setMaximumPhotos(true);
          continue;
        }
        if (element) {
          //Check File Type
          const size: number = element.size;
          const tempArrFileType: string = element.type;
          const arryFileType = tempArrFileType.split("/");
          const fileName = element.name;

          if (
            arryFileType === undefined ||
            arryFileType.length < 1 ||
            arryFileType[0] === undefined
          ) {
            //return error
            console.log("Media - Error File Invalid Format");
            setInvalidPhotoFileFormat(true);
            return;
          } else {
            // const [result, error] = checkFile2("image","jpg",5);
            const URLObj = URL.createObjectURL(element);
            let photosWidth: number = 0;
            let photosHeight: number = 0;
            if (uploadType === productMediaUpload.image) {
              const { width, height } = await getImageSize(URLObj);
              photosWidth = width;
              photosHeight = height;
            }
            const [result, error, maxSize] = productMediaCheckFile(
              arryFileType[0],
              arryFileType[1],
              size,
              photosWidth,
              photosHeight
            );

            if (error !== undefined) {
              if (maxSize !== 0) {
                console.log(
                  "Media - Error Process Validate Maxsize: " + maxSize
                );
                if (result === productMediaUpload.image) {
                  setInvalidPhotoFileSize(true);
                } else if (result === productMediaUpload.video) {
                  setInvalidVideoFileSize(true);
                  resetUploadProductMedia(productMediaUpload.video, 0);
                }
                return;
              } else {
                console.log("Media -  Process Validate File : " + error);
                if (result === productMediaUpload.image) {
                  setInvalidPhotoFileFormat(true);
                } else if (result === productMediaUpload.video) {
                  setInvalidVideoFileFormat(true);
                }
              }
              return;
            }

            if (!result || result === "") {
              console.log("Media - Error File Invalid Format");
              if (result === productMediaUpload.image) {
                setInvalidPhotoFileFormat(true);
              } else if (result === productMediaUpload.video) {
                setInvalidVideoFileFormat(true);
              }
              return;
            } else {
              let newFile = {
                url: URLObj,
                size: formatSize(size),
                type: tempArrFileType,
                extension: arryFileType[1],
                name: fileName,
              };
              if (result === productMediaUpload.image) {
                dispatch(ReduxProduct.addProductPhotos(newFile));
              } else if (result === productMediaUpload.video) {
                dispatch(ReduxProduct.setProductVideo(newFile));
              } else {
                console.log("Media - Error File Invalid Format");
                if (result === productMediaUpload.image) {
                  setInvalidPhotoFileFormat(true);
                } else if (result === productMediaUpload.video) {
                  setInvalidVideoFileFormat(true);
                }
              }
            }
          }
        }
      }
    }
  };

  /***
   * Media Page - Handle Next Page
   */
  const handleNext = async () => {
    if ((await validateData()) && !isSummary) {
      nav(`${URI}/package-weight`);
    } else {
      return;
    }
  };

  /***
   * Media Page - Saved Changes
   */
  const handleSavedChanges = async () => {
    let newProduct = { ...rd_dataProduct };
    let clientRef: any;
    const lcClient = lc.getItemLC(lc.LCName.Client);
    if (lcClient) {
      clientRef = createRef("clients", lcClient.id);
      newProduct.client = clientRef;
    }
    let resultFirestorageURL: string[] = [];
    let resultPhotosMediaID: string[] = [];
    let resultVideoMediaID: string = "";
    let isSuccess = true;
    let productID = "";
    let resultShipmentsId: string[] = [];
    let resultMarketplaceId: string[] = [];
    let resultVariantId: string[] = [];
    let resultProductVariantPhoto: string[] = [];

    setLoading(true);

    if (!newProduct || !newProduct.id || newProduct.id === "") {
      //Create Product
      newProduct.status = productStatus.drafted;
      newProduct.lastStep = productStep.onMedia;
      productID = await actions.createDataProduct(newProduct);
      console.log("Saved Product : " + productID);
      newProduct.id = productID;
      dispatch(ReduxProduct.setNewProduct(newProduct));
    } else {
      //Update Product
      productID = newProduct.id;
      newProduct.updatedAt = Timestamp.now();
      newProduct.updatedBy = refUser;
      actions.updateDataProduct(productID, newProduct);
      actions.deleteMediaDocData(productID);
      actions.deleteShipmentsData(productID);
      actions.deleteCompanyAndMarketplacesData(productID);
      actions.removeDataVariants(productID);
      actions.removeDataProductVariants(productID);
      actions.removeDataCategoryAttributes(productID);
    }

    if (newProduct && newProduct.id && newProduct.id !== "") {
      //update numberofproducts storefront realtime db
      if (
        reduxNewProduct &&
        reduxNewProduct.storeFront &&
        reduxNewProduct.storeFront.id
      ) {
        const storefrontId = reduxNewProduct.storeFront.id.toString();
        //jika punya tempStorefront dan id selected storefront sama tidak perlu update
        //jika temp dan id storefront tidak sama update increment dan decrement
        if (
          rd_tempSelectedStorefront &&
          rd_tempSelectedStorefront !== reduxNewProduct.storeFront.id
        ) {
          const tempStorefrontId = rd_tempSelectedStorefront;
          //const updateDecrementNumberOfProductStorefront =
          await actions.decrementNumberOfProductStorefront(tempStorefrontId);
          // updateIncrementNumberOfProductStorefront =
          await actions.incrementNumberOfProductStorefront(storefrontId);
          console.log(
            "update decrementNumberOfProductStorefront for storefront id : ",
            tempStorefrontId
          );
          console.log(
            "update incrementNumberOfProductStorefront for storefront id : ",
            storefrontId
          );
        }
        // tidak punya tempStorefront maka increment
        if (!rd_tempSelectedStorefront || rd_tempSelectedStorefront === "") {
          // updateIncrementNumberOfProductStorefront =
          await actions.incrementNumberOfProductStorefront(storefrontId);
          console.log(
            "update incrementNumberOfProductStorefront for storefront id : ",
            storefrontId
          );
        }
      }
      /***
       * Media Page - Process Save Photos
       */
      if (rd_ProductPhotos && rd_ProductPhotos.length > 0) {
        for (let index = 0; index < rd_ProductPhotos.length; index++) {
          const element = rd_ProductPhotos[index];
          //Upload File to Storage
          const result_FS_URL = await uploadToFirestorage(
            element.url,
            element.type,
            element.name,
            "product/photos/" + newProduct.id
          );
          console.log("Result URL Photos : " + result_FS_URL);
          if (result_FS_URL && result_FS_URL !== "") {
            resultFirestorageURL.push(result_FS_URL);
          } else {
            isSuccess = false;
          }

          //Create Media Collection
          const dataMedia = await actions.formatMediaCollection(
            element.url,
            element.name,
            element.extension,
            element.type,
            element.size,
            result_FS_URL,
            rd_PhotosCoverIndex === index ? true : false,
            "",
            refUser
          );
          const mediaID = await actions.createDataMedia(
            newProduct.id,
            "photos",
            dataMedia
          );
          if (mediaID && mediaID !== "") {
            resultPhotosMediaID.push(mediaID);
          } else {
            isSuccess = false;
          }
        }
      }

      /***
       * Media Page - Process Save Video
       */
      if (rd_ProductVideo && rd_ProductVideo !== "") {
        // if (rd_VideoYoutubeURL && rd_VideoYoutubeURL !== "") {
        //Upload File to Storage
        const result_FS_URL = await uploadToFirestorage(
          rd_ProductVideo.url,
          rd_ProductVideo.type,
          rd_ProductVideo.name,
          "product/videos/" + newProduct.id
        );
        if (result_FS_URL && result_FS_URL !== "") {
          resultFirestorageURL.push(result_FS_URL);
        } else {
          isSuccess = false;
        }

        //Create Media Collection
        const dataMedia = await actions.formatMediaCollection(
          rd_ProductVideo.url,
          rd_ProductVideo.name,
          rd_ProductVideo.extension,
          rd_ProductVideo.type,
          rd_ProductVideo.size,
          result_FS_URL,
          false,
          rd_VideoYoutubeURL,
          refUser
        );
        const mediaID = await actions.createDataMedia(
          newProduct.id,
          "video",
          dataMedia
        );
        if (mediaID && mediaID !== "") {
          resultVideoMediaID = mediaID;
        } else {
          isSuccess = false;
        }
        // }
      }

      //save shipments data
      if (rd_Shipments && rd_Shipments.length > 0) {
        console.log("check redux rd_Shipments", rd_Shipments);
        for (let index = 0; index < rd_Shipments.length; index++) {
          const element = rd_Shipments[index];
          // check isActive and have types
          // save if isActive or not active but have types
          if (
            element.isActive ||
            (!element.isActive && element.types.length > 0)
          ) {
            const shipmentParentRef = createRef("shipments", element.id);
            console.log("parent shipments id : ", shipmentParentRef);
            console.log("element types : ", element.types);
            let typesData: any = [];
            for (let index = 0; index < element.types.length; index++) {
              const type = element.types[index];
              const shipmentChildrenRef = createRef("shipments", type.id);
              console.log("children shipments id : ", shipmentParentRef);
              const dataShipmentsType = await actions.formatShipmentsType(
                shipmentChildrenRef,
                type.name
              );
              typesData = [...typesData, dataShipmentsType];
            }
            console.log("check typesData : ", typesData);
            const dataShipments = await actions.formatShipmentsCollection(
              shipmentParentRef,
              element.name,
              element.isActive,
              typesData,
              refUser
            );
            const shipmentId = await actions.createDataShipments(
              productID,
              dataShipments
            );
            if (shipmentId && shipmentId !== "") {
              resultShipmentsId.push(shipmentId);
            } else {
              isSuccess = false;
            }
          }
        }
      }
      // save company data
      if (rd_SelectedCompanies && rd_SelectedCompanies.length > 0) {
        const companies = rd_SelectedCompanies;
        const marketplaces = rd_marketplaces;

        // create companies
        let newCompanies: any = [];
        console.log("companies : ", companies);
        for (let index = 0; index < companies.length; index++) {
          const company = companies[index];
          const companyRef = createRef("company", company.company);
          newCompanies.push({ ...company, company: companyRef });
        }
        // const dataCompanies
        await actions.createDataCompany(productID, newCompanies);

        // create marketplace collection
        for await (const market of marketplaces) {
          // destructure
          let { company, category, isActive, marketplace } = market;

          // set company ref
          const companyRef = createRef("company", company!);

          // set category to empty array rather than undefined
          const categoryData = { ...(category[0] || []) };
          let arrayData = [];
          arrayData.push({ ...categoryData });
          // const categoryData = category || [];

          const findAccount = await getAccountByCompanyAndMarketplace(
            company,
            marketplace
          );
          const account: any =
            findAccount && findAccount[0] && findAccount[0].id
              ? findAccount[0].id
              : "";
          const accountRef = createRef("account", account ? account : "");

          //find attributes list by last category id
          let attributesList: attributeList[] = [];
          const cat: any[] = Object.values(category[0]);
          console.log(`category : ${cat}`);
          const lastCategory = cat.find(
            (category: any) => category.hasChildren === false
          );
          console.log(`find lastCategory : ${JSON.stringify(lastCategory)}`);
          if (
            rd_categoryAttributes &&
            rd_categoryAttributes.shopee &&
            lastCategory &&
            marketplace &&
            marketplace.toLowerCase() === "shopee"
          ) {
            const attributes = rd_categoryAttributes.shopee;
            const findAttributes = attributes.filter((att: any) => {
              if (att.inputValues && att.inputValues.length > 0) {
                const findCategoryById = att.categoryId?.find((cat: string) => {
                  if (cat === lastCategory?.id) return cat;
                });
                if (findCategoryById) return att;
              }
            });
            console.log(`findAttributes : ${findAttributes}`);
            if (findAttributes && findAttributes.length > 0) {
              for (let index = 0; index < findAttributes.length; index++) {
                const attr = findAttributes[index];
                const inputValues = attr.inputValues!;
                const valueList: attributeValueList[] = [];
                console.log(`inputValues : ${JSON.stringify(inputValues)}`);
                if (
                  attr.inputType === "MULTIPLE_SELECT" ||
                  attr.inputType === "MULTIPLE_SELECT_COMBO_BOX"
                ) {
                  const multipleValues = inputValues[0];
                  if (multipleValues && multipleValues.length > 0) {
                    for (
                      let index = 0;
                      index < multipleValues.length;
                      index++
                    ) {
                      const values = multipleValues[index];
                      console.log(`values for index ${index} : ${values}`);
                      let originalValueName: string = "";
                      if (values && values.value && values?.label) {
                        const getValueByInputType =
                          values?.value === values?.label ? 0 : values?.value;
                        console.log(
                          `getValueByInputType : ${getValueByInputType}`
                        );
                        if (getValueByInputType === 0)
                          originalValueName = values?.label;
                        const createValueList: attributeValueList =
                          await actions.formatAttributeValueList(
                            getValueByInputType,
                            "",
                            originalValueName
                          );
                        valueList.push({ ...createValueList });
                      }
                    }
                    console.log(`multiple : ${JSON.stringify(valueList)}`);
                  }
                } else {
                  const values = inputValues[0];
                  console.log(`values for index ${0} : ${values}`);
                  let getValueByInputType: number = 0;
                  let originalValueName: string = "";
                  if (values !== undefined && values !== null) {
                    getValueByInputType =
                      (attr.inputType === "COMBO_BOX" ||
                        attr.inputType === "DROP_DOWN") &&
                      values
                        ? values.value === values.label
                          ? 0
                          : values.value
                        : 0;
                    if (getValueByInputType === 0) {
                      originalValueName =
                        values && values.label
                          ? values.label
                          : values
                          ? values
                          : "";
                      if (attr.inputValidation === "DATE_TYPE")
                        originalValueName = values.replaceAll("-", "_");
                    }
                  }
                  const valueUnit =
                    attr.formatType === "QUANTITATIVE" &&
                    inputValues[1] &&
                    inputValues[1].value
                      ? inputValues[1].value
                      : "";
                  console.log(`getValueByInputType : ${getValueByInputType}`);
                  console.log(`originalValueName : ${originalValueName}`);
                  console.log(`value unit : ${valueUnit}`);
                  const createValueList: attributeValueList =
                    await actions.formatAttributeValueList(
                      getValueByInputType,
                      valueUnit,
                      originalValueName
                    );
                  if (
                    (createValueList &&
                      createValueList.valueId === 0 &&
                      createValueList.originalValueName !== "" &&
                      createValueList.originalValueName &&
                      createValueList.originalValueName !== null) ||
                    (createValueList &&
                      createValueList.valueId &&
                      createValueList.valueId > 0)
                  ) {
                    console.log(`valueList : ${createValueList}`);
                    valueList.push({ ...createValueList });
                  }
                }
                console.log(`result valueList : ${valueList}`);
                if (valueList && valueList.length > 0) {
                  const createAttributeModel: attributeList =
                    await actions.formatAttributeList(
                      attr.attributeId,
                      valueList
                    );
                  console.log(`attributeModel : ${createAttributeModel}`);
                  attributesList.push({ ...createAttributeModel });
                }
              }
            }
            console.log(
              `result attributesList: ${JSON.stringify(attributesList)}`
            );
          }
          console.log(`attributesList: ${JSON.stringify(attributesList)}`);
          const dataMarketplaces = await actions.formatMarketplacesCollection(
            companyRef,
            isActive,
            marketplace,
            arrayData,
            refUser,
            accountRef,
            attributesList && attributesList.length > 0 ? attributesList : []
          );

          const marketplaceId = await actions.createDataMarketplace(
            productID,
            dataMarketplaces
          );
          if (marketplaceId && marketplaceId !== "") {
            resultMarketplaceId.push(marketplaceId);
          } else {
            isSuccess = false;
          }
        }
      }

      //if have photo from redux upload then set image in option list
      if (rd_VariantPhotos && rd_VariantPhotos.length > 0) {
        //upload to firestore
        console.log("rd_VariantPhotos : ", rd_VariantPhotos);
        const sortData = [...rd_VariantPhotos];
        const sortVariantPhoto = sortData.sort((a: any, b: any) =>
          a.index > b.index! ? 1 : -1
        );
        console.log("sortVariantPhoto : ", sortVariantPhoto);

        for (let index = 0; index < sortVariantPhoto.length; index++) {
          const element = sortVariantPhoto[index];
          let elem = {
            ...element,
            name: element.name || "variant-photo",
            type: element.type || "jpeg",
          };
          const result_FS_URL = await uploadToFirestorage(
            elem.url,
            elem.type,
            elem.name,
            "product/variant-photos/" + newProduct.id
          );
          console.log("Result URL Variant Photos : " + result_FS_URL);
          if (result_FS_URL && result_FS_URL !== "") {
            resultProductVariantPhoto.push(result_FS_URL);
          } else {
            isSuccess = false;
          }
        }
      }
      //save variant category
      if (
        rd_isProductHaveVariants === "true" &&
        rd_Variants &&
        rd_Variants.length > 0
      ) {
        for (let i = 0; i < rd_Variants.length; i++) {
          const variant: any = rd_Variants[i];
          console.log("VAR, variant", variant);
          const variantName = variant.name ? variant.name.trim() : "";
          const optionList = variant.optionList ? variant.optionList : [];
          let optionListData: any = [];
          if (i === 0) {
            for (let i = 0; i < optionList.length; i++) {
              const opt = optionList[i].option;
              const image = resultProductVariantPhoto[i];
              const optionData = await actions.formatVariantOptionList(
                opt ? opt : "",
                image ? image : ""
              );
              optionListData.push(optionData);
            }
          } else {
            for (let i = 0; i < optionList.length; i++) {
              const opt = optionList[i].option;
              const optionData =
                await actions.formatVariantOptionListOptionOnly(opt ? opt : "");
              optionListData.push(optionData);
            }
          }
          console.log("optionListData : ", optionListData);

          let variantId: any;
          if (variantName !== "" || optionList.length > 0) {
            const dataVariant = await actions.formatVariantCollection(
              variantName,
              variant.index,
              optionListData && optionListData.length > 0
                ? optionListData
                : optionList,
              refUser
            );

            variantId = actions.createDataVariant(productID, dataVariant);
          }

          if (variantId && variantId !== "") {
            console.log("success create data variant");
          } else {
            isSuccess = false;
          }
        }
      }
      //save variant list/matrix
      console.log("RD VAR MATRIX", rd_VariantMatrix);
      if (rd_VariantMatrix && rd_VariantMatrix.length > 0) {
        console.log("VARLIST enter save matrix");
        let variantId: any;
        for await (const variantMatrix of rd_VariantMatrix) {
          const productVariant = variantMatrix;
          console.log("productVariant : ", productVariant);
          const dataProductVariant =
            await actions.formatProductVariantCollection(
              productVariant.tierIndex,
              productVariant.sku ? productVariant.sku : "",
              productVariant.weight ? productVariant.weight : 0,
              productVariant.isActive === true ? true : false,
              productVariant.isMainVariant === true ? true : false,
              productVariant.stock ? productVariant.stock : 0,
              refUser
            );

          variantId = await actions.createDataProductVariant(
            productID,
            dataProductVariant
          );
        }
        if (variantId && variantId !== "") {
          resultVariantId.push(variantId);
          console.log("success create data variant");
        } else {
          isSuccess = false;
        }
      }
      //save category attributes to subCollection categoryAttributes
      console.log(`start process category attributes`);
      if (rd_categoryAttributes && rd_categoryAttributes.shopee) {
        const categoryAttributes = rd_categoryAttributes.shopee;
        for (let index = 0; index < categoryAttributes.length; index++) {
          const attribute = categoryAttributes[index];
          console.log(`attributes id : ${attribute.attributeId}`);
          const categoryId =
            attribute.categoryId && attribute.categoryId.length > 0
              ? attribute.categoryId
              : [];
          //reformat input values to get id value or string
          let inputValues: any[] = [];
          if (
            attribute.inputValues &&
            (attribute.inputValues[0] || attribute.inputValues[1])
          ) {
            if (
              attribute.inputType === "MULTIPLE_SELECT" ||
              attribute.inputType === "MULTIPLE_SELECT_COMBO_BOX"
            ) {
              //check if have value and save in subCollection categoryAttributes
              const multipleValues = attribute.inputValues[0];
              let multiple: any[] = [];
              for (let index = 0; index < multipleValues.length; index++) {
                const value = multipleValues[index];
                multiple.push({ ...value });
              }
              inputValues.push({ ...multiple });
              console.log(
                `input values multiple : ${JSON.stringify(inputValues)}`
              );
            } else {
              if (attribute.inputValues[0] || attribute.inputValues[1]) {
                inputValues.push(attribute.inputValues[0] || null);
              }
              attribute.inputValues[1] &&
                inputValues.push(attribute.inputValues[1]);
              console.log(
                `input values single : ${JSON.stringify(inputValues)}`
              );
            }
            const categoryAttributeModel =
              await actions.formatProductCategoryAttributes(
                attribute.attributeId.toString(),
                inputValues,
                categoryId,
                "shopee",
                refUser
              );
            console.log(
              `categoryAttributeModel : ${JSON.stringify(
                categoryAttributeModel
              )}`
            );
            await actions.createDataProductCategoryAttributes(
              productID,
              attribute && attribute.attributeId
                ? attribute.attributeId.toString()
                : "",
              categoryAttributeModel
            );
          }
        }
      }

      if (isSuccess) {
        dispatch(ReduxProduct.cleanReduxProduct(true));
        setLoading(false);
        nav("/product/list");
      } else {
        //Revert Data and Alert Failed Save Data
        actions.revertUploadAndSavedMedia(
          resultFirestorageURL,
          resultPhotosMediaID,
          resultVideoMediaID,
          newProduct.id
        );
        const failedMediaAlertNotif = document.getElementById(
          "failedProcessAlertNotif"
        ) as HTMLDivElement;
        failedMediaAlertNotif.innerHTML = t("AddNewProduct.Alert.SavingFailed");
        const buttonFailedAlert = document.getElementById(
          "AddNewProduct.Alert.SavingFailed"
        );
        if (buttonFailedAlert) {
          buttonFailedAlert.click();
        }
      }
    } else {
      //Modal retry Save
      const failedMediaAlertNotif = document.getElementById(
        "failedProcessAlertNotif"
      ) as HTMLDivElement;
      failedMediaAlertNotif.innerHTML = t("AddNewProduct.Alert.SavingFailed");
      const buttonFailedAlert = document.getElementById(
        "AddNewProduct.Alert.SavingFailed"
      );
      if (buttonFailedAlert) {
        buttonFailedAlert.click();
      }
    }
  };

  /***
   * Media Page - Unsaved Changes
   */
  const handleUnsavedChanges = () => {
    dispatch(ReduxProduct.cleanReduxProduct(true));
    nav("/product/list");
  };

  const uploadToFirestorage = async (
    file: any,
    filetype: string,
    fileName: string,
    path: string
  ) => {
    const inputcontentType = filetype;
    const inputFileName = fileName;
    return await uploadFile(file, inputcontentType, inputFileName, path);
  };

  const removeProductPhotos = (rmIndex: any) => {
    console.log("removed Photos : " + rmIndex);
    cleanErrPhotos();
    let newProductPhotos: any[] = [];
    for (let index = 0; index < rd_ProductPhotos.length; index++) {
      const element = rd_ProductPhotos[index];
      if (index !== rmIndex) {
        newProductPhotos.push(element);
      }
    }
    if (rmIndex === rd_PhotosCoverIndex) {
      dispatch(ReduxProduct.setCoverIndex(0));
    }
    console.log("Hasil Redux Photos : " + newProductPhotos.toString());
    dispatch(ReduxProduct.setProductPhotos(newProductPhotos));
  };

  const removeProductVideo = () => {
    console.log("remove Video");
    // cleanErrPhotos();
    setVideoDurationToShort(false);
    setVideoDurationToLong(false);
    dispatch(ReduxProduct.setProductVideo(""));
  };

  useEffect(() => {
    if (onClickUpload && isSummary) handleNext();
  }, [onClickUpload, isSummary]);

  useEffect(() => {
    const totalButtonUpload = 5 - rd_ProductPhotos.length;

    let newButtonUpload: number[] = [];
    if (totalButtonUpload > 0) {
      for (let index = 0; index < totalButtonUpload; index++) {
        newButtonUpload.push(1);
      }
    }
    console.log("Hasil 2 : " + newButtonUpload);

    setButtonUploadPhotos(newButtonUpload);
  }, [rd_ProductPhotos]);

  useEffect(() => {
    dispatch(ReduxProduct.setLastStep(productStep.onMedia));
    if (!isSummary) {
      if (
        !rd_dataProduct ||
        !rd_dataProduct.brand ||
        !rd_dataProduct.name ||
        rd_dataProduct.name === "" ||
        !rd_dataProduct.storeFront ||
        !rd_dataProduct.description ||
        rd_dataProduct.description === ""
      ) {
        if (rd_productID !== "") {
          return;
        } else {
          nav(`${URI}/basic-information`);
        }
      }
    }
  }, []);

  //useEffect for editing data
  // useEffect(() => {
  //   if (rd_productID !== "") {
  //     let newProduct = { ...reduxNewProduct };
  //     let dataProductFB: any;
  //     const getProduct: any = productList.find(
  //       (product) => product.id === rd_productID
  //     );
  //     //Storefront
  //     let dataStorefront: any;
  //     let storefrontName: string;
  //     let storefrontSetter: any;
  //     //Brand
  //     let dataBrand: any;
  //     let brandName: string;
  //     let brandSetter: any;
  //     console.log("DATPROD, GET: ", getProduct);
  //     if(getProduct.name){
  //       if(!reduxNewProduct.name){
  //         console.log("productname that's get: ", getProduct.name);
  //         dispatch(ReduxProduct.setProductName(getProduct.name))
  //         dispatch(ReduxProduct.setProductNameLength(getProduct.name.length))
  //         newProduct.name = getProduct.name;
  //         newProduct.nameInsensitive = getProduct.name.trim().toLowerCase();
  //         dispatch(ReduxProduct.setNewProduct(newProduct))
  //       }else{
  //         console.log("data product name is avaible")
  //       }
  //     }
  //     if(getProduct.storeFront){
  //       if(!reduxNewProduct.storeFront){
  //         console.log("storefront that's get: ", getProduct.storeFront.id);
  //         getStorefrontById(getProduct.storeFront.id).then(
  //           (data) => {
  //           dataStorefront = data;
  //           storefrontName = dataStorefront.data.name;
  //           storefrontSetter = {
  //             label: storefrontName,
  //             value: getProduct.storeFront.id
  //           }
  //           console.log("SRF, DATA SET TO REDUX ", storefrontSetter)
  //           dispatch(ReduxProduct.setSelectedStoreFrontData(storefrontSetter));
  //         })
  //       }else{
  //         console.log("data product storefront is avaible")
  //       }
  //     }
  //     if(getProduct.brand){
  //       if(!reduxNewProduct.brand){
  //         console.log("brand that's get: ", getProduct.brand.id);
  //         getBrandById(getProduct.brand.id).then(
  //           (data) => {
  //           dataBrand = data;
  //           console.log("BRN, DATA ", dataBrand)
  //           brandName = dataBrand.brandName;
  //           brandSetter = {
  //             label: brandName,
  //             value: getProduct.brand.id
  //           }
  //           console.log("BRN, DATA SET TO REDUX ", brandSetter)
  //           dispatch(ReduxProduct.setSelectedBrandData(brandSetter));
  //         })
  //       }else{
  //         console.log("data product brand is avaible")
  //       }
  //     }
  //     if(getProduct.description){
  //       if(!reduxNewProduct.description){
  //         console.log("description that's get: ", getProduct.description);
  //         dispatch(ReduxProduct.setDescriptionProduct(getProduct.description))
  //         dispatch(ReduxProduct.setDescriptionProductLength(getProduct.description.length))
  //         dispatch(ReduxProduct.setNewProduct({...newProduct, description : getProduct.description}))
  //       }
  //     }
  //     //Image initiation when in media
  //     if (getProduct.coverImage !== "") {
  //       getProductPhotosByProductId(rd_productID).then((photos) => {
  //         console.log("data product photos in firebase: ", photos);
  //         const listPhotos = photos.map((photo) => {
  //           console.log("DATA PERPHOTO OKE", photo);
  //           let dataPhoto = {
  //             url: photo.fileURL,
  //             size: photo.fileSize,
  //             type: photo.fileType,
  //             extension: photo.extension,
  //             name: photo.fileName,
  //           };
  //           console.log("DATA PERPHOTO OKE, FOR SETTER", dataPhoto);
  //           return dataPhoto;

  //         });
  //         dispatch(ReduxProduct.setProductPhotos(listPhotos));
  //       });
  //     }

  //     const isHaveVideo = getisHaveVideo(rd_productID).then((ishave) => {
  //       console.log("IS HAVE VIDEO?", ishave);
  //       if (ishave > 0) {
  //         getProductVideoByProductId(rd_productID).then((video: any[]) => {
  //           console.log("data product video in firebase: ", video);
  //           if (video.length > 0) {
  //             video.map((vid: any) => {
  //               console.log("DATA PERPHOTO OKE", video);
  //               let dataVideo = {
  //                 url: vid.fileURL,
  //                 size: vid.fileSize,
  //                 type: vid.fileType,
  //                 extension: vid.extension,
  //                 name: vid.fileName,
  //               };
  //               console.log("DATA PERPHOTO OKE, FOR SETTER", dataVideo);
  //               dispatch(ReduxProduct.setProductVideo(dataVideo));
  //             });
  //           }
  //         });
  //       } else {
  //         console.log("there is no video");
  //       }
  //     });
  //   }
  // }, [rd_productID]);

  useEffect(() => {
    let newProduct = { ...reduxNewProduct };
    if (
      reduxSelectedBrandData &&
      reduxSelectedBrandData !== null &&
      reduxSelectedBrandData.value &&
      reduxSelectedBrandData.value !== null
    ) {
      const brandRef = createRef("brands", reduxSelectedBrandData.value);
      newProduct.brand = brandRef;
      dispatch(ReduxProduct.setNewProduct(newProduct));
    }
  }, [reduxSelectedBrandData]);

  /***
   * Media Page - Validate Data
   */
  const validateData = async () => {
    let isValid = true;

    if (rd_VideoYoutubeURL !== "") {
      const youtubeCheckerLink =
        "https://www.youtube.com/oembed?url=" +
        rd_VideoYoutubeURL +
        "&format=json";
      const validYTURL = await fetch(youtubeCheckerLink)
        .then((response) =>
          response.json().then((json) => {
            if (
              JSON.stringify(json) === "" ||
              JSON.stringify(json) === "[]" ||
              JSON.stringify(json) === "{}"
            ) {
              isValid = false;
              return true;
            } else {
              setDataYT(json);
              return false;
            }
          })
        )
        .catch((error) => {
          console.log("403/404 Video");
          isValid = false;
          return true;
        });

      setInvalidVideoURL(validYTURL);

      console.log(
        "check is youtube link valid: ",
        isInvalidVideoURL + " - " + isValid
      );
    }

    if (rd_ProductPhotos && rd_ProductPhotos.length <= 0) {
      setValidPhoto(false);
      isValid = false;
    }

    if (rd_ProductVideo && rd_ProductVideo !== "") {
      if (videoDuration > 61) {
        setVideoDurationToLong(true);
        isValid = false;
      } else if (videoDuration < 10) {
        setVideoDurationToShort(true);
        isValid = false;
      }
    }

    if (
      isInvalidPhotoFileFormat &&
      isInvalidPhotoFileSize &&
      !isValidPhoto &&
      isInvalidVideoFileFormat &&
      isInvalidVideoFileFormat &&
      isInvalidVideoFileSize &&
      isVideoDurationToLong &&
      isVideoDurationToShort &&
      isInvalidVideoURL &&
      isMaximumPhotos &&
      isInvalidVideoURL
    ) {
      isValid = false;
    }
    console.log("isvalid : " + isValid);
    return isValid;
  };

  /***
   * Media Page - UI
   */
  return (
    <>
      {!isSummary && (
        <div>
          {/* Modal Success & Modal Failed*/}
          <button
            id="triggerSuccessProcess"
            data-bs-toggle="modal"
            data-bs-target="#modal_success_process"
            hidden
          ></button>
          <AlertSuccessProcess />

          <button
            id="triggerFailedProcess"
            data-bs-toggle="modal"
            data-bs-target="#modal_failed_process"
            hidden
          ></button>
          <AlertFaieldProcess />
          {/* End of Modal Success & Modal Failed*/}

          <PageTitle breadcrumbs={UsersBreadcrumbs}>
            {t("AddNewProduct.Title.Media")}
          </PageTitle>

          <ModalConfirmationMoveToOtherPage
            isDirty={
              rd_ProductPhotos.length > 0 ||
              rd_ProductVideo ||
              (rd_dataProduct &&
                (!rd_dataProduct.id || rd_dataProduct.id === "")) ||
              (rd_VideoYoutubeURL && rd_VideoYoutubeURL !== "")
            }
            path={`${URI}/`}
            onSave={() =>
              handleSavedChanges().then(() => {
                setLoading(false);
                dispatch(ReduxProductList.cleanReduxProductList(true));
                dispatch(ReduxProduct.cleanReduxProduct(true));
              })
            }
            onUnsave={() => handleUnsavedChanges()}
            isEmpty={false}
          />

          <ModalConfirmationWihtButtonSavedUnsavedChanges
            id="handleCancelButton"
            title={`${t("Alert.Info")}`}
            body={t("Alert.UnsavedChanges")}
            savedOption={`${
              rd_ProductPhotos.length > 0 ||
              rd_ProductVideo ||
              (rd_dataProduct &&
                (!rd_dataProduct.id || rd_dataProduct.id === "")) ||
              (rd_VideoYoutubeURL && rd_VideoYoutubeURL !== "")
                ? t("Alert.Button.SaveChanges")
                : ""
            }`}
            unsavedOption={`${t("Alert.Button.LeaveWithoutSave")}`}
            cancelOption={`${t("Alert.Button.Cancel")}`}
            onSavedChanges={() =>
              handleSavedChanges().then(() => {
                setLoading(false);
                dispatch(ReduxProductList.cleanReduxProductList(true));
                dispatch(ReduxProduct.cleanReduxProduct(true));
                nav("product/list");
              })
            }
            onUnsavedChanges={() => handleUnsavedChanges()}
          />
        </div>
      )}

      {/* {!isTabletOrMobile && (
        <div className="card mb-1 mb-xl-2 p-3 d-flex flex-row space">
          <div>
            <div className="h2">
              <h2 className="w-100">
                <span
                  className="bi bi-window-plus fs-2x"
                  style={{ marginRight: "10px", marginLeft: "10px" }}
                ></span>
                {t("AddNewProduct.Title.Media")}
              </h2>
            </div>
          </div>
        </div>
      )} */}

      <div
        className={clsx("d-flex flex-column flex-lg-column ", {
          "h-auto": isTabletOrMobile,
          "h-100": !isTabletOrMobile && !isSummary,
        })}
      >
        {!isSummary &&
          !isTabletOrMobile &&
          ProductHeaderTitle("AddNewProduct.Title.Media")}
        <div
          className={clsx(
            "d-flex flex-column flex-lg-column ",
            isTabletOrMobile ? "h-auto" : ""
          )}
          style={{ minHeight: "92.5%" }}
        >
          <div
            className={clsx(
              isSummary ? "" : "overflow-hidden card",
              `h-100 ${isTabletOrMobile ? "" : isSummary ? "" : "p-7"}`
            )}
          >
            {!isSummary && isTabletOrMobile && <ProductMobileIcon />}

            <div
              style={{
                height: "90%",
              }}
              className={clsx(
                `d-flex flex-column ${
                  !isTabletOrMobile && !isSummary ? "p-5" : "pt-5"
                }`
              )}
            >
              <div
                className={clsx(
                  // "flex-lg-column-fluid tab-content h-100",
                  "flex-lg-column-fluid tab-content",
                  isTabletOrMobile ? "" : isSummary ? "" : "overflow-auto ",
                  isSummary ? "" : "h-100"
                )}
              >
                <div className="d-flex flex-column">
                  {!isSummary && isTabletOrMobile && (
                    <div className="d-flex flex-column mt-5">
                      {ProductStep(2, "AddNewProduct.Title.Media")}
                    </div>
                  )}
                  {/* Product Name Input */}
                  <div className="step-sub-title mb-2 fs-4">
                    <label
                      id="section-photo"
                      className="form-check-label"
                      data-testid="MediaUploadTitlePhoto"
                    >
                      {t("AddNewProduct.Title.Photo")}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  {!isSummary && (
                    <div
                      className="text-gray-800 fw-normal mb-5"
                      data-testid="MediaUploadSubtitlePhoto"
                    >
                      {t("AddNewProduct.SubTitle.Photo")}
                    </div>
                  )}

                  {/* TODO replace with image upload */}
                  <div
                    style={{
                      marginBottom: !isTabletOrMobile ? "0%" : "",
                      width: isTabletOrMobile ? "100%" : "70%",
                      maxHeight: "20%",
                      paddingBottom:
                        checkErrPhotos() && rd_ProductPhotos.length > 0
                          ? "3%"
                          : "0%",
                    }}
                  >
                    <div
                      className="row"
                      style={{
                        marginRight: !isTabletOrMobile ? "7px" : "",
                        paddingLeft: "2%",
                      }}
                    >
                      {rd_ProductPhotos.length > 0 &&
                        rd_ProductPhotos.map((photos, index) => (
                          <div
                            className={clsx(
                              isTabletOrMobile ? "col" : "col-sm"
                            )}
                            style={{
                              position: "sticky",
                              marginLeft: "1%",
                              height: isTabletOrMobile ? "70px" : "100px",
                            }}
                            key={`photos-${index}`}
                          >
                            <UploadImageThumbnail
                              idRadioButton={"image-" + index}
                              isMobile={isTabletOrMobile ? true : false}
                              cName={clsx(
                                "bi bi-card-image",
                                isTabletOrMobile ? "display-3" : "display-3"
                              )}
                              datatestid={"image-" + index}
                              imgSrc={photos.url}
                              isVideoButton={false}
                              onClickFn={() => {
                                removeProductPhotos(index);
                                resetUploadProductMedia(
                                  productMediaUpload.image,
                                  0
                                );
                              }}
                              onChecked={() => onSelectedImageAsCover(index)}
                              checked={
                                rd_PhotosCoverIndex === index ? true : false
                              }
                              isHasCover={true}
                            />
                          </div>
                        ))}

                      {buttonUploadPhotos.map((data, index) => (
                        <div
                          className={clsx(isTabletOrMobile ? "col" : "col-sm")}
                          key={`div_up_image-${index}`}
                        >
                          <ButtonUploadMedia
                            idRadioButton={`up_image-${index}`}
                            isMultipleUpload={true}
                            isMobile={isTabletOrMobile}
                            accept={imageExtention}
                            isVideoButton={false}
                            isInvalidUpload={checkErrPhotos()}
                            cName={
                              "bi bi-card-image " +
                              (isTabletOrMobile ? "display-4" : "display-3")
                            }
                            refId={arrInputRefImage[index]}
                            datatestid={"BtnUploadImage-" + index}
                            style={{ color: "black" }}
                            mediaType={productMediaUpload.image}
                            onChangeFn={(e: any) => {
                              uploadProductMedia(e, productMediaUpload.image);
                            }}
                            // onFinishFn={(media, errors) => {
                            //   if (media) dispatch(ReduxProduct.addProductPhotos(media));
                            //   if (errors) {
                            //     // if (errors.isMaximumPhotos)
                            //       setMaximumPhotos(errors.isMaximumPhotos ?? false)
                            //       setInvalidPhotoFileFormat(errors.isValidPhotoFileFormat ?? false)
                            //   }
                            // }}
                            // onRemoveFn={()=> {

                            // }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div
                    className={clsx(
                      `"d-flex flex-row ${isTabletOrMobile ? "w-100" : "w-50"}`
                    )}
                  >
                    <div className="pt-5" data-testid="MediaUploadErrorPhotos">
                      {isInvalidPhotoFileFormat && (
                        <div
                          id="media-invalid-photo-format"
                          style={{ color: "red" }}
                          role="alert-select-storefront"
                          data-testid="MediaUploadErrorPhotosInvalidFormat"
                        >
                          {t("AddNewProduct.Alert.Photo.Invalidformatfile")}
                        </div>
                      )}
                      {isInvalidPhotoFileSize && (
                        <div
                          id="media-invalid-photo-exceed-size"
                          style={{ color: "red" }}
                          role="alert-select-storefront"
                          data-testid="MediaUploadErrorPhotosFilesizeexceeds"
                        >
                          {t("AddNewProduct.Alert.Photo.Filesizeexceeds")}
                        </div>
                      )}
                      {isMaximumPhotos && (
                        <div
                          id="media-invalid-photo-maximum-upload"
                          style={{ color: "red" }}
                          role="alert-select-storefront"
                          data-testid="MediaUploadErrorPhotosMaximumUpload"
                        >
                          {t("AddNewProduct.Alert.Photo.MaximumUpload")}
                        </div>
                      )}
                      {!isValidPhoto && (
                        <div
                          id="media-invalid-photo-required"
                          style={{ color: "red" }}
                          role="alert-select-storefront"
                          data-testid="MediaUploadErrorPhotosMandatoryPhoto"
                        >
                          {t("AddNewProduct.Alert.Photo")}
                        </div>
                      )}
                    </div>
                  </div>

                  {!isSummary && (
                    <div>
                      <div
                        className="step-sub-title mt-3 mb-2 fs-6"
                        style={{
                          paddingTop:
                            rd_ProductPhotos && rd_ProductPhotos.length > 0
                              ? "10px"
                              : "5px",
                        }}
                      >
                        <label
                          className="form-check-label"
                          data-testid="MediaUploadPhotoRequirements"
                        >
                          {t("AddNewProduct.SubTitle.PhotoFileRequirements")}
                        </label>
                      </div>
                      <ul>
                        <li
                          className="text-gray-800 fw-normal "
                          data-testid="MediaUploadPhotoRequirementsFormatPhoto"
                        >
                          {t(
                            "AddNewProduct.SubTitle.PhotoFileRequirements.FormatPhoto"
                          )}
                        </li>
                        <li
                          className="text-gray-800 fw-normal "
                          data-testid="MediaUploadPhotoRequirementsFormatRatio"
                        >
                          {t(
                            "AddNewProduct.SubTitle.PhotoFileRequirements.Ratio"
                          )}
                          1:1
                        </li>
                        <li
                          className="text-gray-800 fw-normal "
                          data-testid="MediaUploadPhotoRequirementsResolution"
                        >
                          {t(
                            "AddNewProduct.SubTitle.PhotoFileRequirements.Resolution"
                          )}
                        </li>
                        <li
                          className="text-gray-800 fw-normal "
                          data-testid="MediaUploadPhotoRequirementsFileSize"
                        >
                          {t(
                            "AddNewProduct.SubTitle.PhotoFileRequirements.FileSize"
                          )}
                        </li>
                      </ul>
                    </div>
                  )}

                  {/* UPLOAD VIDEO */}

                  <div
                    className={clsx(
                      "step-sub-title mb-2 fs-4",
                      isSummary ? "mt-10" : "mt-5"
                    )}
                  >
                    <label
                      className="form-check-label"
                      data-testid="MediaUploadTitleVideo"
                    >
                      {t("AddNewProduct.Title.Video")}
                    </label>
                  </div>
                  {!isSummary && (
                    <div
                      className="text-gray-800 fw-normal mb-5"
                      data-testid="MediaUploadSubtitleVideo"
                    >
                      {t("AddNewProduct.SubTitle.Video")}
                    </div>
                  )}

                  {/* Componen Upload Video */}
                  {rd_ProductVideo === "" && (
                    <div
                      style={{
                        marginBottom: isTabletOrMobile ? "2%" : "1%",
                        width: isTabletOrMobile ? "16%" : "12%",
                        height: "20%",
                      }}
                    >
                      <ButtonUploadMedia
                        isMobile={isTabletOrMobile ? true : false}
                        cName={clsx(
                          "bi bi-film",
                          isTabletOrMobile ? "display-5" : "display-3"
                        )}
                        datatestid="BtnUploadVideo"
                        style={{ color: "black" }}
                        isVideoButton={true}
                        refId={InputRefVideo}
                        accept={videoExtention}
                        onChangeFn={(e: any) => {
                          uploadProductMedia(e, productMediaUpload.video);
                        }}
                        isInvalidUpload={checkErrVideo()}
                      />
                    </div>
                  )}
                  {rd_ProductVideo &&
                    rd_ProductVideo !== "" &&
                    rd_ProductVideo !== null &&
                    rd_ProductVideo.url &&
                    rd_ProductVideo.url !== "" && (
                      <div
                        style={{
                          position: "relative",
                          marginBottom: isTabletOrMobile ? "2%" : "1%",
                          width: isTabletOrMobile ? "30%" : "11%",
                          height: "20%",
                        }}
                      >
                        <UploadedVideoThumbnail
                          refId={videoRef}
                          vidSrc={rd_ProductVideo.url.toString()}
                          onClickFn={() => {
                            removeProductVideo();
                          }}
                          datatestid="VideoThumbnail"
                          handleLoadedMetadata={() => {
                            handleLoadedMetadata();
                          }}
                        />
                      </div>
                    )}
                  {/* Warning Upload Video */}
                  {!isSummary && (
                    <div className="card border-warning mb-3 ">
                      <div className="card-body text-warning p-1">
                        <p className="card-text">
                          <i
                            className="bi bi-exclamation-triangle text-warning"
                            data-testid="MediaUploadWarningUploadVideo"
                          ></i>
                          {t("AddNewProduct.Warning.UploadVideo")}
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="" data-testid="MediaTitleErrValid">
                    {isInvalidVideoFileFormat && (
                      <div
                        id="error-media-video-format"
                        style={{ color: "red" }}
                        role="alert-select-storefront"
                        data-testid="MediaUploadErrorVideoInvalidFormat"
                      >
                        {t("AddNewProduct.Alert.Video.Invalidformatfile")}
                      </div>
                    )}
                    {isVideoDurationToLong && (
                      <div
                        id="error-media-video-too-long"
                        style={{ color: "red" }}
                        role="alert-select-storefront"
                        data-testid="MediaUploadErrorVideoDurationToLong"
                      >
                        {t("AddNewProduct.Alert.Video.DurationTooLong")}
                      </div>
                    )}
                    {isVideoDurationToShort && (
                      <div
                        id="error-media-video-too-short"
                        style={{ color: "red" }}
                        role="alert-select-storefront"
                        data-testid="MediaUploadErrorVideoDurationToShort"
                      >
                        {t("AddNewProduct.Alert.Video.DurationTooShort")}
                      </div>
                    )}
                    {isInvalidVideoFileSize && (
                      <div
                        id="error-media-video-exceed-size"
                        style={{ color: "red" }}
                        role="alert-select-storefront"
                        data-testid="MediaUploadErrorVideoFileSizeExceeds"
                      >
                        {t("AddNewProduct.Alert.Video.Filesizeexceeds")}
                      </div>
                    )}
                  </div>
                  {!isSummary && (
                    <div className="step-sub-title mb-2 fs-6">
                      <label
                        className="form-check-label"
                        data-testid="MediaUploadErrorVideoFilerequirements"
                      >
                        {t("AddNewProduct.SubTitle.VideoFileRequirements")}
                      </label>
                    </div>
                  )}

                  {!isSummary && (
                    <ul>
                      <li
                        className="text-gray-800 fw-normal "
                        data-testid="MediaUploadErrorVideoFormatVideoResolution"
                      >
                        {t(
                          "AddNewProduct.SubTitle.VideoFileRequirements.Resolution"
                        )}
                      </li>
                      <li
                        className="text-gray-800 fw-normal "
                        data-testid="MediaUploadErrorVideoFormatVideo"
                      >
                        {t(
                          "AddNewProduct.SubTitle.VideoFileRequirements.FormatVideo"
                        )}
                      </li>
                      <li
                        className="text-gray-800 fw-normal "
                        data-testid="MediaUploadErrorVideoDuration"
                      >
                        {t(
                          "AddNewProduct.SubTitle.VideoFileRequirements.Duration"
                        )}
                      </li>
                      <li
                        className="text-gray-800 fw-normal "
                        data-testid="MediaUploadErrorVideoFilesize"
                      >
                        {t(
                          "AddNewProduct.SubTitle.VideoFileRequirements.FilSize"
                        )}
                      </li>
                    </ul>
                  )}

                  {/* URL Video Input */}
                  <div>
                    <div className="step-sub-title mt-2 mb-2 fs-6">
                      <label
                        id="media-youtube-input"
                        className="form-check-label"
                        data-testid="MediaUploadAddURLYoutube"
                      >
                        {!isSummary
                          ? t("AddNewProduct.Info.AddURLYoutubeVideo")
                          : "URL YOUTUBE VIDEO"}
                      </label>
                    </div>
                    <div
                      className={clsx(
                        `"d-flex flex-row mb-2 ${
                          isTabletOrMobile ? "w-100" : "w-75"
                        }`
                      )}
                    >
                      <div className="w-100">
                        <div className="d-flex align-items-center form-control border-2 px-0 py-0">
                          <input
                            data-testid="MediaUploadAddURLYoutubeInput"
                            id="bpi-input-product-name"
                            name="bpi-input-product-name"
                            className="form-control border-0"
                            onChange={(e) => {
                              dispatch(
                                ReduxProduct.setVideoYoutubeURL(e.target.value)
                              );
                              setInvalidVideoURL(false);
                            }}
                            value={rd_VideoYoutubeURL}
                            placeholder={t(
                              "AddNewProduct.Input.AddURLYoutubeVideo"
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    {isInvalidVideoURL && (
                      <div
                        style={{ color: "red" }}
                        id="media-invalid-video-url"
                        role="alert-input-productname"
                      >
                        {t("AddNewProduct.Alert.Video.InputURLYoutube")}
                      </div>
                    )}
                    {!isSummary && (
                      <div className="card border-warning mb-3 ">
                        <div className="card-body text-warning p-1">
                          <p className="card-text">
                            <i
                              className="bi bi-exclamation-triangle text-warning"
                              data-testid="MediaUploadWarningURLVideo"
                            ></i>
                            {t("AddNewProduct.Warning.URLVideo")}
                          </p>
                        </div>
                      </div>
                    )}
                    <div
                      className="d-flex"
                      data-testid="MadiaUploadErrorInputYoutube"
                    ></div>
                  </div>
                  <Button
                    id="btnValidateDataMedia"
                    data-testid="btnValidateDataMedia"
                    type="button"
                    btnbs="primary"
                    cName="mx-5 "
                    style={{
                      visibility: "hidden",
                      pointerEvents: "none",
                      height: "0px",
                      padding: "0.1px",
                    }}
                    onClick={() => validateData()}
                  >
                    {`${t("AddNewProduct.Button.BPI.Next")}`}
                  </Button>
                </div>
              </div>
            </div>
            {!isSummary && (
              <div
                className={clsx(
                  `d-flex space justify-content-between w-100 mt-auto ${
                    isTabletOrMobile ? "mb-5" : ""
                  }`
                )}
              >
                <Link to={`${URI}/basic-information`}>
                  <Button
                    btnbs="primary"
                    cName={isTabletOrMobile ? "mx-5" : ""}
                  >
                    {`${t("Storefront.Button.Back")}`}
                  </Button>
                </Link>
                <div className="d-flex">
                  <Link to="/product/list">
                    <Button
                      data-testid="btnCancel"
                      btnbs="primary"
                      type="button"
                    >
                      {t("AddNewProduct.Button.BPI.Cancel")}
                    </Button>
                  </Link>
                  <Button
                    data-testid="btnNext"
                    type="button"
                    btnbs="primary"
                    cName="mx-5"
                    onClick={() => handleNext()}
                  >
                    {`${t("AddNewProduct.Button.BPI.Next")}`}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
    </>
  );
};
export default MediaUpload;
