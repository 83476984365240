import { PackingStation } from "src/app/models/PackingStation";
import db from ".";
import { converter2 } from "./converter";

export const fetchPackingStations = async () => {
  const packingStations = await db
    .collection("packingStations")
    .withConverter(converter2<PackingStation>())
    .get();

  return packingStations.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
};
