import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ID, toAbsoluteUrl } from "../../../../../resources/helpers";
import { MediaType } from "../../../../../util/TemplateMediaSupport";
import { CampaignActionsCell } from "../../../../layout/campaign/campaign-list/table/columns/CampaignActionsCell";
import { CampaignTemplateStatusCell } from "../../../../layout/campaign/campaign-list/table/columns/CampaignTemplateStatusCell";
import { CampaignMessageActionsCell } from "../../../../layout/campaign/campaign-report/campaign-report-details/table/columns/CampaignMessageActionCell";
import { TargetSelectionCell } from "../../../../layout/campaign/campaign-target/table/columns/TargetSelectionCell";
import { CIQActionsCell } from "../../../../layout/ciq-management/ciq-list/table/columns/CIQActionsCell";
import { CIQSelectionCell } from "../../../../layout/ciq-management/ciq-list/table/columns/CIQSelectionCell";
import { ContactActionsCell } from "../../../../layout/contact-management/contact-list/table/columns/ContactActionsCell";
import { ContactSelectionCell } from "../../../../layout/contact-management/contact-list/table/columns/ContactSelectionCell";
import { ProductActionsCell } from "../../../../layout/product-management/product/product-list/table/columns/ProductActionCell";
import { ProductSelectionCell } from "../../../../layout/product-management/product/product-list/table/columns/ProductSelectionCell";
import { ProductStatusCell } from "../../../../layout/product-management/product/product-list/table/columns/ProductStatusCell";
import { StorefrontActionsCell } from "../../../../layout/product-management/storefront/storefront-list/table/columns/StorefrontActionCell";
import { StorefrontSelectionCell } from "../../../../layout/product-management/storefront/storefront-list/table/columns/StorefrontSelectionCell";
import { WABAAccountActionsCell } from "../../../../layout/user-menu/settings/WABAAccount/table/columns/WABAAccountActionsCell";
import { WABAAccountProgressCell } from "../../../../layout/user-menu/settings/WABAAccount/table/columns/WABAAccountProgressCell";
import { WABAAccountRegisteredPhoneNumberCell } from "../../../../layout/user-menu/settings/WABAAccount/table/columns/WABAAccountRegisteredPhoneNumberCell";
import { WABAAccountStatusCell } from "../../../../layout/user-menu/settings/WABAAccount/table/columns/WABAAccountStatusCell";
import WABAAccount from "../../../../models/WABAccount";
import { TemplateActionsCell } from "../../../../layout/template/template-list/table/columns/TemplateActionsCell";
import moment from "moment";
import db from "../../../../../db";
import iso6391 from "iso-639-1";

type ColumnProps = {
  columnName?: string;
  columnDataValue?: any;
  columnNameClass?: string;
  columnDataValueClass?: string;
};

const ColumnData: FC<ColumnProps> = ({
  columnName,
  columnDataValue,
  columnNameClass,
  columnDataValueClass,
}) => {
  return (
    <div className="d-flex flex-row gap-2 align-items-center">
      {columnName && (
        <div className={clsx("w-50", columnNameClass)}>{columnName}</div>
      )}
      <div className={clsx("w-50", columnDataValueClass)}>
        {columnDataValue}
      </div>
    </div>
  );
};

interface CardItemProps {
  id: ID;
  collaborationId?: string;
  name?: string;
  numOfProduct?: number;
  numOfVariant?: number;
  marketplaces?: [];
  lastStep?: string;
  isStorefrontList?: boolean;
  isProductList?: boolean;
  imgURL?: string;
  status?: string;
  isAccountSelection?: boolean;
  checked?: boolean;
  AccountName?: string;
  CompanyName?: string;
  phoneNumber?: string;
  onChange?: any;
  AccountId?: string;
  mediaSupport?: string[];
  isContactList?: boolean;
  index?: number;
  email?: string;
  lastInteraction?: Date;
  contactData?: any;
  campaignData?: any;
  isCampaignList?: boolean;
  isTemplateList?: boolean;
  isTargetList?: boolean;
  templateData?: any;
  targetData?: any;
  isTargetSelectedList?: boolean;
  targetSelectedData?: any;
  isCampaignReportList?: boolean;
  campaignReportData?: any;
  isCIQList?: boolean;
  CIQData?: any;
  isWABAList?: boolean;
  wabaData?: WABAAccount;
}
const CardItem: FC<CardItemProps> = ({
  id,
  collaborationId,
  name,
  numOfProduct = 0,
  numOfVariant = 0,
  marketplaces,
  lastStep,
  isStorefrontList = false,
  isProductList = false,
  imgURL,
  status,
  isAccountSelection = false,
  checked,
  AccountName,
  CompanyName,
  phoneNumber,
  onChange,
  AccountId,
  mediaSupport,
  isContactList = false,
  index = 0,
  email,
  lastInteraction,
  contactData,
  isCampaignList = false,
  campaignData,
  isTemplateList = false,
  templateData,
  isTargetList = false,
  targetData,
  isTargetSelectedList = false,
  targetSelectedData,
  isCampaignReportList = false,
  campaignReportData,
  isCIQList = false,
  CIQData,
  isWABAList = false,
  wabaData,
}) => {
  const { t } = useTranslation();
  const dummyImg = "/media/images/dummy-image-square.jpg";
  const [dataTemplate, setDataTemplate] = useState<any>({});
  const src = imgURL && imgURL !== "" ? imgURL : dummyImg;
  const updatedAtCampaignData = campaignData?.updatedAt?.toDate();
  const formattedCampaignDataDate = updatedAtCampaignData?.toLocaleString();
  // const lastInteractionCiq = CIQData?.lastInteractionAt?.toString();
  // const formattedLastInteractionCiq = lastInteractionCiq?.toLocaleString();

  const handleClickOnProduct = () => {
    const editButton = document.getElementById(`editOnProduct-${id}`);
    editButton?.click();
  };

  const [completeName, setCompleteName] = useState("");
  useEffect(() => {
    if (contactData || targetData) {
      const data = contactData ? contactData : targetData ? targetData : null;
      let completeName =
        data.firstName! + (data.lastName ? " " + data.lastName : "");
      if (completeName.length > 25) {
        completeName = completeName.slice(0, 24).concat("...");
      }
      setCompleteName(completeName);
    }
  }, []);

  useEffect(() => {
    const processingAccount = async () => {
      let processingData = await db
        .collection("account")
        .doc(templateData?.accounts[0]?.id)
        .get();
      if (processingData.exists) {
        let data = processingData.data();
        let newDataTemplate = { ...templateData };
        newDataTemplate["accountName"] =
          data?.name ||
          data?.businessName ||
          data?.type ||
          (data?.whatsappBusinessAccount_ID && "whatsapp") ||
          " - ";
        setDataTemplate(newDataTemplate);
      }
    };
    processingAccount();
  }, [templateData]);

  console.log(name, completeName, CIQData, "testing pixko");

  return (
    <div
      className={
        isWABAList
          ? "w-100 p-3 rounded border border-primary"
          : "w-100 p-4 bg-primary bg-opacity-25 rounded"
      }
    >
      <div
        className={clsx(
          "d-flex flex-row w-100",
          isAccountSelection ? "" : "align-items-start"
        )}
      >
        <div
          className="me-3 w-100"
          style={{
            flex: 1,
          }}
        >
          {isStorefrontList && <StorefrontSelectionCell id={id} />}
          {isProductList && <ProductSelectionCell id={id} />}
          {isAccountSelection && (
            <input
              type="checkbox"
              style={{ alignItems: "flex-start" }}
              className="form-check-input"
              id={`checkbox-${AccountId}`}
              name={`checkbox-${AccountId}`}
              checked={checked}
              onChange={onChange}
            />
          )}
          {isContactList && <ContactSelectionCell id={id} />}
          {isTargetList && <TargetSelectionCell customer={targetData} />}
          {isCIQList && <CIQSelectionCell id={id} />}
        </div>
        {isProductList && (
          <div onClick={() => handleClickOnProduct()}>
            <img alt="" className="me-4" src={src} style={{ height: "60px" }} />
          </div>
        )}
        {isContactList && (
          <Link
            to={"/contact/contact-detail/overview/" + id}
            state={{
              id: id,
              name: name,
            }}
            className="text-gray-800 text-hover-primary mb-1"
          >
            <img
              id={"user_avatar" + id}
              style={{ width: "60px", height: "60px", borderRadius: "50%" }}
              src={toAbsoluteUrl(
                imgURL ? imgURL : "/media/icons/avatar/user-icon.png"
              )}
              alt="7daysUser"
              className="mh-40px mw-40px"
            />
          </Link>
        )}
        <div
          className="w-100 d-flex flex-column"
          style={{
            overflow: "hidden",
          }}
        >
          <div
            className="border-bottom pb-2 mb-2 justify-content-between align-items-end"
            style={{
              flex: 1,
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            {isContactList === true || isTargetList === true ? (
              <h1
                className="fs-3 p-0 m-0"
                style={{
                  maxWidth: "85%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {completeName}
              </h1>
            ) : isCIQList === true ? (
              <h1
                className="fs-3 p-0 m-0 align-self-start"
                style={{
                  maxWidth: "85%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {CIQData?.profileName}
              </h1>
            ) : (
              <h1
                className="fs-3 p-0 m-0"
                style={{
                  maxWidth: "85%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {name}
              </h1>
            )}
            {isTargetSelectedList && <h1 className="fs-1">{phoneNumber}</h1>}
            {isTargetSelectedList && <h1 className="fs-1">{name}</h1>}
            {isStorefrontList && (
              <StorefrontActionsCell
                id={id}
                numberOfProduct={numOfProduct}
                lastStep={lastStep}
              />
            )}
            {isProductList && (
              <ProductActionsCell
                id={id}
                numberOfVariant={numOfVariant}
                lastStep={lastStep}
              />
            )}
            {isContactList && <ContactActionsCell id={id} indexId={index} />}
            {isCampaignList && (
              <CampaignActionsCell
                id={id}
                indexId={index}
                campaign={campaignData}
              />
            )}
            {isTemplateList && (
              <TemplateActionsCell
                id={id}
                indexId={index}
                languages={templateData}
              />
            )}
            {isCampaignReportList && (
              <CampaignMessageActionsCell
                id={id}
                indexId={index}
                messageContent={contactData.message ? contactData.message : ""}
              />
            )}
            {isCIQList && (
              <CIQActionsCell
                id={id}
                collaborationId={collaborationId ?? ""}
                indexId={index}
              />
            )}
          </div>
          {isWABAList && wabaData && (
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-2">
                <h2 className="fs-2 m-0">{wabaData.businessName}</h2>
                <WABAAccountRegisteredPhoneNumberCell
                  id={wabaData.id}
                  phoneNumbers={wabaData.whatsappNumbers!}
                />
              </div>
              <WABAAccountActionsCell id={wabaData.id} indexId={index} />
            </div>
          )}

          <div className="mb-2">
            {isStorefrontList || isProductList ? (
              <>
                <span>
                  {isStorefrontList ? numOfProduct : numOfVariant}
                  {isStorefrontList
                    ? ` ${t("StorefrontList.Column.Product")}`
                    : ` ${t("ProductList.Column.Variant")}`}
                </span>
                {/* <div className="row mt-2 align-items-start">
                  <span>{isAccountSelection === false ? status : ""}</span>
                </div> */}
              </>
            ) : isContactList ? (
              <div className="d-flex flex-column">
                <div>
                  {phoneNumber && phoneNumber !== "" ? phoneNumber : "-"}
                </div>
                <div> {email && email !== "" ? email : "-"} </div>
                <div>{` ${lastInteraction ?? "-"} `}</div>
              </div>
            ) : (
              <div>
                <span>{CompanyName}</span>
                {phoneNumber && phoneNumber !== "" && (
                  <span> - {phoneNumber} </span>
                )}
                {AccountName && AccountName !== "" && (
                  <span> - {AccountName} </span>
                )}
              </div>
            )}
          </div>

          {isProductList && <ProductStatusCell id={id} status={status} />}

          {isAccountSelection && (
            <div className="mt-2">
              <div className="d-flex flex-row gap-2">
                {mediaSupport?.map((mediaName, index) => {
                  const media =
                    index === 0
                      ? "Text"
                      : index === 1
                      ? "Image"
                      : index === 2
                      ? "Video"
                      : index === 3
                      ? "Document"
                      : index === 4
                      ? "Audio"
                      : undefined;
                  if (!media) return undefined;

                  return (
                    <img
                      className="mh-30px"
                      src={toAbsoluteUrl(
                        `/media/icons/mediasupport/${media}_${
                          mediaName === media.toLowerCase()
                            ? "Active.png"
                            : "Inactive.png"
                        }`
                      )}
                      key={index}
                      alt={`media-support-${media.toLowerCase()}`}
                      data-testid={`media-support-${media.toLowerCase()}`}
                    />
                  );
                })}
              </div>
            </div>
          )}
          {isCampaignList && campaignData && (
            <div className="mt-2">
              <div className="d-flex flex-row gap-2 align-items-center">
                <div className="w-50">{campaignData.companyName}</div>
                <div className="w-50 d-flex justify-content-end">
                  {campaignData.template ? (
                    <CampaignTemplateStatusCell
                      template={campaignData.template}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="d-flex flex-row gap-2 align-items-end">
                <div className="w-50">
                  {campaignData.templateName ? campaignData.templateName : "-"}
                </div>
                <div className="w-50 text-end">
                  {formattedCampaignDataDate ? formattedCampaignDataDate : "-"}
                </div>
              </div>
            </div>
          )}
          {isTemplateList &&
            templateData &&
            templateData?.languages?.length > 0 && (
              <div className="mt-2">
                <div className="d-flex flex-row gap-2 align-items-center justify-content-between">
                  <div className="w-50">
                    {dataTemplate?.accountName ? dataTemplate.accountName : "-"}
                  </div>
                  <div
                    className={`w-50 d-flex justify-content-end p-2 badge badge-light-${
                      templateData?.languages[0]?.templateStatus === "APPROVED"
                        ? "success"
                        : templateData?.languages[0]?.templateStatus ===
                          "PENDING"
                        ? "warning"
                        : "danger"
                    } w-auto align-self-end rounded rounded-sm text-${
                      templateData?.languages[0]?.templateStatus === "APPROVED"
                        ? "success"
                        : templateData?.languages[0]?.templateStatus ===
                          "PENDING"
                        ? "warning"
                        : "danger"
                    } fw-bold`}
                  >
                    {templateData?.languages?.length
                      ? `${iso6391.getName(
                          templateData?.languages[0]?.templateLanguage?.substring(
                            0,
                            2
                          )
                        )} (${templateData?.languages[0]?.templateLanguage?.replace(
                          "_",
                          "-"
                        )})`
                      : "-"}
                  </div>
                </div>
                <div className="d-flex flex-row gap-2 align-items-end mt-2">
                  <div className="w-50">
                    {templateData.category ? templateData.category : "-"}
                  </div>
                  <div className="w-50 text-end">
                    {moment(
                      new Date(templateData?.createdAt?.seconds * 1000)
                    ).format("DD MMM YYYY, HH:mm")}
                  </div>
                </div>
              </div>
            )}
          {isTargetList && targetData && (
            <div className="mt-2">
              <div className="d-flex flex-row gap-2 align-items-center">
                <div className="w-50">{t("Common.Column.PhoneNumber")}</div>
                <div className="w-50">{targetData.phoneNumber}</div>
              </div>
              <div className="d-flex flex-row gap-2 align-items-end">
                <div className="w-50">{t("Common.Column.CreatedAt")}</div>
                <div className="w-50">
                  {targetData.createdAt ? targetData.createdAt : "-"}
                </div>
              </div>
              <div className="d-flex flex-row gap-2 align-items-end">
                <div className="w-50">
                  {t("Common.Column.LastInteractions")}
                </div>
                <div className="w-50">
                  {targetData.lastInteractionAt ?? "-"}
                </div>
              </div>
            </div>
          )}
          {isTargetSelectedList && targetSelectedData && (
            <div className="mt-2">
              <div className="d-flex flex-row gap-2 align-items-center">
                <div className="w-50">Header</div>
                <div className="w-50">{targetSelectedData.header}</div>
              </div>
              <div className="d-flex flex-row gap-2 align-items-end">
                <div className="w-50">{t("Common.Column.CreatedAt")}</div>
                <div className="w-50">
                  {targetData.createdAt ? targetData.createdAt : "-"}
                </div>
              </div>
              <div className="d-flex flex-row gap-2 align-items-end">
                <div className="w-50">
                  {t("Common.Column.LastInteractions")}
                </div>
                <div className="w-50">
                  {targetData.lastInteractionAt ?? "-"}
                </div>
              </div>
            </div>
          )}
          {isCIQList && CIQData && (
            <div className="mt-2 w-100">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div className="w-auto">{CIQData.companyName}</div>
                <div className="w-auto">{CIQData.phoneNumber}</div>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div className="w-auto">{CIQData?.lastInteractionChannel}</div>
                <div className="w-auto">
                  {moment(
                    new Date(CIQData?.lastInteractionAt?.seconds * 1000)
                  ).format("DD MMM YYYY, HH:mm")}
                </div>
                {/* <div className="w-50">{lastInteractionCiq}</div> */}
              </div>
            </div>
          )}
          {isCampaignReportList && contactData && (
            <div className="mt-2">
              <ColumnData
                columnNameClass={"text-gray-700"}
                columnDataValueClass={"text-gray-700 fw-bolder"}
                columnName={t("Common.Column.PhoneNumber")}
                columnDataValue={
                  contactData.phoneNumber ? contactData.phoneNumber : "-"
                }
              />
              <ColumnData
                columnNameClass={"text-gray-700"}
                columnDataValueClass={"text-gray-700 fw-bolder"}
                columnName={t("Campaign.Column.LastUpdated")}
                columnDataValue={
                  contactData.updatedAt ? contactData.updatedAt : "-"
                }
              />
              <ColumnData
                columnNameClass={"text-gray-700"}
                columnDataValueClass={"text-gray-700 fw-bolder"}
                columnName={t("DLR.Column.Status")}
                columnDataValue={
                  contactData.status ? contactData.status.toUpperCase() : "-"
                }
              />
            </div>
          )}
          {isWABAList && wabaData && (
            <div>
              <WABAAccountProgressCell
                id={wabaData?.id}
                progress={wabaData?.progress}
                lastStep={wabaData?.lastStep}
                errorMessage={wabaData?.errorMessage || ""}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default CardItem;
