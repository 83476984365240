import { createContext, useEffect, useState } from "react";
import {
  FirestoreQueryState,
  ResponseRTS,
  TableContextValue,
  TableProviderProps,
} from "../models/Models";
import * as lc from "../../../modules/localstorage/index";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import useFirestoreData from "../hook/useFirestoreData";
import {
  reqDownloadCSVExportedOrders,
  reqReadyToShipOrder,
} from "src/api/server/order";
import { ShipmentDeadlines } from "src/app/modules/product/shipment/redux/ShipmentsRedux";
import { Order } from "src/app/models/Order";
import moment from "moment";
import { minimalDefaultValue } from "./defaultValue";
import SocketConnection from "src/setup/socket/socket";

export const TableContext =
  createContext<TableContextValue<any>>(minimalDefaultValue);

async function defaultMapper<T>(
  docs: QueryDocumentSnapshot<T, DocumentData>[]
): Promise<T[]> {
  return docs.map((doc) => ({ ...doc.data(), id: doc.id } as T));
}

export function TableProvider<T>({
  children,
  childCollection,
  collection,
  collectionGroup,
  columns,
  selection,
  filters,
  sorting,
  searching,
  extra,
  tabs,
  pagination = true,
  mapper = defaultMapper,
  useFilter,
  action,
  childColumns, // array of child column [array[0] array[1]
  subcollection,
  subSubcollection,
  childIndex, //numberƒƒ
}: TableProviderProps<T>) {
  const clientID = lc.getItemLC(lc.LCName.Client)?.id;
  const userID = lc.getItemLC(lc.LCName.UserID);
  const [TableState, setTableState] = useState<FirestoreQueryState<T>>({
    collection: collection,
    clientID: clientID,
    filters:
      tabs && tabs?.length > 0 ? tabs[0].filter : filters ? filters : undefined,
    sorting: tabs && tabs?.length > 0 ? tabs[0].sort : sorting,
    // limit: pagination ? 2 : undefined,
    limit: 10,
    currentPage: 1,
    searchKey: searching,
    collectionGroup: collectionGroup,
    tabActive: 0,
    useFilter: useFilter,
    action,
    priorityOrder: true,
    subcollection: subcollection,
    subSubcollection: subSubcollection,
  } as FirestoreQueryState<T>);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { data, isFetching, isLoading, refetch } = useFirestoreData<T>(
    TableState,
    mapper
  );

  const [loadingRTS, setLoadingRTS] = useState(false);
  const [responseRTS, setResponseRTS] = useState<ResponseRTS | undefined>(
    undefined
  );
  const [responseExportOrder, setResponseExportOrder] = useState<
    ResponseRTS | undefined
  >(undefined);

  const updateTableState = (newData: Partial<FirestoreQueryState<T>>) => {
    let newState = newData;

    if (!newData.currentPage) {
      newState.currentPage = 1;
    }

    setTableState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const updatePage = (newPage: number) => {
    updateTableState({
      currentPage: newPage,
    });
    setSelectedItems([]);
  };

  const refreshData = async () => {
    let filter_data = tabs ? tabs[TableState.tabActive].filter : filters;
    let sorting_data = tabs ? tabs[TableState.tabActive].sort : sorting;
    updateTableState({
      useFilter: useFilter,
      filters: filter_data
        .filter((x: any) => x.label !== "end-search")
        .filter((x: any) => x.label !== "start-search"),
      sorting: sorting_data.filter((x: any) => x.label !== "search"),
      currentPage: 1,
      limit: 10,
    });
    setSelectedItems([]);
    refetch();
  };

  const setReadyToShipOrder = async (items: string[]) => {
    setLoadingRTS(true);
    const res = await reqReadyToShipOrder(items, userID);
    setResponseRTS(res);
    setLoadingRTS(false);
  };

  const setExportedOrder = async (items: string[]) => {
    const response = await reqDownloadCSVExportedOrders(items, userID);
    setResponseExportOrder(response);
  };

  const resetResponseRTS = () => {
    setResponseRTS(undefined);
    setLoadingRTS(false);
  };

  const checkifAvailableToProcessOrder = (
    shipmentDeadlines: ShipmentDeadlines[],
    data: Order
  ) => {
    const currentTime = moment().format("HHmmss");
    const logisticData = shipmentDeadlines.find(
      (logistic) =>
        logistic.logisticSearchKey ===
          data.marketplaceLogisticChannelName
            ?.replaceAll(" ", "")
            .toLowerCase() && logistic.marketplaceName === data.marketplaceType
    );
    const logisticMaxShipTime = moment(
      logisticData?.maxShipTime?.toDate() ?? moment().endOf("day")
    ).format("HHmmss");
    const logisticMinShipTime = moment(
      logisticData?.minShipTime?.toDate() ?? moment().startOf("day")
    ).format("HHmmss");
    const accountData = data.accountData;
    const accountMaxShipTime = moment(
      accountData?.maxShipTime?.toDate() ?? moment().endOf("day")
    ).format("HHmmss");
    const accountMinShipTime = moment(
      accountData?.minShipTime?.toDate() ?? moment().startOf("day")
    ).format("HHmmss");
    const checkifAvailableToProcessOrder = logisticData
      ? parseInt(currentTime) >= parseInt(logisticMinShipTime) &&
        parseInt(currentTime) < parseInt(logisticMaxShipTime)
      : parseInt(currentTime) >= parseInt(accountMinShipTime) &&
        parseInt(currentTime) < parseInt(accountMaxShipTime);
    return checkifAvailableToProcessOrder;
  };

  useEffect(() => {
    const socket = SocketConnection();
    const onDataChange = () => refreshData();
    socket.on(`${collection}-data-update`, onDataChange);

    return () => {
      socket.off(`${collection}-data-update`, onDataChange);
    };
  }, [collection, refreshData]);

  const value = {
    TableState,
    selectedItems,
    setSelectedItems,
    updateTableState,
    setReadyToShipOrder,
    setExportedOrder,
    resetResponseRTS,
    loadingRTS,
    responseRTS,
    responseExportOrder,
    updatePage,
    checkifAvailableToProcessOrder,
    isLoading,
    isFetching,
    refreshData,
    data,
  };

  return (
    <TableContext.Provider value={value as any}>
      {children}
    </TableContext.Provider>
  );
}
