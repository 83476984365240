/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState } from "react";
import "./ModalStyles.css";
import { useTranslation } from "react-i18next";
import { sendRequestShippingMark } from "src/api/server/order";
import { fetchPackingStations } from "src/db/servicePackingStations";
import ConfirmationModal from "./ConfirmationModal";

type AddShippingMarkModalProps = {
  onClose: () => void;
  handoverId?: string;
  shipper?: string;
};

const AddShippingMarkModal: React.FC<AddShippingMarkModalProps> = ({
  onClose,
  handoverId,
  shipper,
}) => {
  const [activeView, setActiveView] = useState<string | null>(null);
  const [selectedPackingStations, setSelectedPackingStations] = useState<
    string[]
  >([]);
  const [orderSN, setOrderSN] = useState("");
  const [inputQty, setInputQty] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);

  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleButtonClick = (view: string) => {
    setActiveView(view);
    if (view !== "packingStation") {
      setSelectedPackingStations([]);
      setError(null);
    }
  };

  const handleCheckboxChange = (station: string) => {
    setSelectedPackingStations((prev) =>
      prev.includes(station)
        ? prev.filter((s) => s !== station)
        : [...prev, station]
    );
  };

  const handleSavePacking = async () => {
    if (inputQty <= 0) {
      setError(t("Handover.Error.Zero"));
      return;
    }
    if (selectedPackingStations.length === 0) {
      setError(t("Shipping.Error.ChoosePacking"));
      return;
    }

    setError(null);

    try {
      const result = await sendRequestShippingMark(
        selectedPackingStations,
        inputQty,
        null,
        shipper,
        handoverId
      );

      if (result.status !== "error") {
        console.log("Response from server after saving packing:", result);
        setError(null);
        setShowConfirmation(true);
      } else {
        setError(result.message);
      }
    } catch (error: any) {
      console.error("Error saving packing:", error.message);
      if (error.message.includes("packing station not found")) {
        setError(t("Shipping.Error.Packing"));
      } else if (error.message.includes("Order with ordersn")) {
        setError(
          t("Shipping.Error.OrderSn") +
            " : " +
            orderSN +
            t("Shipping.Error.NotFound")
        );
      } else if (error.message.includes("Order with shipper")) {
        setError(
          t("Shipping.Error.Shipper") + shipper + t("Shipping.Error.NotFound")
        );
      } else if (error.message.includes("Either orderSN")) {
        setError(t("Shipping.Error.OrderPacking"));
      } else if (error.message.includes("qtyCount not")) {
        setError(t("Shipping.Error.Qty"));
      } else {
        setError(t("Shipping.Error.Nothing"));
      }
    }
  };

  const handleSearch = async () => {
    try {
      const result = await sendRequestShippingMark(
        [],
        1,
        orderSN,
        shipper,
        handoverId
      );
      console.log("Response from server for sales order:", result);

      if (result) {
        setError(null);
        setSelectedPackingStations(result);
        setShowConfirmation(true);
      } else {
        setError(t("OrderList.NoData"));
      }
    } catch (error: any) {
      console.error("Error saving packing:", error.message);
      if (error.message.includes("packing station not found")) {
        setError(t("Shipping.Error.Packing"));
      } else if (error.message.includes("Order with ordersn")) {
        setError(
          t("Shipping.Error.OrderSn") +
            " : " +
            orderSN +
            t("Shipping.Error.NotFound")
        );
      } else if (error.message.includes("Order with shipper")) {
        setError(
          t("Shipping.Error.Shipper") + shipper + t("Shipping.Error.NotFound")
        );
      } else if (error.message.includes("Either orderSN")) {
        setError(t("Shipping.Error.OrderPacking"));
      } else if (error.message.includes("qtyCount not")) {
        setError(t("Shipping.Error.Qty"));
      } else {
        setError(t("Shipping.Error.Nothing"));
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value < 0) {
      setError(t("Handover.Error.Zero"));
    } else {
      setError(null);
    }
    setInputQty(value < 0 ? 0 : value);
  };

  const [packingStations, setPackingStations] = useState<string[]>([]);

  useEffect(() => {
    const fetchPackingStationData = async () => {
      try {
        const getAllPackingStationData = await fetchPackingStations();
        const packingStationNames = getAllPackingStationData
          .map((packingStation) => packingStation.name)
          .filter((name): name is string => name !== undefined);

        setPackingStations(packingStationNames);
        console.log("packing stations names", packingStationNames);
      } catch (error) {
        console.error("Error fetching packing station data:", error);
      }
    };

    fetchPackingStationData();
  }, []);

  const handleConfirm = () => {
    onClose();
  };

  return (
    <>
      <div className="modal-overlay" data-testid="addd-shipping-mark-modal">
        <div className="modal-content">
          <button className="modal-close-btn" onClick={onClose}>
            X
          </button>
          <h2>{t("Shipping.Handover.Mark.Add")}</h2>

          {!activeView ? (
            <div className="button-options">
              <button
                className="option-button"
                onClick={() => handleButtonClick("salesOrder")}
              >
                <span role="img" aria-label="paperclip">
                  📎
                </span>
                {t("Shipping.Mark.So")}
              </button>
              <button
                className="option-button"
                onClick={() => handleButtonClick("packingStation")}
              >
                <span role="img" aria-label="box">
                  📦
                </span>
                {t("Shipping.Mark.Station")}
              </button>
            </div>
          ) : (
            <div className="active-view">
              {activeView === "salesOrder" && (
                <div>
                  <h3>{t("Shipping.Mark.So")}</h3>
                  <div className="button-row">
                    <input
                      type="text"
                      value={orderSN}
                      onChange={(e) => setOrderSN(e.target.value)}
                      placeholder={t("Shipping.Mark.So")}
                    />
                  </div>
                  <h4>
                    {t("Shipping.Mark.Shipper")} : {shipper}
                  </h4>
                  {/* <div className="button-row">
                    <select value={shipperName} onChange={handleShipperChange}>
                      <option value="" disabled>
                        {t("Shipping.Mark.Shipper")}
                      </option>
                      {shipments.map((shipment, index) => (
                        <option key={shipment + index} value={shipment}>
                          {shipment}
                        </option>
                      ))}
                    </select>
                  </div>
                  {shipmentError && (
                    <p className="error-message">{shipmentError}</p>
                  )} */}

                  {error && <p className="error-message">{error}</p>}
                  <div className="button-row">
                    <button className="btn btn-light-primary" onClick={onClose}>
                      {t("Common.Button.Cancel")}
                    </button>
                    <button className="btn btn-primary" onClick={handleSearch}>
                      {t("Common.Button.Save")}
                    </button>
                  </div>
                </div>
              )}
              {activeView === "packingStation" && (
                <div>
                  <h4>
                    {t("Shipping.Mark.Shipper")} : {shipper}
                  </h4>
                  <h4 data-testid="packing-station-title">
                    {t("Shipping.Mark.Station")}:
                  </h4>
                  <div className="checkbox-group">
                    {packingStations.map((station, index) => (
                      <div className="checkbox-item" key={station + index}>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedPackingStations.includes(station)}
                            onChange={() => handleCheckboxChange(station)}
                            aria-label={station}
                          />
                          {station}
                        </label>
                      </div>
                    ))}
                  </div>
                  <h4>{t("Shipping.Mark.Item")}:</h4>
                  <div className="checkbox-group">
                    <input
                      type="number"
                      value={inputQty}
                      onChange={handleChange}
                      placeholder={t("Shipping.Mark.Item")}
                    />
                    {error && <p className="error-message">{error}</p>}
                  </div>
                  <div className="button-row">
                    <button className="btn btn-light-primary" onClick={onClose}>
                      {t("Common.Button.Cancel")}
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={handleSavePacking}
                    >
                      {t("Common.Button.Save")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {showConfirmation && (
        <ConfirmationModal
          message={t("Shipping.Mark.Success")}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
};

export default AddShippingMarkModal;
