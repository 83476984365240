import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import ShipmentHandovermodal from "../modal/ShipmentHandoverModal";
import PrintHandoverModal from "../modal/PrintHandoverModal";
import AddShippingMarkModal from "../modal/StepperModal";
import ViewShippinngMarkModal from "../modal/ViewShippingMarkModal";

type Props = {
  data?: any;
};

const ActionsCell: FC<Props> = ({ data }) => {
  const items = data["zsubcollection"]?.shippingMark || [];
  const { t } = useTranslation();

  const [isAddHandoverModalOpen, setAddHandoverModalOpen] =
    useState<boolean>(false);
  const [isSaveHandoverModalOpen, setSaveHandoverModalOpen] =
    useState<boolean>(false);
  const [isViewShippingModalOpen, setViewShippingModalOpen] =
    useState<boolean>(false);
  const [isAddShippingModalOpen, setAddShippingModalOpen] =
    useState<boolean>(false);

  // Check if date is available and convert to milliseconds
  const firebaseTimestamp = data.datePickup
    ? {
        seconds: data.datePickup.seconds,
        nanoseconds: data.datePickup.nanoseconds,
      }
    : null;

  let formattedDate = "N/A";
  let formattedTime = "N/A";

  if (firebaseTimestamp) {
    const totalMilliseconds =
      firebaseTimestamp.seconds * 1000 +
      firebaseTimestamp.nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);

    formattedDate = date.toLocaleDateString("id-ID");
    formattedTime = date.toLocaleTimeString("id-ID", {
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  const handleCloseModal = () => {
    setAddHandoverModalOpen(false);
    setSaveHandoverModalOpen(false);
    setAddShippingModalOpen(false);
    setViewShippingModalOpen(false);

    window.document.getElementById("button-refetch")?.click();
  };

  const handleEditHandover = () => setAddHandoverModalOpen(true);
  const handlePrintHandover = () => setSaveHandoverModalOpen(true);
  const handleViewShippingmark = () => setViewShippingModalOpen(true);
  const handleAddShippingmark = () => setAddShippingModalOpen(true);
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          variant="secondary"
          id="dropdown-basic"
          data-testid="dropdownToggleAction"
          className="px-2 py-1 fs-7 my-4"
        >
          {t("Marketplace.Table.Header.Action")}
        </Dropdown.Toggle>
        <Dropdown.Menu className="bg-light text-center">
          <div>
            {!data.isPrinted && (
              <div className="dropdown-item btn btn-active-light btn-sm">
                <button
                  type="button"
                  className="btn btn-sm"
                  onClick={handleEditHandover}
                  data-testid="edit-handover-button"
                >
                  {t("Shipping.Handover.Edit")}
                </button>
              </div>
            )}
            <div className="dropdown-item btn btn-active-light btn-sm">
              <button
                type="button"
                className="btn btn-sm"
                onClick={handlePrintHandover}
                data-testid="print-handover-button"
              >
                {t("Shipping.Handover.Print")}
              </button>
            </div>
            <div className="dropdown-item btn btn-active-light btn-sm">
              <button
                type="button"
                className="btn btn-sm"
                onClick={handleViewShippingmark}
                data-testid="view-shippingmark-button"
              >
                {t("Shipping.Handover.Mark.View")}
              </button>
            </div>
            {!data.isPrinted && (
              <div className="dropdown-item btn btn-active-light btn-sm">
                <button
                  type="button"
                  className="btn btn-sm"
                  onClick={handleAddShippingmark}
                  data-testid="add-shippingmark-button"
                >
                  {t("Shipping.Handover.Mark.Add")}
                </button>
              </div>
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>

      {/* Modal View Handover */}
      {isAddHandoverModalOpen && (
        <ShipmentHandovermodal
          onClose={handleCloseModal}
          defaultData={{
            noForm: data.id,
            tanggalPickUp: formattedDate,
            waktuPickUp: formattedTime,
            layananPengiriman: data.shipper,
            namaDriver: data.driverName,
            nomorKendaraan: data.vehicleNumber,
            paketDiscan: data.packageScannedQty,
            paketBerhasilScan: data.successScanned,
            paketGagal: data.failedScanned,
            paketBatal: data.packageCanceled,
            catatan: data.description,
          }}
        />
      )}

      {/* Modal Print Handover */}
      {isSaveHandoverModalOpen && (
        <PrintHandoverModal
          onClose={handleCloseModal}
          defaultData={{
            noForm: data.id,
            tanggalPickUp: formattedDate,
            waktuPickUp: formattedTime,
            layananPengiriman: data.shipper,
            namaDriver: data.driverName,
            nomorKendaraan: data.vehicleNumber,
            paketDiscan: data.packageScannedQty,
            paketBerhasilScan: data.successScanned,
            paketGagal: data.failedScanned,
            paketBatal: data.packageCanceled,
            catatan: data.description,
          }}
        />
      )}

      {/* Modal Add Shipping Mark */}
      {isAddShippingModalOpen && (
        <AddShippingMarkModal
          handoverId={data.id}
          shipper={data.shipper}
          onClose={handleCloseModal}
        />
      )}

      {/* Modal View Shipping Mark Modal */}
      {isViewShippingModalOpen && (
        <ViewShippinngMarkModal
          onClose={handleCloseModal}
          defaultData={items}
          shipper={data.shipper}
        />
      )}
    </>
  );
};

export { ActionsCell };
