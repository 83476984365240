import clsx from "clsx";
import React, { FC, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Option from "../../../../styles/components/Option";
import MultiSelect from "../../../../styles/components/MultiSelect";
import Button from "../../../../styles/components/Button";
import { OrderExportHeaderTitle } from "../../../modules/order/orderHeader/OrderExportHeaderTitle";
import { PageTitle, PageLink } from "../../core";
import { useNavigate } from "react-router-dom";
// import { useHistory } from 'react-router';
import {
  endOfDay,
  endOfMonth,
  format,
  startOfDay,
  startOfMonth,
} from "date-fns";
import { uploadFile } from "../../../../api/firebase/storage";
import { Files } from "../../../models/Files";
import { addExportOrderFiles, addFiles } from "../../../../db/serviceFiles";
import {
  IExportOrderResponse,
  sendExportOrder,
} from "../../../../api/server/order";
import * as lStorage from "../../../modules/localstorage";
import Account from "../../../models/Account";
import * as actAccount from "../../../../actions/account";
import AlertFailed from "../../../modules/alert/AlertFailed";
import { getByClient } from "../../../../db/serviceAccount";
import { OrderStatus7Days } from "../../../models/Order";
import { DocumentReference } from "firebase/firestore";
import db, { createRef } from "src/db";
import { Loading } from "src/app/modules/util/Loading";

const OrderExport: FC = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();
  const nav = useNavigate();

  const today = new Date();
  const firstDayOfMonth = startOfMonth(today);
  const lastDayOfMonth = endOfMonth(today);
  const startOfDayTime = startOfDay(today);
  const endOfDayTime = endOfDay(today);
  const formattedStartDate = format(firstDayOfMonth, "yyyy-MM-dd");
  const formattedEndDate = format(today, "yyyy-MM-dd");
  const formattedStartTime = format(startOfDayTime, "HH:mm:ss");
  const formattedEndTime = format(endOfDayTime, "HH:mm:ss");

  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Menu.Order"),
      path: "#",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const StatusOptions: any[] = [
    { value: OrderStatus7Days.Unpaid, label: OrderStatus7Days.Unpaid },
    { value: OrderStatus7Days.NewOrder, label: OrderStatus7Days.NewOrder },
    {
      value: OrderStatus7Days.ReadyToShip,
      label: OrderStatus7Days.ReadyToShip,
    },
    { value: OrderStatus7Days.Shipping, label: OrderStatus7Days.Shipping },
    { value: OrderStatus7Days.Completed, label: OrderStatus7Days.Completed },
    { value: OrderStatus7Days.Cancelled, label: OrderStatus7Days.Cancelled },
    { value: OrderStatus7Days.Return, label: OrderStatus7Days.Return },
    { value: "PROCESSED", label: "PROCESSED" },
  ];

  const AllOption = [{ value: "*", label: "All" }, ...StatusOptions];

  const [AllOptionWarehouse, setAllOptionWarehouse] = useState<any[]>([
    { value: "*", label: "All" },
  ]);

  const [accountListByClient, setAccountListByClient] = useState<Account[]>([]);
  const [fileTypeList, setFileTypeList] = useState<any[]>([
    { id: "headerLess", name: "HeaderLess" },
    { id: "header", name: "Header" },
  ]);
  const [exportTemplateListByClient, setExportTemplateListByClient] = useState<
    any[]
  >([]);
  const [selectedExportTemplate, setSelectedExportTemplate] = useState<any>();
  const [selectedExportFileUrl, setSelectedExportFileUrl] = useState<any>();

  const client = lStorage.getClientRef();

  const [accountID, setAccountID] = useState<string>();
  const [selectedStatus, setSelectedStatus] = useState<any[]>([
    { value: "READY_TO_SHIP", label: "READY_TO_SHIP" },
  ]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<any[]>([]);
  const [statusValue, setStatusValue] = useState<any[] | undefined>();
  const [warehouseValue, setWarehouseValue] = useState<any[] | undefined>();
  const [startDate, setStartDate] = useState(formattedStartDate); //set dafault value within current month
  const [startTime, setStartTime] = useState(formattedStartTime);
  const [endDate, setEndDate] = useState(formattedEndDate);
  const [endTime, setEndTime] = useState(formattedEndTime);
  const [selectedFileType, setSelectedFileType] = useState<string>();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedFileDisplay, setSelectedFileDisplay] = useState<string>("");

  const [errExportResponse, setErrExportResponse] = useState<string>();
  const [isValidExportResponse, setIsValidExportResponse] =
    useState<boolean>(true);
  const [successUploadedFile, setSuccessUploadedFile] =
    useState<boolean>(false);

  const [isFirstRun, setIsFirstRun] = useState<boolean>(true);
  const [isAccountValid, setIsAccountValid] = useState<boolean>(false);
  const [isFileValid, setIsFileValid] = useState<boolean>(true);
  const [isFileSizeValid, setIsFileSizeValid] = useState<boolean>(true);
  const [isStatusValid, setIsStatusValid] = useState<boolean>(true);
  const [isWarehouseValid, setIsWarehouseValid] = useState<boolean>(true);
  const [isStartDateValid, setIsStartDateValid] = useState<boolean>(true);
  const [isStartTimeValid, setIsStartTimeValid] = useState<boolean>(true);
  const [isEndDateValid, setIsEndDateValid] = useState<boolean>(true);
  const [isEndTimeValid, setIsEndTimeValid] = useState<boolean>(true);
  const [isEndDateLessThanStartDate, setIsEndDateLessThanStartDate] =
    useState<boolean>(true);
  const [isEndTimeLessThanStartTime, setIsEndTimeLessThanStartTime] =
    useState<boolean>(true);
  const [processingExport, setProcessingExport] = useState<boolean>(false);

  const handleOnChangeAccountMarketplace = (selected: any) => {
    setIsFirstRun(false);
    const accountSelected = selected.target.value;
    if (accountSelected === "1") {
      setAccountID(undefined);
    } else {
      setAccountID(accountSelected);
    }
    console.log(`accountSelected : `, accountSelected);
  };

  const handleOnChangeExportTemplate = (selected: any) => {
    setIsFirstRun(false);
    const exportTemplate = selected.target.value;
    if (exportTemplate === "1") {
      setSelectedExportTemplate(undefined);
    } else {
      setSelectedExportTemplate(exportTemplate);
    }
  };

  const handleOnChangeStatus = (selected: any) => {
    if (!selected) return undefined;
    setSelectedStatus(selected);

    console.log(`status stringgify selected : ${JSON.stringify(selected)}`);
  };

  const handleOnChangeWarehouse = (selected: any) => {
    if (!selected) return undefined;
    setSelectedWarehouse(selected);

    console.log(`warehouse stringgify selected : ${JSON.stringify(selected)}`);
  };

  const handleOnChangeStartDate = (e: any) => {
    setStartDate(e.target.value);
    // console.log(`start date value : `, startDate);
  };

  const handleOnChangeStartTime = (e: any) => {
    setStartTime(e.target.value);
    // console.log(`start time value : `, startTime);
  };

  const handleOnChangeEndDate = (e: any) => {
    setEndDate(e.target.value);
    // console.log(`end date value : `, endDate);
  };

  const handleOnChangeEndTime = (e: any) => {
    setEndTime(e.target.value);
    // console.log(`end time value : `, endTime);
  };

  const handleButtonUpload = async () => {
    setSuccessUploadedFile(false);
    const file = selectedFile;
    let fileURL: string | undefined;

    if (file) {
      const fileName = file.name;
      const fileType = file.type;
      const fileSize = file.size.toString();
      const extension = file.name.split(".").pop();
      const name = file.name.replace(`.${extension}`, "");
      const storagePath = `orders/${fileName}`;
      const menu = "order";
      const field = "Export Order";

      // save file to firestorage
      fileURL = await uploadFile(file, storagePath);

      if (!fileURL) {
        fileURL = "";
      }
      setSelectedExportFileUrl(fileURL);
      console.log(`fileURL : `, fileURL);
      console.log(`fileTYPE : `, fileType);

      const FileData: Files = {
        extension,
        fileType,
        fileName,
        fileSize,
        fileURL,
        name,
        for: {
          menu,
          field,
        },
      };

      if (client) {
        await addFiles(FileData, client?.id);
        setSuccessUploadedFile(true);
      }
    }
  };

  const handleFileChange = (event: any) => {
    setIsFileValid(true);
    setSuccessUploadedFile(false);

    const file = event.target.files[0];

    if (!file) {
      if (selectedFile) {
        const fileType = selectedFile.type;
        const allowedFileTypes = [
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "text/csv",
        ];

        if (!allowedFileTypes.includes(fileType)) {
          setIsFileValid(false);
        }

        const fileSize = selectedFile.size;
        const maxSizeInBytes: number = 1024 * 1024;

        if (fileSize > maxSizeInBytes) {
          setIsFileSizeValid(false);
        }
      }
      return;
    }

    const fileType = file.type;
    const allowedFileTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      "application/vnd.ms-excel", // .xls (old Excel format)
      "application/vnd.oasis.opendocument.spreadsheet", // .ods (LibreOffice/OpenOffice format)
      "text/csv", // .csv
      "application/csv", // another possible MIME type for .csv
      "text/plain", // sometimes .csv files use this type
      "application/vnd.google-apps.spreadsheet", // Google Sheets
    ];

    if (!allowedFileTypes.includes(fileType)) {
      setIsFileValid(false);
    }

    const fileSize = file.size;
    const maxSizeInBytes: number = 1024 * 1024;

    if (fileSize > maxSizeInBytes) {
      setIsFileSizeValid(false);
    }

    setSelectedFileDisplay(file.name);
    setSelectedFile(file);
    // ValidationFile();

    console.log(`size : `, event.target.files[0].size);
    console.log(`fileType : `, event.target.files[0].type);
    console.log(`fileName : `, event.target.files[0].name);
    console.log(`extension : `, event.target.files[0].name.split(".").pop());
  };

  const handleFileClear = () => {
    setIsFileValid(true);
    setSelectedFileDisplay("");
    setSelectedFile(undefined);
    setSuccessUploadedFile(false);
  };

  const ValidationData = () => {
    if (
      !isStatusValid ||
      !selectedFileType ||
      !isWarehouseValid ||
      !isStartTimeValid ||
      !isStartDateValid ||
      !isEndTimeValid ||
      !isEndDateValid ||
      !isEndDateLessThanStartDate ||
      !isEndTimeLessThanStartTime ||
      !isAccountValid ||
      !isFileValid ||
      !isFileSizeValid
    ) {
      return false;
    }

    return true;
  };

  const handleExport = async () => {
    setIsFirstRun(false);
    setIsValidExportResponse(true);
    setProcessingExport(true);

    const isValidData = await ValidationData();
    if (!isValidData) return;

    console.log(`isValidData : `, isValidData);

    // hit api to export data
    const account = accountID;
    const warehouse = warehouseValue;
    const fileType = selectedFileType;
    const from_time = `${startDate} ${startTime} GMT+0700`;
    const to_time = `${endDate} ${endTime} GMT+0700`;
    const status = statusValue;
    const urlFile = selectedExportTemplate || selectedExportFileUrl;
    const reqData = {
      account,
      warehouse,
      fileType,
      from_time,
      to_time,
      status,
      urlFile,
    };
    console.log(`reqData : `, reqData);

    await sendExportOrder(
      reqData,
      (err: number, response: IExportOrderResponse) => {
        console.log(`response : `, JSON.stringify(response));
        if (!response.error) {
          setProcessingExport(false);
          nav("/order/export/download", { state: response });
        } else {
          setProcessingExport(false);
          const errorMessage = response.message || response.error;
          setErrExportResponse(errorMessage);
          setIsValidExportResponse(false);
        }
      }
    );
  };

  const handleLearnMoreClick = () => {
    nav("/order/export/information");
  };

  const getClientExportTemplate = async (client: any) => {
    if (client) {
      let queryExportData = await db
        .collection("files")
        .where("for.field", "==", "Export Order")
        .where("client", "==", client)
        .get();
      let clientData = await client.get().then((doc: any) => {
        if (doc.exists) {
          return {
            ...doc.data(),
            id: doc.id,
          };
        }
      });
      let exportFormat: { id: string; name: string; url: string }[] = [];
      if (queryExportData.empty === false) {
        queryExportData.forEach((doc) => {
          exportFormat.push({
            id: doc.id,
            name: doc.data().name,
            url: doc.data().fileURL,
          });
        });
      }
      return {
        ...clientData,
        otherFormat: exportFormat,
        id: clientData.id,
      };
    }
  };

  const getMarketplaceWarehouseMapByAccount = async (accountID: string) => {
    if (accountID) {
      const query = await db
        .collection("marketplaceWarehouseMap")
        .where("account", "==", createRef("account", accountID))
        .get();
      if (!query.empty) {
        const data: any[] = [];
        query.forEach((doc) => {
          data.push({
            ...doc.data(),
            id: doc.id,
          });
        });

        if (data.length > 0) {
          return Promise.all(
            data.map(async (item: any) => {
              const pickupWarehouse = await item.pickupWarehouse.get();
              const pickupWarehouseName = pickupWarehouse.data().name;
              const marketplaceWarehouseId = item.marketplaceWarehouseId;
              return {
                label: pickupWarehouseName,
                value: marketplaceWarehouseId,
              };
            })
          );
        }
      }
    }
    return [];
  };

  const processGetWarehouseList = async (accountID: string) => {
    const warehouseList = await getMarketplaceWarehouseMapByAccount(accountID);
    setAllOptionWarehouse([{ value: "*", label: "All" }, ...warehouseList]);
  };

  useEffect(() => {
    if (accountID !== undefined) {
      processGetWarehouseList(accountID);
    }
  }, [accountID]);

  useEffect(() => {
    console.log(`isValidExportResponse : `, isValidExportResponse);
    if (!isValidExportResponse) {
      const buttonFailedAlert = document.getElementById("triggerFailedExport");
      if (!buttonFailedAlert) return;

      buttonFailedAlert.click();
    }
  }, [isValidExportResponse]);

  useEffect(() => {
    let objValueStatus = selectedStatus.map((stat: any) => {
      return stat.value;
    });

    if (!objValueStatus) {
      objValueStatus = [];
    }
    setStatusValue(objValueStatus);

    let objValueWh = selectedWarehouse.map((stat: any) => {
      return stat.value;
    });

    if (!objValueWh) {
      objValueWh = [];
    }
    setWarehouseValue(objValueWh);

    const start = `${startDate} ${startTime}`;
    const formattedStart = new Date(start);
    const end = `${endDate} ${endTime}`;
    const formattedEnd = new Date(end);

    if (objValueStatus.length === 0) setIsStatusValid(false);
    else setIsStatusValid(true);
    if (objValueWh.length === 0) setIsWarehouseValid(false);
    else setIsWarehouseValid(true);
    if (!accountID || accountID === "1") setIsAccountValid(false);
    else setIsAccountValid(true);
    if (!startDate) setIsStartDateValid(false);
    else setIsStartDateValid(true);
    if (!startTime) setIsStartTimeValid(false);
    else setIsStartTimeValid(true);
    if (!endDate) setIsEndDateValid(false);
    else setIsEndDateValid(true);
    if (!endTime) setIsEndTimeValid(false);
    else setIsEndTimeValid(true);
    if (endDate && endDate < startDate) setIsEndDateLessThanStartDate(false);
    else setIsEndDateLessThanStartDate(true);
    if (formattedEnd < formattedStart) setIsEndTimeLessThanStartTime(false);
    else setIsEndTimeLessThanStartTime(true);
  }, [
    startDate,
    startTime,
    endDate,
    endTime,
    selectedStatus,
    selectedWarehouse,
    accountID,
  ]);

  useEffect(() => {
    if (client) {
      console.log(client.id);
      getByClient(client).then((accounts) => {
        setAccountListByClient(accounts);
      });
      getClientExportTemplate(client).then((clientData) => {
        if (clientData) {
          setExportTemplateListByClient((prevList) => {
            const newList = [...prevList];

            if (
              clientData?.exportFormatFileURL &&
              !newList?.some((item) => item?.id === "exportFormatFileURL")
            ) {
              newList.push({
                id: "exportFormatFileURL",
                name: "Export Format File",
                url: clientData?.exportFormatFileURL,
              });
            }

            if (
              clientData?.exportFormatPrepackURL &&
              !newList?.some((item) => item?.id === "exportFormatPrepackURL")
            ) {
              newList.push({
                id: "exportFormatPrepackURL",
                name: "Export Format Prepack",
                url: clientData?.exportFormatPrepackURL,
              });
            }

            if (clientData?.otherFormat) {
              clientData?.otherFormat?.forEach((format: any) => {
                if (
                  format?.url &&
                  !newList?.some((item) => item?.id === format?.id)
                ) {
                  newList.push({
                    id: format?.id,
                    name: format?.name,
                    url: format?.url,
                  });
                }
              });
            }

            return newList;
          });
        }
      });
    }
    console.log(`accountListByClient : `, accountListByClient);
    console.log(`exportTemplateListByClient : `, exportTemplateListByClient);
  }, []);

  return (
    <>
      {processingExport && <Loading />}
      {/* Error Export CSV */}
      {/* invisible button*/}
      <button
        id="triggerFailedExport"
        data-bs-toggle="modal"
        data-bs-target="#modal_failed"
        data-testid="trigger-failed-export"
        style={{ display: "none" }}
      ></button>
      <AlertFailed title="Failed Export" errorMessage={errExportResponse} />

      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {t("ExportOrder.Title")}
      </PageTitle>

      <div
        className="d-flex flex-column flex-lg-column h-100"
        data-testid="export-order"
      >
        <div
          className="d-flex flex-column flex-lg-column"
          style={{ minHeight: "92.5%" }}
        >
          <div
            // className="overflow-hidden card mb-1 mb-xl-2 p-7 h-100"
            className={clsx(
              "overflow-hidden card",
              `h-100 ${isTabletOrMobile ? "" : "p-7"}`
            )}
          >
            <div
              style={{
                height: "90%",
                // isSummary ? "" : "90%",
              }}
              className={clsx(
                `d-flex flex-column ${isTabletOrMobile && "p-5"}`
              )}
            >
              <div
                className={clsx(
                  // "flex-lg-column-fluid tab-content h-100",
                  "flex-lg-column-fluid tab-content p-5 h-100",
                  {
                    "overflow-auto": !isTabletOrMobile,
                  }
                )}
              >
                <div className="d-flex flex-column">
                  <div className="mb-2 fs-4">
                    <p style={{ fontSize: "16px" }}>{t("ExportOrder.Info")}</p>
                    <p style={{ fontSize: "16px" }}>{t("ExportOrder.Step1")}</p>
                    <p style={{ fontSize: "16px" }}>{t("ExportOrder.Step2")}</p>
                    <p style={{ fontSize: "16px" }}>{t("ExportOrder.Step3")}</p>
                    <p style={{ fontSize: "16px" }}>{t("ExportOrder.Step4")}</p>
                    <button
                      data-testid="learn-more-btn"
                      onClick={handleLearnMoreClick}
                      style={{
                        background: "none",
                        border: "none",
                        fontSize: "16px",
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                    >
                      {t("ExportOrder.LearnMore")}
                    </button>
                  </div>

                  {/* Select File / Upload File Input */}
                  <div className="step-sub-title mt-5 mb-2 fs-4">
                    <label className="form-check-label">
                      {t("ExportOrder.SelectFile.Info")} /{" "}
                      {t("ExportOrder.UploadFile.Info")}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className={clsx(
                      `d-flex flex-row mb-0 gap-2 ${
                        isTabletOrMobile ? "w-100" : "w-75"
                      }`
                    )}
                  >
                    {/* select existing template file csv */}
                    <div className="w-50">
                      <select
                        className="form-select me-10"
                        aria-label="Select file"
                        id="templateFileSelect"
                        onChange={handleOnChangeExportTemplate}
                        data-testid="export-template-selection"
                        disabled={selectedFile !== undefined}
                      >
                        <option value="1">
                          {t("ExportOrder.SelectFile.Info")}
                        </option>
                        {exportTemplateListByClient.length > 0 &&
                          exportTemplateListByClient.map((element, index) => {
                            return (
                              <option
                                value={element?.url}
                                key={element?.id + index}
                                data-key={element?.id}
                                data-testid="export-template-options"
                              >
                                {element?.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    {/* upload template file csv  */}
                    <div className="w-50">
                      <div className="d-flex align-items-center">
                        <div className="input-group border border-black rounded">
                          <div
                            className={`d-flex align-items-center px-4 flex-grow-1`}
                          >
                            {selectedFileDisplay ? (
                              <>
                                <label className="flex-grow-1 text-gray-600 text-truncate">
                                  {selectedFileDisplay.length > 25
                                    ? selectedFileDisplay.substring(0, 25) +
                                      "..."
                                    : selectedFileDisplay}
                                </label>
                                <span
                                  className="ms-3 bi bi-x-lg btn btn-sm btn-icon-danger btn-text-danger p-0"
                                  onClick={handleFileClear}
                                ></span>
                              </>
                            ) : (
                              <label className="flex-grow-1 text-gray-600 text-truncate">
                                {t("Common.NoFileChosen")}
                              </label>
                            )}
                          </div>
                          <label
                            className={clsx(
                              `fs-6 ${
                                selectedFile !== undefined
                                  ? "hidden p-0"
                                  : "flex"
                              } ${
                                selectedExportTemplate !== undefined
                                  ? "cursor-not-allowed btn btn-secondary disabled "
                                  : "cursor-pointer btn btn-secondary "
                              }`
                            )}
                            data-testid="display-upload-file"
                            id="uploadTargetCSV"
                          >
                            {selectedFile === undefined && (
                              <span
                                className={`ms-2 ${
                                  selectedExportTemplate !== undefined
                                    ? "cursor-not-allowed"
                                    : "cursor-pointer"
                                }`}
                              >
                                {t("Common.Modal.Button.Browse")}
                              </span>
                            )}
                            <input
                              id="input-upload-file"
                              data-testid="input-upload-file"
                              type="file"
                              name="input-upload-file"
                              multiple={false}
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                              disabled={selectedExportTemplate !== undefined}
                            />
                          </label>
                          {selectedFile !== undefined &&
                            successUploadedFile !== true && (
                              <Button
                                data-testid="btnUpload"
                                type="button"
                                btnbs="primary"
                                onClick={handleButtonUpload}
                              >
                                {t("ExportOrder.UploadFile.Info")}
                              </Button>
                            )}
                          {successUploadedFile !== false &&
                            selectedFile !== undefined && (
                              <Button
                                data-testid="btnSuccess"
                                type="button"
                                btnbs="success"
                                disabled={true}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 14 14"
                                  className="m-0 p-0"
                                >
                                  <g
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path d="m4 8l2.05 1.64a.48.48 0 0 0 .4.1a.5.5 0 0 0 .34-.24L10 4" />
                                    <circle cx="7" cy="7" r="6.5" />
                                  </g>
                                </svg>
                              </Button>
                            )}
                        </div>
                      </div>
                      <div className="d-flex gap-2">
                        {!isFileValid && (
                          <div
                            style={{ color: "red" }}
                            role="alertInputUploadFile"
                            data-testid="alert-input-upload-file"
                          >
                            {t("ExportOrder.UploadFile.Alert.InvalidFormat")}
                          </div>
                        )}
                        {!isFileSizeValid && (
                          <div
                            style={{ color: "red" }}
                            role="alertInputUploadFile"
                            data-testid="alert-input-upload-file"
                          >
                            {t("ExportOrder.UploadFile.Alert.MaxFileSize")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex w-100" data-testid="fileErrValid">
                    {!selectedExportTemplate && !selectedFile && !isFirstRun && (
                      <div
                        style={{ color: "red" }}
                        id="alert-select-file"
                        role="alert-select-file"
                      >
                        {t("ExportOrder.SelectFile.Alert")}
                        {" / "}
                        {t("ExportOrder.UploadFile.Alert")}
                      </div>
                    )}
                  </div>

                  {/* FileType Selection */}
                  <div
                    className="step-sub-title mt-5 mb-2 fs-4"
                    id="bpi-storefront"
                  >
                    <label className="form-check-label" id="file-type">
                      {t("ExportOrder.FileType.Info")}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className={clsx(
                      `"d-flex flex-row mb-2 ${
                        isTabletOrMobile ? "w-100" : "w-75"
                      }`
                    )}
                  >
                    <div className="w-100">
                      <select
                        className={`form-select me-10 ${
                          selectedExportFileUrl === undefined &&
                          selectedExportTemplate === undefined
                            ? "border-1"
                            : "border-2"
                        }`}
                        aria-label="Select file type"
                        id="fileTypeSelect"
                        onChange={(event) =>
                          setSelectedFileType(event.target.value)
                        }
                        data-testid="file-type-selection"
                        disabled={
                          selectedExportFileUrl === undefined &&
                          selectedExportTemplate === undefined
                        }
                        style={{
                          backgroundColor:
                            selectedExportFileUrl === undefined &&
                            selectedExportTemplate === undefined
                              ? "hsl(0, 0%, 95%)"
                              : "",
                          borderColor:
                            selectedExportFileUrl === undefined &&
                            selectedExportTemplate === undefined
                              ? "hsl(0, 0%, 90%)"
                              : "",
                          color:
                            selectedExportFileUrl === undefined &&
                            selectedExportTemplate === undefined
                              ? "hsl(0, 0%, 50%)"
                              : "",
                        }}
                      >
                        <option value="1">
                          {t("ExportOrder.FileType.Selection.Info")}
                        </option>
                        {fileTypeList.length > 0 &&
                          fileTypeList.map((element, index) => {
                            return (
                              <option
                                value={element.id}
                                key={element.id + index}
                                data-key={element.id}
                                data-testid="file-type-options"
                              >
                                {element.name}
                              </option>
                            );
                          })}
                      </select>
                      <div className="d-flex" data-testid="accountErrValid">
                        {!selectedFileType && !isFirstRun && (
                          <div
                            style={{ color: "red" }}
                            id="alert-select-account"
                            role="alert-select-account"
                          >
                            {t("ExportOrder.FileType.Alert")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Marketplace Account Selection */}
                  <div
                    className="step-sub-title mt-5 mb-2 fs-4"
                    id="bpi-storefront"
                  >
                    <label
                      className="form-check-label"
                      id="marketplace-account"
                    >
                      {t("ExportOrder.MarketplaceAccount.Info")}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className={clsx(
                      `"d-flex flex-row mb-2 ${
                        isTabletOrMobile ? "w-100" : "w-75"
                      }`
                    )}
                  >
                    <div className="w-100">
                      <select
                        className={`form-select me-10 ${
                          selectedExportFileUrl === undefined &&
                          selectedExportTemplate === undefined
                            ? "border-1"
                            : "border-2"
                        }`}
                        aria-label="Select channel"
                        id="senderCampaignSelect"
                        onChange={handleOnChangeAccountMarketplace}
                        data-testid="account-marketplace-selection"
                        disabled={
                          selectedExportFileUrl === undefined &&
                          selectedExportTemplate === undefined
                        }
                        style={{
                          backgroundColor:
                            selectedExportFileUrl === undefined &&
                            selectedExportTemplate === undefined
                              ? "hsl(0, 0%, 95%)"
                              : "",
                          borderColor:
                            selectedExportFileUrl === undefined &&
                            selectedExportTemplate === undefined
                              ? "hsl(0, 0%, 90%)"
                              : "",
                          color:
                            selectedExportFileUrl === undefined &&
                            selectedExportTemplate === undefined
                              ? "hsl(0, 0%, 50%)"
                              : "",
                        }}
                      >
                        <option value="1">
                          {t("ExportOrder.MarketplaceAccount.Selection.Info")}
                        </option>
                        {accountListByClient.length > 0 &&
                          accountListByClient.map((element, index) => {
                            return (
                              <option
                                value={element.id}
                                key={element.id + index}
                                data-key={element.id}
                                data-testid="account-marketplace-options"
                              >
                                {`${element.type
                                  .charAt(0)
                                  .toUpperCase()}${element.type.substring(
                                  1,
                                  element.type.length
                                )}${
                                  element.searchKey
                                    ? ` - ${element.searchKey}`
                                    : element.name
                                    ? ` - ${element.name}`
                                    : ""
                                }`}
                              </option>
                            );
                          })}
                      </select>
                      <div className="d-flex" data-testid="accountErrValid">
                        {!isAccountValid && !isFirstRun && (
                          <div
                            style={{ color: "red" }}
                            id="alert-select-account"
                            role="alert-select-account"
                          >
                            {t("ExportOrder.MarketplaceAccount.Alert")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Multiple Marketplace Warehouse Selection */}
                  <div
                    className="step-sub-title mt-5 mb-2 fs-4"
                    id="bpi-storefront"
                  >
                    <label
                      className="form-check-label"
                      id="marketplace-warehouse"
                    >
                      {t("ExportOrder.MarketplaceWarehouse.Info")}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className={clsx(
                      `"d-flex flex-row mb-2 ${
                        isTabletOrMobile ? "w-100" : "w-75"
                      }`
                    )}
                  >
                    <div className="w-100">
                      <MultiSelect
                        data-testid="warehouse-selections"
                        className="d-flex align-items-center form-control border-2 px-0 py-0"
                        options={AllOptionWarehouse}
                        defaultValue={selectedWarehouse}
                        allOptionValue={AllOptionWarehouse[0].value}
                        onChange={handleOnChangeWarehouse}
                        components={{ Option }}
                        isClearable={true}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        data-role="warehouse-select"
                        disabled={accountID === undefined}
                      />
                      <div className="d-flex" data-testid="warehouseErrValid">
                        {!isWarehouseValid && !isFirstRun && (
                          <div
                            style={{ color: "red" }}
                            data-tesid="alertWarehouseSelection"
                            role="alert-warehouse-selection"
                          >
                            {t("ExportOrder.MarketplaceWarehouse.Alert")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Status Multiple Selection */}
                  <div
                    className="step-sub-title mt-5 mb-2 fs-4"
                    id="bpi-storefront"
                  >
                    <label className="form-check-label">
                      {t("ExportOrder.Status.Info")}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className={clsx(
                      `"d-flex flex-row mb-2 ${
                        isTabletOrMobile ? "w-100" : "w-75"
                      }`
                    )}
                  >
                    <div className="w-100">
                      <MultiSelect
                        data-testid="status-selections"
                        className="d-flex align-items-center form-control border-2 px-0 py-0"
                        options={AllOption}
                        defaultValue={selectedStatus}
                        allOptionValue={AllOption[0].value}
                        onChange={handleOnChangeStatus}
                        components={{ Option }}
                        isClearable={true}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        data-role="status-select"
                        disabled={selectedWarehouse === undefined}
                      />
                      <div className="d-flex" data-testid="statusErrValid">
                        {!isStatusValid && (
                          <div
                            style={{ color: "red" }}
                            data-tesid="alertStatusSelection"
                            role="alert-status-selection"
                          >
                            {t("ExportOrder.OrderStatus.Alert")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Start Date & Time Input */}
                  <div
                    className="step-sub-title mt-5 mb-2 fs-4"
                    id="bpi-storefront"
                  >
                    <label className="form-check-label">
                      {t("ExportOrder.Start.Info")}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className={clsx(
                      `d-flex flex-row mb-2 gap-2 ${
                        isTabletOrMobile ? "w-100" : "w-75"
                      }`
                    )}
                  >
                    <div className="w-50">
                      <div className="d-flex align-items-center form-control border-2 px-0 py-0">
                        <input
                          data-testid="start-date-input"
                          id="start-date-input"
                          name="start-date-input"
                          maxLength={50}
                          className="form-control border-0"
                          onChange={handleOnChangeStartDate}
                          value={startDate}
                          type="date"
                        />
                      </div>
                      <div className="d-flex">
                        {!isStartDateValid && (
                          <div
                            style={{ color: "red" }}
                            role="alert-input-start-date"
                            data-testid="startDateErrValid"
                          >
                            {t("ExportOrder.StartDate.Alert")}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="w-50">
                      <div className="d-flex align-items-center form-control border-2 px-0 py-0">
                        <input
                          data-testid="start-time-input"
                          id="start-time-input"
                          name="start-time-input"
                          maxLength={50}
                          className="form-control border-0"
                          onChange={handleOnChangeStartTime}
                          value={startTime}
                          type="time"
                          step="1"
                        />
                      </div>
                      <div className="d-flex">
                        {!isStartTimeValid && (
                          <div
                            style={{ color: "red" }}
                            aria-role="alert-input-start-time"
                            data-testid="startTimeErrValid"
                          >
                            {t("ExportOrder.StartTime.Alert")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* End Date & Time Input */}
                  <div
                    className="step-sub-title mt-5 mb-2 fs-4"
                    id="bpi-storefront"
                  >
                    <label className="form-check-label">
                      {t("ExportOrder.End.Info")}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className={clsx(
                      `d-flex flex-row mb-2 gap-2 ${
                        isTabletOrMobile ? "w-100" : "w-75"
                      }`
                    )}
                  >
                    <div className="w-50">
                      <div className="d-flex align-items-center form-control border-2 px-0 py-0">
                        <input
                          data-testid="end-date-input"
                          id="end-date-input"
                          name="end-date-input"
                          maxLength={50}
                          className="form-control border-0"
                          onChange={handleOnChangeEndDate}
                          value={endDate}
                          type="date"
                          min={startDate}
                          // max={formattedEndDate}
                        />
                      </div>
                      <div className="d-flex">
                        {!isEndDateValid && (
                          <div
                            style={{ color: "red" }}
                            role="alert-input-end-date"
                            data-testid="endDateErrValid"
                          >
                            {t("ExportOrder.EndDate.Alert1")}
                          </div>
                        )}
                        {!isEndDateLessThanStartDate && (
                          <div
                            style={{ color: "red" }}
                            role="alert-input-end-date-less-than-start-date"
                            data-testid="endDateLessThanStartDate"
                          >
                            {t("ExportOrder.EndDate.Alert2")}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="w-50">
                      <div className="d-flex align-items-center form-control border-2 px-0 py-0">
                        <input
                          data-testid="end-time-input"
                          id="end-time-input"
                          name="end-time-input"
                          maxLength={50}
                          className="form-control border-0"
                          onChange={handleOnChangeEndTime}
                          value={endTime}
                          type="time"
                        />
                      </div>
                      <div className="d-flex">
                        {!isEndTimeValid && (
                          <div
                            style={{ color: "red" }}
                            role="alert-input-end-time"
                            data-testid="endTimeErrValid"
                          >
                            {t("ExportOrder.EndTime.Alert1")}
                          </div>
                        )}
                        {!isEndTimeLessThanStartTime && (
                          <div
                            style={{ color: "red" }}
                            role="alert-input-end-time-less-than-start-time"
                            data-testid="endDateLessThanEndDate"
                          >
                            {t("ExportOrder.EndTime.Alert2")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={clsx(
                `d-flex bg-white justify-content-end mt-auto ${
                  isTabletOrMobile ? "mb-5" : ""
                }`
              )}
            >
              <Button
                data-testid="btnExport"
                type="button"
                btnbs="primary"
                cName="mx-5"
                onClick={handleExport}
              >
                {t("ExportOrder.Button.Export")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderExport;

// const handleOnChangeChannel = (selected: any) => {
//     const channelSelected: string = selected.target.value;
//     setChannel(channelSelected);
//     console.log(`channelSelected : `, channelSelected);

//     actAccount.fetchByChannel(channelSelected).then(
//       (accounts:any)=>{
//         setAccountList(accounts);
//       }
//     );
//   }
//   Saluran Selection
//                   <div
//                     className="step-sub-title mt-5 mb-2 fs-4"
//                     id="bpi-storefront"
//                   >
//                     <label className="form-check-label">
//                       Saluran
//                       <span className="text-danger">*</span>
//                     </label>
//                   </div>
//                   <div
//                     className={clsx(
//                       `"d-flex flex-row mb-2 ${
//                         isTabletOrMobile ? "w-100" : "w-75"
//                       }`
//                     )}
//                   >
//                     <div className="w-100">
//                     <select
//                         // disabled={selectedCampaign.status !== undefined && selectedCampaign.status !== "CREATED"}
//                         className={clsx("form-select mw-300px me-10")}
//                         aria-label="Select channel" id="channelCampaignSelect"
//                         onChange={handleOnChangeChannel}
//                         data-testid="channel-selection"
//                         role="channel-selection"

//                       >
//                         <option value="1">Pilih Saluran</option>
//                         { channelList !== null &&
//                           channelList.map((element,index) => {
//                             return <option value={element} key={element+index} data-testid="channel-selection-option"
//                             // selected={campaign?.accountModel?.type === element}
//                           >{`${element.charAt(0).toUpperCase()}${element.substring(1,element.length)}`}</option>
//                           })
//                         }
//                       </select>
//                       <div
//                         className="d-flex"
//                         data-testid="channelErrValid"
//                       >
//                         {!isChannelValid && !isFirstRun && (
//                           <div
//                             style={{ color: "red" }}
//                             id="channel-selection"
//                             role="alert-select-channel"
//                           >
//                             Saluran is required
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                   </div>
