import db, { createRef } from ".";
import * as conn from "./connection";
import { converter2 } from "./converter";
import * as userService from "./serviceUser";
import Message from "../app/models/Message";
import User, { UserCompanies } from "../app/models/User";
import { Collaboration, CollabsStatus, Room } from "../app/models/Chat";
import firebase from "firebase/compat/app";
import { ID } from "../resources/helpers";
import { removeFromNotificationsByCollabID } from "../app/modules/notify/Notification/helper";

import * as lc from "../app/modules/localstorage";
import { AnalyticsEvent } from "firebase-functions/v1/analytics";
import { FieldValue, Timestamp, arrayUnion } from "firebase/firestore";
import {
  fetchRoomByCollaborationId,
  fetchUserCollaborationByID,
} from "../actions/chat";

export const getCollborationbyCustomerRef = async (id: string) => {
  try {
    const querySnapshot = await db
      .collection("collaborations")
      .where("customer", "==", createRef("customers", id))
      .get();

    const collabPromises = querySnapshot.docs.map((doc) => {
      const roomPromise = fetchRoomByCollaborationId(doc.id);
      return roomPromise.then((roomsData) => ({
        ...doc.data(),
        id: doc.id,
        roomsModel: roomsData,
      }));
    });

    return Promise.all(collabPromises);
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Deletes a collaboration from the "collaborations" collection in Firestore.
 *
 * @param {string | undefined} id - The ID of the collaboration to delete.
 * @return {Promise<void>} A promise that resolves when the collaboration is successfully deleted, or rejects with an error if the deletion fails.
 */
export const deleteCollaboration = async (id: string) => {
  try {
    // Delete the collaboration document from the "collaborations" collection in Firestore.
    // The document is identified by its ID.
    await db
      .collection("collaborations")
      .doc(id)
      .delete();
  } catch (error) {
    // If an error occurs during the deletion, log the error message.
    console.error(error);
  }
}
// =========== Customer in Queue =========== //
export const fetchCIQ = async (
  clientId: string,
  companyAccess: UserCompanies[],
  limit?: number
) => {
  let query = db
    .collectionGroup("rooms")
    .withConverter(converter2<Room>())
    .where("status", "in", ["queue", "handled"])
    .orderBy("status", "asc")
    .orderBy("createdAt", "asc")
    .orderBy("updatedAt", "asc");

  try {
    const roomsQuerySnapshot = await query.get();
    let rooms: Room[] = [];
    // let collabModel: Collaboration[] = [];
    const userId = lc.getItemLC("UID");

    const promises: Promise<void>[] = roomsQuerySnapshot.docs.map(
      async (roomDoc) => {
        const room = roomDoc.data() as Room;
        room["id"] = roomDoc.id;

        let toUserModel: any[] = [];
        await Promise.all(
          room.toUser?.map(async (c: any) => {
            const userDoc = await c.get();
            let userData = userDoc.data();
            toUserModel.push(userData);
          }) || []
        );
        // room["toUserModel"] = toUserModel;
        (room as any)["toUserModel"] = toUserModel;

        // const collaborationRef = roomDoc.ref.parent?.parent;
        if (
          // collaborationRef &&
          room.status === "handled" &&
          room.toUser &&
          room.toUser.length > 0 &&
          room.toUser.every(async (userRef) => {
            const userDoc = await userRef.get();
            const userData = userDoc.data();
            return (
              userData?.state !== undefined &&
              userData?.state !== "online" &&
              room?.lastCustomerInteractionAt &&
              room?.lastCustomerInteractionAt > userData?.last_changed
            );
          }) === true &&
          room?.toUser?.every((userRef) => {
            return userRef?.id !== userId;
          }) === false
        ) {
          return; // Skip iteration if conditions are met
        }

        // const collaborationDoc = await collaborationRef?.get();
        // let collaborationData = collaborationDoc?.data() as Collaboration;

        // if (
        //   collaborationRef &&
        //   collaborationData &&
        //   collaborationData.client &&
        //   collaborationData.company &&
        //   collaborationData.company.id &&
        //   collaborationData.client.id === clientId &&
        //   companyAccess.some((company) => company.id === collaborationData?.company?.id)
        // ) {
        //   collaborationData.id = collaborationDoc?.id || "";
        //   collaborationData.toUserData = toUserModel;
        //   collaborationData.lastCustomerInteractionAt = room.lastCustomerInteractionAt as Timestamp;
        // room.collaborationModel = collaborationData;
        rooms.push(room);
        // collabModel.push(collaborationData);
        // }
      }
    );

    await Promise.all(promises);
    console.log(rooms, "apakah keload lagi kbr");
    return rooms;
  } catch (error) {
    console.error(`query CIQ ${error}`);
    return [] as Room[];
  }
};

export const fetchAccessibleCIQ = async (
  clientId: string,
  companyAccess: UserCompanies[]
) => {
  const getRooms = await db
    .collectionGroup("rooms")
    .orderBy("createdAt", "desc")
    .get();
  let dataRooms: any[] = [];
  await Promise.all(
    getRooms.docs.map(async (roomDoc) => {
      const roomData = roomDoc.data();

      const collabDocRef = roomDoc.ref.parent.parent;

      if (collabDocRef) {
        try {
          const collabDoc = await collabDocRef.get();
          let collabData = collabDoc.data() as any;
          let customerRef = collabData?.customer;
          if (customerRef) {
            const customerDoc = await customerRef.get();
            let customerData = customerDoc.data();
            collabData["customerModel"] = customerData;
          }

          const accountDocRef = roomData.account;
          const accountDoc = await accountDocRef.get();
          const accountData = accountDoc.data();

          let toUserData = await Promise.all(
            (roomData.toUser || []).map(async (z: any) => {
              const user = await userService.getUserByID(z.id);
              return user;
            })
          );

          dataRooms.push({
            ...roomData,
            idRoom: roomDoc.id,
            collaborationModel: collabData,
            accountModel: accountData,
            toUserData: toUserData,
            idCollab: collabDoc.id,
          });
        } catch (error) {
          console.error("Error retrieving data:", error);
        }
      }
    })
  );

  console.log(dataRooms, "data colgroup ax");

  return dataRooms;

  // let query = db
  //   .collection("collaborations")
  //   .withConverter(converter2<Collaboration>());

  // const hasAllCompanyAccess = companyAccess.find(
  //   (accessCompany) => accessCompany.id === "*"
  // );
  // if (!hasAllCompanyAccess) {
  //   const companyAccessQuery = companyAccess.map((company) =>
  //     createRef("company", company.id)
  //   );
  //   query
  //     .where("company", "in", companyAccessQuery)
  //     .where("client", "==", createRef("clients", clientId))
  //     .where("isActive", "==", true)
  //     .where("status", "==", CollabsStatus.queue);
  // }

  // return await query
  //   .orderBy("lastInteractionAt", "desc")
  //   .get()
  //   .then(async (snaps) => {
  //     const collabs: Collaboration[] = await Promise.all(
  //       snaps.docs.map(async (doc) => {
  //         const roomsDoc = await doc.ref
  //           .collection("rooms")
  //           .get()
  //           .then(async (querySnapshot) => {
  //             return Promise.all(querySnapshot.docs.map(async (roomDoc) => {
  //               if (roomDoc.exists) {
  //                 const accountRef = roomDoc.data()?.account?.id;
  //                 let accountsModel = null as any;

  //                 if (accountRef) {
  //                   const accountDoc = await db.collection("account").doc(accountRef).get();
  //                   if (accountDoc.exists) {
  //                     accountsModel = accountDoc.data();
  //                   }
  //                 }

  //                 return { ...roomDoc.data(), id: roomDoc.id, accountsModel: accountsModel };
  //               }
  //               return undefined;
  //             }));
  //           });
  //         let data = doc.data() as any;

  //         await Promise.all(roomsDoc.map(async (y: any) => {
  //           let temp2 = await Promise.all(
  //             (y.toUser || []).map(async (z: any) => {
  //               const user = await userService.getUserByID(z.id);
  //               return user;
  //             })
  //           );

  //           y.toUserData = temp2;
  //         }));

  //         return {
  //           ...data,
  //           id: doc.id,
  //           roomsModel: roomsDoc,
  //         };
  //       })
  //     );

  //     console.log(collabs, "isis colab ax");
  //     return collabs;
  //   })
  //   .catch((error) => {
  //     //TODO
  //     console.error("Error fetchAccessibleCIQ : " + error);
  //     return [] as Collaboration[];
  //   });
};

export const fetchCountCollaborations = (CompanyID: string) =>
  db
    .collection("company")
    .doc(CompanyID)
    .get()
    .then((doc) => {
      let count = 0;
      if (!doc.exists) {
        // doc.data() will be undefined in this case
        console.error("No such document!");
      } else {
        const company = doc.data();
        if (company) {
          count = company.ciqCount;
        }
      }
      return count;
    })
    .catch((error) => {
      console.error("No such document!");
      return 0;
    });

export const updateStatus = async (
  id: string,
  status: string,
  userId: string,
  messageFailed: string
) => {
  console.log(
    "==> masuk updateStatys balezzz",
    id,
    status,
    userId,
    messageFailed
  );
  removeFromNotificationsByCollabID(id);
  const userRef = conn.createRef("users", userId);
  //Check Collaboration
  try {
    let arrUser = [userRef];
    let getCollabByid = await fetchUserCollaborationByID(id);
    console.log(getCollabByid, "getCollabByid balezzz");

    if (getCollabByid) {
      getCollabByid?.toUserModel?.forEach((item) => {
        const isExist = arrUser?.some((user) => user?.id === item?.id);
        if (!isExist && arrUser?.length > 0) {
          let refId = conn.createRef("users", item.id);
          arrUser.push(refId);
        }
      });
    }
    const roomsSnapshot = await db
      .collection("collaborations")
      .doc(id)
      .collection("rooms")
      // .limit(1)
      .get();

    console.log(roomsSnapshot, "roomsSnapshot balezzz");
    console.log(arrUser, "arruser 1 balezzz");

    if (!roomsSnapshot.empty) {
      roomsSnapshot.docs.map(async (x) => {
        const roomDoc = x;
        await roomDoc.ref.update({
          status: status,
          updatedAt: new Date(),
          toUser: arrUser,
        });
      });
    } else {
      return null;
    }

    await db.collection("collaborations").doc(id).update({
      status: status,
      updatedAt: new Date(),
      handleAt: new Date(),
      handledByUser: userRef,
      toUser: arrUser,
    });
    console.log(arrUser, "arrUser 2 balezzz");
    if (!roomsSnapshot.empty) {
      roomsSnapshot.docs.map(async (x) => {
        await db
          .collection("collaborations")
          .doc(id)
          .collection("rooms")
          .doc(x.id)
          .update({
            status: status,
            updatedAt: new Date(),
            // handleAt: new Date(),
            // handledByUser: userRef,
            toUser: arrUser,
            profileName: getCollabByid?.profileName,
          });
      });
    }

    return id;
  } catch (error) {
    console.error("Error updating collaboration status:", error);
    return null;
  }
};

export const updateListCollabStatus = async (
  CIQIds: Array<ID>,
  status: string,
  userId: string,
  messageFailed: string,
  callback: any
) => {
  // let totalSuccess = 0;
  let totalFailed = 0;
  let successCollab: string[] = [];
  const userRef = conn.createRef("users", userId);
  for (let index = 0; index < CIQIds.length; index++) {
    const id = CIQIds[index];
    if (id === undefined || id === null) continue;

    //Check Collaboration
    let resultCollab = await conn
      .createRef("collaborations", id.toString())
      .get()
      .then(async (collab) => {
        let respCollab = collab.data() as Collaboration;
        respCollab.id = collab.id;
        return respCollab;
      });

    let arrUser: any[] = [];
    if (
      resultCollab &&
      resultCollab.toUser &&
      Array.isArray(resultCollab.toUser) &&
      resultCollab.toUser.length > 0
    ) {
      // arrUser = resultCollab.toUser;
      totalFailed += 1;
      continue;
    }

    arrUser.push(userRef);
    console.log(arrUser, "ciqaction pixpo");
    let success: boolean = await db
      .collection("collaborations")
      .doc(id.toString())
      .update({
        status: status,
        updatedAt: new Date(),
        handleAt: new Date(),
        toUser: arrUser,
      })
      .then(() => {
        successCollab.push(id.toString());
        return true;
      })
      .catch(function (error) {
        console.error("Error updateListCollabStatus : " + error);
        return false;
      });

    if (!success) {
      totalFailed += 1;
    }
    removeFromNotificationsByCollabID(id.toString());
  }

  if (totalFailed > 0) {
    alert(totalFailed + " " + messageFailed);
  }
  callback(successCollab);
  return Promise.resolve();
};

// mychat
export const fetchCollaborationsByUser = async (
  uid: string,
  clientid: string
) => {
  const userRef = conn.createRef("users", uid);
  const clientRef = conn.createRef("clients", clientid);
  const company = lc.getItemLC(lc.LCName.CompanyList);

  let query = db
    .collection("collaborations")
    .withConverter(converter2<Collaboration>())
    .where("client", "==", createRef("clients", clientid))
    .where("toUser", "array-contains", userRef)
    .where("status", "==", "handled");

  const hasAllCompanyAccess = company?.find(
    (accessCompany: any) => accessCompany.id === "*"
  );

  if (!hasAllCompanyAccess && company?.length > 0) {
    const companyAccessQuery = company.map((company: any) =>
      createRef("company", company.id)
    );
    query
      .where("company", "in", companyAccessQuery)
      .where("client", "==", createRef("clients", clientid))
      .where("isActive", "==", true)
      .where("status", "==", CollabsStatus.queue);
  }

  const data = await query
    .orderBy("lastInteractionAt", "desc")
    .get()
    .then(async (snapshot) => {
      let collaborations = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const roomsDoc = await doc.ref
            .collection("rooms")
            .get()
            .then(async (querySnapshot) => {
              return Promise.all(
                querySnapshot.docs.map(async (roomDoc) => {
                  if (roomDoc.exists) {
                    const accountRef = roomDoc.data()?.account?.id;
                    let accountsModel = null as any;

                    if (accountRef) {
                      const accountDoc = await db
                        .collection("account")
                        .doc(accountRef)
                        .get();
                      if (accountDoc.exists) {
                        accountsModel = accountDoc.data();
                      }
                    }

                    let temp = [] as any;
                    if (roomDoc.data()?.toUser) {
                      try {
                        const userPromises =
                          roomDoc.data()?.toUser?.map(async (item: any) => {
                            const user = await userService.getUserByID(item.id);
                            if (user) {
                              temp?.push(user);
                            }
                          }) || [];

                        await Promise.all(userPromises);
                      } catch (error) {
                        console.error("Error fetching user:", error);
                      }
                    }

                    return {
                      ...roomDoc.data(),
                      id: roomDoc.id,
                      accountsModel: accountsModel,
                      toUserModel: temp,
                      // toUserData: temp,
                    };
                  }
                  return undefined;
                })
              );
            });

          return { ...doc.data(), id: doc.id, roomsModel: roomsDoc };
        })
      );

      console.log(collaborations, "colabo rati mc");

      return collaborations;
    });

  return data;
};

// all chat
export const fetchCollaborationsByAllCompanyUser = async (
  companies: Array<firebase.firestore.DocumentReference>
) => {
  try {
    const currentClient = lc.getItemLC(lc.LCName.Client);
    const clientId = currentClient.id;
    const collaborationsQuery = db
      .collection("collaborations")
      .withConverter(converter2<Collaboration>())
      .where("client", "==", createRef("clients", clientId))
      .where("isActive", "==", true)
      .where("status", "==", CollabsStatus.queue);
    // .where("company", "==", companies[0])

    const hasAllCompanyAccess = companies?.find(
      (accessCompany) => accessCompany.id === "*"
    );

    if (!hasAllCompanyAccess && companies?.length > 0) {
      const companyAccessQuery = companies.map((company) =>
        createRef("company", company.id)
      );
      collaborationsQuery.where("company", "in", companyAccessQuery);
      // .where("client", "==", createRef("clients", clientId))
      // .where("isActive", "==", true)
      // .where("status", "==", CollabsStatus.queue);
    }

    const collaborations = await collaborationsQuery.get().then((snapshot) => {
      return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    });

    const useruid = lc.getItemLC(lc.LCName.UserID);
    const userref = createRef("users", useruid);

    const filteredCollaborations = await Promise.all(
      collaborations.map(async (collaboration) => {
        const roomsQuery = await db
          .collection(`collaborations/${collaboration?.id}/rooms`)
          .where("status", "!=", "queue")
          .get();

        const rooms = roomsQuery.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        })) as Array<{
          id: string;
          toUser?: Array<any>;
        }>;

        // console.log(rooms, "ini rooms newlid");

        const validRooms = rooms.filter((room) => {
          return (
            room.toUser &&
            !room.toUser.some((userRef: any) => userRef.path === userref.path)
          );
        });

        return validRooms.length > 0 ? collaboration : null;
      })
    );

    const validCollaborations = filteredCollaborations?.filter(
      (collab) => collab !== null
    );

    // console.log(validCollaborations, "qvalid newlid");

    return validCollaborations;
  } catch (error) {
    console.error("Error in fetchCollaborationsByAllCompanyUser:", error);
    throw error;
  }
};

export const updateLastInteraction = (
  collaborationid: string,
  newMessage: Message
) => {
  let lastMessage = newMessage.textContent;
  if (
    (newMessage.textContent === "" || newMessage.textContent === undefined) &&
    newMessage.filename !== undefined
  ) {
    lastMessage = newMessage.filename;
  }
  db.collection("collaborations").doc(collaborationid).update({
    lastInteractionChannel: newMessage.channel,
    lastInteractionAt: newMessage.createdAt,
    lastInteractionType: newMessage.messageType,
    lastInteractionMessage: lastMessage,
  });
};

// =========== User Chat =========== //
export const fetchFriendCollaborationsByUser = (uid: string) => {
  const userRef = conn.createRef("users", uid);

  return db
    .collection("collaborations")
    .withConverter(converter2<Collaboration>())
    .where("isActive", "==", true)
    .where("joinedUsers", "array-contains", userRef)
    .orderBy("lastInteractionAt", "desc")
    .get()
    .then((snapshot) => {
      const collaborations = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      return collaborations;
    });
};

export const create = async (uid: string, friendId: string) => {
  const userRef = conn.createRef("users", uid);
  const friendRef = conn.createRef("users", friendId);
  let collaborationID;
  console.log("createNewCollaboration");

  await db
    .collection("collaborations")
    .add({
      createdAt: new Date(),
      updatedAt: new Date(),
      isActive: true,
      joinedUsers: [userRef, friendRef],
      lastInteractionAt: new Date(),
      lastInteractionType: "",
      lastInteractionMessage: "",
      lastInteractionChannel: "",
    })
    .then((ref) => {
      console.log("collaboration id x : " + ref.id);
      collaborationID = ref.id;
      return ref.id;
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
  return collaborationID;
};

export const getByID = async (collabId: string) => {
  return await db
    .collection("collaborations")
    .doc(collabId)
    .withConverter(converter2<Collaboration>())
    .get()
    .then(async (collab) => {
      let temp = [] as any;
      if (collab.data()?.toUser) {
        try {
          const userPromises =
            collab.data()?.toUser?.map(async (item) => {
              const user = await userService.getCachedUserByID(item.id);
              if (user) {
                temp?.push(user);
              }
            }) || [];

          await Promise.all(userPromises);
        } catch (error) {
          console.error("Error fetching user:", error);
        }
      }
      let roomsData = await fetchRoomByCollaborationId(collab.id);

      let resultCollab = {
        ...collab.data(),
        id: collab.id,
        toUserData: temp,
        roomsModel: roomsData
      } as Collaboration;

      return resultCollab;
    })
    .catch((error) => {
      return Promise.reject(`cannot get collaboration : ${error}`);
    });
};

export const setStatusToHandled = async (id: string, handledUserId: string) => {
  console.log(
    "==> masuk Collaborations setStatusToHandled balezzz",
    id,
    handledUserId
  );

  const handleUserRef = createRef("users", handledUserId);
  await db
    .collection("collaborations")
    .doc(id)
    .update({
      status: "handled",
      handledByUser: handleUserRef,
      toUser: arrayUnion(handleUserRef),
      updatedAt: Timestamp.now(),
      handleAt: Timestamp.now(),
    });
};
