import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { PageLink, PageTitle } from "../../core";
import ListComponent from "src/app/components/list/NewList";
import Customer from "src/app/models/Customer";
import { ContactInfoCell } from "../contact-list/table/columns/ContactInfoCell";
import { ContactPhoneNumberCell } from "../contact-list/table/columns/ContactPhoneNumberCell";
import { ContactEmailCell } from "../contact-list/table/columns/ContactEmailCell";
import CellDate from "src/app/components/table/columns/CellDate";
import { ContactActionsCell } from "../contact-list/table/columns/ContactActionsCell";
import { ContactsListToolbar } from "../contact-list/components/header/ContactListToolbar";
import clsx from "clsx";
import { KTCardBody, KTSVG } from "src/resources/helpers";
import Select from "react-select";
import * as lc from "src/app/modules/localstorage/index";
import { useEffect, useRef, useState } from "react";
import {
  emailValidator,
  letterValidator,
  phoneValidator,
} from "src/app/modules/validators/InputValidator";
import { createRef } from "src/db/connection";
import * as XLSX from "xlsx";
import { format } from "date-fns";
import { createCustomer, getCustomerByPhoneNoAndClient } from "src/db";
import { checkFile, formatSize } from "../contact-list/core/ContactUtil";
import AlertSuccessProcess from "src/app/modules/alert/AlertSuccessProcess";
import AlertFailedProcess from "src/app/modules/alert//AlertFailedProcess";
import { ContactEditModal } from "../contact-list/contact-edit-modal/ContactEditModal";
import { UserCompanies } from "src/app/models/User";

type Columns<T> = {
  title: string;
  field: keyof T;
  value: (props: { value: any }) => JSX.Element | Promise<JSX.Element>;
};
type Action<T> = {
  headerTitle: string;
  value: (props: { value: any }) => JSX.Element | Promise<JSX.Element>;
};

const NewContactPage = () => {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({
    query: "(max-width: 900px)",
  });
  const navigate = useNavigate();
  const client = lc.getItemLC(lc.LCName.Client);
  const [file, setFile] = useState<File>();
  const [data, setData] = useState<any>([]);
  const [errMessage, setErrMessage] = useState<string[]>([]);
  const [fileSize, setFileSize] = useState<string>("");
  const [maxSize, setMaxSize] = useState<string | number | undefined>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [addContactModal, setAddContactModal] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [inputFileName, setInputFileName] = useState<string>("");
  const [, setInputContentType] = useState<string>("");
  const [progress, setProgress] = useState(0);
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);
  const selectCompany = document.getElementById("import-customer-company");
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [listCompany, setListCompany] = useState<any[]>([]);
  const openAddUserModal = () => {
    setAddContactModal(true);
  };
  const closeAddUserModal = () => {
    setAddContactModal(false);
    window.document.getElementById("button-refetch")?.click();
  };

  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Contact.BC.ContactList"),
      path: "#",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const defaultColumns: Columns<Customer>[] = [
    {
      title: "Name",
      field: "firstName",
      value: (props: any) => {
        return <ContactInfoCell contact={props.data[props.row.index]} />;
      },
    },
    {
      title: "Phone Number",
      field: "phoneNumber",
      value: (props: any) => {
        return (
          <ContactPhoneNumberCell
            phoneNumber={props.data[props.row.index].phoneNumber}
          />
        );
      },
    },
    {
      title: "Email",
      field: "email",
      value: (props: any) => {
        return <ContactEmailCell email={props.data[props.row.index].email} />;
      },
    },
    {
      title: "Last Interactions",
      field: "lastInteractionAt",
      value: (props: any) => {
        return (
          <CellDate value={props.data[props.row.index].lastInteractionAt} />
        );
      },
    },
  ];

  const defaultAction: Action<Customer> = {
    headerTitle: "Action",
    value: (props: any) => {
      return (
        <ContactActionsCell
          id={props.row.original.id}
          indexId={props.row.original.index}
          name={`${props.original?.firstName || ""} ${
            props.original?.lastName || ""
          }`}
        />
      );
    },
  };

  const removeFile = () => {
    setFile(undefined);
    setFileSize("");
    setInputFileName("");
    setInputContentType("");
    setProgress(0);
    setEmptyData();
    setErrMessage([]);
    setIsProcessing(false);
    selectCompany?.setAttribute("value", "");
  };

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      removeFile();
      // refetch();
    }
  };

  const onChangeSelectedCompanies = (event: any) => {
    const selectedCompaniesData = event;
    setSelectedCompanies(selectedCompaniesData);
  };

  const downloadCSV = (data: any) => {
    var str =
      "First Name,Last Name,Email,Phone Number,Created,Updated,Status,Error Message\r\n";
    for (var i = 0; i < data.length; i++) {
      var line = "";
      for (var index in data[i]) {
        if (line !== "") line = `${line} ,`;
        line += data[i][index];
      }
      str += line + "\r\n";
    }
    const url = window.URL.createObjectURL(new Blob([str]));
    const link = document.createElement("a");
    link.href = url;
    let reportName =
      "Report-ImportCustomers_" +
      format(new Date(), "d-MMM-yyyy[HHmmss]") +
      ".csv";
    link.setAttribute("download", reportName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = async function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const handleChange = async function (e: any) {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const importCSV = async () => {
    if (!client || client === "") {
      return;
    }

    if (data.length <= 0) {
      alert(t("IC.Error.InvalidFileContent"));
      return;
    }

    setIsProcessing(true);
    let resultData = [];
    let countSuccess = 0;
    let countFailed = 0;

    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      const clientRef = createRef("clients", client?.id);
      const customerWithPhoneNumber = await getCustomerByPhoneNoAndClient(
        element.phoneNumber,
        clientRef
      );
      let dataCust = {
        firstName: element.firstName,
        lastName: element.lastName,
        email: element.email,
        phoneNumber: element.phoneNumber,
        createdAt: element.createdAt,
        updatedAt: element.updatedAt,
        status: "",
        errorMessage: "",
      };
      if (!customerWithPhoneNumber) {
        element.client = clientRef;
        element.firstNameInsensitive = element.firstName.toLowerCase();

        const id = await createCustomer(element, (id: string | null) => {
          return id;
        });

        if (id && id !== null) {
          dataCust.status = "success";
          dataCust.errorMessage = "";
          countSuccess++;
        } else {
          dataCust.status = "failed";
          dataCust.errorMessage = "failed to create contact";
          countFailed++;
        }
      } else {
        dataCust.status = "failed";
        dataCust.errorMessage = "duplicate data with same phone number";
        countFailed++;
      }

      if (dataCust.status === "failed") {
        resultData.push(dataCust);
      }
    }

    removeFile();
    if (countFailed <= 0) {
      const successFriendAddAlertNotif = document.getElementById(
        "successProcessAlertNotif"
      ) as HTMLDivElement;
      successFriendAddAlertNotif.innerHTML = t("IC.Alert.Success");
      const buttonSuccessAlert = document.getElementById(
        "triggerSuccessProcess"
      );
      if (buttonSuccessAlert) {
        buttonSuccessAlert.click();
      }
    } else {
      const successFriendAddAlertNotif = document.getElementById(
        "failedProcessAlertNotif"
      ) as HTMLDivElement;
      successFriendAddAlertNotif.innerHTML = t("IC.Alert.Failed")
        .replace(`<<success>>`, "" + countSuccess)
        .replace(`<<failed>>`, " " + countFailed);
      const buttonFailedAlert = document.getElementById("triggerFailedProcess");
      if (buttonFailedAlert) {
        buttonFailedAlert.click();
      }
      downloadCSV(resultData);
    }
    // updateState({
    //   sort: undefined,
    //   order: undefined,
    //   items_per_page: state.items_per_page,
    //   page: 1,
    //   action: "noAction",
    // });
    setIsProcessing(false);
    cancel(true);
  };

  const setEmptyData = () => {
    let updatedMap: any[] = [];
    for (let index = 0; index < 50; index++) {
      let item = {
        firstName: " ",
        lastName: " ",
        email: " ",
        phoneNumber: " ",
      };
      updatedMap.push(item);
    }
    setData(updatedMap);
  };

  const validateCSV = (data: string[]) => {
    let resultData: any[] = [];
    if (data.length <= 0) {
      setErrMessage((prevNotifications) => [
        "IC.Error.InvalidFormat",
        ...prevNotifications,
      ]);
      return;
    } else {
      const dataHeader: string[] = data[0].split(",");
      const mandatoryColumn = ["First Name", "Phone Number"];
      const error = [];
      for (let index = 0; index < mandatoryColumn.length; index++) {
        const manColumn = dataHeader.find(
          (header) => mandatoryColumn[index] === header
        );
        if (manColumn === undefined) {
          error.push(mandatoryColumn[index]);
        }
      }

      if (error.length > 0) {
        setErrMessage((prevNotifications) => [
          "IC.Error.InvalidFileContent",
          ...prevNotifications,
        ]);
        return;
      }

      const column = ["First Name", "Last Name", "Phone Number", "Email"];
      let columnIndex: any[] = [];
      for (let j = column.length; j >= 0; j--) {
        const columnName = column[j];
        for (let index = 0; index < dataHeader.length; index++) {
          const headerColumn = dataHeader[index];
          if (headerColumn === columnName) {
            columnIndex.push({
              index: index,
              columnName: columnName,
            });
          }
        }
      }

      let arrPhoneNumber: string[] = [];
      const arrError: string[] = [];
      const dateTime = new Date();

      if (data.length <= 1) {
        setErrMessage((prevNotifications) => [
          "IC.Error.InvalidFileContent",
          ...prevNotifications,
        ]);
        return;
      }

      let selectedCompanyRef: any = [];
      if (selectedCompanies.length > 0) {
        selectedCompanies.forEach((company: any) => {
          const companyRef = createRef("company", company.value);
          selectedCompanyRef.push(companyRef);
        });
      }
      for (let i = 1; i < data.length; i++) {
        const element: string[] = data[i].split(",");
        let dataCust = {
          firstName: "",
          lastName: "",
          email: "",
          isActive: true,
          phoneNumber: "",
          createdAt: dateTime,
          updatedAt: dateTime,
          imported: true,
          client: undefined,
          companies: selectedCompanyRef,
        };
        for (let j = 0; j < element.length; j++) {
          for (let k = 0; k < columnIndex.length; k++) {
            const col = columnIndex[k];
            if (col.index === j) {
              if (col.columnName === "First Name") {
                if (!letterValidator(element[j].replace(/ /g, ""))) {
                  const errorCheck = arrError.find(
                    (error) => error === "IC.Error.InvalidFirstName"
                  );
                  if (!errorCheck) {
                    arrError.push("IC.Error.InvalidFirstName");
                  }
                } else {
                  dataCust.firstName = element[j];
                }
              }
              if (col.columnName === "Last Name") {
                if (
                  element[j] &&
                  element[j] !== "" &&
                  !letterValidator(element[j].replace(/ /g, ""))
                ) {
                  const errorCheck = arrError.find(
                    (error) => error === "IC.Error.InvalidLastName"
                  );
                  if (!errorCheck) {
                    arrError.push("IC.Error.InvalidLastName");
                  }
                } else {
                  dataCust.lastName = element[j];
                }
              }
              if (col.columnName === "Email") {
                if (
                  element[j] &&
                  element[j] !== "" &&
                  !emailValidator(element[j].replace(/ /g, ""))
                ) {
                  const errorCheck = arrError.find(
                    (error) => error === "Login.Error.WrongEmail"
                  );
                  if (!errorCheck) {
                    arrError.push("Login.Error.WrongEmail");
                  }
                } else {
                  dataCust.email = element[j];
                }
              }
              if (col.columnName === "Phone Number") {
                let phoneNumber = element[j].replace(/ /g, "");
                if (phoneNumber !== "" && phoneNumber!.startsWith("0")) {
                  phoneNumber = "62" + phoneNumber!.substring(1);
                }
                if (!phoneValidator(phoneNumber)) {
                  const errorCheck = arrError.find(
                    (error) => error === "IC.Error.InvalidPhoneNumber"
                  );
                  if (!errorCheck) {
                    arrError.push("IC.Error.InvalidPhoneNumber");
                  }
                } else {
                  if (arrPhoneNumber.includes(phoneNumber)) {
                    const errorCheck = arrError.find(
                      (error) => error === "IC.Error.DuplicatePhoneNumber"
                    );
                    if (!errorCheck) {
                      arrError.push("IC.Error.DuplicatePhoneNumber");
                    }
                  } else {
                    arrPhoneNumber.push(phoneNumber);
                    dataCust.phoneNumber = phoneNumber;
                  }
                }
              }
            }
          }
        }
        if (arrError.length <= 0) {
          resultData.push(dataCust);
        } else {
          let errormsg = "";
          for (let index = 0; index < arrError.length; index++) {
            const element = t(arrError[index]);
            if (index === arrError.length) {
              errormsg += element;
            } else {
              errormsg += element + ",";
            }
          }
          setErrMessage(arrError);
          return;
        }
      }
      setData(resultData);
      setIsSuccess(true);
    }
  };

  useEffect(() => {
    if (listCompany.length <= 0) {
      const arrCompany: UserCompanies[] =
        lc.getItemLC(lc.LCName.CompanyList) ?? [];
      let companyList = arrCompany.map((companyAccess) => ({
        value: companyAccess.id,
        label: companyAccess.companyName,
      }));
      setListCompany(companyList);
    }
    const modal = document.getElementById("import_customer");
    modal?.addEventListener("hidden.bs.modal", function (event) {
      setSelectedCompanies([]);
    });
  }, []);

  useEffect(() => {
    if (file) {
      const size: number = file.size;
      setInputFileName(file.name);
      setFileSize(formatSize(size));
      setInputContentType(file.type);
      const tempArrFileType: string = file.type;
      const arryFileType = tempArrFileType.split("/");
      if (
        arryFileType === undefined ||
        arryFileType.length < 1 ||
        arryFileType[0] === undefined
      ) {
        setErrMessage((prevNotifications) => [
          "HC.Error.InvalidFiletype",
          ...prevNotifications,
        ]);
        setInputFileName(t("HC.Error.InvalidFiletype"));
        setProgress(100);
        return;
      } else {
        const [result, error, maxSize] = checkFile(
          arryFileType[0],
          arryFileType[1],
          size
        );
        if (error !== undefined) {
          if (maxSize !== 0) {
            setErrMessage((prevNotifications) => [
              "HC.File." + error,
              ...prevNotifications,
            ]);
            setMaxSize(maxSize);
            setInputFileName(
              t(["HC.File." + error]).replace("<<size>>", maxSize + " MB")
            );
            setProgress(100);
          } else {
            setErrMessage((prevNotifications) => [
              "HC.Error." + error,
              ...prevNotifications,
            ]);
            setInputFileName(t(["HC.Error." + error]));
            setProgress(100);
          }
          return;
        }
      }

      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (evt) => {
        if (evt !== null && evt.target !== null) {
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_csv(ws, {});
          const dataSplit: string[] = data.split("\n");
          validateCSV(dataSplit);
        }
      };

      reader.onprogress = (evt) => {
        if (evt.lengthComputable) {
          const percent = Math.round((evt.loaded / evt.total) * 100);
          setProgress(percent);
        }
      };
    } else {
      setEmptyData();
      setIsSuccess(false);
    }
  }, [file]);

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {t("Contact.BC.ContactList")}
      </PageTitle>
      <ListComponent<Customer>
        collection="customers"
        collectionGroup={false}
        columns={defaultColumns}
        selection={{
          rowSelection: true,
          headerSelection: true,
        }}
        defaultFilters={[]}
        defaultSorting={[
          {
            field: "firstNameInsensitive",
            direction: "asc",
            label: "firstName",
          },
        ]}
        useFilter={[]}
        searching={{
          field: "firstName",
          useFirestore: true,
          value: "",
          caseSensitive: false,
        }}
        extra={[
          {
            label: "impor",
            value: (props: any) => {
              return (
                <>
                  <button
                    data-testid="button-import-customer"
                    type="button"
                    className={
                      "btn btn-light-primary d-flex align-items-center justify-content-center"
                    }
                    data-bs-toggle="modal"
                    data-bs-target={`#import_customer`}
                  >
                    <i className="fs-2 bi bi-box-arrow-in-down" />
                    {t("IC.Button.Import")}
                  </button>
                  <form id="modal-customer">
                    <div
                      className="modal fade"
                      tabIndex={-1}
                      id={`import_customer`}
                    >
                      <div
                        className="modal-dialog modal-dialog-centered"
                        role="document"
                        style={{ maxWidth: "800px" }}
                      >
                        <div
                          className="modal-content"
                          data-testid="modal-customer"
                        >
                          <div className="modal-header">
                            <div>
                              <h4 className="text-center">
                                {t("IC.Title.ImportCSV")}
                              </h4>
                            </div>
                            <div
                              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                              id={`close-importcsv`}
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => cancel(true)}
                            >
                              {/* Button Close */}
                              <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                              />
                            </div>
                          </div>

                          <div className="modal-body">
                            <div>
                              {/* begin::Input group */}
                              <div className="fv-row mb-6">
                                <div className="mt-3 mb-3">
                                  {t("Common.Input.CompanySelect")}
                                </div>
                                <Select
                                  defaultValue={selectedCompanies}
                                  isMulti
                                  name="company"
                                  options={listCompany}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  placeholder={t("Common.Input.CompanySelect")}
                                  value={selectedCompanies}
                                  onChange={(e) => onChangeSelectedCompanies(e)}
                                  onKeyDown={(e) => {
                                    console.log("e :", e);
                                    if (e.key === "enter" || e.keyCode === 13) {
                                      e.preventDefault();
                                      return false;
                                    }
                                  }}
                                />
                              </div>
                              {/* end::In
                      data-testid="contact-import-select-company"put group */}

                              {/* Upload File (Drag and Drop) */}
                              {progress > 0 ? (
                                <label id="label-file-upload">
                                  <div className="d-flex flex-fill">
                                    <div className="p-2 bi bi-file-earmark-text fs-3x justify-content-left"></div>
                                    <div className="p-2 flex-grow-1">
                                      <div className="d-flex flex-column mt-4 mb-1">
                                        <div className="d-flex justify-content-between w-auto mt-auto mb-1">
                                          <span className="fw-bolder fs-7 align-self-center text-break">
                                            {inputFileName}
                                            {"(" + fileSize + ")"}
                                          </span>
                                          {errMessage &&
                                          errMessage.length > 0 ? (
                                            <span className="fw-bolder fs-7 align-self-center">
                                              0%
                                            </span>
                                          ) : (
                                            <span className="fw-bolder fs-7 align-self-center">
                                              {progress}%
                                            </span>
                                          )}
                                        </div>
                                        <div className="h-6px mx-0 w-auto bg-light mb-0 rounded">
                                          {errMessage &&
                                          errMessage.length > 0 ? (
                                            <div
                                              className="bg-danger rounded h-6px"
                                              role="progressbar"
                                              style={{ width: `${progress}%` }}
                                            ></div>
                                          ) : (
                                            <div
                                              className="bg-success rounded h-6px"
                                              role="progressbar"
                                              style={{ width: `${progress}%` }}
                                            ></div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-end align-self-center">
                                      <label
                                        className="btn btn-sm btn-icon-danger btn-text-danger me-0 d-flex justify-content-end"
                                        title={t("HC.File.RemoveFile")}
                                      >
                                        <i
                                          className="bi bi-x-circle fs-1"
                                          onClick={removeFile}
                                        ></i>
                                      </label>
                                    </div>
                                  </div>
                                </label>
                              ) : (
                                <div
                                  id="form-file-upload"
                                  onDragEnter={handleDrag}
                                  onSubmit={(e) => e.preventDefault()}
                                >
                                  <input
                                    // ref={attachmentCSVRef}
                                    ref={inputRef}
                                    type="file"
                                    id="input-file-upload"
                                    data-testid="input-file-upload"
                                    name="input-file-upload"
                                    multiple={false}
                                    onChange={handleChange}
                                  />
                                  <label
                                    id="label-file-upload"
                                    htmlFor="input-file-upload"
                                    className={dragActive ? "drag-active" : ""}
                                  >
                                    <div>
                                      {/* Icon Drag Drop */}
                                      <div style={{ paddingTop: "10px" }}>
                                        <i className="fs-4x bi bi-cloud-arrow-up text-primary"></i>
                                      </div>
                                      <p className="upload-button text-primary fs-2">
                                        {t("IC.Button.SelectCSVFiles")}
                                      </p>
                                      <p>{t("IC.Info.DragFile")}</p>
                                    </div>
                                  </label>
                                  {dragActive && (
                                    <div
                                      id="drag-file-element"
                                      onDragEnter={handleDrag}
                                      onDragLeave={handleDrag}
                                      onDragOver={handleDrag}
                                      onDrop={handleDrop}
                                    ></div>
                                  )}
                                </div>
                              )}

                              <div
                                className={clsx(
                                  `d-flex justify-content-${
                                    isTabletOrMobile ? "center" : "end"
                                  } mt-1`
                                )}
                              >
                                <Link
                                  to="/media/documents/FormatImportCustomer2022.csv"
                                  className={clsx(
                                    "btn btn-link text-align-center ps-2 pt-1 pe-2 pb-1 ms-2",
                                    isTabletOrMobile ? "fs-2" : "fs-7"
                                  )}
                                  target="_blank"
                                  download={"FormatImportCustomer2022.csv"}
                                >
                                  <i className="fs-2x bi bi-cloud-arrow-down btn btn-link me-2"></i>
                                  {t("IC.Button.DownloadCSV")}
                                </Link>
                              </div>

                              {errMessage && errMessage.length > 0 && (
                                //alert
                                <div className="alert alert-danger d-flex align-items-center p-5 mb-2">
                                  <span className="svg-icon svg-icon-2hx svg-icon-danger me-3">
                                    <KTSVG
                                      path="/media/icons/duotune/general/gen048.svg"
                                      className="svg-icon-2hx svg-icon-danger me-4"
                                    />
                                  </span>

                                  <div className="d-flex flex-column">
                                    <h5 className="mb-1">Error</h5>
                                    <span>
                                      {errMessage.map((msg, i) => {
                                        console.log(
                                          "Errmessage [" + i + "] : " + msg
                                        );
                                        return (
                                          <p className="p-0 m-0">
                                            {t(msg).replace(
                                              "<<size>>",
                                              maxSize + " MB"
                                            )}
                                          </p>
                                        );
                                      })}
                                    </span>
                                  </div>
                                </div>
                              )}

                              {isSuccess && progress === 100 && (
                                <>
                                  {!isTabletOrMobile && (
                                    <div className="d-flex justify-content-center mt-1">
                                      <p>{t("IC.Title.FilePreview")}</p>
                                    </div>
                                  )}
                                  {!isTabletOrMobile && (
                                    <KTCardBody className=" h-350px overflow-hidden p-0 m-0">
                                      <div
                                        className="table-responsive"
                                        style={{
                                          height: "100%",
                                          overflow: "scroll",
                                        }}
                                      >
                                        <table
                                          id="kt_table_users"
                                          className="table align-middle table-row-dashed fs-6 gy-5 dataTable"
                                          style={{
                                            backgroundColor: "#D9D9D9",
                                            borderCollapse: "separate",
                                            borderSpacing: 0,
                                            border: "2px",
                                            borderStyle: "solid",
                                            borderColor: "black",
                                          }}
                                        >
                                          <thead
                                            className="position-sticky top-0 bg-secondary"
                                            style={{
                                              zIndex: 100,
                                              backgroundColor: "#D9D9D9",
                                              border: "1px",
                                              borderStyle: "solid",
                                              borderColor: "black",
                                            }}
                                          >
                                            <tr
                                              className="position-sticky top-0"
                                              style={{
                                                zIndex: 100,
                                                backgroundColor: "#D9D9D9",
                                                border: "1px",
                                                borderStyle: "solid",
                                                borderColor: "black",
                                              }}
                                            >
                                              <th
                                                className="fw-bolder fs-3 "
                                                style={{ padding: "10px" }}
                                              >
                                                {t("CD.Input.FirstName")}
                                              </th>
                                              <th
                                                className="fw-bolder fs-3"
                                                style={{ padding: "10px" }}
                                              >
                                                {t("CD.Input.LastName")}
                                              </th>
                                              <th
                                                className="fw-bolder fs-3"
                                                style={{ padding: "10px" }}
                                              >
                                                {t("Common.Column.Email")}
                                              </th>
                                              <th
                                                className="fw-bolder fs-3"
                                                style={{ padding: "10px" }}
                                              >
                                                {t("Common.Column.PhoneNumber")}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody style={{ padding: "10px" }}>
                                            {data?.map((item: any) => (
                                              <tr
                                                style={{
                                                  border: "1px",
                                                  borderStyle: "solid",
                                                }}
                                              >
                                                <td style={{ padding: "10px" }}>
                                                  {item?.firstName}
                                                </td>
                                                <td style={{ padding: "10px" }}>
                                                  {item?.lastName}
                                                </td>
                                                <td style={{ padding: "10px" }}>
                                                  {item?.email}
                                                </td>
                                                <td style={{ padding: "10px" }}>
                                                  {item?.phoneNumber}
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </KTCardBody>
                                  )}
                                  {isTabletOrMobile && (
                                    <div className="h-200px overflow-hidden p-0 m-0">
                                      <div
                                        style={{
                                          height: "100%",
                                          overflow: "scroll",
                                        }}
                                      >
                                        {data?.map((item: any) => {
                                          let completeName =
                                            item.firstName! +
                                            (item.lastName
                                              ? " " + item.lastName
                                              : "");
                                          if (completeName.length > 25) {
                                            completeName = completeName
                                              .slice(0, 24)
                                              .concat("...");
                                          }
                                          return (
                                            <div
                                              className="card card-custom card-stretch mb-5"
                                              style={{
                                                backgroundColor: "#D9D9D9",
                                              }}
                                            >
                                              <div className="card-body d-flex flex-column">
                                                <div>{completeName}</div>
                                                <div>
                                                  {item.email
                                                    ? item.email
                                                    : "-"}
                                                </div>
                                                <div>
                                                  {item.phoneNumber
                                                    ? item.phoneNumber
                                                    : "-"}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}

                                  {/** Infobar */}
                                  <div className="w-100 text-warning d-flex overflow-hidden text-wrap">
                                    <i className="bi bi-exclamation-triangle text-warning fa-lg me-2" />
                                    {t("Contacts.Import.Warning")}
                                  </div>
                                </>
                              )}
                            </div>

                            {isProcessing && (
                              <div className="d-flex justify-content-center">
                                <div
                                  className="spinner-border text-primary"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    {t("Common.Pages.Loading")}
                                  </span>
                                </div>
                              </div>
                            )}
                            {isSuccess && progress === 100 && !isProcessing && (
                              <div>
                                <div className="d-flex justify-content-end">
                                  {/* begin::Export */}
                                  <button
                                    type="button"
                                    className="btn btn-light-primary mt-3 me-3"
                                    onClick={importCSV}
                                  >
                                    <i className="svg-icon-2 fs-3 bi bi-box-arrow-in-down" />
                                    {t("IC.Button.Import")}
                                  </button>
                                  {/* end::Export */}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </>
              );
            },
          },
          {
            label: "add_contact",
            value: (props: any) => {
              return (
                <button
                  type="button"
                  data-testid="btnAddContact"
                  className="btn btn-primary"
                  onClick={openAddUserModal}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-2"
                  />
                  {t("Contacts.Button.AddUser")}
                </button>
              );
            },
          },
        ].reverse()}
        action={defaultAction}
      />
      <button
        id="triggerSuccessProcess"
        data-bs-toggle="modal"
        data-bs-target="#modal_success_process"
        hidden
      ></button>
      <AlertSuccessProcess />

      <button
        id="triggerFailedProcess"
        data-bs-toggle="modal"
        data-bs-target="#modal_failed_process"
        hidden
      ></button>
      <AlertFailedProcess />
      {addContactModal && (
        <ContactEditModal
          onClose={closeAddUserModal}
          onSave={closeAddUserModal}
        />
      )}
    </>
  );
};

export default NewContactPage;
