import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import * as lc from '../app/modules/localstorage';
import { getListUserCompanyByID, getUserByID,  } from '../db';
import { getChannel } from "../db/serviceChannel"
import { createSession } from '../db/session';
import { UserCompanies } from '../app/models/User'
import auth from "../auth/firebase"

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  auth.useEmulator('http://192.168.20.20:9099')
}

export const login = async (email:string, password:string, isrememberme:true|false ):Promise<string> => {
  let remember:string = 'session';
  let currentUser = null;
  let client: any = undefined;
  try {
    if(isrememberme){
      remember = 'none';
    }
    console.log("isremember me : "+isrememberme+" - "+remember)
    
    await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(async () => {
        const a = auth
        .signInWithEmailAndPassword(email, password);
        currentUser = (await a).user;
        if(currentUser!=null){
          createSession(currentUser.uid);

          // updatedBy: Vita
          // updatedAt: 01 Feb 2023
          // save client data to localstorage
          console.log("currentUser : ", currentUser);
          const getUserData: any = await getUserByID(currentUser.uid);
          console.log("getUserData : ", getUserData);
          console.log("User data client : ", getUserData.client);
          console.log("User data client data : ", getUserData.clientData);
          const userData = {
            id: getUserData.id,
            avatar: getUserData?.clientData?.avatar ?? "",
            name: getUserData.name ?? "",
            role: getUserData.role ?? ""
          };

          if(getUserData && getUserData.client && getUserData.clientData){
            const userClient = getUserData.client && getUserData.client !== "" ? getUserData.client : "";
            const userClientData = getUserData.clientData;
            client = {
              id: userClient.id ?? "",
              avatar: userClientData.avatar ?? "",
              clientName: userClientData.clientName ?? "",
            };
            console.log("client data to local storage : ", client)
          }
        
          //get all user company
          let userCompanies = await getListUserCompanyByID(currentUser.uid) ?? []

          //save company to localStorage
          
          if(isrememberme){
            lc.setItemLC(lc.LCName.User,currentUser);
            lc.setItemLC("UID",currentUser.uid);
            // if(companyRef !== undefined){
            //   lc.setItemLC("CID",companyRef);
            // }
            lc.setItemLC(lc.LCName.CompanyList, userCompanies);
            lc.setItemLC(lc.LCName.Client, client);
            lc.setItemLC(lc.LCName.UserData, userData);
          }else{
            lc.setItemLCWithExpiry(lc.LCName.User,currentUser,3);
            lc.setItemLCWithExpiry("UID",currentUser.uid,3); 
            // if(companyRef !== undefined){
            //  lc.setItemLCWithExpiry("CID",companyRef,3);
            // }
            lc.setItemLCWithExpiry(lc.LCName.CompanyList, userCompanies,3);
            lc.setItemLCWithExpiry(lc.LCName.Client, client, 3);
            lc.setItemLCWithExpiry(lc.LCName.UserData, userData, 3);
          }

          // Get Channel Provided by 7Days
          let activeChannels = lc.getItemLC("ChannelList");
          if (!activeChannels) {
            activeChannels = getChannel();
          }
        }
      })
      return Promise.resolve("success");
  } catch (error) {
    console.log(`firebase login error ${error}`)
    return Promise.reject(error)
  }
}

export const register = async (email:string, password:string):Promise<string> => {
  let currentUser = null;
  let client: any = undefined;
  try {
    await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(async () => {
        const a = auth
        .createUserWithEmailAndPassword(email, password);
        currentUser = (await a).user;
      })
      console.log(currentUser, "testing curruser yop")
    return Promise.resolve("success");
  } catch (error) {
    return Promise.reject(error)
  }
}

export const onAuthStateChanged = (onAuthCallback: firebase.Observer<any, Error> | ((a: firebase.User | null) => any)) => 
  auth.onAuthStateChanged(onAuthCallback);

export const AuthUser = async (currentUser:any):Promise<boolean>=>{
  let isAuthored = false;
    return new Promise((resolve, reject) => {
      try {
        auth.onAuthStateChanged(function (user) {
          if (user) {
            if(currentUser != null){
              if(currentUser.uid === user.uid ){
                isAuthored = true;
              }
            }
            resolve(isAuthored);
          }
        });
      } catch(error){
        console.log(`firebase login error ${error}`)
        reject(error)
      }
    })
}

export const logout = async () => {
  return auth.signOut()
}
