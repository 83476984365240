import { KTCard } from "../../../../../resources/helpers/components/KTCard";
import { ListViewProvider } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { ProductHeader } from "./components/header/ProductHeader";
import { ProductTable } from "./table/ProductTable";
import ProductPage from "../../productList-Remake/ProductPage";
import ProductListView from "../../productList-Remake/ProductListView";

const ProductList = () => {
  return (
    <>
      <KTCard>
        <ProductHeader />
        <ProductTable />
      </KTCard>
    </>
  );
};

const ProductListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ProductList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export { ProductListWrapper };
