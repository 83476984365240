import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type ApprovalModalProps = {
  onClose: () => void;
  onSubmit?: (email: string, password: string) => void;
  data?: any;
};

const ApprovalModal: React.FC<ApprovalModalProps> = ({
  onClose,
  onSubmit,
  data,
}) => {
  const { t } = useTranslation();

  // State for email and password
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  // State for validation errors
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");

  // Validate email format
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Reset previous errors
    setEmailError("");
    setPasswordError("");

    // Validate email
    if (!email) {
      setEmailError(t("Approval.Error.Email"));
      return;
    }
    if (!validateEmail(email)) {
      setEmailError(t("Approval.Error.EmailFormat"));
      return;
    }

    // Validate password
    if (!password) {
      setPasswordError(t("Approval.Error.Password"));
      return;
    }
    if (password.length < 6) {
      setPasswordError(t("Approval.Error.PasswordFormat"));
      return;
    }

    // If validation passes, submit and close modal
    // onSubmit(email, password);
    onClose();
  };
  console.log(data, " errornya");
  return (
    <>
      <div className="modal-backdrop show"></div>
      <div className="modal show d-block">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-danger">{t("Approval.Menu")}</h5>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                {/* Email Input */}
                <div className="mb-3">
                  <label htmlFor="approval-email" className="form-label">
                    {t("Approval.Email")}
                  </label>
                  <input
                    id="approval-email"
                    type="text"
                    className={`form-control ${emailError ? "is-invalid" : ""}`}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={t("Approval.EnterEmail")}
                  />
                  {emailError && (
                    <div className="text-danger text-start">{emailError}</div>
                  )}
                </div>

                {/* Password Input */}
                <div className="mb-3">
                  <label htmlFor="approval-password" className="form-label">
                    {t("Approval.Password")}
                  </label>
                  <input
                    id="approval-password"
                    type="password"
                    className={`form-control ${
                      passwordError ? "is-invalid" : ""
                    }`}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={t("Approval.EnterPassword")}
                  />
                  {passwordError && (
                    <div className="text-danger text-start">
                      {passwordError}
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary w-100">
                  {t("Common.Button.Submit")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApprovalModal;
