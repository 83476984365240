import { FC } from "react";
import { useTranslation } from "react-i18next";

const AlertFailedProcess: FC<{ processName?: string; title?: string, errorMessage?: string[] }> = ({
  processName = "",
  title,
  errorMessage,
}) => {
  const { t } = useTranslation();
  console.debug(`AlertModal ${processName} Failed`);
  return (
    <div className="modal fade" tabIndex={-1} id="modal_failed_process">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body row align-items-center justify-content-center">
            <i className="bi bi-x-circle fs-5x text-danger text-center"></i>
            <div>
              {title && <h6 className="text-center py-2">{title}</h6>}
              <h6 id="failedProcessAlertNotif" className="text-center py-2">
                {/* Alert */}
                {errorMessage && errorMessage.map((error, index) => (
                  <div key={index}>{error}</div>
                ))}
              </h6>
            </div>
            <div className="text-center">
              <button
                type="button"
                className="btn btn-primary btn-sm fs-5 text py-1"
                data-bs-dismiss="modal"
              >
                {t("BS.Button.Close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertFailedProcess;
