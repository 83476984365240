import { DocumentReference, Timestamp } from "@firebase/firestore-types";
import Company from "./Company";
import Customer from "./Customer";
import Message from "./Message";
import { User, UserCompanies } from "./User";
import Account from "./Account";
import firebase from "firebase/compat/app";

export interface Chat {}

export interface BadgeItem {
  unreadCount: number;
  className: string;
  channel: string;
}

export interface Collaboration {
  client?: DocumentReference;
  id: string;
  idCollab?: string;
  idRoom?: string;
  fullName?: string;
  profileName?: string;
  image?: string;
  status?: "queue" | "handled";
  unreadMessages: BadgeItem[];
  lastMessages?: string;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  handleAt?: Timestamp;
  lastInteractionAt: firebase.firestore.Timestamp;
  lastInteractionChannel?: string;
  lastInteractionType?: string;
  lastInteractionMessage?: string;
  customer?: DocumentReference;
  customerModel?: Customer;
  toUser?: Array<DocumentReference>;
  // toUserData?: Array<any>;
  toUserModel?: Array<any>;
  joinedUsers?: Array<DocumentReference>;
  userModel?: User;
  LastMessageModel?: Message;
  company?: DocumentReference;
  userCompanies?: Array<UserCompanies>;
  companyModel?: Company;
  phoneNumber?: string;
  isActive?: boolean;
  lastUserInteractionAt?: Timestamp;
  roomsModel?: Array<any>;
  collaborationId?: string;
  collaborationModel?: any;
  lastCustomerInteractionAt: Timestamp;
  tokopediaBuyerId?: string;
  parent_document?: any;
  parent_id?: any;
  userSearchKeys?: any;
}

export const CollabsStatus = {
  queue: "queue",
  expired: "expired",
  handled: "handled",
  ended: "ended",
};

export interface Room {
  id: string;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  status?: "queue" | "handled";
  collaboration?: DocumentReference;
  collaborationModel?: Collaboration;
  account?: DocumentReference;
  accountData: {
    name: string;
    searchKey: string;
    type: "whatsapp" | "shopee" | "lazada" | "tokopedia";
  };
  accountModel?: Account;
  toUser?: Array<DocumentReference>;
  toUserOnline?: Array<DocumentReference>;
  toUserModel?: User[];
  lastCustomerInteractionAt?: Timestamp;
  lastCustomerInteractionAtString?: Array<string>;
  parent_document?: any;
  parent_id?: any;
  profileName?: string;
  phoneNumber?: string;
  userSearchKey?: string;
}

export type CIQ = Collaboration & Room;
