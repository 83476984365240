import clsx from "clsx";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { KTSVG, toAbsoluteUrl } from "../../../../resources/helpers";
import Customer from "../../../models/Customer";
import { getContacts } from "../../contact-management/contact-list/core/_requests";
import * as RoomService from "../../../../db/serviceRoom";
import * as CustomerService from "../../../../db/serviceCustomer";
import * as CollaborationService from "../../../../db/serviceCollaborations";
import * as chat from "../../../modules/chat/redux/ChatSlice";
import { Collaboration } from "src/app/modules/collaboration/model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/setup/redux/store";
import db, { createRef } from "src/db";
import { Room } from "src/app/models/Chat";

const ContactListModal: FC<{
  phoneNumber: string | undefined;
  onClose: () => void;
}> = ({ onClose, phoneNumber }) => {
  // const [selected, setSelected] = useState<Customer | undefined>(undefined);
  // const navigate = useNavigate();
  // const { t } = useTranslation();
  // const dispatch = useDispatch();
  // const { selectedRoom, selectedChat, selectedCollaboration } = useSelector(
  //   (state: RootState) => state.Chat
  // );
  // const activeRoom: Room = selectedCollaboration?.roomsModel?.filter(
  //   (itemRoom: Room) => {
  //     return itemRoom.id === selectedRoom;
  //   }
  // )[0];

  // //Select Handler at "Add Exixsting Contact" Modal
  // const selectHandler = async (data: Customer) => {
  //   try {
  //     let {
  //       id: customerId,
  //       firstName,
  //       lastName,
  //       tokopedia,
  //       lazada,
  //       shopee,
  //       phoneNumber,
  //     } = data;
  //     if (!customerId) {
  //       console.error("Customer ID not found");
  //       return;
  //     }
  //     if (!firstName) {
  //       console.error("Customer firstName not found");
  //       return;
  //     }
  //     const name = `${firstName!} ${lastName ?? ""}`;

  //     //collaboration dari contact yg akan di add existing (old)
  //     const collaborationData = selectedCollaboration;

  //     // update collection customer existing dan update collaborations old di merge ke collaborations existig
  //     if (collaborationData) {
  //       if (!collaborationData.roomsModel) {
  //         console.error("Room model not found");
  //         return;
  //       }
  //       collaborationData.roomsModel.map(async (item: Room) => {
  //         let customerData: Partial<Customer> | null = null;

  //         if (
  //           item.accountData.type === "tokopedia" &&
  //           !tokopedia?.some(
  //             (x: any) => x.accountId === String(item?.account?.id)
  //           )
  //         ) {
  //           customerData = {
  //             id: customerId,
  //             tokopedia: [
  //               {
  //                 accountId: String(item.account?.id),
  //                 roomId: item.id,
  //                 name: String(item.profileName),
  //                 userSearchKey: String(item.userSearchKey)
  //               },
  //             ],
  //             updatedAt: new Date(),
  //           };
  //         }
  //         if (
  //           item.accountData.type === "lazada" &&
  //           !lazada?.some((x: any) => x.accountId === String(item?.account?.id))
  //         ) {
  //           customerData = {
  //             id: customerId,
  //             lazada: [
  //               {
  //                 accountId: String(item.account?.id),
  //                 roomId: item.id,
  //                 name: String(item.profileName),
  //                 userSearchKey: String(item.userSearchKey)
  //               },
  //             ],
  //             updatedAt: new Date(),
  //           };
  //         }
  //         if (
  //           item.accountData.type === "shopee" &&
  //           !shopee?.some((x: any) => x.accountId === String(item?.account?.id))
  //         ) {
  //           customerData = {
  //             id: customerId,
  //             shopee: [
  //               {
  //                 accountId: String(item.account?.id),
  //                 roomId: item.id,
  //                 name: String(item.profileName),
  //                 userSearchKey: String(item.userSearchKey)
  //               },
  //             ],
  //             updatedAt: new Date(),
  //           };
  //         }
  //         if (
  //           item.accountData.type === "whatsapp" &&
  //           (!phoneNumber || phoneNumber === "")
  //         ) {
  //           customerData = {
  //             id: customerId,
  //             phoneNumber: item.phoneNumber,
  //             updatedAt: new Date(),
  //           };
  //         }

  //         const updateCustomerData = await CustomerService.updateCustomer(
  //           customerData
  //         );
  //         await mergeCollaboration();
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Select Handler Error", error);
  //     return;
  //   }

  //   //TODO
  //   //1. get data room new id ✅
  //   //2. update customer data with new id and name ✅
  //   //3. update collaboration from ref customer ✅
  //   //4. delete collaboration new id ✅
  //   //5. navigate to contact page that was filled by new id
  // };

  // // merge room old into selected existing room contact
  // const mergeCollaboration = async () => {
  //   // selected customer
  //   let firstName = selected?.firstName;
  //   let lastName = selected?.lastName;
  //   const name = `${firstName!} ${lastName ?? ""}`;

  //   // room dari collaboration yg aktif
  //   let room = activeRoom;
  //   let roomId = room?.id;

  //   // collaboration dari contact existing yg di select
  //   const collaborationSelected =
  //     await CollaborationService.getCollborationbyCustomerRef(
  //       String(selected?.id)
  //     );

  //   if (room && collaborationSelected) {
  //     const oldRoomRef = await db
  //       .collection("collaborations")
  //       .doc(selectedCollaboration?.id)
  //       .collection("rooms")
  //       .doc(roomId);

  //     // merge room into new collab existing contact
  //     const roomRef = await db
  //       .collection("collaborations")
  //       .doc(collaborationSelected[0].id)
  //       .collection("rooms")
  //       .doc(roomId);
  //     await roomRef.set({ ...room });

  //     // update field profileName in new collab existing contact
  //     const docRef = db
  //       .collection("collaborations")
  //       .doc(collaborationSelected[0].id);
  //     await docRef.update({
  //       profileName: name,
  //     });

  //     // update all message into new roomRef
  //     await db
  //       .collection("messages")
  //       .where("room", "==", oldRoomRef)
  //       .get()
  //       .then((querySnapshot) => {
  //         querySnapshot.forEach((doc) => {
  //           doc.ref.update({
  //             room: roomRef,
  //             roomId: roomId,
  //           });
  //         });
  //       });

  //     // delete old room on old collab
  //     await oldRoomRef.delete();

  //     // delete old collab when subcollection room is empty doc because all room doc has moved into new collab
  //     const deleteCollaborationIfEmpty = async () => {
  //       const roomsColl = db
  //         .collection("collaborations")
  //         .doc(selectedCollaboration?.id)
  //         .collection("rooms");
  //       const snapshot = await roomsColl.get();
  //       if (!snapshot.docs.length) {
  //         await CollaborationService.deleteCollaboration(
  //           String(selectedCollaboration?.id)
  //         );
  //       }
  //     };
  //     deleteCollaborationIfEmpty();

  //     window.location.reload();

  //     dispatch(chat.setSelectedChat(collaborationSelected[0].id));
  //     dispatch(chat.setSelectedRoom(roomId));
  //   }
  // };

  const [selected, setSelected] = useState<Customer | undefined>(undefined);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedRoom, selectedChat, selectedCollaboration } = useSelector(
    (state: RootState) => state.Chat
  );
  const activeRoom: Room = selectedCollaboration?.roomsModel?.filter(
    (itemRoom: Room) => {
      return itemRoom.id === selectedRoom;
    }
  )[0];

  //Select Handler at "Add Existing Contact" Modal
  const selectHandler = async (data: Customer) => {
    try {
      const {
        id: customerId,
        firstName,
        lastName,
        tokopedia,
        lazada,
        shopee,
        phoneNumber,
      } = data;

      if (!customerId || !firstName) {
        throw new Error("Customer ID or firstName not found");
      }

      const name = `${firstName} ${lastName ?? ""}`;

      //collaboration dari contact yg akan di add existing (old)
      const collaborationData = selectedCollaboration;

      if (!collaborationData || !collaborationData.roomsModel) {
        throw new Error("Room model not found");
      }

      for (const item of collaborationData.roomsModel) {
        const customerData: Partial<Customer> = {
          id: customerId,
          updatedAt: new Date(),
        };

        if (
          item?.accountData?.type === "tokopedia" &&
          !tokopedia?.some((x) => x?.accountId === String(item.account?.id))
        ) {
          customerData.tokopedia = [
            {
              accountId: String(item.account?.id),
              roomId: item.id,
              name: String(item.profileName),
              userSearchKey: String(item.userSearchKey),
            },
          ];
        } else if (
          item?.accountData?.type === "lazada" &&
          !lazada?.some((x) => x?.accountId === String(item.account?.id))
        ) {
          customerData.lazada = [
            {
              accountId: String(item.account?.id),
              roomId: item.id,
              name: String(item.profileName),
              userSearchKey: String(item.userSearchKey),
            },
          ];
        } else if (
          item?.accountData?.type === "shopee" &&
          !shopee?.some((x) => x?.accountId === String(item.account?.id))
        ) {
          customerData.shopee = [
            {
              accountId: String(item.account?.id),
              roomId: item.id,
              name: String(item.profileName),
              userSearchKey: String(item.userSearchKey),
            },
          ];
        } else if (
          item?.accountData?.type === "whatsapp" &&
          (!phoneNumber || phoneNumber === "")
        ) {
          customerData.phoneNumber = item.phoneNumber;
        }

        if (Object.keys(customerData).length > 1) {
          await CustomerService.updateCustomer(customerData);
        }
      }

      await mergeCollaboration();
    } catch (error) {
      console.error("Select Handler Error", error);
      return;
    }
  };

  // merge room old into selected existing room contact
  const mergeCollaboration = async () => {
    // selected customer
    let firstName = selected?.firstName;
    let lastName = selected?.lastName;
    let name = `${firstName!} ${lastName ?? ""}`;

    // room dari collaboration yg aktif
    let room = activeRoom;
    let roomId = room?.id;
    let type = room?.accountData.type;

    // collaboration dari contact existing yg di select
    const collaborationSelected =
      await CollaborationService.getCollborationbyCustomerRef(
        String(selected?.id)
      );

    if (room && collaborationSelected) {
      const oldRoomRef = await db
        .collection("collaborations")
        .doc(selectedCollaboration?.id)
        .collection("rooms")
        .doc(roomId);

      // update field profileName and other in new collab existing contact
      const docRef = db
        .collection("collaborations")
        .doc(collaborationSelected[0].id);
      const existingRoom = await docRef.collection("rooms").get();

      const existingRoomData = existingRoom.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Room[];

      // Convert Firestore timestamp to JavaScript Date
      const firestoreTimestampToDate = (timestamp: any) => {
        return new Date(timestamp?.seconds * 1000);
      };

      // Convert the room's updatedAt timestamp
      const roomUpdatedAt = firestoreTimestampToDate(room?.updatedAt);

      const isRoomUpdatedAtGreaterThanAll = existingRoomData.every((data) => {
        const existingRoomUpdatedAt = firestoreTimestampToDate(data?.updatedAt);
        return roomUpdatedAt > existingRoomUpdatedAt;
      });

      const docSnapshot = await docRef.get();
      const existingUserSearchKeys = docSnapshot.data()?.userSearchKeys || {};
      // const existingUserSearchKeys =
      //   selectedCollaboration?.userSearchKeys || {};
      const updatedUserSearchKeys = {
        ...existingUserSearchKeys,
        [type]: room.userSearchKey, // Tambah atau perbarui nilai
      };

      if (isRoomUpdatedAtGreaterThanAll) {
        await docRef.update({
          profileName: name,
          lastInteractionAt: selectedCollaboration?.lastInteractionAt,
          lastInteractionChannel: selectedCollaboration?.lastInteractionChannel,
          lastInteractionMessage: selectedCollaboration?.lastInteractionMessage,
          lastInteractionType: selectedCollaboration?.lastInteractionType,
          updatedAt: new Date(),
          userSearchKeys: updatedUserSearchKeys,
          ...(selectedCollaboration?.phoneNumber && {
            phoneNumber: selectedCollaboration?.phoneNumber,
          }),
        });
      } else {
        await docRef.update({
          profileName: name,
          updatedAt: new Date(),
          userSearchKeys: updatedUserSearchKeys,
          ...(selectedCollaboration?.phoneNumber && {
            phoneNumber: selectedCollaboration?.phoneNumber,
          }),
        });
      }

      // merge room into new collab existing contact
      const roomRef = await db
        .collection("collaborations")
        .doc(collaborationSelected[0].id)
        .collection("rooms")
        .doc(roomId);
      await roomRef.set({ ...room });

      // update all message into new roomRef
      await db
        .collection("messages")
        .where("room", "==", oldRoomRef)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.update({
              room: roomRef,
              roomId: roomId,
            });
          });
        });

      // delete old room on old collab
      await oldRoomRef.delete();

      // delete old collab when subcollection room is empty doc because all room doc has moved into new collab
      const roomsColl = db
        .collection("collaborations")
        .doc(selectedCollaboration?.id)
        .collection("rooms");
      const snapshot = await roomsColl.get();
      if (!snapshot.docs.length) {
        await CollaborationService.deleteCollaboration(
          String(selectedCollaboration?.id)
        );
      }

      dispatch(chat.setSelectedChat(collaborationSelected[0].id));
      dispatch(chat.setSelectedRoom(roomId));

      window.location.reload();

      dispatch(chat.setSelectedChat(collaborationSelected[0].id));
      dispatch(chat.setSelectedRoom(roomId));
    }
  };

  return (
    <>
      <div
        data-testid="modalAddContact"
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollabled mw-650px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <ModalHeader onClose={onClose} data-bs-dismiss="modal" />
            {/* begin::Modal body */}
            <div
              className="overflow-scroll"
              style={{
                height: "70vh",
              }}
            >
              <ContactList selected={selected} setSelected={setSelected} />
            </div>
            {/* end::Modal body */}

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => onClose()}
              >
                {t("Alert.Back.Button.Cancel")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                disabled={selected === undefined}
                onClick={() => {
                  if (!selected) {
                    return;
                  }
                  if (!selected.id) {
                    return;
                  }
                  selectHandler(selected);
                  // navigate(`/contact/contact-detail/settings/${selected.id}`, {
                  //   state: {
                  //     id: selected.id,
                  //     name: `${selected.firstName!} ${selected.lastName ?? ""}`,
                  //     phoneNumber: phoneNumber,
                  //   },
                  // });
                }}
              >
                Select
              </button>
            </div>
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

const ContactList: FC<{
  selected: Customer | undefined;
  setSelected: (contact: Customer | undefined) => void;
}> = ({ selected, setSelected }) => {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { selectedRoom, selectedChat, selectedCollaboration } = useSelector(
    (state: RootState) => state.Chat
  );
  const activeRoom: Room = selectedCollaboration?.roomsModel?.filter(
    (itemRoom: Room) => {
      return itemRoom.id === selectedRoom;
    }
  )[0];
  const { data: contacts, isLoading } = useQuery(
    "noPhoneContacts",
    async () => {
      const data = await getContacts(
        "firstNameInsensitive",
        "desc",
        "",
        undefined,
        undefined,
        20
      );
      return activeRoom.accountData.type === "whatsapp"
        ? data.filter(
            (contact) =>
              !contact.phoneNumber ||
              contact.phoneNumber === undefined ||
              contact.phoneNumber === ""
          )
        : data.filter(
            (contact) =>
              contact.phoneNumber || contact.phoneNumber !== undefined
          );
    }
  );

  const [search, setSearch] = useState("");

  if (isLoading) {
    return <div>{t("Common.Pages.Loading")}</div>;
  }

  if ((!isLoading && !contacts) || contacts.length <= 0) {
    return (
      <div className="py-4 fs-3 text-center">{t("Contacts.Info.Empty")}</div>
    );
  }

  if (contacts) {
    const filteredContacts = contacts.filter((contact) => {
      if (search && contact.firstName) {
        return contact.firstName
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase());
      }
      return true;
    });

    console.log("DataContactList", filteredContacts);

    // if (filteredContacts.length <= 0) {
    //   return <div className="py-4">{t("Contacts.Info.Empty")}</div>;
    // }

    return (
      <div className="modal-body">
        <form className="w-100 position-relative mb-7" autoComplete="off">
          <KTSVG
            path="/media/icons/duotune/general/gen021.svg"
            className="svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y"
          />
          <input
            id="search-chat"
            type="text"
            className="form-control form-control-solid px-15"
            name="search"
            placeholder="Search Contact"
            style={{ background: "#EEEEEE", borderColor: "#EEEEEE" }}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
          />
        </form>
        <div className="d-flex flex-column">
          {filteredContacts.length <= 0 && (
            <div className="py-4 fs-3 text-center">
              {t("Contacts.Info.Empty")}
            </div>
          )}
          {filteredContacts.length > 0 &&
            filteredContacts.map((contact) => {
              const displayName = getDisplayName(contact, isTabletOrMobile);

              return (
                <div
                  onClick={() => {
                    if (selected && selected.id === contact.id) {
                      return setSelected(undefined);
                    }
                    return setSelected(contact);
                  }}
                  key={contact.id}
                  className={clsx(
                    "d-flex align-items-center border-bottom border-2 p-3",
                    {
                      "bg-primary": selected && selected.id === contact.id,
                      "bg-hover-primary": !(
                        selected && selected.id === contact.id
                      ),
                    }
                  )}
                >
                  {/* begin:: Avatar */}
                  <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                    {contact.avatar ? (
                      <div className="symbol-label">
                        <img
                          src={`${contact.avatar}`}
                          alt={contact.firstName}
                          className="w-100"
                        />
                      </div>
                    ) : (
                      <div className="symbol-label">
                        <img
                          src={toAbsoluteUrl(
                            "/media/icons/avatar/def-avatar.png"
                          )}
                          alt={contact.firstName}
                          className="w-100"
                        />
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <span
                      // to={"/contact/contact-detail/settings/" + contact.id}
                      // state={{
                      //   id: contact.id,
                      //   name: `${contact.firstName!} ${contact.lastName ?? ""}`,
                      // }}
                      className="text-gray-800 fs-3"
                    >
                      {displayName}
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  return null;
};

const ModalHeader: FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <div className="modal-header">
      {/* begin::Modal title */}
      {/* <h2 className="fw-bolder">{t("Contacts.Button.AddUser")}</h2> */}
      <h2 className="fw-bolder">Select Contact</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className="btn btn-icon btn-sm btn-active-icon-primary"
        data-kt-users-modal-action="close"
        onClick={() => {
          onClose();
        }}
        style={{ cursor: "pointer" }}
      >
        <KTSVG
          path="/media/icons/duotune/arrows/arr061.svg"
          className="svg-icon-1"
        />
      </div>
      {/* end::Close */}
    </div>
  );
};

function getDisplayName(contact: Customer, isTabletOrMobile: boolean) {
  let fullName: string = "";
  const maxLength: number = isTabletOrMobile ? 15 : 50;

  if (
    (!contact.firstName || contact?.firstName === "") &&
    contact?.phoneNumber
  ) {
    fullName = contact.phoneNumber;
  } else if (contact.firstName) {
    if (contact?.lastName && contact?.lastName !== "") {
      fullName = `${contact.firstName!} ${contact.lastName}`;
    } else {
      fullName = contact.firstName!;
    }
  }

  return `
  ${fullName.slice(0, maxLength)}${fullName.length > maxLength ? "..." : ""}
  `;
}

export default ContactListModal;
