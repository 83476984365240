import { FC } from "react";
type Props = {
  numberOfVariant: number;
};

const ProductNumberOfVariant: FC<Props> = ({ numberOfVariant = 0 }) => {
  if (numberOfVariant > 0) {
    return <div className="text-gray-650 mb-1">apanya Variant</div>;
  } else {
    return <div className="text-gray-650 mb-1">{numberOfVariant} Variant</div>;
  }
};

export { ProductNumberOfVariant };
