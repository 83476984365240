import React, { useEffect, useState } from "react";
import "./ShipmentHandover.css";
import { useTranslation } from "react-i18next";
import { sendRequestShippingHandover } from "src/api/server/order";
import { getShipmentWithoutParent } from "src/db/serviceShipment";
import ConfirmationModal from "./ConfirmationModal";

interface ShipmentHandovermodalProps {
  id?: string;
  onClose: () => void;
  defaultData?: {
    noForm?: string;
    tanggalPickUp?: string; // Expecting DD/MM/YYYY format
    waktuPickUp?: string;
    layananPengiriman?: string;
    namaDriver?: string;
    nomorKendaraan?: string;
    paketDiscan?: number;
    paketBerhasilScan?: number;
    paketGagal?: number;
    paketBatal?: number;
    catatan?: string;
  };
}

const initialFormData = {
  noForm: "",
  tanggalPickUp: "",
  waktuPickUp: "",
  layananPengiriman: "",
  namaDriver: "",
  nomorKendaraan: "",
  paketDiscan: 0,
  paketBerhasilScan: 0,
  paketGagal: 0,
  paketBatal: 0,
  catatan: "",
};

const ShipmentHandovermodal: React.FC<ShipmentHandovermodalProps> = ({
  id,
  onClose,
  defaultData = {},
}) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    ...initialFormData,
    ...defaultData,
  });
  const [errors, setErrors] = useState({
    paketGagal: "",
    paketBatal: "",
    paketBerhasil: "",
    message: "",
  });

  const [shipments, setShipments] = useState<string[]>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    const fetchShipmentData = async () => {
      try {
        const getAllShipmentData = await getShipmentWithoutParent();
        const shipmentNames = getAllShipmentData
          .map((shipment) => shipment.name)
          .filter((name): name is string => name !== undefined);

        setShipments(shipmentNames);
      } catch (error) {
        console.error("Error fetching shipment data:", error);
      }
    };

    fetchShipmentData();
  }, []);

  useEffect(() => {
    if (shipments.length > 0) {
      setFormData((prev) => ({
        ...prev,
        layananPengiriman: prev.layananPengiriman || shipments[0],
      }));
    }
  }, [shipments]);

  useEffect(() => {
    if (defaultData.tanggalPickUp) {
      const [day, month, year] = defaultData.tanggalPickUp.split("/");
      const formattedDate = `${year}-${month}-${day}`;

      let formattedTime = "00:00:00";
      if (defaultData.waktuPickUp) {
        const timeParts = defaultData.waktuPickUp.split(".");
        const hours = timeParts[0] || "00";
        const minutes = timeParts[1] || "00";
        formattedTime = `${hours}:${minutes}:00`;
      }

      setFormData((prev) => ({
        ...prev,
        tanggalPickUp: formattedDate,
        waktuPickUp: formattedTime,
      }));
    }
  }, [defaultData.tanggalPickUp, defaultData.waktuPickUp]);

  const validateInputs = (
    name: keyof typeof initialFormData,
    value: number
  ) => {
    const newErrors = { ...errors };

    if (name === "paketGagal") {
      if (value > formData.paketBerhasilScan) {
        newErrors.paketGagal = t("Handover.Error.Failed");
      } else if (
        value >
        formData.paketDiscan -
          formData.paketBerhasilScan -
          (formData.paketBatal || 0)
      ) {
        newErrors.paketGagal = t("Handover.Error.ExceedFailSuccess");
      } else if (value < 0) {
        newErrors.paketGagal = t("Handover.Error.Zero");
      } else {
        newErrors.paketGagal = "";
      }
    }

    if (name === "paketBatal") {
      if (value < 0) {
        newErrors.paketBatal = t("Handover.Error.Zero");
      } else if (value > formData.paketBerhasilScan) {
        newErrors.paketBatal = t("Handover.Error.Exceed");
      } else if (value > formData.paketGagal) {
        newErrors.paketBatal = t("Handover.Error.ExceedFailed");
      } else if (
        value >
        formData.paketDiscan -
          formData.paketBerhasilScan -
          (formData.paketGagal || 0)
      ) {
        newErrors.paketBatal = t("Handover.Error.ExceedFailSuccess");
      } else {
        newErrors.paketBatal = "";
      }
    }

    if (name === "paketBerhasilScan") {
      newErrors.paketBerhasil =
        value > formData.paketDiscan
          ? t("Handover.Error.Success")
          : value < 0
          ? t("Handover.Error.Zero")
          : "";
    }

    setErrors(newErrors);
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    const newValue = name.includes("paket") ? Number(value) : value;

    setFormData((prev) => ({ ...prev, [name]: newValue }));
    if (name.includes("paket")) {
      validateInputs(name as keyof typeof initialFormData, Number(newValue));
    }
  };

  const handleSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (errors.paketGagal || errors.paketBatal || errors.paketBerhasil) {
      console.log("There are errors in the form.");
      return;
    }

    try {
      const result = await sendRequestShippingHandover(
        formData.namaDriver,
        formData.nomorKendaraan,
        formData.paketDiscan,
        formData.paketBerhasilScan,
        formData.paketGagal,
        formData.paketBatal,
        formData.tanggalPickUp,
        formData.waktuPickUp,
        formData.layananPengiriman,
        formData.catatan,
        formData.noForm
      );
      if (result.status !== "error") {
        setShowConfirmation(true);
      } else {
        setErrors({ ...errors, message: result.message });
      }
    } catch (error) {
      console.error("Error saving form data:", error);
    }
  };

  const handleClose = () => {
    setFormData(initialFormData);
    setErrors({
      paketGagal: "",
      paketBatal: "",
      paketBerhasil: "",
      message: "",
    });

    onClose();
  };

  const renderInput = ({
    label,
    name,
    type,
    placeholder,
    options,
    disabled,
    value,
    error,
  }: {
    label: string;
    name: string;
    type: string;
    placeholder?: string;
    options?: string[];
    disabled?: boolean;
    value?: string;
    error?: string;
  }) => {
    const inputValue = formData[name as keyof typeof initialFormData] || "";

    if (type === "select") {
      return (
        <select
          name={name}
          value={inputValue}
          onChange={handleChange}
          disabled={disabled}
          data-testid="selectShipper"
        >
          <option value="">{t("Shipping.Mark.Shipper")}</option>
          {options?.map((option, idx) => (
            <option key={idx} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    } else if (type === "span") {
      return <span>{value !== undefined ? value.toString() : "0"}</span>;
    } else if (type === "date" || type === "time") {
      return (
        <input
          type={type}
          name={name}
          value={inputValue}
          onChange={handleChange}
          placeholder={placeholder}
          disabled={disabled}
          id={name}
        />
      );
    } else {
      return (
        <>
          <input
            type={type}
            name={name}
            value={inputValue}
            onChange={handleChange}
            placeholder={placeholder}
            disabled={disabled}
            id={name}
          />
          {error && <span className="error-message">{error}</span>}
        </>
      );
    }
  };

  const fieldsToRender = [
    {
      label: t("Handover.Modal.No"),
      name: "noForm",
      type: "text",
      disabled: true,
      id: "noForm",
    },
    {
      label: t("Shipping.Handover.Date"),
      name: "tanggalPickUp",
      type: "date",
      id: "tanggalPickup",
    },
    {
      label: t("Shipping.Handover.Time"),
      name: "waktuPickUp",
      type: "time",
      id: "waktuPickup",
    },
    {
      label: t("Shipping.Handover.Shipper"),
      name: "layananPengiriman",
      type: "select",
      options: shipments,
      id: "layananPengiriman",
    },
    {
      label: t("Shipping.Handover.Driver"),
      name: "namaDriver",
      type: "text",
      placeholder: t("Shipping.Placeholder.Driver"),
      id: "namaDriver",
    },
    {
      label: t("Shipping.Handover.Vehicle"),
      name: "nomorKendaraan",
      type: "text",
      placeholder: t("Shipping.Placeholder.Vehicle"),
      id: "nomorKendaraan",
    },
    {
      label: t("Handover.Modal.Scanned"),
      name: "paketDiscan",
      type: "span",
      value:
        formData.paketDiscan !== undefined
          ? formData.paketDiscan.toString()
          : "0",
      id: "paketDiscan",
    },
    {
      label: t("Handover.Modal.Success"),
      name: "paketBerhasilScan",
      type: "number",
      placeholder: t("Shipping.Placeholder.PackagesSuccess"),
      error: errors.paketBerhasil,
      id: "paketBerhasilScan",
    },
    {
      label: t("Handover.Modal.Failed"),
      name: "paketGagal",
      type: "number",
      placeholder: t("Shipping.Placeholder.PackagesFailed"),
      error: errors.paketGagal,
      id: "paketGagal",
    },
    {
      label: t("Handover.Modal.Cancel"),
      name: "paketBatal",
      type: "number",
      placeholder: t("Shipping.Placeholder.PackageCancel"),
      error: errors.paketBatal,
      id: "paketBatal",
    },
    {
      label: t("Handover.Modal.Note"),
      name: "catatan",
      type: "textarea",
      placeholder: t("Shipping.Placeholder.Note"),
      id: "catatan",
    },
  ];

  return (
    <>
      <div data-testid="add-handover-modal" className="modal-overlay" id={id}>
        <div className="modal-content">
          <h2>{t("Shipping.Handover.Aside")}</h2>
          <div className="form-container">
            {fieldsToRender.map((field, index) => (
              <div className="form-row" key={index}>
                <label className="form-label" htmlFor={field.name}>
                  {field.label}
                </label>
                <div className="form-separator">:</div>
                <div className="form-input">{renderInput(field)}</div>
              </div>
            ))}
            {errors.message && (
              <div
                className="form-row error-message"
                data-testid="errorMessage"
              >
                {errors.message}
              </div>
            )}
            <div className="form-actions">
              <button
                data-testid="close-modal-button"
                className="btn btn-light-primary"
                onClick={handleClose}
              >
                {t("Common.Button.Cancel")}
              </button>
              <button className="btn btn-primary" onClick={handleSave}>
                {t("Common.Button.Save")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {showConfirmation && (
        <ConfirmationModal
          message={t("Shipping.Mark.Success")}
          onConfirm={handleClose}
        />
      )}
    </>
  );
};

export default ShipmentHandovermodal;
