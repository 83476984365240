import React, { useState } from "react";
import "./ModalStyles.css";
import { useTranslation } from "react-i18next";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ConfirmationModal from "./ConfirmationModal";

interface DetailShippinngMarkModalProps {
  onClose: () => void;
  defaultData?: any;
  idx?: number;
}

const DetailShippinngMarkModal: React.FC<DetailShippinngMarkModalProps> = ({
  onClose,
  defaultData,
  idx,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { t } = useTranslation();

  const handlePrintOrSave = async (action: "print" | "save") => {
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [50, 1000],
    });

    const content = document.getElementById("print-content");
    if (content) {
      const canvas = await html2canvas(content);
      const imgData = canvas.toDataURL("image/png", 1);
      const imgWidth = 50;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pdf.internal.pageSize.height;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pdf.internal.pageSize.height;
      }

      if (action === "print") {
        const blobUrl = pdf.output("bloburl").toString();
        const iframe = document.createElement("iframe");
        iframe.style.display = "none";
        iframe.src = blobUrl;

        document.body.appendChild(iframe);
        iframe.onload = function () {
          if (iframe.contentWindow) {
            iframe.contentWindow.print();
          }
          setTimeout(() => {
            document.body.removeChild(iframe);
          }, 5000000);
        };
      } else if (action === "save") {
        pdf.save("handover.pdf");
      }
    }

    onClose();
  };

  const handlePrint = () => {
    setShowConfirmation(true);
  };

  const handleConfirmPrint = () => {
    handlePrintOrSave("print");
    setShowConfirmation(false);
  };

  const handleConfirmSave = () => {
    handlePrintOrSave("save");
    setShowConfirmation(false);
  };

  const handleClose = () => {
    onClose();
  };

  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} | ${hours}:${minutes}`;
  };

  let formattedDate = "N/A"; // Fallback value

  if (defaultData?.printed) {
    const { seconds, nanoseconds } = defaultData.printed;
    const totalMilliseconds = seconds * 1000 + nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);
    formattedDate = formatDate(date);
  }

  return (
    <div className="modal-overlay" data-testid="detail-shipping-mark-modal">
      <div className="modal-content">
        <label>{t("Shipping.Mark.Title")}</label>
        <br />

        <div id="print-content">
          <h1>{defaultData?.shipper || "Unknown Shipper"}</h1>
          <ul className="form-list">
            <li>
              <strong>{t("Handover.Modal.No")}</strong> :{" "}
              {defaultData?.id || "N/A"}
            </li>
            <li>
              <strong>{t("Shipping.Mark.Item")}</strong> :{" "}
              {defaultData?.total || "N/A"}
            </li>
            <li>
              <strong>{t("Shipping.Mark.Printed")}</strong> : {formattedDate}
            </li>
          </ul>
          <hr />
          {/* ini data generate*/}
          <div>
            {defaultData?.orderRef.map((item: any, index: number) => (
              <div key={index}>
                <ul className="form-list2">
                  <li>{item?.ordersn || "N/A"}</li>
                  <li>[{item?.shippingTrackingNumber || "N/A"}]</li>
                </ul>
                {index < defaultData.orderRef.length - 1 && <hr />}
              </div>
            ))}
          </div>
          {/* ini data generate*/}
        </div>

        <div className="button-row">
          <button className="btn btn-light-primary" onClick={handleClose}>
            {t("Common.Button.Cancel")}
          </button>
          <button className="btn btn-primary" onClick={handlePrint}>
            {t("Shipping.Handover.Print")}
          </button>
        </div>
      </div>

      {showConfirmation && (
        <ConfirmationModal
          message="Do you want to print or save the document? Press OK for Print, Cancel for Save."
          onConfirm={handleConfirmPrint}
          onCancel={handleConfirmSave}
        />
      )}
    </div>
  );
};

export default DetailShippinngMarkModal;
