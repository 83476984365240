import "./style/styless.scss";
import { ListProps } from "./models/Models";
import { TableProvider } from "./utils/TableProvider";
import ListView from "./components/list/index";
import ListView2 from "./components/list/indexProduct";

export default function ListComponent<T>({
  collection,
  collectionGroup,
  tabs,
  mapper,
  columns: columnsData,
  selection,
  defaultFilters: filters,
  defaultSorting: sorting,
  useFilter,
  searching,
  extra,
  action,
  childCollection,
  children,
  childColumns, // array of child column [array[0] array[1]
  childIndex, //number
  subcollection, // array of string
  subSubcollection,
}: ListProps<T>) {
  return (
    <TableProvider<T>
      collection={collection}
      mapper={mapper}
      collectionGroup={collectionGroup}
      tabs={tabs}
      columns={columnsData}
      selection={selection}
      filters={filters}
      sorting={sorting}
      searching={searching}
      extra={extra}
      useFilter={useFilter}
      subcollection={subcollection}
      subSubcollection={subSubcollection}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* {collection === "products" && (
          <ListView2<T>
            collection={collection}
            collectionGroup={collectionGroup}
            tabs={tabs}
            mapper={mapper}
            columns={columnsData}
            selection={selection}
            defaultFilters={filters}
            defaultSorting={sorting}
            useFilter={useFilter}
            searching={searching}
            extra={extra}
            action={action}
            childCollection={childCollection}
          />
        )} */}
        {collection !== "products" && (
          <ListView<T>
            collection={collection}
            collectionGroup={collectionGroup}
            tabs={tabs}
            mapper={mapper}
            columns={columnsData}
            selection={selection}
            defaultFilters={filters}
            defaultSorting={sorting}
            useFilter={useFilter}
            searching={searching}
            extra={extra}
            action={action}
            childCollection={childCollection}
          />
        )}
      </div>
    </TableProvider>
  );
}
